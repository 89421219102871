import Paginator from '@nord/ui/src/components/Paginator'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import useFlow from '../../../../hooks/useFlow'
import { completeCurrentPortfolioKyc } from '../../../../store/current/portfolioId/actions'
import { fetchCurrentUser } from '../../../../store/current/user'
import { progressChanged } from '../../../../store/ui/progressSlice'
import { pushEventToDataLayer } from '../../../../utilities/pushToDataLayer'

import ProofOfIdentityPage from './ProofOfIdentityPage'
import ProofOfResidencyPage from './ProofOfResidencyPage'

const KYCVerification = ({ ...props }) => {
  const dispatch = useDispatch()
  const flow = useFlow()

  const handleComplete = useCallback(async () => {
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
    const updatedValues = await dispatch(completeCurrentPortfolioKyc())

    // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type '(dispatch: a... Remove this comment to see the full error message
    const { id, expectedInvestment } = updatedValues

    pushEventToDataLayer({
      event: 'kyc_obtained',
      userID: id,
      expectedInvestment,
      flow,
    })
  }, [dispatch, flow])

  const beforePageChange = ({ number, count }: any) => {
    const progress = number / count

    dispatch(fetchCurrentUser())
    dispatch(progressChanged(progress))
  }

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; onFinalSubmit: () => ... Remove this comment to see the full error message
    <Paginator.Form.Wrapper
      onFinalSubmit={handleComplete}
      beforePageChange={beforePageChange}
      navigationComponent={false}
      showPagination
      {...props}
    >
      <ProofOfIdentityPage />
      <ProofOfResidencyPage />
    </Paginator.Form.Wrapper>
  )
}

KYCVerification.ProofOfIdentity = ProofOfIdentityPage
KYCVerification.ProofOfResidency = ProofOfResidencyPage

KYCVerification.defaultProps = {
  pageCount: 2,
}

export default KYCVerification
