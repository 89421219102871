import React from 'react'

import ReferralContext from './ReferralContext'
import useReferral from './useReferral'

export interface ReferralWrapperProps {
  children: React.ReactNode
}

const ReferralWrapper = ({ children }: ReferralWrapperProps) => {
  const referralData = useReferral()

  return <ReferralContext.Provider value={referralData}>{children}</ReferralContext.Provider>
}

export default ReferralWrapper
