import callIntercom from '@nord/ui/src/utilities/callIntercom'
import Cookies from 'js-cookie'

const trackIntercomEvents = () => {
  callIntercom('trackEvent', 'email-investmentplan')

  const utm = Cookies.getJSON('utm')

  if (utm !== undefined && utm.source === 'mybanker') {
    callIntercom('trackEvent', 'my-banker')
  }
}

export default trackIntercomEvents
