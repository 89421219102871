import Loader from '@nord/ui/src/components/Loader'
import { getConfig } from '@nord/ui/src/configuration'
import useApi, { useApiRequest } from '@nord/ui/src/hooks/useApi'
import { numberToPercent, numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import classnames from 'classnames'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import mapValues from 'lodash/mapValues'
import meanBy from 'lodash/meanBy'
import round from 'lodash/round'
import React, { useContext } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Breakpoint from '../../../../Breakpoint'
import { useReferralContext } from '../../../Referral/ReferralContext'

import ResultPageContext from './ResultPageContext'
import Section from './Section'

const pensionMinimumInvestment = getConfig('numbers.minimumInvestment.pension')

export interface FeesLoaderProps {
  children: string
  loadingFeesData: boolean
}

const FeesLoader = ({ loadingFeesData, children }: FeesLoaderProps) => {
  if (loadingFeesData) return <Loader className="text-primary font-size-lg" />

  return children
}

export const formatAverageFees = (fees: Record<string, number | number[]>, presicionRound = 0) => {
  if (isEmpty(fees)) return {}

  return mapValues(fees, (fee) => {
    if (isArray(fee)) return round(meanBy(fee), presicionRound)

    return round(fee, presicionRound)
  })
}

const Costs = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'stocksOnly' does not exist on type 'unkn... Remove this comment to see the full error message
  const { stocksOnly, esg, initialInvestment, expectedInvestment, pension } =
    useContext(ResultPageContext)
  const { partnerDiscount } = useReferralContext()

  const pensionInitialInvestment = expectedInvestment || pensionMinimumInvestment
  const investmentValue = pension ? pensionInitialInvestment : initialInvestment
  const portfolioValue = investmentValue || 0

  const fetchFees = useApi('/portfolios/fees')

  const { data: feesData, loading: loadingFeesData } = useApiRequest(fetchFees, {
    autoCall: true,
    enableDebounce: true,
    payload: {
      dataFormat: 'ratios',
      portfolioValue,
      pension,
      esg,
      stocksOnly,
      partnerDiscount,
    },
  })

  const { display: totalsCosts, totalCosts } = feesData || {}

  const formattedTotalCosts = formatAverageFees(totalCosts)

  const { monthly: monthlyCosts } = formattedTotalCosts

  return (
    <Section title={`Totale omkostninger for ${numberToCurrency(investmentValue)}`}>
      {/* @ts-expect-error TS(2322) FIXME: Type '{ children: (large: boolean) => Element; siz... Remove this comment to see the full error message */}
      <Breakpoint size="lg">
        {(large: boolean) => (
          // @ts-expect-error TS(2322) FIXME: Type '{ children: (medium: boolean) => Element; si... Remove this comment to see the full error message
          <Breakpoint size="md">
            {(medium: boolean) => {
              let sizeClass
              if (large) {
                sizeClass = 'h4'
              } else if (medium) {
                sizeClass = 'h5'
              } else {
                sizeClass = 'h6'
              }

              return (
                <>
                  <Row>
                    <Col>
                      <p>
                        Lave omkostninger er meget vigtigt for din
                        {pension ? ' pensionsopsparing. ' : ' investering. '}
                        Derfor har vi sammensat en portefølje med udgangspunkt i stor spredning og
                        lave omkostninger. Vores totalomkostning dækker alle omkostninger til os,
                        Saxo Bank og de fonde du bliver investeret i.
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex flex-row justify-content-center align-items-center">
                      {/* @ts-expect-error TS(2786) FIXME: 'FeesLoader' cannot be used as a JSX component. */}
                      <FeesLoader loadingFeesData={loadingFeesData}>
                        <span className={classnames('text-primary', 'mb-0', sizeClass)}>
                          {`${numberToPercent(totalsCosts)} pr. år`}
                        </span>
                      </FeesLoader>
                      <span className="text-dark font-size-md mx-2">svarende til</span>
                      {/* @ts-expect-error TS(2786) FIXME: 'FeesLoader' cannot be used as a JSX component. */}
                      <FeesLoader loadingFeesData={loadingFeesData}>
                        <span className={classnames('text-primary', 'mb-0', sizeClass)}>
                          {`${numberToCurrency(monthlyCosts)}/md.`}
                        </span>
                      </FeesLoader>
                    </Col>
                  </Row>
                </>
              )
            }}
          </Breakpoint>
        )}
      </Breakpoint>
    </Section>
  )
}

export default Costs
