export const portfolioSharedCreateAttributeWhitelist = ['expectedInvestment', 'investmentProfileId']

export const portfolioPensionCreateAttributeWhitelist = [
  ...portfolioSharedCreateAttributeWhitelist,
  'advisedRiskScore',
  'pensionDepot',
  'depotType',
  'escalation',
  'migrationDepot',
  'migrationDepotCompanyName',
  'migrationPensionCompanyType',
  'migrationDepotAccountNumber',
  'migrationDepotRegistrationNumber',
  'migrationDepotNumber',
  'migrationPensionPoliceNumber',
  'pensionsInfoMigration',
  'kreditdataId',
  'cpr',
  'investmentProfileAttributes',
]

export const portfolioCreateAttributeWhitelist = [
  ...portfolioSharedCreateAttributeWhitelist,
  ...portfolioPensionCreateAttributeWhitelist,
]

export const portfolioUpdateAttributeWhitelist = [
  ...portfolioCreateAttributeWhitelist,
  'riskScore',
  'esg',
]
