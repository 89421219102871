import Paginator, { usePaginationContext } from '@nord/ui/src/components/Paginator'
import React from 'react'

export interface Props {
  returnButton?: React.ReactNode
}

const OnboardingNavigation = ({ returnButton }: Props) => {
  const pagination = usePaginationContext()
  const { absolute } = pagination
  const { first } = absolute || pagination
  const firstWithReturn = first && returnButton

  return (
    <Paginator.Form.Navigation
      // @ts-expect-error TS(2322) FIXME: Type '{ previousButton: string | number | true | R... Remove this comment to see the full error message
      previousButton={firstWithReturn ? returnButton : undefined}
      nextButton={firstWithReturn ? false : undefined}
      showOnFirst
      showOnLast
    />
  )
}

OnboardingNavigation.defaultProps = {
  returnButton: undefined,
}

export default OnboardingNavigation
