import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import useQuery from '@nord/ui/src/hooks/useQuery'
import React, { useEffect } from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useHistory } from 'react-router-dom'

import { PATHS } from '../../../configuration/constants'
import useRedirectPath from '../../../hooks/useRedirectPath'
import useSignedIn from '../../../hooks/useSignedIn'
import { selectCurrentUser } from '../../../store/current/user'
import ContentContainer from '../ContentContainer'

import DjofContent from './DjofContent'
import FormContent from './FormContent'
import NotSuitableReferralsContent from './NotSuitableReferralsContent'
import { useReferralContext } from './ReferralContext'
import ReferralsContent from './ReferralsContent'

const ReferralPage = () => {
  const history = useHistory()
  const isSignedIn = useSignedIn()
  const redirectPath = useRedirectPath()
  const { referralIsValid, referralData, onApproveReferral, onChangeReferralToken } =
    useReferralContext()

  // @ts-expect-error TS(2339) FIXME: Property 'onboardingState' does not exist on type ... Remove this comment to see the full error message
  const { onboardingState: userOnboardingState } = useObjectSelector(selectCurrentUser)
  const userIsSaxoOnboarded = userOnboardingState === 'saxo_onboarded'

  const referralTokenQuery = useQuery('referralToken')
  const loginPath = `${PATHS.shared.userSignIn()}?redirect_to=${PATHS.shared.referral(
    referralTokenQuery,
  )}`

  const { name } = referralData || {}

  const pathToRedirect = isSignedIn ? redirectPath : PATHS.shared.root()

  const handleAccept = () => {
    onApproveReferral()
    history.push(pathToRedirect)
  }

  useEffect(() => {
    if (!referralTokenQuery) return
    if (userIsSaxoOnboarded) return
    onChangeReferralToken(referralTokenQuery)
  }, [onChangeReferralToken, userIsSaxoOnboarded, referralTokenQuery])

  let content
  if (isSignedIn && userIsSaxoOnboarded)
    content = <NotSuitableReferralsContent pathToRedirect={pathToRedirect} />
  else if (referralIsValid && name === 'Djøf')
    content = <DjofContent onAccept={handleAccept} loginPath={loginPath} />
  else if (referralIsValid)
    content = <ReferralsContent onAccept={handleAccept} loginPath={loginPath} />
  else content = <FormContent />

  return <ContentContainer>{content}</ContentContainer>
}

export default ReferralPage
