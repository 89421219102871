import { isObject, isArray } from 'lodash'

import { calculateStepRatiosFromStepsObject, calculateNearest } from './utilities'

const calculateWithObjectSteps = ({ ratio, steps }: any) => {
  const stepRatios = calculateStepRatiosFromStepsObject(steps)
  const nearestRatio = calculateNearest(stepRatios, ratio)

  return steps[nearestRatio]
}

const calculateWithArraySteps = ({ ratio, steps }: any) => {
  const stepsCount = steps.length - 1
  const stepIndex = Math.round(ratio * stepsCount)
  const value = steps[stepIndex]

  return value
}

const calculateWithoutSteps = ({ ratio, min, max }: any) => {
  const range = max - min
  const value = ratio * range + min

  return value
}

const calculateWithStep = ({ ratio, step, min, max }: any) => {
  const value = calculateWithoutSteps({ ratio, min, max })
  const roundedValue = Math.round(value / step) * step

  return roundedValue
}

const generalRatioToValue =
  ({ min, max, step, steps }: any) =>
  (ratio: any) => {
    if (isArray(steps)) return calculateWithArraySteps({ ratio, steps })
    if (isObject(steps)) return calculateWithObjectSteps({ ratio, steps })
    if (step !== undefined) return calculateWithStep({ ratio, step, min, max })

    return calculateWithoutSteps({ ratio, min, max })
  }

export default generalRatioToValue
