import Paginator from '@nord/ui/src/components/Paginator'
import useIsSignedIn from '@nord/ui/src/hooks/useIsSignedIn'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { unwrapResult } from '@reduxjs/toolkit'
import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Redirect } from 'react-router-dom'

import { PATHS } from '../../../../configuration/constants'
import useFlow from '../../../../hooks/useFlow'
import { createInvestmentProfile } from '../../../../store/current/investmentProfile'
import {
  selectCurrentPortfolio,
  completeCurrentPortfolioInvestmentPlan,
} from '../../../../store/current/portfolioId'
import { fetchCurrentUser } from '../../../../store/current/user'
import { progressChanged } from '../../../../store/ui/progressSlice'

import ExpectedInvestmentSection from './ExpectedInvestmentSection'
import QuestionnaireSection from './QuestionnaireSection'
import ResultPage from './ResultPage'

const InvestmentPlan = () => {
  const dispatch = useDispatch()
  const [completed, setCompleted] = useState(false)
  const [newPortfolioId, setNewPortfolioId] = useState()
  const [answerData, setAnswerData] = useState()
  const isSignedIn = useIsSignedIn()
  const flow = useFlow()
  // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'unknown'.
  const { id: portfolioId } = useObjectSelector(selectCurrentPortfolio)

  const handleCompleteQuestions = (newAnswers: any, version: any, type: any) => {
    setAnswerData({
      // @ts-expect-error TS(2345) FIXME: Argument of type '{ answers: any; version: any; ty... Remove this comment to see the full error message
      answers: newAnswers,
      version,
      type,
    })
  }

  const handleCompleteSuitabilityTest = useCallback(
    async ({ expectedInvestment }: any) => {
      await dispatch(
        // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
        createInvestmentProfile({
          flow,
          attributes: {
            // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
            ...answerData,
            expectedInvestment,
          },
        }),
      )

      const newPortfolioAction = await dispatch(
        completeCurrentPortfolioInvestmentPlan({
          expectedInvestment,
        }),
      )

      // @ts-expect-error TS(2345) FIXME: Argument of type '(dispatch: any, getState: any) =... Remove this comment to see the full error message
      const newPortfolioValues = unwrapResult(newPortfolioAction)

      setNewPortfolioId(newPortfolioValues.id)
      setCompleted(true)
    },
    [answerData, dispatch, flow],
  )

  const beforePageChange = ({ number, count }: any) => {
    const progress = number / count

    dispatch(progressChanged(progress))

    if (!isSignedIn) return

    // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
    dispatch(fetchCurrentUser({ errorHandling: { ignore: { unauthorized: true } } }))
  }

  return (
    <>
      {newPortfolioId ? (
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        <Redirect to={PATHS[flow].investmentPlanResult(newPortfolioId)} />
      ) : (
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        completed && <Redirect to={PATHS[flow].investmentPlanResult(portfolioId)} />
      )}
      {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; showPagination: true;... Remove this comment to see the full error message */}
      <Paginator.Nested.Wrapper.WithQueryParam showPagination useAbsolute>
        <QuestionnaireSection
          // @ts-expect-error TS(2322) FIXME: Type '{ beforePageChange: ({ number, count }: any)... Remove this comment to see the full error message
          beforePageChange={beforePageChange}
          onComplete={handleCompleteQuestions}
        />
        <ExpectedInvestmentSection onSubmit={handleCompleteSuitabilityTest} />
      </Paginator.Nested.Wrapper.WithQueryParam>
    </>
  )
}

InvestmentPlan.ResultPage = ResultPage

export default InvestmentPlan
