import React from 'react'

import { requireOneOf } from '../../utilities/propTypes'

const DEFAULT_HEIGHT = 40

export interface Props {
  alt?: string
  children?: React.ReactNode
  height?: number
  href: string
  src?: any // TODO: requireOneOf(PropTypes.string, 'children')
}

const Basic = ({ alt, children, height, href, src, ...otherProps }: Props) => (
  <a href={href} {...otherProps}>
    {children ? (
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      React.cloneElement(React.Children.only(children), {
        alt,
        height,
      })
    ) : (
      <img alt={alt} src={src} height={height} />
    )}
  </a>
)

Basic.defaultProps = {
  alt: undefined,
  children: undefined,
  height: DEFAULT_HEIGHT,
  src: undefined,
}

export default Basic
