import BaffleButton from '@nord/ui/src/components/BaffleButton'
import Form, { phoneInitialValue } from '@nord/ui/src/components/Form'
import useApi from '@nord/ui/src/hooks/useApi'
import useQuery from '@nord/ui/src/hooks/useQuery'
import queryString from 'query-string'
import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import { SHARED_PATHS, URLS } from '../../../../configuration/constants'

import validationSchema from './validationSchema'

const labels = {
  firstName: 'Fornavn (inkl. mellemnavne)',
  lastName: 'Efternavn',
  email: 'Email',
  phone: 'Telefon',
}

const initialValues = phoneInitialValue

export interface Props {
  buttonText: string
  errors: any // TODO: PropTypes.shape
  loading: boolean
}

const FormContent = ({ errors, buttonText, loading, ...otherProps }: Props) => {
  const [userExists, setUserExists] = useState(false)
  const [emailValue, setEmailValue] = useState(null)
  const redirectTo = useQuery('redirectTo')

  const checkEmail = useApi('/users/emails/:email', {
    errorHandling: {
      ignore: {
        client: true,
      },
    },
  })

  const handleBlurEmailInput = async (event: any) => {
    const {
      target: { value: email },
    } = event

    setEmailValue(email)

    if (!email) return

    const { success, status } = await checkEmail(undefined, {
      pathParams: { email },
    })

    if (success) setUserExists(true)
    else if (status === 404) setUserExists(false)
  }

  const { firstName, lastName, email, phone } = errors || {}
  const signInLinkTo = {
    pathname: SHARED_PATHS.userSignIn(),
    search: emailValue
      ? `?${queryString.stringify({
          email: emailValue,
          // eslint-disable-next-line camelcase
          redirect_to: redirectTo,
        })}`
      : undefined,
  }

  return (
    // @ts-expect-error TS(2741) FIXME: Property 'onSubmit' is missing in type '{ children... Remove this comment to see the full error message
    <Form
      {...otherProps}
      labelDescriptions={labels}
      validationSchema={validationSchema}
      initialValues={initialValues}
      fields={[...Object.keys(labels), ...Object.keys(initialValues)]}
    >
      <Row>
        <Col xs={12} sm={6}>
          {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; name: string; errors: a... Remove this comment to see the full error message */}
          <Form.FieldGroup name="firstName" errors={firstName}>
            <Form.Field />
          </Form.FieldGroup>
        </Col>
        <Col xs={12} sm={6}>
          {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; name: string; errors: a... Remove this comment to see the full error message */}
          <Form.FieldGroup name="lastName" errors={lastName}>
            <Form.Field />
          </Form.FieldGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; name: string; errors: a... Remove this comment to see the full error message */}
          <Form.FieldGroup name="phone" errors={phone}>
            <Form.Fields.Phone />
          </Form.FieldGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Form.FieldGroup name="email">
            {/* @ts-expect-error TS(2322) FIXME: Type '{ type: string; onBlur: (event: any) => Prom... Remove this comment to see the full error message */}
            <Form.Field type="email" onBlur={handleBlurEmailInput} errors={email} />
          </Form.FieldGroup>
          {userExists && (
            <p className="pt-1">
              <span className="text-danger" data-cy="validation-error">
                Det ser ud til at du allerede er oprettet med denne email. Vil du logge ind i
                stedet?
              </span>
              <BaffleButton
                variant="link"
                className="p-1"
                as={Link}
                type={undefined}
                to={signInLinkTo}
              >
                Gå til login
              </BaffleButton>
            </p>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <p>
            Norm Invest må kontakte mig på mail og telefon med tilbud og generel viden om
            investering. Du kan til enhver tid framelde dig.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="text-center pt-3">
          {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; disabled: boolean; varia... Remove this comment to see the full error message */}
          <Form.SubmitButton disabled={userExists} variant="primary" loading={loading} loadingIcon>
            {buttonText}
          </Form.SubmitButton>
          <br />
          <Button
            variant="link"
            className="mt-4 text-base"
            as={Link}
            type={undefined}
            to={signInLinkTo}
          >
            Allerede bruger? Log ind her
          </Button>
          <br />
          <span className="mb-0">
            Vi behandler dine data sikkert i henhold til vores{' '}
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a href={URLS.privacyPolicy} target="_blank">
              privatlivspolitik
            </a>
            .
          </span>
        </Col>
      </Row>
    </Form>
  )
}

export default FormContent
