import BaffleButton from '@nord/ui/src/components/BaffleButton'
import { selectCurrentPortfolioId } from '@nord/ui/src/store/current/portfolioId'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import { PATHS } from '../../../../../../../configuration/constants'
import useFlow from '../../../../../../../hooks/useFlow'
import ResultPageContext from '../../ResultPageContext'

const UpdateRiskScore = ({ ...otherProps }) => {
  const flow = useFlow()
  const portfolioId = useSelector(selectCurrentPortfolioId)
  // @ts-expect-error TS(2339) FIXME: Property 'onUpdateRiskProfile' does not exist on t... Remove this comment to see the full error message
  const { onUpdateRiskProfile } = useContext(ResultPageContext)

  return (
    <BaffleButton
      variant="success"
      onClick={onUpdateRiskProfile}
      as={Link}
      type={undefined}
      // @ts-expect-error TS(2322) FIXME: Type '{ children: string; variant: string; onClick... Remove this comment to see the full error message
      to={PATHS[flow].investmentPlanResult(portfolioId)}
      filled
      {...otherProps}
    >
      Ændr din risikoprofil
    </BaffleButton>
  )
}

export default UpdateRiskScore
