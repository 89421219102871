import BooleanInput from '@nord/ui/src/components/BooleanInput'
import Form from '@nord/ui/src/components/Form'
import { useFormikContext } from 'formik'
import pick from 'lodash/pick'
import React, { useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import NestedFields from '../../../../NestedFields'
import initialValues from '../initialValues'

export const inputsKeys = ['americanCitizen', 'americanTaxpayer', 'americanTaxNumber']
const defaultValues = pick(initialValues, inputsKeys)

const CitizenOrTaxpayerInTheUSA = () => {
  const {
    // @ts-expect-error TS(2339) FIXME: Property 'americanTaxpayer' does not exist on type... Remove this comment to see the full error message
    values: { americanTaxpayer, americanCitizen },
    setFieldValue,
  } = useFormikContext()

  const [open, setOpen] = useState(americanCitizen || americanTaxpayer)
  const [openTaxpayer, setOpenTaxpayer] = useState(americanTaxpayer)

  const handleToggle = (newChecked: any) => {
    if (!newChecked) {
      Object.entries(defaultValues).forEach(([key, value]) => setFieldValue(key, value))

      setOpenTaxpayer(false)
    }

    setOpen(newChecked)
  }

  const handleToggleTaxpayer = (newChecked: any) => setOpenTaxpayer(newChecked)

  return (
    <Row>
      <Col xs={12}>
        <NestedFields.Container>
          <Form.FieldGroup name="citizenOrTaxpayerUSA" label={false}>
            {/* @ts-expect-error TS(2739) FIXME: Type '{ ({ id, name, label, type, onChange, onValu... Remove this comment to see the full error message */}
            <Form.Field inputComponent={BooleanInput} onCheckedChange={handleToggle} value={open} />
          </Form.FieldGroup>
          {open && (
            <NestedFields.FormGroup>
              <Form.FieldGroup name="americanCitizen" label={false}>
                {/* @ts-expect-error TS(2322) FIXME: Type '{ ({ id, name, label, type, onChange, onValu... Remove this comment to see the full error message */}
                <Form.Field inputComponent={BooleanInput} />
              </Form.FieldGroup>
              <Form.FieldGroup name="americanTaxpayer" label={false}>
                {/* @ts-expect-error TS(2322) FIXME: Type '{ ({ id, name, label, type, onChange, onValu... Remove this comment to see the full error message */}
                <Form.Field inputComponent={BooleanInput} onCheckedChange={handleToggleTaxpayer} />
              </Form.FieldGroup>
              {openTaxpayer && (
                <Form.FieldGroup name="americanTaxNumber">
                  <Form.Field />
                </Form.FieldGroup>
              )}
            </NestedFields.FormGroup>
          )}
        </NestedFields.Container>
      </Col>
    </Row>
  )
}

export default CitizenOrTaxpayerInTheUSA
