import { selectPortfolios } from '@nord/ui/src/store/current/portfolios'
import { createSelector } from '@reduxjs/toolkit'
import filter from 'lodash/filter'
import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'

export const selectPortfoliosInOnboarding = (state: any) => {
  const portfolios = selectPortfolios(state)

  // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
  return filter(portfolios, (portfolio) => !portfolio.readyToOnboardAt)
}

export const selectOnboardingPortfolioIds = createSelector([selectPortfolios], (portfolios) => {
  const portfoliosValues = Object.values(portfolios)
  // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
  const filteredPortfolios = filter(portfoliosValues, (portfolio) => !portfolio.readyToOnboardAt)
  const portfoliosByOnboardingFlow = groupBy(
    filteredPortfolios,
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    (portfolio) => portfolio.onboardingFlow,
  )
  const portfolioIdsByOnboardingFlow = mapValues(
    portfoliosByOnboardingFlow,
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    (portfolio) => portfolio[0].id,
  )

  return portfolioIdsByOnboardingFlow
})

export * from '@nord/ui/src/store/current/portfolios/selectors'
