let lastCount = 0

export const resetCount = () => {
  lastCount = 0
}

const nextCount = () => {
  lastCount += 1

  return lastCount
}

export const currentCount = () => lastCount

export default nextCount
