import BooleanCollection from '@nord/ui/src/components/BooleanCollection'
import Form from '@nord/ui/src/components/Form'
import Loader from '@nord/ui/src/components/Loader'
import Text from '@nord/ui/src/components/Text'
import Transition from '@nord/ui/src/components/Transition'
import useApi, { useApiRequest } from '@nord/ui/src/hooks/useApi'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import NestedFields from '../../../NestedFields'
import Referral from '../../Referral'

export interface Props {
  onIsTokenValidChange: (...args: any[]) => any
}

const FormContent = ({ onIsTokenValidChange }: Props) => {
  const [showModal, setShowModal] = useState(false)
  const handleShowModal = () => setShowModal(true)
  const handleHideModal = () => setShowModal(false)

  const fetchReferralOptions = useApi('/reference_data/users/lead_sources', {
    method: 'GET',
  })

  const { data: leadSourcesOptions, loading } = useApiRequest(fetchReferralOptions, {
    autoCall: true,
  })

  const validateTokenApi = useApi('/onboarding/referral_tokens', {
    method: 'GET',
    errorHandling: {
      ignore: {
        client: true,
      },
    },
  })

  const {
    request: validateToken,
    data: validateTokenData,
    success,
  } = useApiRequest(validateTokenApi, {
    enableDebounce: true,
    debounceTimeout: 500,
  })

  const { amount, name, depositLimit } = validateTokenData || {}

  useEffect(() => {
    onIsTokenValidChange(success)
  }, [onIsTokenValidChange, success])

  const handleTokenChange = (value: any) => {
    const formattedValue = value === 'djof' ? '' : value

    validateToken({
      referralToken: formattedValue,
    })
  }

  return (
    <>
      <Row>
        <Col xs={12}>
          <NestedFields.Container>
            {loading ? (
              <Loader className="font-size-lg text-center" />
            ) : (
              <Form.FieldGroup name="leadSources">
                {/* @ts-expect-error TS(2739) FIXME: Type '{ ({ name, options, onChange, onValueChange,... Remove this comment to see the full error message */}
                <Form.Field inputComponent={BooleanCollection} options={leadSourcesOptions} />
              </Form.FieldGroup>
            )}
            <Form.FieldGroup name="referralToken">
              <Form.Field onValueChange={handleTokenChange} />
            </Form.FieldGroup>
            <Referral.TermsModal
              show={showModal}
              onHide={handleHideModal}
              referralData={validateTokenData}
            />
            <Transition.Expand in={success}>
              <Text as="p" size="md">
                Du er blevet inviteret af <strong className="text-primary">{name}</strong> og vil få
                sat <strong className="text-primary">{numberToCurrency(amount)}</strong>
                ind på din investeringskonto, når du har haft investeret{' '}
                {numberToCurrency(depositLimit)} i mere end en måned.{' '}
                <Button variant="link" className="text-base p-0" onClick={handleShowModal}>
                  Læs alle betingelser her.
                </Button>
              </Text>
            </Transition.Expand>
          </NestedFields.Container>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="text-center py-3">
          {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; variant: string; }' is n... Remove this comment to see the full error message */}
          <Form.SubmitButton variant="primary">Indsend</Form.SubmitButton>
        </Col>
      </Row>
    </>
  )
}

export default FormContent
