import AlertModal from '@nord/ui/src/components/AlertModal'
import Form from '@nord/ui/src/components/Form'
import Paginator from '@nord/ui/src/components/Paginator'
import Select from '@nord/ui/src/components/Select'
import useApi from '@nord/ui/src/hooks/useApi'
import { changeCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import classNames from 'classnames'
import { useFormikContext } from 'formik'
import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useDispatch, useSelector } from 'react-redux'
import { object, boolean, string } from 'yup'

import {
  changeCurrentPortfolioDepotType,
  selectCurrentPortfolio,
} from '../../../../store/current/portfolioId'
import { selectCurrentUser } from '../../../../store/current/user'
import ContentTitle from '../../ContentTitle'
import { migrationOptions } from '../InvestmentPlan/SelectTypePage'

export const migrationOptionsRequirement = {
  livrente: () => false,
  ratepension: () => true,
  aldersopsparing: (migration: any) => !!migration,
  kapitalpension: (migration: any) => !!migration,
}

const validationSchema = object().shape({
  migrationDepot: boolean().required('Skal vælges'),
  depotType: string().when('migrationDepot', {
    is: false,
    then: string()
      .oneOf(
        ['ratepension'],
        'I øjeblikket kan du kun oprette en ny ratepension. Du kan dog flytte andre pensioner til nord.',
      )
      .required(),
    otherwise: string().oneOf(['ratepension', 'kapitalpension', 'aldersopsparing']).required(),
  }),
})

const labels = {
  migrationDepot: 'Jeg ønsker at overflytte et eksisterende pensionsdepot.',
  depotType: 'Pensionstype',
}

const fields = Object.keys(labels)

const ChooseMigrationOrNewPage = (otherProps: any) => {
  const dispatch = useDispatch()
  const { birthdate } = useSelector(selectCurrentUser)
  const { depotType } = useSelector(selectCurrentPortfolio)
  const [unconfirmedDepotType, setUnconfirmedDepotType] = useState()
  const {
    setFieldValue,
    // @ts-expect-error TS(2339) FIXME: Property 'migrationDepot' does not exist on type '... Remove this comment to see the full error message
    values: { migrationDepot },
  } = useFormikContext()
  const getPensionRiskScore = useApi('/risk_profile/pension_risk_data')

  const handleChangeDepotType = async () => {
    await getPensionRiskScore({ birthdate, depotType: unconfirmedDepotType })
    await dispatch(changeCurrentPortfolioDepotType(unconfirmedDepotType))
  }

  // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
  const handleRejectWarning = () => setUnconfirmedDepotType()

  const options = migrationOptions.map((option) => ({
    ...option,
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    isDisabled: !migrationOptionsRequirement[option.value](migrationDepot !== false),
  }))

  const handleChangeMigrationDepot = (newValue: any) => {
    setFieldValue('migrationDepot', newValue, true)
    dispatch(changeCurrentPortfolio({ migrationDepot: newValue }))
  }

  const handleSelectDepotType = (newDepotType: any) => {
    if (
      depotType !== newDepotType &&
      (depotType === 'ratepension' || newDepotType === 'ratepension')
    ) {
      setUnconfirmedDepotType(newDepotType)

      return depotType
    }

    return newDepotType
  }

  return (
    <Paginator.Form.Page
      validationSchema={validationSchema}
      fields={fields}
      labelDescriptions={labels}
      {...otherProps}
    >
      <ContentTitle title="Ny eller eksisterende pension?" />
      <Row>
        <Col>
          <div className="mb-2">Jeg ønsker at:</div>
          <ButtonGroup className="text-center">
            <Button
              variant="primary"
              onClick={() => handleChangeMigrationDepot(false)}
              className={classNames('mr-2', { 'btn-filled': migrationDepot === false })}
            >
              Oprette ny pension
            </Button>
            <Button
              variant="primary"
              onClick={() => handleChangeMigrationDepot(true)}
              className={classNames({ 'btn-filled': migrationDepot === true })}
            >
              Flytte en pension
            </Button>
          </ButtonGroup>
          <Form.ErrorMessage name="migrationDepot" />
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="mt-5">
            Vælg den type af pension du ønsker at {migrationDepot ? 'flytte' : 'oprette'}. Du kan
            oprette og flytte flere depoter, når du har færdiggjort denne oprettelse.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.FieldGroup name="depotType">
            <Form.Field
              // @ts-expect-error TS(2739) FIXME: Type '{ ({ onChange, onValueChange, onSelect, opti... Remove this comment to see the full error message
              inputComponent={Select}
              options={options}
              onValueChange={handleSelectDepotType}
            />
          </Form.FieldGroup>
        </Col>
      </Row>
      <AlertModal
        show={!!unconfirmedDepotType}
        title="Skift af pension"
        onHide={handleRejectWarning}
        onAccept={handleChangeDepotType}
        acceptButtonText="Jeg ønsker at skifte"
        acceptable
      >
        <p>
          Du er ved at skifte fra <strong>{depotType}</strong> til en{' '}
          <strong>{unconfirmedDepotType}</strong>, som har betydning for din risikoprofil. Dette
          kræver derfor at du godkender en ny investeringsplan. Hvis du i stedet beholder din{' '}
          {depotType} oprettelse, vil det ikke længere være muligt at skifte.
        </p>
        <p>Vil du gå til din investeringsplan for en {unconfirmedDepotType}?</p>
      </AlertModal>
    </Paginator.Form.Page>
  )
}

export default ChooseMigrationOrNewPage
