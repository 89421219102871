import { useEffect } from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useHistory } from 'react-router-dom'

import { PATHS } from '../configuration/constants'

import useRedirectPath from './useRedirectPath'
import useSignedIn from './useSignedIn'

// @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
const ignoredRedirectPaths = [PATHS.shared.referral()]

const useRedirect = () => {
  const redirectPath = useRedirectPath()
  const history = useHistory()
  const signedIn = useSignedIn()

  const {
    location: { pathname: locationPathName },
  } = useHistory()

  const ignoreRedirect = ignoredRedirectPaths.includes(locationPathName)

  useEffect(() => {
    if (!signedIn) return
    if (!redirectPath) return
    if (ignoreRedirect) return

    history.push(redirectPath)
  }, [history, ignoreRedirect, redirectPath, signedIn])
}

export default useRedirect
