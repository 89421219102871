// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'airb... Remove this comment to see the full error message
import { ref as refPropType } from 'airbnb-prop-types'
import React from 'react'

export interface Props {
  fill?: boolean
  percentage: number
  onStart: (...args: any[]) => any
  onEnd: (...args: any[]) => any
  onClickOrDrag: (...args: any[]) => any
  onBlur: (...args: any[]) => any
  onFocus: (...args: any[]) => any
  sliderRef: any // TODO: refPropType()
  handleRef: any // TODO: refPropType()
}

const Horizontal = ({
  fill,
  percentage,
  onStart,
  onEnd,
  onClickOrDrag,
  onFocus,
  onBlur,
  sliderRef,
  handleRef,
}: Props) => {
  const fillStyles = {
    width: `${percentage}%`,
  }

  const handleStyle = {
    left: `${percentage}%`,
  }

  return (
    <div
      ref={sliderRef}
      className="custom-range"
      onMouseDown={onStart}
      onMouseUp={onEnd}
      onTouchMove={onClickOrDrag}
      onTouchEnd={onEnd}
      role="button"
      tabIndex={0}
    >
      <div className="custom-range-track">
        {fill && <div className="custom-range-fill" style={fillStyles} />}
      </div>
      {/* eslint-disable jsx-a11y/control-has-associated-label */}
      <div
        className="custom-range-handle"
        onMouseDown={onStart}
        onMouseUp={onEnd}
        onTouchMove={onClickOrDrag}
        onTouchEnd={onEnd}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={handleRef}
        style={handleStyle}
        role="button"
        tabIndex={0}
      />
    </div>
  )
}

Horizontal.defaultProps = {
  fill: undefined,
}

Horizontal.displayName = 'RangeInput.Horizontal'

export default Horizontal
