import BaffleButton from '@nord/ui/src/components/BaffleButton'
import IconButton from '@nord/ui/src/components/IconButton'
import extractTransitionClasses from '@nord/ui/src/utilities/extractTransitionClasses'
import classNames from 'classnames'
import React from 'react'
import Modal from 'react-bootstrap/Modal'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ArrowLeft from 'react-feather/dist/icons/arrow-left'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ArrowRight from 'react-feather/dist/icons/arrow-right'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ExternalLink from 'react-feather/dist/icons/external-link'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import DocumentWrapper from './DocumentWrapper'
import styles from './index.module.scss'

const timeout = parseInt(styles.timeout, 10)

export interface ModalDialogProps {
  document: any // TODO: documentPropType()
  transitionReverse?: boolean
}

const ModalDialog = ({ document, transitionReverse, ...props }: ModalDialogProps) =>
  document.url && (
    <SwitchTransition mode="out-in">
      <CSSTransition
        classNames={extractTransitionClasses({
          styles,
          className: 'documentSwitch',
        })}
        key={document.filename}
        timeout={timeout}
      >
        <Modal.Dialog {...props} className={styles.documentSwitch} />
      </CSSTransition>
    </SwitchTransition>
  )

ModalDialog.defaultProps = {
  transitionReverse: undefined,
}

export interface DocumentModalProps {
  allDocumentsRead?: boolean
  document: any // TODO: documentPropType()
  onClosed?: (...args: any[]) => any
  onHide?: (...args: any[]) => any
  onNext?: (...args: any[]) => any
  onPrevious?: (...args: any[]) => any
  show?: boolean
  transitionReverse?: boolean
}

const DocumentModal = ({
  show,
  onHide,
  document,
  onClosed,
  onNext,
  onPrevious,
  transitionReverse,
  allDocumentsRead,
}: DocumentModalProps) => (
  <Modal
    show={show}
    onHide={onHide}
    onExited={onClosed}
    className={classNames(styles.documentsModal, 'text-dark', {
      [styles.documentSwitchReverse]: transitionReverse,
    })}
    size="lg"
    dialogAs={ModalDialog}
    document={document}
    transitionReverse={transitionReverse}
  >
    <Modal.Header closeButton>
      <Modal.Title className="w-100 text-center mx-auto" data-cy="modal-title">
        {document.filename}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p className="text-center">
        Læs nedenstående dokument igennem, og forsæt derefter ved at trykke "Næste dokument" nederst
        på siden.
      </p>
      <div className={classNames('border', 'mt-4', styles.documentWrapper)}>
        {document.url && <DocumentWrapper document={document} className="w-100 h-100" />}
      </div>
      <div className="text-center mt-2">
        <IconButton
          as="a"
          variant="link"
          icon={ExternalLink}
          href={document.url}
          target="_blank"
          rel="noopener noreferrer"
          iconPlacement="last"
          download
        >
          Hent og gem dokument
        </IconButton>
      </div>
    </Modal.Body>
    <Modal.Footer className="justify-content-center">
      <BaffleButton variant="link" onClick={onHide}>
        Tilbage
      </BaffleButton>
      {allDocumentsRead ? (
        <BaffleButton key="finish" variant="success" onClick={onHide} filled>
          Luk og fortsæt
        </BaffleButton>
      ) : (
        <BaffleButton key="next" variant="success" onClick={onNext} filled>
          Næste dokument
        </BaffleButton>
      )}
    </Modal.Footer>
    <div className="d-none d-lg-block">
      <IconButton
        icon={ArrowLeft}
        className={classNames(styles.documentsArrow, styles.documentsArrowLeft, 'btn-round')}
        onClick={onPrevious}
      />
      <IconButton
        icon={ArrowRight}
        className={classNames(styles.documentsArrow, styles.documentsArrowRight, 'btn-round')}
        onClick={onNext}
      />
    </div>
  </Modal>
)

DocumentModal.defaultProps = {
  allDocumentsRead: undefined,
  onClosed: undefined,
  onHide: undefined,
  onNext: undefined,
  onPrevious: undefined,
  show: undefined,
  transitionReverse: undefined,
}

export default DocumentModal
