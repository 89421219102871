import { subMonths, isEqual } from 'date-fns'
import React from 'react'

import formatDate from '../../utilities/formatDate'
import { numberToPercent } from '../../utilities/numberFormatter'

import styles from './SelectionLabel.module.scss'

export interface Props {
  returnValue: number
  startDate: any // TODO: PropTypes.instanceOf(Date)
  endDate: any // TODO: PropTypes.instanceOf(Date)
  viewBox: {
    x?: number
    y?: number
    width?: number
    height?: number
  }
}

const SelectionLabel = ({ viewBox, returnValue, startDate, endDate }: Props) => {
  const positiveReturn = returnValue >= 0
  const colorClass = positiveReturn ? 'positiveLabel' : 'negativeLabel'
  const formattedEndDate = subMonths(endDate, 1)

  let dateRangeText
  if (isEqual(startDate, formattedEndDate)) {
    dateRangeText = formatDate(formattedEndDate, 'MMMM yyyy')
  } else {
    dateRangeText = ` ${formatDate(startDate, 'MMM yyyy')} t.o.m. ${formatDate(
      formattedEndDate,
      'MMM yyyy',
    )}`
  }

  return (
    <g>
      <rect
        className={styles.selectionLabel}
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        x={viewBox.x + viewBox.width / 2 - 150 / 2}
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        y={viewBox.height - 30}
        height="60"
        width="180"
      />
      <text
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        x={viewBox.x + (viewBox.width + 30) / 2}
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        y={viewBox.height - 10}
        textAnchor="middle"
        className={styles[colorClass]}
      >
        {positiveReturn && '+'}
        {numberToPercent(returnValue, { fractionDigits: 1 })}
      </text>
      {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
      <text x={viewBox.x + (viewBox.width + 30) / 2} y={viewBox.height + 15} textAnchor="middle">
        {dateRangeText}
      </text>
    </g>
  )
}

export default SelectionLabel
