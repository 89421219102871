import BaffleButton from '@nord/ui/src/components/BaffleButton'
import IconButton from '@nord/ui/src/components/IconButton'
import React from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

export const BaffleButtonLink = (props: any) => (
  <BaffleButton type={undefined} {...props} as={Link} />
)

const IconButtonLink = (props: any) => <IconButton as={BaffleButtonLink} {...props} />

export default IconButtonLink
