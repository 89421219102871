import classNames from 'classnames'
import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Info from 'react-feather/dist/icons/info'

import useId from '../../hooks/useId'
import extractProps from '../../utilities/extractProps'
import BooleanInput from '../BooleanInput'
import IconButton from '../IconButton'

const overlayPropKeys = [
  'placement',
  'trigger',
  'delay',
  'flip',
  'onHide',
  'onToggle',
  'popperConfig',
]

export interface Props {
  label: React.ReactNode
  name: string
  tooltip?: any
  tooltipText: React.ReactNode
}

const Switch = ({ name, label, tooltipText, tooltip, ...otherProps }: Props) => {
  const id = useId(name)
  const [overlayProps, tooltipButtonProps] = extractProps(tooltip || {}, overlayPropKeys)

  return (
    <BooleanInput
      name={name}
      id={id}
      type="switch"
      label={
        <>
          {label}
          {/* @ts-expect-error TS(2741) FIXME: Property 'id' is missing in type '{ children: Reac... Remove this comment to see the full error message */}
          <OverlayTrigger {...overlayProps} overlay={<Tooltip>{tooltipText}</Tooltip>}>
            <IconButton
              icon={Info}
              variant="link"
              {...tooltipButtonProps}
              // @ts-expect-error TS(2345) FIXME: Argument of type '["p-0", unknown]' is not assigna... Remove this comment to see the full error message
              className={classNames('p-0', tooltipButtonProps.className)}
            />
          </OverlayTrigger>
        </>
      }
      {...otherProps}
    />
  )
}

Switch.defaultProps = {
  tooltip: undefined,
}

export default Switch
