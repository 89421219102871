import classNames from 'classnames'
import React from 'react'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'

import { selectInitialLoading } from '../../store/current/loading'
import LoadingIcon from '../LoadingIcon'

import styles from './ContentContainer.module.scss'

export interface Props {
  children: React.ReactNode
  transitionEntering?: boolean
  transitionDirection?: string
  className?: string
}

const ContentContainer = ({
  children,
  transitionEntering,
  transitionDirection,
  className,
  ...otherProps
}: Props) => {
  const loading = useSelector(selectInitialLoading)

  return (
    <Row>
      <Col
        xs={12}
        md={{ offset: 1, span: 10 }}
        lg={{ offset: 2, span: 8 }}
        xl={{ offset: 3, span: 6 }}
        className={classNames('px-2', 'px-sm-3', className)}
        {...otherProps}
      >
        <CSSTransition in={transitionEntering} timeout={300} classNames="animate" appear>
          <div className={transitionDirection ? `animate-${transitionDirection}` : undefined}>
            <Card className="shadow-lg rounded-lg mb-6">
              <Card.Body className={styles.contentContainerBody} data-cy="section-body">
                {loading ? <LoadingIcon>Vi henter data, vent et øjeblik...</LoadingIcon> : children}
              </Card.Body>
            </Card>
          </div>
        </CSSTransition>
      </Col>
    </Row>
  )
}

ContentContainer.defaultProps = {
  className: undefined,
  transitionDirection: undefined,
  transitionEntering: undefined,
}

export default ContentContainer
