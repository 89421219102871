import type React from 'react'
import { useState, useEffect } from 'react'

type PageCondition = {
  page: React.FC
  condition: boolean
}

const getPages = (pageConditions: PageCondition[]) =>
  pageConditions.filter((item) => item.condition).map((item) => item.page)

const useCachedPages = (
  pageConditions: PageCondition[],
  dependencies: React.DependencyList = [],
) => {
  const [pages, setPages] = useState(getPages(pageConditions))

  useEffect(() => {
    const newPages = getPages(pageConditions)
    setPages(newPages)
  }, dependencies) // eslint-disable-line react-hooks/exhaustive-deps

  return pages
}

export default useCachedPages
