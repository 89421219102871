import { getConfig } from '@nord/ui/src/configuration'
import omit from 'lodash/omit'

const { simple: minimumInvestment, pension: pensionMinimumInvestment } = getConfig(
  'numbers.minimumInvestment',
)

export const MINIMUM_INVESTMENT = minimumInvestment
export const MINIMUM_PENSION_INVESTMENT = pensionMinimumInvestment
export const INTEGER_LIMIT = 1_000_000_000 // 1 billion
export const ORDERED_COUNTRIES = ['DK', 'NO', 'SE', 'DE', 'GB']
export const OTHER_SOURCE_OF_ASSETS_VALUE = 'Others (Source of Wealth)'
export const PRICE_FOR_RISK_PROFILE_CHANGE_PER_SCORE = 0.019578
export const MAX_RISK_SCORE_DIFF = 3
export const HIGH_EXPECTED_INVESTMENT_LIMIT = 2_000_000 // 2 million
export const LOW_EXPECTED_REVENUE_FACTOR = 0.00383 * 7
export const HIGH_EXPECTED_REVENUE_FACTOR = 0.00233 * 7
export const EXPECTED_REVENUE_INVESTMENT_LIMIT = 2_000_000 // 2 million
export const UTM_COOKIE_EXPIRY = 365
export const INVESTMENT_PLAN_COOKIE_EXPIRY = 7
/* eslint-disable camelcase */
export const CLOSING_RATIO = {
  investment_plan_completed: 0.1,
  saxo_verified: 0.7,
  kyc_obtained: 0.7,
  submitted_to_saxo: 0.7,
} as const
/* eslint-enable camelcase */

const {
  website: { frontpage, generalTerms, privacyPolicy, responsibleInvestment },
  backend: { signOut },
} = getConfig('urls')

export const URLS = {
  frontpage,
  privacyPolicy,
  generalTerms,
  responsibleInvestment,
  signOut,
} as const

export const FLOW_PATHS = {
  private: '/privat',
  company: '/virksomhed',
  pension: '/pension',
} as const

const formatId = (id: string, newFallback = false) => {
  if (id === 'new') return 'ny'
  if (!id && newFallback) return 'ny'

  return id
}

export const LEGACY_PATHS = {
  investmentPlan: () => '/investeringsplan' as const,
  investmentPlanResult: (flow: keyof typeof FLOW_PATHS) =>
    `${FLOW_PATHS[flow] || ''}/investeringsplan/resultat` as const,
} as const

export const SHARED_PATHS = {
  root: () => '/' as const,
  referral: (referralToken: string) =>
    `/henvisning${referralToken ? `?referral_token=${referralToken}` : ''}` as const,
  userSignIn: () => '/brugere/logind' as const,
  forgotPassword: () => '/brugere/adgangskode/glemt' as const,
} as const

const buildPaths = <T extends string>(prefix: T) => ({
  root: () => `/${prefix}` as const,
  investmentPlan: (id: string) => `/${prefix}/${formatId(id, true)}/investeringsplan` as const,
  investmentPlanResult: (id: string) =>
    `/${prefix}/${formatId(id, true)}/investeringsplan/resultat` as const,
  onboarding: (id: string) => `/${prefix}/${id}/opret` as const,
  verification: (id: string) => `/${prefix}/${id}/verificering` as const,
  confirmation: (id: string) => `/${prefix}/${id}/bekraeft` as const,
  documents: (id: string) => `/${prefix}/${id}/dokumenter` as const,
  saxoVerificationStart: (id: string) => `/${prefix}/${id}/saxo_verificering` as const,
  saxoVerificationFailure: (id: string) => `/${prefix}/${id}/saxo_verificering/afvist` as const,
  saxoVerificationCompleted: (id: string) =>
    `/${prefix}/${id}/saxo_verificering/afsluttet` as const,
})

export const PRIVATE_PATHS = buildPaths('privat')

export const COMPANY_PATHS = {
  ...omit(buildPaths('virksomhed'), [
    'saxoVerificationStart',
    'saxoVerificationFailure',
    'saxoVerificationCompleted',
  ]),
  verificationComplete: (id: string) => `/virksomhed/${id}/afsluttet` as const,
} as const

export const PENSION_PATHS = {
  ...buildPaths('pension'),
  pensionsInfo: (id: string) => `/pension/${formatId(id, true)}/pensions_info` as const,
} as const

export const PATHS = {
  legacy: LEGACY_PATHS,
  shared: SHARED_PATHS,
  private: PRIVATE_PATHS,
  company: COMPANY_PATHS,
  pension: PENSION_PATHS,
} as const
