import Cookies from 'js-cookie'

const getGaClientId = () => {
  const gaCookie = Cookies.getJSON('_ga')

  if (!gaCookie) return ''

  const clientId = gaCookie.split('.').splice(2).join('.')

  return clientId
}

export default getGaClientId
