import { createSlice } from '@reduxjs/toolkit'

export const progressSlice = createSlice({
  name: 'loading',
  initialState: {
    stage: null,
    progress: null,
  },
  reducers: {
    stageChanged: (_state, action) => ({
      stage: action.payload,
      progress: null,
    }),
    progressChanged: (state, action) => {
      state.progress = action.payload
    },
  },
})

export const { stageChanged, progressChanged } = progressSlice.actions

export const selectProgress = (state: any) => state.ui.progress.progress

export const selectStage = (state: any) => state.ui.progress.stage

export const progressReducer = progressSlice.reducer
export default progressSlice
