import type yup from 'yup'
import { addMethod, number } from 'yup'

function testSafeInteger(this: yup.NumberSchema, message: string) {
  return this.integer(message)
    .min(-(2 ** 31), message)
    .max(2 ** 31 - 1, message)
}

/* eslint-disable no-template-curly-in-string */
function testMinLength(
  this: yup.NumberSchema,
  length: number,
  message = 'Skal være mindst ${length} lang',
) {
  return this.test({
    name: 'minLength',
    message,
    params: {
      length,
    },
    exclusive: true,
    test: (value) => (value ? value.toString().length >= length : length === 0),
  })
}

function testMaxLength(
  this: yup.NumberSchema,
  length: number,
  message = 'Skal være højest ${length} lang',
) {
  return this.test({
    name: 'maxLength',
    message,
    params: {
      length,
    },
    exclusive: true,
    test: (value) => (value ? value.toString().length <= length : length === 0),
  })
}

function testLength(this: yup.NumberSchema, length: number, message = 'Skal være ${length} lang') {
  return this.test({
    name: 'length',
    message,
    params: {
      length,
    },
    exclusive: true,
    test: (value) => (value ? value.toString().length === length : length === 0),
  })
}
/* eslint-enable no-template-curly-in-string */

addMethod(number, 'safeInteger', testSafeInteger)
addMethod(number, 'length', testLength)
addMethod(number, 'minLength', testMinLength)
addMethod(number, 'maxLength', testMaxLength)

declare module 'yup' {
  interface NumberSchema {
    safeInteger(message?: string): NumberSchema
    minLength(length: number, message?: string): NumberSchema
    maxLength(length: number, message?: string): NumberSchema
    length(length: number, message?: string): NumberSchema
  }
}
