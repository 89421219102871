import isFinite from 'lodash/isFinite'
import snakeCase from 'lodash/snakeCase'
import { z } from 'zod'

import { deepFreeze } from '../../../utilities/deepIterate'

import development from './development'
import staging from './development'
import local from './local'
import production from './production'
import saxo from './saxo'
import test from './test'

/**
 * Looks up a environment variable from `process.env` and returns it's value.
 * Works with REACT_APP_ prefixed environment variables from Create React App.
 * Does NOT work with GATSBY_ prefixed variables.
 * Does NOT work with STORYBOOK_ prefixed variables
 *
 * @param {string} variableName - The name of the variable to lookup
 * @return {string|number|boolean} - The value of the environment variable
 * @example
 *
 * // .env
 * MY_ENV_VAR=something
 * REACT_APP_ENV_VAR=react
 *
 * // index.js
 * getEnvironmentVariable('MY_ENV_VAR') #=> 'something'
 * getEnvironmentVariable('myEnvVar') #=> 'something'
 * getEnvironmentVariable('envVar') #=> 'react'
 */

const booleanValues = ['true', 'false']

export const getEnvironmentVariable = (
  variableName: string,
): string | number | boolean | undefined => {
  const constantName = snakeCase(variableName).toUpperCase()

  // We can not dynamically import gatsby & storybook ENV vars, so this only works in CRA apps
  const value = process.env[`REACT_APP_${constantName}`] || process.env[constantName]

  // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
  if (booleanValues.includes(value)) return JSON.parse(value)

  if (isFinite(Number(value))) return Number(value)

  return value
}

const environments = z.enum(['development', 'local', 'saxo', 'staging', 'test', 'production'])

const environmentName = environments.parse(
  process.env.GATSBY_ENVIRONMENT ||
    process.env.STORYBOOK_ENVIRONMENT ||
    getEnvironmentVariable('environment') ||
    process.env.NODE_ENV,
)

const customEnvironment = environments.parse(
  process.env.GATSBY_CUSTOM_ENVIRONMENT ||
    process.env.STORYBOOK_CUSTOM_ENVIRONMENT ||
    getEnvironmentVariable('customEnvironment') ||
    environmentName,
)

const isProduction = environmentName === 'production'
const isDevelopment =
  environmentName === 'development' || customEnvironment === 'local' || customEnvironment === 'test'

export const environmentConfigurations = {
  development,
  local,
  saxo,
  staging,
  test,
  production,
} as const

let environmentConfig = environmentConfigurations[environmentName]
if (customEnvironment && environmentName !== 'production')
  environmentConfig = environmentConfigurations[customEnvironment]
if (!environmentConfig) environmentConfig = development

export const currentEnvironment = {
  name: environmentName,
  custom: customEnvironment,
  isProduction,
  isDevelopment,
} as const

const environment = deepFreeze({
  environment: currentEnvironment,
  ...environmentConfig,
})

export default environment
