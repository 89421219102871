import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import React from 'react'

import { selectCurrentUser } from '../../../../store/current/user'

import DocumentsAcceptPage from './DocumentsAcceptPage'
import PenneoSigningPage from './PenneoSigningPage'

const DocumentsPage = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'submittedToSaxoAt' does not exist on typ... Remove this comment to see the full error message
  const { submittedToSaxoAt } = useObjectSelector(selectCurrentUser)

  const previouslyOnboarded = !!submittedToSaxoAt

  if (previouslyOnboarded) return <PenneoSigningPage />

  return <DocumentsAcceptPage />
}

export default DocumentsPage
