import {
  updateSingleRecordFromApiResponse,
  getAttributesFromApiResponse,
} from '@nord/ui/src/store/helpers'
import { createSlice, current } from '@reduxjs/toolkit'

import { createInvestmentProfile } from '../investmentProfile'
import { updateUserOnboarding } from '../user/actions'

import {
  createPortfolioOnboarding,
  updatePortfolioOnboarding,
  acceptPortfolioDocuments,
  completePortfolioKyc,
  completePortfolioOnboarding,
  changePortfolioDepotType,
} from './actions'

const initialState = null

// @ts-expect-error TS(2345) FIXME: Argument of type '{ name: "current.portfolios"; in... Remove this comment to see the full error message
export const portfoliosSlice = createSlice({
  name: 'current.portfolios',
  initialState,
  extraReducers: {
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [createPortfolioOnboarding.fulfilled]: (state: any, action: any) => {
      const currentState = current(state)
      const { new: newPortfolio, ...remainingState } = currentState

      const { user, ...portfolioAttributes } = getAttributesFromApiResponse(action)

      return updateSingleRecordFromApiResponse(remainingState, {
        payload: portfolioAttributes,
      })
    },
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [updateUserOnboarding.fulfilled]: (state: any, action: any) => {
      const { portfolio } = getAttributesFromApiResponse(action)

      return updateSingleRecordFromApiResponse(state, { payload: portfolio })
    },
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [updatePortfolioOnboarding.fulfilled]: (state: any, action: any) => {
      const { user, ...portfolioAttributes } = getAttributesFromApiResponse(action)

      return updateSingleRecordFromApiResponse(state, {
        payload: portfolioAttributes,
      })
    },
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [acceptPortfolioDocuments.fulfilled]: updateSingleRecordFromApiResponse,
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [completePortfolioKyc.fulfilled]: (state: any, action: any) => {
      const { portfolio } = getAttributesFromApiResponse(action)

      return updateSingleRecordFromApiResponse(state, { payload: portfolio })
    },
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [completePortfolioOnboarding.fulfilled]: updateSingleRecordFromApiResponse,
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [changePortfolioDepotType.fulfilled]: updateSingleRecordFromApiResponse,
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [createInvestmentProfile.fulfilled]: (
      state: any,
      {
        payload: { riskScore: advisedRiskScore, investmentProfileId, portfolioId, esg: advisedEsg },
      }: any,
    ) => {
      if (portfolioId) {
        return {
          ...state,
          [portfolioId]: {
            advisedRiskScore,
            advisedEsg,
            investmentProfileId,
          },
        }
      }

      return {
        ...state,
        new: {
          advisedRiskScore,
          advisedEsg,
          investmentProfileId,
          userOnboardingState: 'investment_plan_completed',
        },
      }
    },
  },
})

export * from '@nord/ui/src/store/current/portfolios/slice'
export default portfoliosSlice
