import classNames from 'classnames'
import Cookies from 'js-cookie'
import React, { useState, useCallback, useEffect, useLayoutEffect } from 'react'
import { CloseButton } from 'react-bootstrap'
import { CSSTransition } from 'react-transition-group'

import extractTransitionClasses from '../../utilities/extractTransitionClasses'

import styles from './Banner.module.scss'

const timeout = parseInt(styles.timeout, 10)

const COOKIE_KEY = 'closed-page-banners'

export interface Props {
  children?: React.ReactNode
  className?: string
  closed?: boolean
  cookieID?: any // TODO: requiredBy('withClose', PropTypes.string)
  variant?: any // TODO: bootstrapVariant()
  withClose?: boolean
}

const Banner = ({
  children,
  className,
  closed: defaultClosed,
  cookieID,
  variant,
  withClose,
  ...otherProps
}: Props) => {
  const [hidden, setHidden] = useState(withClose)
  const [closed, setClosed] = useState(withClose)

  const handleClose = useCallback(() => {
    setClosed(true)

    const closedBanners = Cookies.getJSON(COOKIE_KEY) || []
    closedBanners.push(cookieID)
    Cookies.set(COOKIE_KEY, closedBanners)
  }, [cookieID])

  useLayoutEffect(() => {
    if (!withClose) return

    const initialClosedBanners = Cookies.getJSON(COOKIE_KEY) || []

    if (!initialClosedBanners.includes(cookieID)) {
      setHidden(false)
      setClosed(false)
    }
  }, [withClose, cookieID])

  useEffect(() => {
    if (!withClose) return

    if (defaultClosed === true) {
      handleClose()
    } else if (defaultClosed === false) {
      setClosed(false)
    }
  }, [withClose, defaultClosed, handleClose])

  if (hidden) return null

  if (withClose) {
    return (
      <CSSTransition
        classNames={extractTransitionClasses({
          styles,
          className: 'collapse',
          appearKey: 'enter',
        })}
        in={!closed}
        timeout={timeout}
        onExited={() => setHidden(true)}
        {...otherProps}
      >
        <div className={styles.collapseContainer}>
          <div className={classNames(styles.pageBanner, `bg-${variant}`, className)}>
            <CloseButton onClick={handleClose} />
            {children}
          </div>
        </div>
      </CSSTransition>
    )
  }

  return (
    <div className={classNames(styles.pageBanner, `bg-${variant}`, className)} {...otherProps}>
      {children}
    </div>
  )
}

Banner.defaultProps = {
  children: undefined,
  className: undefined,
  closed: undefined,
  cookieID: undefined,
  variant: 'warning',
  withClose: undefined,
}

export default Banner
