import useApi, { useApiRequest } from '@nord/ui/src/hooks/useApi'
import useForceUpdate from '@nord/ui/src/hooks/useForceUpdate'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import usePrevious from '@nord/ui/src/hooks/usePrevious'
import useQuery from '@nord/ui/src/hooks/useQuery'
import Cookies from 'js-cookie'
import { useCallback, useEffect, useMemo, useState } from 'react'

import useSignedIn from '../../../hooks/useSignedIn'
import { selectCurrentUser } from '../../../store/current/user'

const partnersWithDiscount = ['djof']

export const cookieExpiration = 7 // 7 days

const useReferral = () => {
  const forceUpdate = useForceUpdate()
  // @ts-expect-error TS(2339) FIXME: Property 'referredByToken' does not exist on type ... Remove this comment to see the full error message
  const { referredByToken, onboardingState: userOnboardingState } =
    useObjectSelector(selectCurrentUser)
  const userIsSaxoOnboarded = userOnboardingState === 'saxo_onboarded'

  const isSignedIn = useSignedIn()
  const wasSignedIn = usePrevious(isSignedIn)

  const referralTokenCookie = Cookies.get('referralToken')
  const referralTokenQuery = useQuery('referralToken')
  const [referralToken, setReferralToken] = useState(referralTokenCookie)
  const referralAccepted = !!referralTokenCookie

  const removeReferralToken = () => {
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
    setReferralToken()
    Cookies.remove('referralToken')
  }

  useEffect(() => {
    if (!referredByToken) return
    if (referralToken && referredByToken !== referralToken) return

    setReferralToken(referredByToken)
  }, [referredByToken, referralToken])

  useEffect(() => {
    if (!referralTokenQuery) return
    if (!referralTokenCookie) return
    if (referralTokenCookie === referralTokenQuery) return

    Cookies.remove('referralToken')
    setReferralToken(referralTokenQuery)
  }, [referralTokenCookie, referralTokenQuery])

  useEffect(() => {
    if (wasSignedIn) removeReferralToken()
  }, [wasSignedIn])

  useEffect(() => {
    if (userIsSaxoOnboarded) removeReferralToken()
  }, [userIsSaxoOnboarded])

  useEffect(() => {
    if (isSignedIn && referralAccepted) Cookies.remove('referralToken')
  }, [isSignedIn, referralAccepted])

  const callValidateToken = useApi('/onboarding/referral_tokens', {
    method: 'GET',
    errorHandling: {
      ignore: {
        client: true,
      },
    },
  })

  const callSaveToken = useApi('/users/referrals', {
    method: 'POST',
    withCredentials: true,
  })

  let payload = { referralToken }
  // @ts-expect-error TS(2322) FIXME: Type '{ user: { referralToken: string | undefined;... Remove this comment to see the full error message
  if (isSignedIn && referralAccepted) payload = { user: payload }

  const requestFunction = isSignedIn && referralAccepted ? callSaveToken : callValidateToken

  const { data: referralData, success } = useApiRequest(requestFunction, {
    autoCall: !!referralToken,
    payload,
  })

  const referralIsValid = Boolean(referralToken && success)

  const handleApproveReferral = useCallback(() => {
    if (referralToken) {
      Cookies.set('referralToken', referralToken, {
        expires: cookieExpiration,
      })
    } else {
      Cookies.remove('referralToken')
    }

    forceUpdate()
  }, [forceUpdate, referralToken])

  const handlePageLeave = useCallback(() => {
    setReferralToken(undefined)
  }, [])

  const partnerDiscount = Boolean(referralToken && partnersWithDiscount.includes(referralToken))

  return useMemo(
    () => ({
      referralToken,
      referralData,
      referralIsValid,
      partnerDiscount,
      onChangeReferralToken: setReferralToken,
      onApproveReferral: handleApproveReferral,
      onPageLeave: handlePageLeave,
    }),
    [
      partnerDiscount,
      referralToken,
      referralData,
      referralIsValid,
      handleApproveReferral,
      handlePageLeave,
    ],
  )
}

export default useReferral
