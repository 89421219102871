import Paginator from '@nord/ui/src/components/Paginator'
import React from 'react'
import { object, string } from 'yup'

import ContentTitle from '../../../ContentTitle'

import CompanyNameAndCVRFields from './CompanyNameAndCVRFields'

const labels = {
  cvr: 'CVR nummer',
  companyName: 'Virksomhedens navn',
  naceCode: 'Branchekode',
  address: 'Adresse',
  city: 'By',
  postalCode: 'Postnummer',
}

const fields = Object.keys(labels)

const validationSchema = object().shape({
  cvr: string().required(),
  companyName: string().required(),
  naceCode: string(),
  address: string().required(),
  city: string().required(),
  postalCode: string().required(),
})

const CompanyInformationPage = (otherProps: any) => (
  <Paginator.Form.Page
    fields={fields}
    labelDescriptions={labels}
    validationSchema={validationSchema}
    {...otherProps}
  >
    <ContentTitle title="Fortæl os om din virksomhed" />
    <CompanyNameAndCVRFields />
  </Paginator.Form.Page>
)

export default CompanyInformationPage
