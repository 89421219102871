import PenneoSigningLink from '@nord/ui/src/components/PenneoSigningLink'
import useApi from '@nord/ui/src/hooks/useApi'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import useWaitForStateChange from '@nord/ui/src/hooks/useWaitForStateChange'
import React, { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'

import {
  selectCurrentPortfolio,
  selectOnboardingState,
} from '../../../../store/current/portfolioId'
import extractOnboardingState from '../../../../utilities/extractOnboardingState'
import ContentContainer from '../../ContentContainer'
import ContentTitle from '../../ContentTitle'

const PenneoSigningPage = () => {
  const [timedOut, setTimedOut] = useState(false)
  const [userReturnedToWindow, setUserReturnedToWindow] = useState(false)
  // @ts-expect-error TS(2339) FIXME: Property 'penneoSigningUrl' does not exist on type... Remove this comment to see the full error message
  const { penneoSigningUrl, id: portfolioId } = useObjectSelector(selectCurrentPortfolio)
  const currentOnboardingState = useSelector(selectOnboardingState)
  const startSigningOfDocuments = useApi('/onboarding/:portfolioId/documents/signing', {
    method: 'POST',
    withCredentials: true,
    errorHandling: {
      ignore: {
        client: true,
      },
    },
  })

  const handleTimeout = useCallback(() => setTimedOut(true), [])
  const goalState = userReturnedToWindow
    ? ['documents_signed', 'ready_to_onboard', 'kyc_obtained', 'saxo_verified']
    : false

  useWaitForStateChange(goalState, handleTimeout, {
    currentState: currentOnboardingState,
    extractNewState: extractOnboardingState,
  })

  const handleFocusWindow = useCallback(() => {
    if (userReturnedToWindow) return

    setUserReturnedToWindow(true)
    startSigningOfDocuments(undefined, { pathParams: { portfolioId } })
  }, [portfolioId, startSigningOfDocuments, userReturnedToWindow])

  const handleClickLink = () => {
    startSigningOfDocuments(undefined, { pathParams: { portfolioId } })
  }

  return (
    <ContentContainer>
      <ContentTitle title="Du er der næsten..." />
      <PenneoSigningLink
        penneoSigningUrl={penneoSigningUrl}
        onClick={handleClickLink}
        onWindowFocus={handleFocusWindow}
        timedOut={timedOut}
        description={
          <>
            <p>
              Du mangler kun at underskrive dine dokumenter! Når du trykker nedenfor, åbner dine
              dokumenter i en ny side, hvor du kan underskrive dem.
            </p>
            <p>
              Herefter skal du lukke dokument vinduet, for at vende tilbage hertil og færdiggøre din
              oprettelse.
            </p>
          </>
        }
      />
    </ContentContainer>
  )
}

export default PenneoSigningPage
