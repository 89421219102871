import { combineReducers } from '@reduxjs/toolkit'

import investmentProfile from './investmentProfile'
import portfolioId from './portfolioId'
import portfolios from './portfolios'
import user from './user'

export default combineReducers({
  user,
  portfolioId,
  portfolios,
  investmentProfile,
})
