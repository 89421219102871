import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import '@nord/ui/src/configuration/setup/polyfills'

import 'filepond-polyfill'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'smoo... Remove this comment to see the full error message
import smoothscroll from 'smoothscroll-polyfill'

smoothscroll.polyfill()
