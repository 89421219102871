import {
  LEGACY_PATHS,
  SHARED_PATHS,
  PRIVATE_PATHS,
  COMPANY_PATHS,
  PENSION_PATHS,
} from '../../configuration/constants'

import Company from './Company'
import Pension from './Pension'
import Private from './Private'
import RedirectPage from './RedirectPage'
import Shared from './Shared'

export const LEGACY_PAGES = [
  {
    stage: null,
    progress: null,
    path: LEGACY_PATHS.investmentPlan(),
    component: RedirectPage,
    title: 'Gratis investeringsplan',
    requireSignIn: false,
    requireFlow: null,
    company: null,
  },
  {
    stage: null,
    progress: null,
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
    path: LEGACY_PATHS.investmentPlanResult(),
    component: Shared.InvestmentPlan.ResultPage,
    title: 'Gratis investeringsplan',
    requireSignIn: false,
    requireFlow: null,
    company: null,
  },
  {
    stage: null,
    progress: null,
    path: LEGACY_PATHS.investmentPlanResult('private'),
    component: Shared.InvestmentPlan.ResultPage,
    title: 'Gratis investeringsplan til pension',
    requireSignIn: false,
    requireFlow: 'private',
    company: false,
  },
  {
    stage: null,
    progress: null,
    path: LEGACY_PATHS.investmentPlanResult('company'),
    component: Shared.InvestmentPlan.ResultPage,
    title: 'Gratis investeringsplan til pension',
    requireSignIn: false,
    requireFlow: 'company',
    company: true,
  },
  {
    stage: null,
    progress: null,
    path: LEGACY_PATHS.investmentPlanResult('pension'),
    component: Pension.ResultPage,
    title: 'Gratis investeringsplan til pension',
    requireSignIn: false,
    requireFlow: 'pension',
    company: false,
  },
] as const

export const INITIAL_PAGES = [
  {
    stage: null,
    progress: 0,
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
    path: SHARED_PATHS.referral(),
    component: Shared.ReferralPage,
    title: 'Henvisning',
    requireSignIn: null,
    requireFlow: false,
    company: null,
  },
  {
    stage: null,
    progress: 0,
    path: SHARED_PATHS.root(),
    component: Shared.ChooseFlowPage,
    title: 'Privat, pension eller virksomhed?',
    requireSignIn: null,
    requireFlow: false,
    company: null,
  },
  {
    stage: null,
    progress: 0,
    path: SHARED_PATHS.userSignIn(),
    component: Shared.SignInPage,
    title: 'Log ind',
    requireSignIn: false,
    requireFlow: null,
    company: null,
  },
  {
    stage: null,
    progress: 0,
    path: SHARED_PATHS.forgotPassword(),
    component: Shared.ForgotPasswordPage,
    title: 'Nulstil adgangskode',
    requireSignIn: false,
    requireFlow: null,
    company: null,
  },
] as const

export const PRIVATE_PAGES = [
  {
    stage: 0,
    progress: 0,
    path: PRIVATE_PATHS.root(),
    component: Private.StartPage,
    title: 'Gratis investeringsplan',
    requireSignIn: null,
    requireFlow: 'private',
    company: false,
  },
  {
    stage: 0,
    progress: null,
    path: PRIVATE_PATHS.investmentPlan(':id'),
    component: Shared.InvestmentPlan,
    title: 'Gratis investeringsplan',
    requireSignIn: null,
    requireFlow: 'private',
    company: false,
  },
  {
    stage: 0,
    progress: 1,
    path: PRIVATE_PATHS.investmentPlanResult(':id'),
    component: Shared.InvestmentPlan.ResultPage,
    title: 'Gratis investeringsplan',
    requireSignIn: null,
    requireFlow: 'private',
    company: false,
  },
  {
    stage: 1,
    progress: null,
    path: PRIVATE_PATHS.onboarding(':id'),
    component: Private.Onboarding,
    title: 'Opret depot',
    requireSignIn: true,
    requireFlow: 'private',
    company: false,
  },
  {
    stage: 2,
    progress: 0,
    path: PRIVATE_PATHS.confirmation(':id'),
    component: Private.ConfirmationPage,
    title: 'Bekræft',
    requireSignIn: true,
    requireFlow: 'private',
    company: false,
  },
  {
    stage: 2,
    progress: 0,
    path: PRIVATE_PATHS.documents(':id'),
    component: Shared.DocumentsPage,
    title: 'Dokumenter',
    requireSignIn: true,
    requireFlow: 'private',
    company: false,
  },
  {
    stage: 2,
    progress: 0.5,
    path: PRIVATE_PATHS.saxoVerificationStart(':id'),
    component: Private.SaxoVerification.StartPage,
    title: 'Verificering',
    requireSignIn: true,
    requireFlow: 'private',
    company: false,
  },
  {
    stage: 2,
    progress: 0.5,
    path: PRIVATE_PATHS.saxoVerificationFailure(':id'),
    component: Private.SaxoVerification.FailurePage,
    title: 'Verificering',
    requireSignIn: null,
    requireFlow: 'private',
    company: false,
  },
  {
    stage: 2,
    progress: 0.5,
    path: PRIVATE_PATHS.verification(':id'),
    component: Shared.KYCVerification,
    title: 'Verificering',
    requireSignIn: true,
    requireFlow: 'private',
    company: false,
  },
  {
    stage: 2,
    progress: 1,
    path: PRIVATE_PATHS.saxoVerificationCompleted(':id'),
    component: Private.SaxoVerification.CompletePage,
    title: 'Tak for din oprettelse',
    requireSignIn: true,
    requireFlow: 'private',
    company: false,
  },
] as const

export const COMPANY_PAGES = [
  {
    stage: 0,
    progress: 0,
    path: COMPANY_PATHS.root(),
    component: Company.StartPage,
    title: 'Gratis investeringsplan',
    requireSignIn: null,
    requireFlow: 'company',
    company: true,
  },
  {
    stage: 0,
    progress: null,
    path: COMPANY_PATHS.investmentPlan(':id'),
    component: Shared.InvestmentPlan,
    title: 'Gratis investeringsplan',
    requireSignIn: null,
    requireFlow: 'company',
    company: true,
  },
  {
    stage: 0,
    progress: 1,
    path: COMPANY_PATHS.investmentPlanResult(':id'),
    component: Shared.InvestmentPlan.ResultPage,
    title: 'Gratis investeringsplan',
    requireSignIn: null,
    requireFlow: 'company',
    company: true,
  },
  {
    stage: 1,
    progress: null,
    path: COMPANY_PATHS.onboarding(':id'),
    component: Company.Onboarding,
    title: 'Opret depot',
    requireSignIn: true,
    requireFlow: 'company',
    company: true,
  },
  {
    stage: 1,
    progress: 0.5,
    path: COMPANY_PATHS.verification(':id'),
    component: Shared.KYCVerification,
    title: 'Verificering',
    requireSignIn: true,
    requireFlow: 'company',
    company: true,
  },
  {
    stage: 2,
    progress: 0,
    path: COMPANY_PATHS.confirmation(':id'),
    component: Company.ConfirmationPage,
    title: 'Bekræft',
    requireSignIn: true,
    requireFlow: 'company',
    company: true,
  },
  {
    stage: 2,
    progress: 1,
    path: COMPANY_PATHS.verificationComplete(':id'),
    component: Company.OnboardingCompletePage,
    title: 'Tak for din oprettelse',
    requireSignIn: true,
    requireFlow: 'company',
    company: true,
  },
] as const

export const PENSION_PAGES = [
  {
    stage: 0,
    progress: 0,
    path: PENSION_PATHS.root(),
    component: Pension.StartPage,
    title: 'Gratis investeringsplan til pension',
    requireSignIn: null,
    requireFlow: 'pension',
    company: false,
  },
  {
    stage: 0,
    progress: null,
    path: PENSION_PATHS.investmentPlan(':id'),
    component: Pension.InvestmentPlan,
    title: 'Gratis investeringsplan til pension',
    requireSignIn: null,
    requireFlow: 'pension',
    company: false,
  },
  {
    stage: 0,
    progress: null,
    path: PENSION_PATHS.pensionsInfo(':id'),
    component: Pension.PensionsInfoPage,
    title: 'Gratis investeringsplan til pension',
    requireSignIn: null,
    requireFlow: 'pension',
    company: false,
  },
  {
    stage: 0,
    progress: 1,
    path: PENSION_PATHS.investmentPlanResult(':id'),
    component: Pension.ResultPage,
    title: 'Gratis investeringsplan til pension',
    requireSignIn: null,
    requireFlow: 'pension',
    company: false,
  },
  {
    stage: 1,
    progress: null,
    path: PENSION_PATHS.onboarding(':id'),
    component: Pension.Onboarding,
    title: 'Opret pensionsdepot',
    requireSignIn: true,
    requireFlow: 'pension',
    company: false,
  },
  {
    stage: 2,
    progress: 0,
    path: PENSION_PATHS.confirmation(':id'),
    component: Pension.ConfirmationPage,
    title: 'Opret pensionsdepot',
    requireSignIn: true,
    requireFlow: 'pension',
    company: false,
  },
  {
    stage: 2,
    progress: 0,
    path: PENSION_PATHS.documents(':id'),
    component: Shared.DocumentsPage,
    title: 'Dokumenter',
    requireSignIn: true,
    requireFlow: 'pension',
    company: false,
  },
  {
    stage: 2,
    progress: 0.5,
    path: PENSION_PATHS.saxoVerificationStart(':id'),
    component: Private.SaxoVerification.StartPage,
    title: 'Verificering',
    requireSignIn: true,
    requireFlow: 'pension',
    company: false,
  },
  {
    stage: 2,
    progress: 0.5,
    path: PENSION_PATHS.saxoVerificationFailure(':id'),
    component: Private.SaxoVerification.FailurePage,
    title: 'Verificering',
    requireSignIn: null,
    requireFlow: 'pension',
    company: false,
  },
  {
    stage: 2,
    progress: 0.5,
    path: PENSION_PATHS.verification(':id'),
    component: Shared.KYCVerification,
    title: 'Verificering',
    requireSignIn: true,
    requireFlow: 'pension',
    company: false,
  },
  {
    stage: 2,
    progress: 1,
    path: PENSION_PATHS.saxoVerificationCompleted(':id'),
    component: Pension.CompletePage,
    title: 'Tak for din oprettelse',
    requireSignIn: true,
    requireFlow: 'pension',
    company: false,
  },
] as const

export const SHARED_PAGES = [...INITIAL_PAGES] as const

export const PAGES = {
  legacy: LEGACY_PAGES,
  shared: SHARED_PAGES,
  initial: INITIAL_PAGES,
  private: PRIVATE_PAGES,
  company: COMPANY_PAGES,
  pension: PENSION_PAGES,
} as const
