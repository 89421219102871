import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'

import { deepFreeze } from '../../../utilities/deepIterate'

import development from './development'

const local = deepFreeze(
  merge(cloneDeep(development), {
    debug: true,
    domains: {
      website: 'http://localhost:8888',
      backend: 'http://localhost:3001',
      webSocket: 'ws://localhost:3001/cable',
      app: 'http://localhost:3000',
      onboarding: 'http://localhost:3000',
      blog: 'http://localhost:8000',
      suitabilityFlow: 'http://localhost:3000',
    },
  }),
)

export default local
