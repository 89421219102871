import apiRequestCall from '@nord/ui/src/store/api/apiRequestCall'
import { createAsyncThunk } from '@reduxjs/toolkit'

// eslint-disable-next-line import/prefer-default-export
export const createInvestmentProfile = createAsyncThunk(
  'current.investmentProfile/create',
  // @ts-expect-error TS(2339) FIXME: Property 'flow' does not exist on type 'void'.
  ({ flow, attributes }, { dispatch }) =>
    dispatch(
      // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
      apiRequestCall({
        method: 'POST',
        url: '/investment_profiles',
        data: {
          investmentProfile: attributes,
        },
        params: {
          type: flow === 'company' ? 'company' : 'private',
        },
      }),
    ).unwrap(),
)
