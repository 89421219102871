import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import { useMemo } from 'react'

import useFlow from '../../../../../hooks/useFlow'

const useResultPageTitle = () => {
  const flow = useFlow()
  const {
    // @ts-expect-error TS(2339) FIXME: Property 'advisedRiskScore' does not exist on type... Remove this comment to see the full error message
    advisedRiskScore,
    // @ts-expect-error TS(2339) FIXME: Property 'onboardingState' does not exist on type ... Remove this comment to see the full error message
    onboardingState: portfolioOnboardingState,
    // @ts-expect-error TS(2339) FIXME: Property 'title' does not exist on type 'unknown'.
    title: portfolioTitle,
  } = useObjectSelector(selectCurrentPortfolio)

  const title = useMemo(() => {
    if (!advisedRiskScore) return 'Investeringsplan'
    if (portfolioOnboardingState === 'created_in_saxo')
      return `Investeringsplan for din portefølje: ${portfolioTitle}`
    if (flow === 'company') return 'Her er investeringsplanen for din virksomhed'

    return 'Her er din personlige investeringsplan'
  }, [advisedRiskScore, flow, portfolioOnboardingState, portfolioTitle])

  return title
}

export default useResultPageTitle
