import DateInput from '@nord/ui/src/components/DateInput'
import Form from '@nord/ui/src/components/Form'
import Paginator from '@nord/ui/src/components/Paginator'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { object, date } from 'yup'

import ContentTitle from '../../ContentTitle'

const currentDate = new Date()
const birthdateMinimumDate = new Date(
  currentDate.getFullYear() - 18,
  currentDate.getMonth(),
  currentDate.getDate(),
)
const validationSchema = object().shape({
  birthdate: date()
    // eslint-disable-next-line func-names
    .transform(function (value) {
      if (this.isType(value)) return value

      return new Date('')
    })
    .min(new Date(1800, 0, 1).toDateString(), 'Ugyldig dato, skriv i formattet dd/mm/åååå')
    .max(birthdateMinimumDate.toDateString(), 'Du skal være mindst 18 år gammel')
    .required(),
})

const labels = {
  birthdate: 'Fødselsdag',
}

const fields = Object.keys(labels)

const BirthdatePage = (otherProps: any) => (
  <Paginator.Form.Page
    validationSchema={validationSchema}
    fields={fields}
    labelDescriptions={labels}
    {...otherProps}
  >
    <ContentTitle title="Din alder" />
    <Row>
      <Col>
        <div className="mb-2">
          Hvornår er din fødselsdag? Vi skal bruge den til at anbefale en nedtrapningsplan.
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <Form.FieldGroup name="birthdate">
          {/* @ts-expect-error TS(2739) FIXME: Type '{ ({ defaultValue, value, pipeOptions, onCha... Remove this comment to see the full error message */}
          <Form.Field inputComponent={DateInput} />
        </Form.FieldGroup>
      </Col>
    </Row>

    <Row>
      <Col>
        {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; variant: string; "data-c... Remove this comment to see the full error message */}
        <Form.SubmitButton variant="primary" data-cy="next-button">
          Fortsæt
        </Form.SubmitButton>
      </Col>
    </Row>
  </Paginator.Form.Page>
)

export default BirthdatePage
