import { createContext, useContext } from 'react'

export interface ReferralContextType {
  referralIsValid: boolean
  partnerDiscount: boolean
  referralData?: {
    amount: number
    name: string
    contactInformation?: string | null
    status: string
    type: string
    title: string
    message: string
    depositLimit: number
    errors?: Record<string, string>
  }
  onPageLeave(): void
  onChangeReferralToken(token: string): void
  onApproveReferral(): void
}

const ReferralContext = createContext<ReferralContextType | null>(null)

export const useReferralContext = () => {
  const context = useContext(ReferralContext)

  if (!context) {
    throw new Error('useReferralContext must be used within a ReferralProvider')
  }

  return context
}

export default ReferralContext
