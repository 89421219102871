import { useRef } from 'react'

import nextCount from '../utilities/nextCount'
import { idFromCount } from '../utilities/nextId'

const useId = (prefix: any) => {
  const countRef = useRef(nextCount())

  return idFromCount(countRef.current, `${prefix}__`)
}

export default useId
