import React from 'react'

const PepInformation = () => (
  <>
    <h5>Politisk Eksponeret Person</h5>
    <p>Fysisk person, der har eller har haft et af følgende offentlige erhverv:</p>

    <ol type="A" className="list-alpha">
      <li>Statschef, regeringschef, minister og viceminister eller assisterende minister.</li>
      <li>Parlamentsmedlem eller medlem af tilsvarende lovgivende organer.</li>
      <li>Medlem af politisk partis styrelsesorgan.</li>
      <li>
        Højesteretsdommer, medlem af forfatningsdomstole og af andre højtstående retsinstanser, hvis
        afgørelser kun er genstand for yderligere prøvelse under ekstraordinære omstændigheder.
      </li>
      <li>Medlem af revisionsretter og øverste ledelsesorganer for centralbanker.</li>
      <li>Ambassadør, chargé d'affaires og højtstående officer i de væbnede styrker.</li>
      <li>Medlem af statsejet virksomheds administrative, ledende eller kontrollerende organ.</li>
      <li>
        Direktør, vicedirektør og medlem af bestyrelsen eller person med tilsvarende hverv i en
        international organisation.
      </li>
    </ol>

    <h5>Tilknytning til en Politisk eksponeret person er nærtstående og nære samarbejdspartnere</h5>

    <p>Familiemedlemmer</p>
    <ol type="A" className="list-alpha">
      <li>
        Ægtefællen til en politisk eksponeret person, eller en person, der kan sidestilles med en
        ægtefælle til en politisk eksponeret person
      </li>
      <li>
        Børn og deres ægtefæller eller personer, der kan sidestilles med en ægtefælle til en
        politisk eksponeret person
      </li>
      <li>Forældre til en politisk eksponeret person.</li>
    </ol>

    <p>Personer, der er kendt som nære samarbejdspartnere</p>
    <ol type="A" className="list-alpha">
      <li>
        Fysiske personer, som vides at have fælles reelt ejerskab af juridiske enheder eller
        juridiske arrangementer eller på anden måde har nære forretningsforbindelser med en politisk
        eksponeret person
      </li>
      <li>
        Fysiske personer, der som de eneste har reelt ejerskab af en juridisk enhed eller et
        juridisk arrangement, som vides at være oprettet til de facto-fordel for en politisk
        eksponeret person
      </li>
    </ol>
  </>
)

export default PepInformation
