import React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import ProgressBar from '../ProgressBar'

import './styles.scss'

const classNameForStage = (activeStage: any, currentStage: any) => {
  if (activeStage === 0 && currentStage === null) {
    return undefined
  }

  if (currentStage === activeStage) {
    return 'active'
  }

  return 'd-none d-md-block'
}

const progressBarRatios = [
  0.415, // size ratio for "Investeringsplan"
  0.315, // size ratio for "Opret depot"
  0.27, // size ratio for "Bekræft"
]

const completedProgressBarRatios = [
  0.0,
  progressBarRatios[0],
  progressBarRatios[0] + progressBarRatios[1],
]

export interface BreadcrumpsProps {
  progress?: number
  stages: string[]
  stage: number
}

const Breadcrumps = ({ progress, stages, stage }: BreadcrumpsProps) => (
  <Container>
    <Row>
      <Col>
        <div className="breadcrumbs-wrapper">
          <ol className="breadcrumbs list-unstyled">
            {stages.map((currentStage, currentIndex) => (
              <li key={currentStage} className={classNameForStage(currentIndex, stage)}>
                {currentIndex + 1}. {currentStage}
              </li>
            ))}
          </ol>
          <ProgressBar
            // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
            progress={progress}
            completedRatio={completedProgressBarRatios[stage] || 0.0}
            currentRatio={progressBarRatios[stage] || 0.0}
          />
        </div>
      </Col>
    </Row>
  </Container>
)

Breadcrumps.defaultProps = {
  progress: 0,
}

export default Breadcrumps
