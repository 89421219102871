import BooleanInput from '@nord/ui/src/components/BooleanInput'
import Form from '@nord/ui/src/components/Form'
import Paginator from '@nord/ui/src/components/Paginator'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { object, boolean } from 'yup'

import ContentTitle from '../../ContentTitle'

const validationSchema = object().shape({
  understandsCompanyEtfs: boolean().required().accepted(),
})

const labels = {
  understandsCompanyEtfs: 'Jeg bekræfter, at jeg har læst og forstået hvad en ETF er.',
}

const fields = Object.keys(labels)

const ETFPage = (otherProps: any) => (
  <Paginator.Form.Page
    validationSchema={validationSchema}
    labelDescriptions={labels}
    fields={fields}
    {...otherProps}
  >
    <ContentTitle title="Hvad er en ETF?" />
    <Row>
      <Col xs={12}>
        <p className="text-center">
          Når du investerer via Norm Invest, investeres der i en type indeksfonde, der hedder Exchange
          Traded Funds (ETF'er).skat Grunden til, at vi investerer i ETF'er er, at de har meget lave
          omkostninger og høj risikospredning. Via dit ejerskab i disse ETF'er får du ejerandele i
          mere end 5.000 forskellige aktieselskaber og obligationer fra hele verden.
        </p>
      </Col>
    </Row>
    <Row>
      <Col xs={12} className="text-center">
        <Form.FieldGroup name="understandsCompanyEtfs" label={false}>
          {/* @ts-expect-error TS(2739) FIXME: Type '{ ({ id, name, label, type, onChange, onValu... Remove this comment to see the full error message */}
          <Form.Field inputComponent={BooleanInput} asFormControl />
        </Form.FieldGroup>
      </Col>
    </Row>
  </Paginator.Form.Page>
)

export default ETFPage
