import anime from 'animejs'
import React, { useState, useEffect } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'

export interface Props {
  timeoutLimit?: number
}

const defaultTimeoutLimit = 30 * 1000

const TimeoutProgressBar = ({ timeoutLimit, ...props }: Props) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const animationProps = { progress: 0 }

    anime({
      targets: animationProps,
      progress: 100,
      duration: timeoutLimit || defaultTimeoutLimit,
      round: 1,
      easing: 'easeOutQuad',
      update: () => setProgress(animationProps.progress),
    })

    return () => anime.remove(animationProps)
  }, [timeoutLimit])

  return <ProgressBar now={progress} animated {...props} />
}

export default TimeoutProgressBar
