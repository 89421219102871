import React, { useRef, useImperativeHandle, forwardRef } from 'react'
import ReactWebcam from 'react-webcam'

export interface Props {
  audio?: boolean
  imageSmoothing?: boolean
  mirrored?: boolean
  onError?: (...args: any[]) => any
  screenshotFormat?: string
}

const Webcam = forwardRef<any, Props>(({ onError, ...otherProps }, ref) => {
  const webcamRef = useRef()

  useImperativeHandle(ref, () => ({
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    capture: () => webcamRef.current.getScreenshot(),
  }))

  // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
  return <ReactWebcam ref={webcamRef} onUserMediaError={onError} {...otherProps} />
})

// @ts-expect-error TS(2339) FIXME: Property 'displayName' does not exist on type '(pr... Remove this comment to see the full error message
Webcam.displayName = 'Webcam'

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type '(p... Remove this comment to see the full error message
Webcam.defaultProps = {
  audio: false,
  imageSmoothing: false,
  mirrored: true,
  onError: undefined,
  screenshotFormat: 'image/jpeg',
}

export default Webcam
