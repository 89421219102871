import IconButton from '@nord/ui/src/components/IconButton'
import RiskScoreCard from '@nord/ui/src/components/RiskScoreCard'
import React, { useContext } from 'react'
import Col from 'react-bootstrap/Col'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Info from 'react-feather/dist/icons/info'

import { useReferralContext } from '../../../Referral/ReferralContext'

import ResultPageContext from './ResultPageContext'

export interface Props {
  advisedScore?: number
  callToActionButton?: React.ReactNode
  riskScoreAdvisedState: string
  secondaryCallToAction?: React.ReactNode
}

const RiskScore = ({
  advisedScore,
  callToActionButton,
  secondaryCallToAction,
  riskScoreAdvisedState,
}: Props) => {
  const { referralIsValid } = useReferralContext()
  // @ts-expect-error TS(2339) FIXME: Property 'onRiskScoreChange' does not exist on typ... Remove this comment to see the full error message
  const { onRiskScoreChange, riskScore, esg } = useContext(ResultPageContext)

  let advisedRiskScoreText
  if (riskScoreAdvisedState === 'not_advised')
    advisedRiskScoreText = 'Din valgte risikoprofil afviger fra vores anbefaling'
  if (riskScoreAdvisedState === 'strongly_not_advised')
    advisedRiskScoreText = 'Din valgte risikoprofil er for langt fra vores anbefaling'

  return (
    <Col xs={12} lg={4} xl={{ offset: 1, span: 3 }}>
      <RiskScoreCard
        // @ts-expect-error TS(2322) FIXME: Type '{ className: string; advisedRiskScoreText: s... Remove this comment to see the full error message
        className="mb-grid"
        advisedRiskScoreText={advisedRiskScoreText}
        riskScore={riskScore}
        onValueChange={onRiskScoreChange}
        esg={esg}
        data-cy="Risikoprofil"
        footer={
          secondaryCallToAction && <div className="text-center mt-5">{secondaryCallToAction}</div>
        }
        content={callToActionButton && <div className="text-center mt-4">{callToActionButton}</div>}
        renderTitle={() => (
          <>
            <RiskScoreCard.Title>Risikoprofil</RiskScoreCard.Title>
            {advisedScore && (
              <div className="text-muted">
                Vores anbefaling baseret på din besvarelse:
                {` ${advisedScore}`}
                <OverlayTrigger
                  overlay={
                    // @ts-expect-error TS(2741) FIXME: Property 'id' is missing in type '{ children: stri... Remove this comment to see the full error message
                    <Tooltip>
                      Du kan vælge en anden risikoprofil end den vi anbefaler, men du bliver så
                      eksponeret for en større eller mindre risiko end det som dine forhold ligger
                      op til.
                    </Tooltip>
                  }
                >
                  <IconButton icon={Info} variant="link" className="p-0" />
                </OverlayTrigger>
              </div>
            )}
          </>
        )}
        // TODO: Remove this prop also in storybook when HeadersWrapper is done
        withReferralBanner={referralIsValid}
        enableESGSwitch
        enableStocksOnlySwitch
      />
    </Col>
  )
}

RiskScore.defaultProps = {
  advisedScore: undefined,
  callToActionButton: undefined,
  secondaryCallToAction: undefined,
}

export default RiskScore
