import React from 'react'

import Basic from './Basic'
import googlePlayBadge from './google-play-badge.png'

const googlePlayStoreUrl = 'https://play.google.com/store/apps/details?id=investments.nord'
const defaultAlt = 'Nu på Google Play'

export interface Props {
  alt?: string
}

const Google = (props: Props) => (
  <Basic href={googlePlayStoreUrl} src={googlePlayBadge} {...props} />
)

Google.defaultProps = {
  alt: defaultAlt,
}

export default Google
