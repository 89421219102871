import queryString from 'query-string'
import React from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ArrowRight from 'react-feather/dist/icons/arrow-right'

import { PATHS } from '../../../../../../../configuration/constants'
import IconButtonLink from '../../../../../../IconButtonLink'

const SignIn = ({ ...otherProps }) => {
  const { email } = queryString.parse(window.location.search)

  return (
    <IconButtonLink
      variant="success"
      icon={ArrowRight}
      iconPlacement="last"
      to={{
        pathname: PATHS.shared.userSignIn(),
        search: `?${queryString.stringify({
          email,
        })}`,
      }}
      filled
      {...otherProps}
    >
      Start din investering nu
    </IconButtonLink>
  )
}

export default SignIn
