import device from 'current-device'
import { isMatch } from 'date-fns'
import { padEnd } from 'lodash'
import React, { useEffect, useState } from 'react'
import FormControl from 'react-bootstrap/FormControl'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'

import useDetectBrowser from '../hooks/useDetectBrowser'
import chainEventHandler from '../utilities/chainEventHandler'
import isSSR from '../utilities/isSSR'

import MaskedInput from './MaskedInput'

// input[type="date"] is not supported only on desktop on IE & Safari
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date

const mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]

const formatter = (value: any) => {
  if (!value) return ''

  const [year, month, date] = value.split('-')
  const parts = [
    padEnd(date, 2, '_').slice(0, 2),
    padEnd(month, 2, '_').slice(0, 2),
    padEnd(year, 4, '_').slice(0, 4),
  ]

  return parts.join('/')
}

const parser = (inputValue: any) => {
  if (!inputValue) return ''
  const [date, month, year] = inputValue.split('/')

  const parsedDate = `${year}-${month}-${date}`

  return parsedDate
}

const createPipe = ({ format, ...options }: any) => createAutoCorrectedDatePipe(format, options)

export interface DateInputProps {
  browserInput?: boolean
  defaultValue?: string
  onChange?: (...args: any[]) => any
  onValueChange?: (...args: any[]) => any
  pipeOptions?: {
    format: string
    minYear?: number
    maxYear?: number
  }
  value?: string
}

const DateInput = ({
  defaultValue,
  value,
  pipeOptions,
  onChange,
  onValueChange,
  browserInput,
  ...otherProps
}: DateInputProps) => {
  const [internalValue, setInternalValue] = useState(defaultValue)
  const notSupportedBrowsers = useDetectBrowser(['safari', 'ie'])

  const notSupportedBrowser = device.desktop() && notSupportedBrowsers

  const useBrowserInput = !isSSR() && !notSupportedBrowser

  useEffect(() => {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    if (!isMatch(value, 'yyyy-MM-dd')) return
    setInternalValue(value)
  }, [value])

  if (browserInput === true || (browserInput === undefined && useBrowserInput)) {
    const handleChange = chainEventHandler((event: any) => {
      if (!onValueChange) return
      const {
        target: { value: newValue },
      } = event

      onValueChange(newValue)
    }, onChange)

    return (
      <FormControl
        value={value}
        defaultValue={defaultValue}
        type="date"
        {...otherProps}
        onChange={handleChange}
      />
    )
  }

  const handleValueChange = (newValue: any) => {
    setInternalValue(newValue)
    if (!onValueChange) return

    if (isMatch(newValue, 'yyyy-MM-dd')) onValueChange(newValue)
    else onValueChange('')
  }

  return (
    <MaskedInput
      value={internalValue}
      mask={mask}
      createPipe={createPipe}
      // @ts-expect-error TS(2322) FIXME: Type '{ value: string | undefined; mask: (string |... Remove this comment to see the full error message
      placeholder="dd/mm/åååå"
      formatter={formatter}
      parser={parser}
      pipeOptions={pipeOptions}
      onChange={onChange}
      onValueChange={handleValueChange}
      {...otherProps}
    />
  )
}

DateInput.defaultProps = {
  browserInput: undefined,
  defaultValue: undefined,
  onChange: undefined,
  onValueChange: undefined,
  pipeOptions: {
    format: 'dd/mm/yyyy',
  },
  value: undefined,
}

export default DateInput
