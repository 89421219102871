import Form from '@nord/ui/src/components/Form'
import Paginator from '@nord/ui/src/components/Paginator'
import PasswordInput from '@nord/ui/src/components/PasswordInput'
import withStrengthChecker from '@nord/ui/src/components/withStrengthChecker'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { object, string, ref } from 'yup'

import ContentTitle from './ContentTitle'

const PasswordInputWithStrengthChecker = withStrengthChecker(PasswordInput)

const validationSchema = object().shape({
  password: string().required().min(6).max(128),
  passwordConfirmation: string()
    .required()
    .equals([ref('password')], 'Adganskoder stemmer ikke overens'),
})

const labels = {
  email: 'Email',
  password: 'Adgangskode',
  passwordConfirmation: 'Bekræft adgangskode',
}

const fields = Object.keys(labels)

const CreatePasswordPage = (otherProps: any) => (
  <Paginator.Form.Page
    validationSchema={validationSchema}
    labelDescriptions={labels}
    fields={fields}
    {...otherProps}
  >
    <ContentTitle title="Opret en adgangskode til din konto" />
    <Row>
      <Col xs={12}>
        <Form.FieldGroup name="email">
          {/* @ts-expect-error TS(2322) FIXME: Type '{ type: string; disabled: true; }' is not as... Remove this comment to see the full error message */}
          <Form.Field type="email" disabled />
        </Form.FieldGroup>
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <Form.FieldGroup name="password">
          {/* @ts-expect-error TS(2739) FIXME: Type '{ ({ onBlur, onFocus, onChange, userInputs, ... Remove this comment to see the full error message */}
          <Form.Field inputComponent={PasswordInputWithStrengthChecker} />
        </Form.FieldGroup>
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <Form.FieldGroup name="passwordConfirmation">
          {/* @ts-expect-error TS(2322) FIXME: Type '(props: Props & RefAttributes<any>) => React... Remove this comment to see the full error message */}
          <Form.Field inputComponent={PasswordInput} />
        </Form.FieldGroup>
      </Col>
    </Row>
  </Paginator.Form.Page>
)

export default CreatePasswordPage
