import { object, string, boolean, date } from 'yup'

const validationSchema = object().shape({
  cpr: string()
    .matches(/^[0-9]*$/, 'Må kun bestå af tal')
    .required()
    .length(10),
  birthdate: date().required(),
  firstName: string().required(),
  lastName: string().required(),
  address: string().required(),
  postalCode: string().required(),
  city: string().required(),
  country: string().required(),
  nationality: string().required(),
  pep: boolean().default(false),
  americanCitizen: boolean().default(false),
  americanTaxpayer: boolean().default(false),
  americanTaxNumber: string().when('americanTaxpayer', {
    is: true,
    then: string().numerical().required().length(9),
  }),
})

export default validationSchema
