import Form from '@nord/ui/src/components/Form'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import React from 'react'

import { selectCurrentUser } from '../../store/current/user'

const SourceOfFundsField = (props: any) => {
  // @ts-expect-error TS(2339) FIXME: Property 'email' does not exist on type 'unknown'.
  const { email } = useObjectSelector(selectCurrentUser)

  return (
    <Form.FieldGroup
      name="sourceOfFunds"
      help="Vi skal bruge dokumentation på, hvor de penge du forventer at investere, kommer fra (f.eks. lønsedler, bevis på arv, bevis på salg af bolig mm.). Har du ikke dette tilgængelig nu, kan du forsætte uden og tilsende dokumentation senere."
    >
      <Form.Fields.File {...props} email={email} dropzone />
    </Form.FieldGroup>
  )
}

export default SourceOfFundsField
