import React from 'react'
import { PieChart, Pie, Cell } from 'recharts'

import Breakpoint from '../../../../../Breakpoint'
import ResponsiveContainer from '../../../../../ResponsiveContainer'
import colors from '../colors'

import styles from './index.module.scss'
import renderActiveShape from './renderActiveShape'

export interface Props {
  currentHover?: number
  data?: {
    name?: string
    value?: number
  }[]
  onCurrentHoverChange: (...args: any[]) => any
}

const Chart = ({ data, currentHover, onCurrentHoverChange }: Props) => {
  const handleMouseEnter = (_: any, index: any) => onCurrentHoverChange(index)

  const handleMouseLeave = () => onCurrentHoverChange(null)

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: (large: any) => Element; size: s... Remove this comment to see the full error message
    <Breakpoint size="sm">
      {(large: any) => {
        const marginTop = large ? 0 : 20

        return (
          <div className={styles.allocationWrapper}>
            <ResponsiveContainer>
              {/* @ts-expect-error TS(2322) FIXME: Type '({ width }: any) => JSX.Element' is not assi... Remove this comment to see the full error message */}
              {({ width }: any) => {
                const formattedWidth = large ? width / 1.5 : width
                const height = large ? formattedWidth : width + marginTop
                const yCenter = large ? height / 2 : width / 3 + marginTop

                return (
                  <PieChart
                    margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                    width={formattedWidth}
                    height={height}
                    className="ml-auto mt-2 mb-n6 mb-lg-0"
                  >
                    <Pie
                      dataKey="value"
                      data={data}
                      outerRadius="80%"
                      activeIndex={currentHover}
                      activeShape={large && renderActiveShape}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      cx={large ? '30%' : '50%'}
                      cy={yCenter}
                      isAnimationActive
                    >
                      {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
                      {data.map((entry, index) => (
                        <Cell key={entry.name} fill={colors[index]} stroke={colors[index]} />
                      ))}
                    </Pie>
                  </PieChart>
                )
              }}
            </ResponsiveContainer>
          </div>
        )
      }}
    </Breakpoint>
  )
}

Chart.defaultProps = {
  currentHover: null,
  data: [],
}

export default Chart
