import React from 'react'

export interface Props {
  progress: number
  completedRatio: number
  currentRatio: number
}

const ProgressBar = ({ progress, completedRatio, currentRatio }: Props) => (
  <div className="progress-bar-wrapper">
    <span
      className="progress-bar"
      style={{
        transform: `translate3d(${Math.round(
          (completedRatio + currentRatio * progress - 1) * 100,
        )}%, 0, 0)`,
      }}
    />
  </div>
)

export default ProgressBar
