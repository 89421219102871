import React from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ArrowRight from 'react-feather/dist/icons/arrow-right'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import { COMPANY_PATHS, SHARED_PATHS } from '../../../configuration/constants'
import useSignedIn from '../../../hooks/useSignedIn'
import IconButtonLink from '../../IconButtonLink'
import ContentContainer from '../ContentContainer'
import ContentTitle from '../ContentTitle'

const StartPage = () => {
  const signedIn = useSignedIn()

  return (
    <ContentContainer>
      <ContentTitle title="Få en investeringsplan til din virksomhed - gratis" />
      <Row>
        <Col>
          <p className="subheader">
            Om under 5 minutter har du investeringsplan til din virksomhed. Når du er færdig har du
            muligheden for at færdiggøre din oprettelse og starte din investering.
          </p>
          <p>
            <IconButtonLink
              variant="primary"
              to={COMPANY_PATHS.investmentPlan('ny')}
              icon={ArrowRight}
              iconPlacement="last"
              filled
            >
              Kom i gang
            </IconButtonLink>
          </p>
          {!signedIn && (
            <p>
              <Button
                variant="link"
                className="text-base"
                type={undefined}
                as={Link}
                to={SHARED_PATHS.userSignIn()}
              >
                Er du eksisterende bruger? Log ind her
              </Button>
            </p>
          )}
        </Col>
      </Row>
    </ContentContainer>
  )
}

export default StartPage
