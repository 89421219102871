import { useInitialValues } from '@nord/ui/src/components/Form'
import Paginator from '@nord/ui/src/components/Paginator'
import useApi from '@nord/ui/src/hooks/useApi'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import useQuery from '@nord/ui/src/hooks/useQuery'
import React, { useMemo, useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useHistory } from 'react-router-dom'

import { PENSION_PATHS } from '../../../../configuration/constants'
import useCachedPages from '../../../../hooks/useCachedPages'
import { selectInitialLoading } from '../../../../store/current/loading'
import {
  completeCurrentPortfolioInvestmentPlan,
  selectCurrentPortfolio,
} from '../../../../store/current/portfolioId'
import { fetchCurrentUser, selectCurrentUser } from '../../../../store/current/user'
import { progressChanged } from '../../../../store/ui/progressSlice'
import defaultToUndefined from '../../../../utilities/defaultToUndefined'

import BirthdatePage from './BirthdatePage'
import ExpectedInvestmentPage from './ExpectedInvestmentPage'
import InvestmentPlanContext from './InvestmentPlanContext'
import Navigation from './Navigation'
import PensionsInfoStartPage from './PensionsInfoStartPage'
import SelectTypePage from './SelectTypePage'
import SustainabilityPreferenceQuestion from './SustainabilityPreferenceQuestion'

export const useCreateInvestmentPlan = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const getPensionRiskScore = useApi('/risk_profile/pension_risk_data')

  const createInvestmentPlan = async ({
    depotType,
    birthdate,
    kreditdataId,
    cpr,
    ...otherValues
  }: any) => {
    const {
      data: { riskScore: newAdvisedRiskScore },
    } = await getPensionRiskScore({
      depotType,
      birthdate,
    })

    const valuesWithAdvisedRiskScore = {
      ...otherValues,
      depotType,
      pensionDepot: true,
      advisedRiskScore: newAdvisedRiskScore,
      user: { birthdate, cpr, kreditdataId },
    }

    // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type '(dispatch: a... Remove this comment to see the full error message
    const { id: newPortfolioId } = await dispatch(
      completeCurrentPortfolioInvestmentPlan(valuesWithAdvisedRiskScore),
    )

    history.push(PENSION_PATHS.investmentPlanResult(newPortfolioId))
  }

  return createInvestmentPlan
}

const usePages = () => {
  const loading = useSelector(selectInitialLoading)
  // @ts-expect-error TS(2339) FIXME: Property 'birthdate' does not exist on type 'unkno... Remove this comment to see the full error message
  const { birthdate } = useObjectSelector(selectCurrentUser)
  const currentPortfolio = useObjectSelector(selectCurrentPortfolio)
  const pensionsInfo = useQuery('pensionsInfo')

  // @ts-expect-error TS(2339) FIXME: Property 'depotType' does not exist on type 'unkno... Remove this comment to see the full error message
  const { depotType } = currentPortfolio

  return useCachedPages(
    [
      { page: PensionsInfoStartPage, condition: !pensionsInfo },
      { page: SelectTypePage, condition: !depotType },
      { page: BirthdatePage, condition: !birthdate },
      { page: SustainabilityPreferenceQuestion, condition: true },
      { page: ExpectedInvestmentPage, condition: true },
    ],
    // Do not include depotType or birthdate, as this would change the page order
    [loading],
  )
}

const fields = ['birthdate', 'depotType', 'expectedInvestment']

const InvestmentPlan = () => {
  const loading = useSelector(selectInitialLoading)
  const dispatch = useDispatch()
  const {
    // @ts-expect-error TS(2339) FIXME: Property 'depotType' does not exist on type 'unkno... Remove this comment to see the full error message
    depotType,
    // @ts-expect-error TS(2339) FIXME: Property 'expectedInvestment' does not exist on ty... Remove this comment to see the full error message
    expectedInvestment,
    // @ts-expect-error TS(2339) FIXME: Property 'migrationDepot' does not exist on type '... Remove this comment to see the full error message
    migrationDepot,
    // @ts-expect-error TS(2339) FIXME: Property 'migrationDepotCompanyName' does not exis... Remove this comment to see the full error message
    migrationDepotCompanyName,
    // @ts-expect-error TS(2339) FIXME: Property 'migrationDepotAccountNumber' does not ex... Remove this comment to see the full error message
    migrationDepotAccountNumber,
    // @ts-expect-error TS(2339) FIXME: Property 'migrationDepotRegistrationNumber' does n... Remove this comment to see the full error message
    migrationDepotRegistrationNumber,
    // @ts-expect-error TS(2339) FIXME: Property 'migrationDepotNumber' does not exist on ... Remove this comment to see the full error message
    migrationDepotNumber,
    // @ts-expect-error TS(2339) FIXME: Property 'migrationPensionPoliceNumber' does not e... Remove this comment to see the full error message
    migrationPensionPoliceNumber,
    // @ts-expect-error TS(2339) FIXME: Property 'migrationPensionCompanyType' does not ex... Remove this comment to see the full error message
    migrationPensionCompanyType,
    // @ts-expect-error TS(2339) FIXME: Property 'pensionsInfoMigration' does not exist on... Remove this comment to see the full error message
    pensionsInfoMigration,
    // @ts-expect-error TS(2339) FIXME: Property 'kreditdataId' does not exist on type 'un... Remove this comment to see the full error message
    kreditdataId,
  } = useObjectSelector(selectCurrentPortfolio)
  // @ts-expect-error TS(2339) FIXME: Property 'birthdate' does not exist on type 'unkno... Remove this comment to see the full error message
  const { birthdate } = useObjectSelector(selectCurrentUser)
  const unformattedInitialValues = useMemo(
    () => ({
      birthdate,
      depotType: depotType || 'ratepension',
      expectedInvestment,
    }),
    [birthdate, depotType, expectedInvestment],
  )
  const initialValues = useInitialValues({
    autoInitialValues: true,
    initialValues: unformattedInitialValues,
    fields,
  })
  const [formState, setFormState] = useState(initialValues)

  const [investmentProfileAttributes, setInvestmentProfileAttributes] = useState({})

  const context = useMemo(
    () => ({
      investmentProfileAttributes,
      setInvestmentProfileAttributes,
    }),
    [investmentProfileAttributes],
  )

  const createInvestmentPlan = useCreateInvestmentPlan()
  const pages = usePages()
  const initiallyCompleted = pages.length === 0

  const pensionInfoMigrationAttributes = useMemo(
    () => ({
      migrationDepot,
      migrationDepotCompanyName,
      migrationPensionCompanyType,
      migrationDepotAccountNumber,
      migrationDepotRegistrationNumber,
      migrationDepotNumber,
      migrationPensionPoliceNumber,
      pensionsInfoMigration,
      kreditdataId,
    }),
    [
      migrationDepot,
      migrationDepotCompanyName,
      migrationPensionCompanyType,
      migrationDepotAccountNumber,
      migrationDepotRegistrationNumber,
      migrationDepotNumber,
      migrationPensionPoliceNumber,
      pensionsInfoMigration,
      kreditdataId,
    ],
  )

  const handleSubmit = useCallback(
    (newValues: any) => {
      setFormState({ ...formState, ...newValues })
    },
    [formState],
  )

  const handleFinalSubmit = useCallback(
    async (newValues: any) => {
      createInvestmentPlan({
        ...formState,
        ...newValues,
        ...pensionInfoMigrationAttributes,
        investmentProfileAttributes,
      })
    },
    [investmentProfileAttributes, createInvestmentPlan, formState, pensionInfoMigrationAttributes],
  )

  const beforePageChange = ({ number, count }: any) => {
    const progress = number / count

    // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
    dispatch(fetchCurrentUser({ errorHandling: { ignore: { unauthorized: true } } }))
    dispatch(progressChanged(progress))
  }

  useEffect(() => {
    if (initiallyCompleted)
      handleFinalSubmit({
        depotType,
        birthdate,
        expectedInvestment,
        ...pensionInfoMigrationAttributes,
      })
  }, [
    birthdate,
    depotType,
    expectedInvestment,
    handleFinalSubmit,
    initiallyCompleted,
    pensionInfoMigrationAttributes,
  ])

  useEffect(() => {
    if (!loading) setFormState(initialValues)
  }, [initialValues, loading])

  return (
    <InvestmentPlanContext.Provider value={context}>
      {/* @ts-expect-error TS(2322) FIXME: Type '{ children: any; beforePageChange: ({ number... Remove this comment to see the full error message */}
      <Paginator.Form.Wrapper.WithQueryParam
        beforePageChange={beforePageChange}
        navigationComponent={Navigation}
        onSubmit={handleSubmit}
        onFinalSubmit={handleFinalSubmit}
        initialValues={defaultToUndefined(initialValues)}
        loading={loading}
        showPagination
        useOnLoad
      >
        {pages.map((Page) => (
          <Page key={Page.displayName || Page.name} />
        ))}
      </Paginator.Form.Wrapper.WithQueryParam>
    </InvestmentPlanContext.Provider>
  )
}

InvestmentPlan.SelectTypePage = SelectTypePage
InvestmentPlan.ExpectedInvestmentPage = ExpectedInvestmentPage

export default InvestmentPlan
