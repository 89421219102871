import IconButton from '@nord/ui/src/components/IconButton'
import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ArrowLeft from 'react-feather/dist/icons/arrow-left'

export interface Props {
  buttonText: string
  infoText: string
  modalTitle: string
}

const InfoModal = ({ buttonText, infoText, modalTitle }: Props) => {
  const [showModal, setShowModal] = useState(false)

  const handleShowModal = () => setShowModal(true)

  const handleHideModal = () => setShowModal(false)

  return (
    <>
      <Button variant="link" className="text-base" onClick={handleShowModal}>
        {buttonText}
      </Button>

      {/* @ts-expect-error TS(2322) FIXME: Type '"md"' is not assignable to type '"sm" | "lg"... Remove this comment to see the full error message */}
      <Modal show={showModal} onHide={handleHideModal} size="md" className="text-dark" centered>
        <Modal.Header closeButton>
          <Modal.Title className="w-100 text-center">{modalTitle}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="text-center">{infoText}</p>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <IconButton
            iconPlacement="first"
            variant="primary"
            icon={ArrowLeft}
            onClick={handleHideModal}
          >
            Tilbage
          </IconButton>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default InfoModal
