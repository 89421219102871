import classNames from 'classnames'
import React from 'react'

import CheckItem from './CheckItem'

const childrenMargin = {
  md: 1,
  lg: 3,
}

export interface Props {
  children: React.ReactNode
  iconVariant?: string
  className?: string
  size?: string
  textVariant?: string
}

const CheckList = ({ children, className, size, iconVariant, textVariant }: Props) => (
  <div className={classNames('d-flex justify-content-center justify-content-lg-start', className)}>
    {/* @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type. */}
    <ul className={`list-unstyled children-my-${childrenMargin[size]}`}>
      {React.Children.map(children, (child) =>
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        React.cloneElement(child, {
          iconVariant,
          textVariant,
          size,
          // @ts-expect-error TS(2533) FIXME: Object is possibly 'null' or 'undefined'.
          ...child.props,
        }),
      )}
    </ul>
  </div>
)

CheckList.defaultProps = {
  className: undefined,
  iconVariant: 'success',
  size: 'lg',
  textVariant: 'white',
}

CheckList.Item = CheckItem

export default CheckList
