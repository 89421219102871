import { addMonths, startOfMonth, getUnixTime } from 'date-fns'
import findIndex from 'lodash/findIndex'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useMemo, useState } from 'react'

import { getConfig } from '../../configuration'
import useApi, { useApiRequest } from '../../hooks/useApi'
import { valueToDate } from '../../utilities/formatDate'

import ChartBrush from './ChartBrush'
import ChartWrapper from './ChartWrapper'
import { nordStartDate } from './constants'
import Header from './Header'
import HistoricalReturnContext from './HistoricalReturnContext'
import styles from './index.module.scss'
import ReturnTable from './ReturnTable'

const {
  privateAndCompany: privateAndCompanyHistoricalReturnUrl,
  pension: pensionHistoricalReturn,
} = getConfig('urls.external.historicalReturn')

const formatData = (data: any) => {
  if (!data) return []

  const formattedData = data.values.map((value: any, index: any) => {
    const timestamp = data.timestamps[index]
    // TODO: Fix date format in API
    const date = startOfMonth(addMonths(valueToDate(timestamp), 1))

    return {
      value,
      timestamp: getUnixTime(date),
      date,
    }
  })

  return formattedData
}

export interface HistoricalReturnProps {
  children?: React.ReactNode
  depotType?: string
  riskScore?: number
}

const HistoricalReturn = ({ children, riskScore, depotType }: HistoricalReturnProps) => {
  const [brushStartIndex, setBrushStartIndex] = useState(0)
  const [brushEndIndex, setBrushEndIndex] = useState(0)

  const handleBrushStartIndexChange = (value: any) => setBrushStartIndex(value)
  const handleBrushEndIndexChange = (value: any) => setBrushEndIndex(value)

  const isPension = depotType === 'pension'

  const fetchHistoricalReturn = useApi('', {
    baseURL: isPension ? pensionHistoricalReturn : privateAndCompanyHistoricalReturnUrl,
    method: 'POST',
  })

  const { data, loading } = useApiRequest(fetchHistoricalReturn, {
    autoCall: true,
    enableDebounce: true,
    payload: {
      risk: riskScore,
    },
  })

  const chartData = useMemo(() => formatData(data), [data])

  const nordStartIndex = isEmpty(chartData)
    ? 0
    : findIndex(chartData, {
        date: nordStartDate,
      })

  useEffect(() => {
    if (chartData.length === 0) return

    handleBrushEndIndexChange(chartData.length - 1)
  }, [chartData.length])

  const nordStartPoint =
    chartData && chartData.length > 0
      ? (nordStartIndex - brushStartIndex) / (brushEndIndex - brushStartIndex)
      : 1

  const context = useMemo(
    () => ({
      brushStartIndex,
      brushEndIndex,
      chartData,
      depotType,
      nordStartPoint,
      nordStartIndex,
      riskScore,
      loading,
      onBrushStartIndexChange: handleBrushStartIndexChange,
      onBrushEndIndexChange: handleBrushEndIndexChange,
    }),
    [
      brushEndIndex,
      brushStartIndex,
      chartData,
      depotType,
      loading,
      nordStartIndex,
      nordStartPoint,
      riskScore,
    ],
  )

  return (
    <HistoricalReturnContext.Provider value={context}>
      <div className={styles.historicalReturnWrapper}>{children}</div>
    </HistoricalReturnContext.Provider>
  )
}

HistoricalReturn.defaultProps = {
  children: undefined,
  depotType: 'free_assets',
  riskScore: 10,
}

HistoricalReturn.Header = Header
HistoricalReturn.Chart = ChartWrapper
HistoricalReturn.Brush = ChartBrush
HistoricalReturn.Table = ReturnTable

export default HistoricalReturn
