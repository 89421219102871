import CurrencyInput from '@nord/ui/src/components/CurrencyInput'
import Loader from '@nord/ui/src/components/Loader'
import RangeInput from '@nord/ui/src/components/RangeInput'
import { getConfig } from '@nord/ui/src/configuration'
import useApi, { useApiRequest } from '@nord/ui/src/hooks/useApi'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import React, { useState, useEffect, useMemo, useContext, useCallback } from 'react'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import FormControl from 'react-bootstrap/FormControl'
import FormLabel from 'react-bootstrap/FormLabel'
import Row from 'react-bootstrap/Row'
import { ComposedChart, XAxis, YAxis, Legend, Area, Line, Tooltip } from 'recharts'

import Breakpoint from '../../../../Breakpoint'
import ResponsiveContainer from '../../../../ResponsiveContainer'

import colors from './colors'
import Costs from './Costs'
import styles from './ExpectedReturn.module.scss'
import ResultPageContext from './ResultPageContext'
import Section from './Section'

const initialInvestmentSteps = {
  0: 1e4, // 10 000
  0.1: 3e4, // 30 000
  0.2: 5e4, // 50 000
  0.3: 1e5, // 100 000
  0.4: 2e5, // 200 000
  0.5: 3e5, // 300 000
  0.6: 4e5, // 400 000
  0.7: 5e5, // 500 000
  0.75: 1e6, // 1 000 000
  0.8: 2e6, // 2 000 000
  0.85: 3e6, // 3 000 000
  0.9: 5e6, // 5 000 000
  1: 10e6, // 10 000 000
}

const monthlyInvestmentSteps = [0, 1000, 2000, 3000, 5000, 7000, 8000, 10000, 15000, 20000, 30000]

const defaultMonthlyInvestment = 0

const minimumInvestment = getConfig('numbers.minimumInvestment.default')
const expectedReturnURL = getConfig('urls.external.expectedReturn')

const formatData = (
  data: undefined | Record<string, unknown[]>,
  initialInvestment: any,
  monthlyInvestment: any,
) => {
  if (!data) return []

  const formattedData =
    data &&
    Object.entries(data).reduce((result, [name, values]) => {
      values.forEach((value, index) => {
        result[index] = {
          years: index,
          ...result[index],
          [name]: value,
        }
      })

      return result
    }, [] as Record<string, unknown>[])

  formattedData.forEach((dataPoint: any, index: any) => {
    dataPoint.TotalPayment = initialInvestment + monthlyInvestment * index * 12
  })

  return formattedData
}

const ExpectedReturn = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'riskScore' does not exist on type 'unkno... Remove this comment to see the full error message
  const { riskScore, expectedInvestment, initialInvestment, onInitialInvestmentChange, simple } =
    useContext(ResultPageContext)

  const [activeTooltipIndex, setActiveTooltipIndex] = useState(10)
  const [monthlyInvestment, setMonthlyInvestment] = useState(defaultMonthlyInvestment)

  const fetchData = useApi('', {
    baseURL: expectedReturnURL,
    method: 'POST',
  })

  const initialAmount = initialInvestment || 0
  const monthlyAmount = monthlyInvestment || 0

  const { data: rawData, loading } = useApiRequest(fetchData, {
    autoCall: true,
    enableDebounce: true,
    payload: {
      risk: riskScore,
      years: 31,
      initialAmount,
      monthlyAmount,
      isSimple: simple,
    },
  })

  const data = useMemo(
    () => formatData(rawData, initialInvestment, monthlyInvestment),
    [initialInvestment, monthlyInvestment, rawData],
  )

  useEffect(() => {
    if (!expectedInvestment) return
    const newInitialInvestment = expectedInvestment

    onInitialInvestmentChange(newInitialInvestment)
  }, [expectedInvestment, onInitialInvestmentChange])

  const handleChangeExpectedInitialInvestment = (newInitialInvestment: any) =>
    onInitialInvestmentChange(newInitialInvestment)

  const handleChangeExpectedMonthlyInvestment = (newMonthlyInvestment: any) => {
    setMonthlyInvestment(newMonthlyInvestment)
  }

  const handleHoverChart = ({
    activeTooltipIndex: newActiveTooltipIndex,
    isTooltipActive,
  }: any) => {
    if (isTooltipActive) {
      setActiveTooltipIndex(newActiveTooltipIndex)
    }
  }

  const TooltipContent = useCallback(
    ({ payload, label }: any) =>
      payload &&
      payload.length && (
        <Card>
          <Card.Body>
            <Card.Text>{label === 0 ? `I dag` : `Om ${label} år`}</Card.Text>
            <ul className="list-unstyled text-left mb-0">
              {payload.map(({ color, value }: any) => (
                <li style={{ color }} key={color}>
                  {numberToCurrency(value)}
                </li>
              ))}
            </ul>
          </Card.Body>
        </Card>
      ),
    [],
  )

  let investmentValidation
  if (initialInvestment < minimumInvestment) {
    investmentValidation = `Min investeringsbeløb er ${numberToCurrency(minimumInvestment)}`
  } else if (initialInvestment > 1000000000) {
    onInitialInvestmentChange(1000000000)
  } else {
    investmentValidation = null
  }

  const renderChart = (large: any) => {
    const currentYear = new Date().getFullYear()

    if (data === undefined) {
      return data
    }

    return (
      <ResponsiveContainer>
        {/* @ts-expect-error TS(2322) FIXME: Type '({ width }: any) => JSX.Element' is not assi... Remove this comment to see the full error message */}
        {({ width }: any) => {
          const chartHeight = (width / 5) * 2
          const chartInnerHeight = chartHeight - 30
          const widthRatio = 1 - 150 / (width - 60)
          const marginTop = large ? 0 : 96 - Math.min(chartInnerHeight * widthRatio, 96)
          const marginRight = 5
          const height = Math.max(chartHeight + marginTop, 300)
          const legendPayload = [
            {
              value: <span className="text-dark">Højest forventede afkast</span>,
              type: 'circle',
              color: colors[4],
            },
            {
              value: <span className="text-dark">Forventede afkast</span>,
              type: 'circle',
              color: colors[2],
            },
            {
              value: <span className="text-dark">Lavest forventede afkast</span>,
              type: 'circle',
              color: colors[0],
            },
            {
              value: <span className="text-dark">Samlet indbetaling</span>,
              type: 'plainline',
              color: '#00FF96',
              payload: { strokeDasharray: '1 0' },
            },
          ]

          return (
            <div className={styles.expectedReturnWrapper}>
              <ComposedChart
                margin={{
                  top: marginTop,
                  right: -marginRight,
                  bottom: 0,
                  left: 0,
                }}
                data={data}
                onMouseMove={handleHoverChart}
                width={width + marginRight}
                height={height}
                className="absolute-legend"
              >
                <XAxis
                  dataKey="years"
                  tickFormatter={(years) => (years === 0 ? 'I dag' : currentYear + years)}
                  interval="preserveStartEnd"
                  type="number"
                  tickCount={6}
                  tickMargin={10}
                />
                <YAxis
                  orientation="right"
                  domain={[0, 'auto']}
                  tickMargin={5}
                  width={60}
                  tickFormatter={(value) => `${(value / 1000 / 1000).toLocaleString('da')} mil.`}
                />
                <Tooltip content={TooltipContent} />
                <Legend
                  layout="vertical"
                  align="left"
                  verticalAlign="top"
                  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
                  payload={legendPayload}
                  // wrapperStyle={{
                  //   top: 0,
                  //   width: 0
                  // }}
                />
                <Area
                  type="monotone"
                  stackId="1"
                  fillOpacity={1}
                  stroke={colors[4]}
                  fill={colors[4]}
                  dataKey="bestCase"
                />
                <Area
                  type="monotone"
                  stackId="2"
                  fillOpacity={1}
                  stroke={colors[2]}
                  fill={colors[2]}
                  dataKey="expectedCase"
                />
                <Area
                  type="monotone"
                  stackId="3"
                  fillOpacity={1}
                  stroke={colors[0]}
                  fill={colors[0]}
                  dataKey="worstCase"
                />
                <Line
                  type="monotone"
                  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
                  stackId="4"
                  fillOpacity={1}
                  stroke="#00FF96"
                  fill="#00FF96"
                  dataKey="TotalPayment"
                  dot={false}
                  strokeDasharray="10 5"
                />
              </ComposedChart>
              {loading && <Loader className="font-size-xl text-secondary" centeredInParent />}
            </div>
          )
        }}
      </ResponsiveContainer>
    )
  }

  const currentPayload = (data || [])[activeTooltipIndex]

  return (
    <>
      {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; title: string; id: st... Remove this comment to see the full error message */}
      <Section title="Forventet afkast" id="forventet-afkast">
        <Row>
          <Col>
            <p className="text-muted subheader">
              Du bestemmer selv startindskud og evt. månedligt indskud og kan herunder se hvordan
              disse påvirker det forventede afkast. Dette er kun eksempler og du er ikke bundet af
              disse valg.
            </p>
          </Col>
        </Row>
        <Row className="mb-5 pb-5">
          <Col sm={6} className="pr-sm-5">
            <div>
              <FormLabel htmlFor="initial-investment">Startindskud (kr.)</FormLabel>
              <CurrencyInput
                // @ts-expect-error TS(2322) FIXME: Type '{ name: string; value: any; onValueChange: (... Remove this comment to see the full error message
                name="initialInvestment"
                value={initialInvestment}
                onValueChange={handleChangeExpectedInitialInvestment}
                className="mb-4"
              />
              <RangeInput
                // @ts-expect-error TS(2322) FIXME: Type '{ id: string; steps: { 0: number; 0.1: numbe... Remove this comment to see the full error message
                id="initial-investment"
                steps={initialInvestmentSteps}
                value={initialInvestment}
                onValueChange={handleChangeExpectedInitialInvestment}
                snapToSteps
                fill
              />
              {investmentValidation && (
                <FormControl.Feedback type="invalid">{investmentValidation}</FormControl.Feedback>
              )}
            </div>
          </Col>
          <Col sm={6} className="pl-sm-5 mt-4 mt-lg-0">
            <div>
              <FormLabel htmlFor="monthly-investment">Månedligt indskud (kr.)</FormLabel>
              <CurrencyInput
                value={monthlyInvestment}
                onValueChange={(value) => handleChangeExpectedMonthlyInvestment(value)}
                className="mb-4"
              />
              <RangeInput
                // @ts-expect-error TS(2322) FIXME: Type '{ id: string; steps: number[]; defaultValue:... Remove this comment to see the full error message
                id="monthly-investment"
                steps={monthlyInvestmentSteps}
                defaultValue={defaultMonthlyInvestment}
                value={monthlyInvestment}
                onValueChange={handleChangeExpectedMonthlyInvestment}
                snapToSteps
                fill
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {/* @ts-expect-error TS(2322) FIXME: Type '{ children: (large: any) => Element; size: s... Remove this comment to see the full error message */}
            <Breakpoint size="sm">
              {(large: any) =>
                large ? (
                  <Card className="h-100">
                    <Card.Body className="h-100">{renderChart(large)}</Card.Body>
                  </Card>
                ) : (
                  renderChart(large)
                )
              }
            </Breakpoint>
          </Col>
        </Row>
        {currentPayload && (
          <Row>
            <Col sm={{ offset: 1, span: 10 }} md={{ offset: 2, span: 8 }}>
              <Card className="card-arrow card-arrow-bottom mt-4">
                <div className="w-100 d-flex justify-content-center">
                  <div className="arrow" />
                </div>
                <Card.Body>
                  <Card.Text className="text-center">
                    {currentPayload.years === 0 ? (
                      <>
                        <span className="text-primary">I dag</span>
                        {' er værdien af din portefølje dit startindskud på '}
                        <span className="text-primary">
                          {numberToCurrency(currentPayload.expectedCase)}
                        </span>
                      </>
                    ) : (
                      <>
                        {'Om '}
                        <span className="text-primary">{`${currentPayload.years} år`}</span>
                        {' er den forventede værdi af din portefølje '}
                        <span className="text-primary">
                          {numberToCurrency(currentPayload.expectedCase)}
                        </span>
                        <br />
                        {'Med 90% sandsynlighed ligger værdien af din portefølje mellem '}
                        <span className="text-primary">
                          {numberToCurrency(currentPayload.worstCase)}
                        </span>
                        {' og '}
                        <span className="text-primary">
                          {numberToCurrency(currentPayload.bestCase)}
                        </span>
                      </>
                    )}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
      </Section>
      <Costs />
    </>
  )
}

export default ExpectedReturn
