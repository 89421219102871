import { useState, useCallback } from 'react'

const useForceUpdate = () => {
  const [_state, setState] = useState()

  // @ts-expect-error TS(2345) FIXME: Argument of type '() => number' is not assignable ... Remove this comment to see the full error message
  const forceUpdate = useCallback(() => setState(Math.random), [])

  return forceUpdate
}

export default useForceUpdate
