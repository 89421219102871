import Loader from '@nord/ui/src/components/Loader'
import PortfolioTable from '@nord/ui/src/components/PortfolioTable'
import { getConfig } from '@nord/ui/src/configuration'
import useApi, { useApiRequest } from '@nord/ui/src/hooks/useApi'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import cloneDeep from 'lodash/cloneDeep'
import difference from 'lodash/difference'
import sortBy from 'lodash/sortBy'
import React, { useRef, useState, useLayoutEffect, useCallback, useMemo, useContext } from 'react'
import { useResizeDetector } from 'react-resize-detector'

import ResultPageContext from './ResultPageContext'
import Section from './Section'
import SimpleAllocationSwitch from './SimpleAllocationSwitch'

const freeAssetsMinimumInvestment = getConfig('numbers.minimumInvestment.freeAssets')

const formatData = (data: any) => {
  if (!data) return {}

  const formattedData = data.reduce(
    // @ts-expect-error TS(7006) FIXME: Parameter 'result' implicitly has an 'any' type.
    (result, { symbol, ...etf }: any) => ({
      ...result,

      [symbol]: {
        ...etf,
        symbol,
      },
    }),
    {},
  )

  return formattedData
}

const getDataWithOffset = ({ data, order, tableRef }: any) => {
  const newData = cloneDeep(data)
  const heights = {}

  const { children } = tableRef.current

  for (let i = 0; i < children.length; i += 1) {
    const child = children[i]
    const symbol = child.getAttribute('data-key')

    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    heights[symbol] = child.offsetHeight
  }

  if (difference(order.render, Object.keys(heights)).length > 0) return null
  if (difference(Object.keys(heights), order.render).length > 0) return null

  let currentInitialOffset = 0
  order.render.forEach((symbol: any) => {
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const height = heights[symbol]

    newData[symbol].initialOffset = currentInitialOffset
    newData[symbol].height = height
    currentInitialOffset += height
  })

  let currentOffset = 0
  order.list.forEach((symbol: any) => {
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const height = heights[symbol]

    if (newData[symbol] === undefined) return

    newData[symbol].offset = currentOffset - newData[symbol].initialOffset
    newData[symbol].height = height

    currentOffset += height
  })

  return newData
}

const Portfolio = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'riskScore' does not exist on type 'unkno... Remove this comment to see the full error message
  const { riskScore, esg, pension, simple } = useContext(ResultPageContext)

  const tableRef = useRef<HTMLTableSectionElement>(null)
  const [dataWithOffset, setDataWithOffset] = useState(null)

  const fetchData = useApi('/allocation')

  const { data: rawData, loading } = useApiRequest(fetchData, {
    autoCall: true,
    enableDebounce: true,
    payload: {
      esg,
      pension,
      riskScore,
      simple,
    },
  })

  const data = useMemo(() => formatData(rawData), [rawData])

  const filteredData = useMemo(
    () =>
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      Object.fromEntries(Object.entries(data).filter(([, dataEntry]) => dataEntry.percentage > 0)),
    [data],
  )
  const dataOrder = useMemo(
    () => ({
      render: Object.keys(filteredData).sort(),
      list: sortBy(Object.values(filteredData), 'percentage')
        // @ts-expect-error TS(2345) FIXME: Argument of type '({ symbol }: { symbol: any; }) =... Remove this comment to see the full error message
        .map(({ symbol }) => symbol)
        .reverse(),
    }),
    [filteredData],
  )
  const currentData: any = sortBy(Object.values(dataWithOffset || filteredData), ({ symbol }) =>
    dataOrder.render.indexOf(symbol),
  )
  const numberOfEtfs = Object.keys(filteredData).length

  const handleResize = useCallback(() => {
    const newDataWithOffset = getDataWithOffset({
      data: filteredData,
      order: dataOrder,
      tableRef,
    })

    setDataWithOffset(newDataWithOffset)
  }, [dataOrder, filteredData])

  const { ref: resizeDetectorRef } = useResizeDetector({
    onResize: handleResize,
    refreshMode: 'debounce',
    refreshRate: 100,
  })

  // Update when data changes
  useLayoutEffect(() => {
    handleResize()
  }, [handleResize])

  useLayoutEffect(() => {
    if (!dataWithOffset) handleResize()
  }, [dataWithOffset, handleResize])

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; title: string; id: st... Remove this comment to see the full error message
    <Section title="Portefølje" id="portefølje">
      <p>
        {simple ? (
          <>
            Du har angivet, at din forventede initiale investering er mindre end{' '}
            {numberToCurrency(freeAssetsMinimumInvestment)} Investerer du mindre end{' '}
            {numberToCurrency(freeAssetsMinimumInvestment)}, vil Norm Invest fordele din portefølje
            på aktivklasserne: Aktier og Statsobligationer. For at sikre en spredning på tværs af
            geografi, industri størrelse af selskaber såvel som på forskellige aktivklasser, vil din
            portefølje altid indeholde en aktiefond og en statsobligationsfond, der er nøje udvalgt
            af Norm Invest. Konkret investeres din portefølje så den tilstræbte fordeling, der er
            angivet nedenfor, opnås bedst muligt under hensyntagen til stykstørrelsen på de anvendte
            ETF'ere.
          </>
        ) : (
          <>
            Porteføljen svarende til den valgte risikoprofil består af {numberOfEtfs} forskellige
            fonde, der sikrer en stor spredning på tværs af aktivklasser, geografi, industri og
            størrelse af selskaber.
          </>
        )}
      </p>
      <div ref={resizeDetectorRef}>
        {loading && <Loader className="font-size-xl text-secondary" centeredInParent />}
        <PortfolioTable data={currentData} ref={tableRef} />
      </div>
      <SimpleAllocationSwitch />
    </Section>
  )
}

export default Portfolio
