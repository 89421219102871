import { detect } from 'detect-browser'
import isArray from 'lodash/isArray'

const useDetectBrowser = (browsers: any) => {
  // eslint-disable-next-line no-param-reassign
  if (!isArray(browsers)) browsers = [browsers]
  const browser = detect() || {}

  // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type '{}'.
  return browsers.includes(browser.name)
}

export default useDetectBrowser
