import { getCookieConsent } from '../../utilities/cookieConsent'
import loadGoogleTagManager from '../../utilities/loadGoogleTagManager'
import setupABTest from '../../utilities/setupABTest'
import { getConfig } from '../config'

const enableGoogleTagManager = getConfig('setup.enableGoogleTagManager')

const cookiesType = getCookieConsent()
const allCookiesAccepted = cookiesType === 'all'

if (allCookiesAccepted && enableGoogleTagManager) {
  loadGoogleTagManager()
  setupABTest()
}
