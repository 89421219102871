import apiRequestCall from '@nord/ui/src/store/api/apiRequestCall'
import { selectCurrentPortfolioId } from '@nord/ui/src/store/current/portfolioId'
import setupUserData from '@nord/ui/src/utilities/setupUserData'
import { createAsyncThunk } from '@reduxjs/toolkit'
import pick from 'lodash/pick'

import getUTMParameters from '../../../utilities/getUTMParameters'
import { pushEventToDataLayer } from '../../../utilities/pushToDataLayer'
import { selectFlow } from '../portfolioId/selectors'

import {
  userCreateAttributesWhitelist,
  userUpdateAttributeWhitelistsByType,
} from './attributeWhitelist'

export const createUser = createAsyncThunk(
  'current.user/create',
  async (attributes, { dispatch, getState }) => {
    const userData = await dispatch(
      apiRequestCall({
        method: 'POST',
        url: '/users',
        data: { user: pick(attributes, userCreateAttributesWhitelist) },
        withCredentials: true,
        errorHandling: {
          ignore: {
            client: true,
          },
        },
      }),
    ).unwrap()

    const state = getState()
    const flow = selectFlow(state)

    setupUserData(userData)
    pushEventToDataLayer({
      event: 'user_created',
      userID: userData.id,
      flow,
    })

    const utm = getUTMParameters()

    return { ...userData, utm }
  },
)

export const updateUserOnboarding = createAsyncThunk(
  'current.user/onboarding/update',
  async (attributes: object, { dispatch, getState, rejectWithValue }) => {
    const state = getState()
    const flow: keyof typeof userUpdateAttributeWhitelistsByType = selectFlow(state)
    const portfolioId = selectCurrentPortfolioId(state)

    try {
      const result = await dispatch(
        apiRequestCall({
          method: 'PATCH',
          url: `/onboarding/${portfolioId}/users`,
          data: {
            user: pick(attributes, userUpdateAttributeWhitelistsByType[flow]),
          },
          withCredentials: true,
          errorHandling: {
            ignore: {
              client: true,
            },
          },
        }),
      ).unwrap()

      return result
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const checkUserPEPAndAML = createAsyncThunk(
  'current.user/checkPEPAndAML',
  async (cpr, { dispatch }) =>
    dispatch(
      apiRequestCall({
        method: 'POST',
        url: `/onboarding/pep_checks`,
        data: { user: { cpr } },
        withCredentials: true,
        errorHandling: {
          ignore: {
            client: true,
            server: true,
          },
        },
      }),
    ).unwrap(),
)

export const changeUserAccountType = createAsyncThunk(
  'current.user/changeAccountType',
  (accountType, { dispatch }) =>
    dispatch(
      apiRequestCall({
        method: 'PATCH',
        url: `/onboarding/users/change_type`,
        withCredentials: true,
        data: { user: { accountType } },
      }),
    ),
)

export * from '@nord/ui/src/store/current/user/actions'
