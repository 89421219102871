import classNames from 'classnames'
import React from 'react'

import styles from './TableColumn.module.scss'

export interface Props {
  children: React.ReactNode
  justify?: string
  className?: string
  fit?: boolean
  as?: React.ReactElement
}

const TableColumn = ({
  children,
  justify,
  className,
  fit,
  as: Component,
  ...otherProps
}: Props) => (
  // @ts-expect-error TS(2604) FIXME: JSX element type 'Component' does not have any con... Remove this comment to see the full error message
  <Component
    className={classNames('align-middle', `text-${justify}`, className, {
      [styles.fit]: fit,
    })}
    {...otherProps}
  >
    {children}
  </Component>
)

TableColumn.defaultProps = {
  as: 'td',
  className: undefined,
  fit: undefined,
  justify: 'center',
}

export default TableColumn
