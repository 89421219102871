import classNames from 'classnames'
import React, { useRef, useMemo } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Minus from 'react-feather/dist/icons/minus'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import MinusCircle from 'react-feather/dist/icons/minus-circle'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Plus from 'react-feather/dist/icons/plus'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import PlusCircle from 'react-feather/dist/icons/plus-circle'
import { useResizeDetector } from 'react-resize-detector'

import useBreakpointHandler from '../../hooks/useBreakpointHandler'
import useDetectBrowser from '../../hooks/useDetectBrowser'
import chainEventHandler from '../../utilities/chainEventHandler'
import getRiskScoreDescription from '../../utilities/getRiskScoreDescription'
import type { bootstrapSize } from '../../utilities/propTypes'
import Card from '../Card'
import Icon from '../Icon'
import RangeInput from '../RangeInput'
import StickyCard from '../StickyCard'
import Text from '../Text'

import ESGSwitch from './ESGSwitch'
import styles from './index.module.scss'
import StocksOnlySwitch from './StocksOnlySwitch'

const MINIMUM_WIDTH = 200
const DEFAULT_MIN_RISK_SCORE = 1
const DEFAULT_MAX_RISK_SCORE = 20
const PENSION_MAX_RISK_SCORE = 6

export interface TitleProps {
  children: React.ReactNode
}

// TODO: make normal section as use this as default header
const Title = ({ children }: TitleProps) => (
  // @ts-expect-error TS(2322) FIXME: Type '{ children: ReactNode; align: string; classN... Remove this comment to see the full error message
  <Card.Title align="left" className="font-size-xl" as="h4">
    {children}
  </Card.Title>
)

export interface RiskScoreCardProps {
  advisedRiskScoreText?: string
  bottom?: boolean
  // @ts-expect-error TS(2749) FIXME: 'bootstrapSize' refers to a value, but is being us... Remove this comment to see the full error message
  breakpoint?: bootstrapSize
  children?: React.ReactNode
  content?: React.ReactNode
  enableESGSwitch?: boolean
  enableStocksOnlySwitch?: boolean
  esg?: boolean
  header?: React.ReactNode
  isPension?: boolean
  onChange?: (...args: any[]) => any
  onValueChange?: (...args: any[]) => any
  onChangeRiskScore?: (...args: any[]) => any
  onToggleESG?: (...args: any[]) => any
  onToggleStocksOnly?: (...args: any[]) => any
  renderTitle?: (...args: any[]) => any
  riskScore: number
  sticky?: boolean
}

const RiskScoreCard = ({
  advisedRiskScoreText,
  onChange,
  onValueChange,
  content,
  header,
  renderTitle,
  riskScore,
  esg,
  enableESGSwitch,
  enableStocksOnlySwitch,
  onChangeRiskScore,
  onToggleESG,
  onToggleStocksOnly,
  sticky,
  breakpoint,
  children,
  bottom,
  isPension,
  ...otherProps
}: RiskScoreCardProps) => {
  const esgBeforeStocksOnly = useRef(esg)
  const overBreakpoint = useBreakpointHandler(breakpoint)
  const isIE = useDetectBrowser('ie')
  // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
  const riskText = isPension ? {} : getRiskScoreDescription(riskScore)
  const stocksOnly = riskScore === 100

  const minRiskScore = DEFAULT_MIN_RISK_SCORE
  const maxRiskScore = isPension ? PENSION_MAX_RISK_SCORE : DEFAULT_MAX_RISK_SCORE

  const { ref: resizeDetectorRef, width } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'debounce',
    refreshRate: 100,
  })

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const roundInput = !isIE && overBreakpoint && width > MINIMUM_WIDTH

  const memoizedValue = useMemo(
    () => ({
      riskScore,
      esg,
      stocksOnly,
    }),
    [esg, riskScore, stocksOnly],
  )

  const handleValueChange = ({
    // @ts-expect-error TS(7031) FIXME: Binding element 'newRiskScore' implicitly has an '... Remove this comment to see the full error message
    riskScore: newRiskScore,
    // @ts-expect-error TS(7031) FIXME: Binding element 'newESG' implicitly has an 'any' t... Remove this comment to see the full error message
    esg: newESG,
    // @ts-expect-error TS(7031) FIXME: Binding element 'newStocksOnly' implicitly has an ... Remove this comment to see the full error message
    stocksOnly: newStocksOnly,
  }) => {
    const newValue = {
      riskScore: newRiskScore === undefined ? memoizedValue.riskScore : newRiskScore,
      esg: newESG === undefined ? memoizedValue.esg : newESG,
      stocksOnly: newStocksOnly === undefined ? memoizedValue.stocksOnly : newStocksOnly,
    }

    if (onValueChange) onValueChange(newValue)
  }

  const handleMinus = () => {
    if (riskScore <= 1 || riskScore === 100) return
    // @ts-expect-error TS(2345) FIXME: Argument of type '{ riskScore: number; }' is not a... Remove this comment to see the full error message
    handleValueChange({ riskScore: riskScore - 1 })
  }

  const handlePlus = () => {
    if (riskScore >= maxRiskScore) return
    // @ts-expect-error TS(2345) FIXME: Argument of type '{ riskScore: number; }' is not a... Remove this comment to see the full error message
    handleValueChange({ riskScore: riskScore + 1 })
  }

  const handleChangeRiskScore = chainEventHandler((newRiskScore: any) => {
    // @ts-expect-error TS(2345) FIXME: Argument of type '{ riskScore: any; }' is not assi... Remove this comment to see the full error message
    handleValueChange({ riskScore: newRiskScore })

    return newRiskScore
  }, onChangeRiskScore)

  const handleToggleESG = chainEventHandler((newEsg: any) => {
    // @ts-expect-error TS(2345) FIXME: Argument of type '{ esg: any; }' is not assignable... Remove this comment to see the full error message
    handleValueChange({ esg: newEsg })

    return newEsg
  }, onToggleESG)

  const handleToggleStocksOnly = chainEventHandler((newStocksOnly: any) => {
    if (newStocksOnly) esgBeforeStocksOnly.current = esg
    const previousESG = esgBeforeStocksOnly.current
    let newEsg
    if (!newStocksOnly && esg !== previousESG) newEsg = previousESG
    if (newStocksOnly && esg !== true) newEsg = true

    if (newStocksOnly)
      handleValueChange({
        riskScore: 100,
        stocksOnly: true,
        esg: newEsg,
      })
    else
      handleValueChange({
        riskScore: 20,
        stocksOnly: false,
        esg: newEsg,
      })

    return newStocksOnly
  }, onToggleStocksOnly)

  const riskScoreContent =
    riskScore === 100 ? (
      <span className="font-size-lg text-primary">100% aktier</span>
    ) : (
      <>
        <Icon
          icon={MinusCircle}
          size="12"
          className="mr-3 d-none d-lg-inline-block"
          // @ts-expect-error TS(2322) FIXME: Type '{ icon: any; size: string; className: string... Remove this comment to see the full error message
          onClick={handleMinus}
          data-cy="risk-score-minus"
        />
        <span className="font-size-xl text-primary">{isPension ? `P${riskScore}` : riskScore}</span>
        <span className="font-size-md text-primary">
          {' / '}
          {isPension ? `P${maxRiskScore}` : maxRiskScore}
        </span>
        <Icon
          icon={PlusCircle}
          size="12"
          className="ml-3 d-none d-lg-inline-block"
          // @ts-expect-error TS(2322) FIXME: Type '{ icon: any; size: string; className: string... Remove this comment to see the full error message
          onClick={handlePlus}
          data-cy="risk-score-plus"
        />
      </>
    )

  const showStocksOnlySwitch = enableStocksOnlySwitch && (riskScore === 20 || riskScore === 100)

  return (
    <StickyCard
      breakpoint={breakpoint}
      enabled={sticky}
      bottom={bottom}
      // eslint-disable-next-line react/no-unstable-nested-components
      content={(inputRef) => (
        <>
          {renderTitle ? renderTitle() : <Title>Vælg Risikoprofil</Title>}

          <div className="position-relative" ref={inputRef}>
            <div ref={resizeDetectorRef} className={roundInput ? styles.riskScoreText : undefined}>
              <Card.Text
                // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; align: string; classNam... Remove this comment to see the full error message
                align="center"
                className={classNames('text-primary m-0', {
                  'pb-4': isPension,
                })}
              >
                {riskScoreContent}
              </Card.Text>
              <Text
                as="p"
                variant="secondary"
                align="center"
                className="text-uppercase font-size-md m-0"
              >
                {riskText.title}
              </Text>
            </div>
            <RangeInput
              // @ts-expect-error TS(2322) FIXME: Type 'boolean | ""' is not assignable to type 'boo... Remove this comment to see the full error message
              round={roundInput}
              defaultValue={riskScore}
              value={riskScore}
              min={minRiskScore}
              max={maxRiskScore}
              onValueChange={handleChangeRiskScore}
              onChange={onChange}
              data-cy="risk-score-input"
              fill
            />
          </div>
          <Text as="p" variant="dark" className="mt-3">
            {riskText.description}
          </Text>
          {advisedRiskScoreText && (
            <Text as="p" variant="primary" className="my-3">
              {advisedRiskScoreText}
            </Text>
          )}
          <div>
            {enableESGSwitch && (
              <ESGSwitch
                onChange={onChange}
                onCheckedChange={handleToggleESG}
                checked={esg || stocksOnly}
                disabled={stocksOnly}
              />
            )}
            {showStocksOnlySwitch && (
              <StocksOnlySwitch
                checked={stocksOnly}
                onChange={onChange}
                onCheckedChange={handleToggleStocksOnly}
              />
            )}
          </div>
          {children || content}
        </>
      )}
      header={
        <div>
          <Row className="align-items-center">
            <Col className="d-flex justify-content-center">
              {/* @ts-expect-error TS(2322) FIXME: Type '{ icon: any; size: number; onClick: () => vo... Remove this comment to see the full error message */}
              {riskScore !== 100 && <Icon icon={Minus} size={2} onClick={handleMinus} />}
            </Col>
            <Col>
              <div>
                <div>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; align: string; }' is no... Remove this comment to see the full error message */}
                  <Card.Text align="center">{riskScoreContent}</Card.Text>
                </div>
                <Text as="div" variant="secondary" align="center" className="text-uppercase">
                  {isPension ? riskText.description : riskText.title}
                </Text>
              </div>
            </Col>
            <Col className="d-flex justify-content-center">
              {/* @ts-expect-error TS(2322) FIXME: Type '{ icon: any; size: number; onClick: () => vo... Remove this comment to see the full error message */}
              {riskScore !== 100 && <Icon icon={Plus} size={2} onClick={handlePlus} />}
            </Col>
          </Row>
          {advisedRiskScoreText && (
            <Text as="div" className="mt-3 text-secondary text-center">
              {advisedRiskScoreText}
            </Text>
          )}
          <div className="d-flex justify-content-center align-items-center flex-wrap flex-column">
            {enableESGSwitch && (
              <div>
                <ESGSwitch
                  onCheckedChange={handleToggleESG}
                  checked={esg || stocksOnly}
                  disabled={stocksOnly}
                  className="text-secondary"
                  tooltip={{
                    placement: 'bottom',
                    className: 'btn-link-light',
                  }}
                />
              </div>
            )}
            {showStocksOnlySwitch && (
              <div>
                <StocksOnlySwitch
                  checked={stocksOnly}
                  onCheckedChange={handleToggleStocksOnly}
                  className="text-secondary"
                  tooltip={{
                    placement: bottom ? 'top' : 'bottom',
                    className: 'btn-link-light',
                  }}
                />
              </div>
            )}
          </div>
          <Row>{children || header}</Row>
        </div>
      }
      {...otherProps}
    />
  )
}

RiskScoreCard.defaultProps = {
  advisedRiskScoreText: undefined,
  bottom: undefined,
  breakpoint: 'lg',
  children: undefined,
  content: undefined,
  enableESGSwitch: false,
  enableStocksOnlySwitch: false,
  esg: undefined,
  header: undefined,
  isPension: false,
  onChange: undefined,
  onChangeRiskScore: undefined,
  onToggleESG: undefined,
  onToggleStocksOnly: undefined,
  onValueChange: undefined,
  renderTitle: undefined,
  sticky: undefined,
}

RiskScoreCard.displayName = 'RiskScoreCard'
RiskScoreCard.Title = Title

export default RiskScoreCard
