import { clamp, isObject, isArray } from 'lodash'

import { calculateStepRatiosFromStepsObject, calculateNearest } from './utilities'

const ROUND_TO_DECIMAL_POINT = 2
export const ROUND_FACTOR = 10 ** ROUND_TO_DECIMAL_POINT

const calculateNearestRatioFromStepCount = ({ ratio, stepsCount }: any) => {
  const stepRatio = Math.round((1.0 / stepsCount) * ROUND_FACTOR) / ROUND_FACTOR
  const numberOfSteps = Math.round(ratio / stepRatio)

  return numberOfSteps * stepRatio
}

const calculateWithObjectSteps = ({ ratio, steps }: any) => {
  const stepRatios = calculateStepRatiosFromStepsObject(steps)
  const nearestRatio = calculateNearest(stepRatios, ratio)

  return nearestRatio
}

const calculateWithArraySteps = ({ ratio, steps }: any) => {
  const stepsCount = steps.length - 1

  return calculateNearestRatioFromStepCount({ ratio, stepsCount })
}

const calculateWithStep = ({ ratio, step, min, max }: any) => {
  const range = max - min
  const stepsCount = range / step

  return calculateNearestRatioFromStepCount({ ratio, stepsCount })
}

const generalSnapRatioToSteps =
  ({ max, min, steps, step, snapToSteps }: any) =>
  (ratio: any) => {
    const clampedRatio = clamp(ratio, 0, 1)
    const roundedRatio = Math.round(clampedRatio * ROUND_FACTOR) / ROUND_FACTOR

    if (!snapToSteps) return roundedRatio

    if (isArray(steps)) return calculateWithArraySteps({ ratio: roundedRatio, steps })
    if (isObject(steps)) return calculateWithObjectSteps({ ratio: roundedRatio, steps })
    if (step) return calculateWithStep({ ratio: roundedRatio, step, min, max })

    return roundedRatio
  }

export default generalSnapRatioToSteps
