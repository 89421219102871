import React, { useCallback, useState } from 'react'
import AsyncSelect from 'react-select/async'

import Select from './Select'

export interface Props {
  value?: string
  loadOptions?: (value: string) => Promise<void>
  onSelect?: (...args: any[]) => any
}

const AutocompleteSelect = ({ onSelect, value, ...otherProps }: Props) => {
  const initialSelectedOption = value
    ? {
        value,
        label: value,
      }
    : undefined
  const [selectedOption, setSelectedOption] = useState(initialSelectedOption)

  const handleSelect = useCallback(
    (newSelectedOption: any) => {
      if (onSelect) onSelect(newSelectedOption)

      setSelectedOption(newSelectedOption)
    },
    [onSelect],
  )

  return (
    <Select
      // @ts-expect-error TS(2322) FIXME: Type '{ component: AsyncSelect; allowBrowserInput:... Remove this comment to see the full error message
      component={AsyncSelect}
      allowBrowserInput={false}
      selectedOption={selectedOption || initialSelectedOption}
      onSelect={handleSelect}
      value={value}
      {...otherProps}
    />
  )
}

AutocompleteSelect.defaultProps = {
  onSelect: undefined,
  value: undefined,
}

export default AutocompleteSelect
