import { differenceInMilliseconds } from 'date-fns'
import merge from 'lodash/merge'
import { useCallback, useEffect, useRef } from 'react'

const defaultOptions = {
  enabled: true,
  duration: 1000,
  timeout: 5000,
}

const useAsyncInterval = (asyncFunction: any, options: any) => {
  const {
    duration: intervalDuration,
    timeout: timeoutLimit,
    enabled,
    callback,
    onTimeout,
  } = merge({}, defaultOptions, options)

  const enabledRef = useRef(enabled)
  const timeoutId = useRef()
  const startTime = useRef()
  const intervalCallbackRef = useRef()

  const timeoutCallback = useCallback(async () => {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
    if (differenceInMilliseconds(new Date(), startTime.current) > timeoutLimit) {
      if (onTimeout) await onTimeout()

      return
    }

    if (!enabledRef.current) return

    const { current: intervalCallback } = intervalCallbackRef

    // @ts-expect-error TS(2349) FIXME: This expression is not callable.
    if (intervalCallback) await intervalCallback()

    // @ts-expect-error TS(2322) FIXME: Type 'Timeout' is not assignable to type 'undefine... Remove this comment to see the full error message
    timeoutId.current = setTimeout(timeoutCallback, intervalDuration)
  }, [intervalDuration, onTimeout, timeoutLimit])

  useEffect(() => {
    // @ts-expect-error TS(2322) FIXME: Type '() => Promise<void>' is not assignable to ty... Remove this comment to see the full error message
    intervalCallbackRef.current = async () => {
      const response = await asyncFunction()

      if (callback) await callback(response)
    }
  }, [asyncFunction, callback])

  useEffect(() => {
    enabledRef.current = enabled
    if (!enabled) return undefined

    // @ts-expect-error TS(2322) FIXME: Type 'Date' is not assignable to type 'undefined'.
    startTime.current = new Date()

    timeoutCallback()

    return () => clearTimeout(timeoutId.current)
  }, [enabled, timeoutCallback])
}

export default useAsyncInterval
