// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'airb... Remove this comment to see the full error message
import { ref as refPropType } from 'airbnb-prop-types'
import React from 'react'
import { useResizeDetector } from 'react-resize-detector'

import styles from './Round.module.scss'

const offset = parseFloat(styles.offset)

const generatePath = ({ xStart, yStart, xEnd, yEnd, radius }: any) =>
  `M${xStart} ${yStart} A ${radius} ${radius} 0 0 1 ${xEnd} ${yEnd}`

export interface RoundProps {
  fill?: boolean
  ratio: number
  onStart: (...args: any[]) => any
  onEnd: (...args: any[]) => any
  onClickOrDrag: (...args: any[]) => any
  onBlur: (...args: any[]) => any
  onFocus: (...args: any[]) => any
  sliderRef: any // TODO: refPropType()
  handleRef: any // TODO: refPropType()
}

const Round = ({
  fill,
  onStart,
  onEnd,
  onClickOrDrag,
  onFocus,
  onBlur,
  sliderRef,
  handleRef,
  ratio,
  ...otherProps
}: RoundProps) => {
  const { width, ref: resizeDetectorRef } = useResizeDetector({
    handleHeight: false,
    refreshRate: 100,
    refreshMode: 'debounce',
  })

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const radius = width / 2 - offset
  const xPercent = (1 - Math.cos(Math.PI * ratio)) * radius
  const yPercent = (1 - Math.sin(Math.PI * ratio)) * radius
  const xPosition = xPercent + offset
  const yPosition = yPercent + offset
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const height = width / 2 + offset
  const trackPath = generatePath({
    xStart: offset,
    yStart: radius + offset,
    xEnd: radius * 2 + offset,
    yEnd: radius + offset,
    radius,
  })
  const fillPath = generatePath({
    xStart: offset,
    yStart: radius + offset,
    xEnd: xPosition,
    yEnd: yPosition,
    radius,
  })

  return (
    <div ref={resizeDetectorRef}>
      <svg
        className={styles.customRangeRound}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        ref={sliderRef}
        onMouseDown={onStart}
        onMouseUp={onEnd}
        onTouchMove={onClickOrDrag}
        onTouchEnd={onEnd}
        {...otherProps}
      >
        <g>
          <path
            className={styles.customRangeTrack}
            d={trackPath}
            strokeLinecap="round"
            fill="none"
          />
          {fill && (
            <path
              className={styles.customRangeFill}
              d={fillPath}
              strokeLinecap="round"
              fill="none"
            />
          )}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            role="button"
            tabIndex={0}
            className={styles.customRangeHandleWrapper}
            ref={handleRef}
            onMouseDown={onStart}
            onMouseUp={onEnd}
            onTouchMove={onClickOrDrag}
            onTouchEnd={onEnd}
            onFocus={onFocus}
            onBlur={onBlur}
          >
            <circle
              className={styles.customRangeHandleShadow}
              r={12}
              cx={xPosition}
              cy={yPosition}
            />
            <circle className={styles.customRangeHandle} r={12} cx={xPosition} cy={yPosition} />
          </a>
        </g>
      </svg>
    </div>
  )
}

Round.defaultProps = {
  fill: undefined,
}

Round.displayName = 'RangeInput.Round'

export default Round
