import AlertModal from '@nord/ui/src/components/AlertModal'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'

import useSignedIn from '../../../../../../hooks/useSignedIn'
import {
  selectCurrentPortfolio,
  selectOnboardingState,
} from '../../../../../../store/current/portfolioId'
import ResultPageContext from '../ResultPageContext'

import ModalContent from './ModalContent'
import UpdateRiskScoreModal from './UpdateRiskScoreModal'
import useModalState from './useModalState'

const modalData = {
  esgDiffers: {
    acceptable: true,
    content: <ModalContent.EsgDiffers />,
  },
  riskScoreDiffers: {
    acceptable: true,
    content: <ModalContent.RiskScoreDiffers />,
  },
  riskScoreOutsideRange: {
    acceptable: false,
    content: <ModalContent.RiskScoreOutsideRange />,
  },
}

export interface Props {
  onHide: (...args: any[]) => any
  onRiskScoreSave: (...args: any[]) => any
}

const UpdateAlertModal = ({ onHide, onRiskScoreSave, ...otherProps }: Props) => {
  const modalState = useModalState()
  // @ts-expect-error TS(2339) FIXME: Property 'onRiskScoreChange' does not exist on typ... Remove this comment to see the full error message
  const { onRiskScoreChange } = useContext(ResultPageContext)
  const {
    // @ts-expect-error TS(2339) FIXME: Property 'advisedEsg' does not exist on type 'unkn... Remove this comment to see the full error message
    advisedEsg,
    // @ts-expect-error TS(2339) FIXME: Property 'esg' does not exist on type 'unknown'.
    esg: initialEsg,
    // @ts-expect-error TS(2339) FIXME: Property 'advisedRiskScore' does not exist on type... Remove this comment to see the full error message
    advisedRiskScore,
    // @ts-expect-error TS(2339) FIXME: Property 'riskScore' does not exist on type 'unkno... Remove this comment to see the full error message
    riskScore: initialRiskScore,
  } = useObjectSelector(selectCurrentPortfolio)

  const onboardingState = useSelector(selectOnboardingState)

  const signedIn = useSignedIn()

  const userAlreadyOnboarded = signedIn && onboardingState === 'saxo_onboarded'

  if (!modalState) return null

  if (modalState === 'updateRiskScore')
    // @ts-expect-error TS(2741) FIXME: Property 'show' is missing in type '{ onHide: (...... Remove this comment to see the full error message
    return <UpdateRiskScoreModal {...otherProps} onHide={onHide} />

  const {
    [modalState]: { content, acceptable },
  } = modalData

  const handleHide = () => {
    if (userAlreadyOnboarded) onRiskScoreChange({ riskScore: initialRiskScore, esg: initialEsg })
    else onRiskScoreChange({ riskScore: advisedRiskScore, esg: advisedEsg })

    onHide()
  }

  const handleAccept = () => {
    if (!acceptable) return

    onRiskScoreSave()
  }

  return (
    <AlertModal
      title="Afvigelse fra anbefaling"
      acceptable={acceptable}
      onHide={handleHide}
      onAccept={handleAccept}
      {...otherProps}
    >
      {content}
    </AlertModal>
  )
}

export default UpdateAlertModal
