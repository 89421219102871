import useQuery from '@nord/ui/src/hooks/useQuery'
import isEmpty from 'lodash/isEmpty'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  PRIVATE_PATHS,
  PENSION_PATHS,
  COMPANY_PATHS,
  SHARED_PATHS,
} from '../configuration/constants'
import { selectInitialLoading } from '../store/current/loading'
import { selectPortfolioById, selectPortfolioIds } from '../store/current/portfolios'
import { selectCurrentUser } from '../store/current/user'
import extractOnboardingState from '../utilities/extractOnboardingState'

import usePortfolioFlow from './usePortfolioFlow'
import usePortfolioId from './usePortfolioId'
import useRouteMatchPortfolioId from './useRouteMatchPortfolioId'

/* eslint-disable camelcase */
const onboardingStatePathFunctionsMap = {
  private: {
    user_created: PRIVATE_PATHS.root,
    investment_plan_completed: PRIVATE_PATHS.investmentPlanResult,
    saxo_onboarded: PRIVATE_PATHS.investmentPlanResult,
    risk_profile_chosen: PRIVATE_PATHS.onboarding,
    ready_for_saxo: PRIVATE_PATHS.confirmation,
    saxo_client_created: PRIVATE_PATHS.confirmation,
    documents_ready_to_sign: PRIVATE_PATHS.confirmation,
    documents_signed: PRIVATE_PATHS.saxoVerificationStart,
    ready_for_verification: PRIVATE_PATHS.saxoVerificationStart,
    saxo_verification_failed: PRIVATE_PATHS.saxoVerificationFailure,
    ready_for_kyc: PRIVATE_PATHS.verification,
    kyc_obtained: PRIVATE_PATHS.saxoVerificationCompleted,
    saxo_verified: PRIVATE_PATHS.saxoVerificationCompleted,
    ready_to_onboard: PRIVATE_PATHS.saxoVerificationCompleted,
    submitted_to_saxo: PRIVATE_PATHS.saxoVerificationCompleted,
  },
  pension: {
    user_created: PENSION_PATHS.root,
    investment_plan_completed: PENSION_PATHS.investmentPlanResult,
    saxo_onboarded: PENSION_PATHS.investmentPlanResult,
    risk_profile_chosen: PENSION_PATHS.onboarding,
    ready_for_saxo: PENSION_PATHS.confirmation,
    saxo_client_created: PENSION_PATHS.confirmation,
    documents_ready_to_sign: PENSION_PATHS.confirmation,
    documents_signed: PRIVATE_PATHS.saxoVerificationStart,
    ready_for_verification: PENSION_PATHS.saxoVerificationStart,
    saxo_verification_failed: PENSION_PATHS.saxoVerificationFailure,
    kyc_obtained: PENSION_PATHS.saxoVerificationCompleted,
    saxo_verified: PENSION_PATHS.saxoVerificationCompleted,
    ready_to_onboard: PENSION_PATHS.saxoVerificationCompleted,
    submitted_to_saxo: PENSION_PATHS.saxoVerificationCompleted,
  },
  company: {
    user_created: COMPANY_PATHS.root,
    investment_plan_completed: COMPANY_PATHS.investmentPlanResult,
    saxo_onboarded: COMPANY_PATHS.investmentPlanResult,
    risk_profile_chosen: COMPANY_PATHS.onboarding,
    ready_for_kyc: COMPANY_PATHS.verification,
    ready_for_saxo: COMPANY_PATHS.confirmation,
    ready_to_onboard: COMPANY_PATHS.verificationComplete,
  },
}
/* eslint-enable camelcase, @typescript-eslint/naming-convention */

const newPortfolioAllowedForOnboardingStates = [
  'ready_to_onboard',
  'submitted_to_saxo',
  'saxo_onboarded',
]

const useRedirectPath = () => {
  const loading = useSelector(selectInitialLoading)
  const portfolioId = usePortfolioId()
  const currentUser = useSelector(selectCurrentUser)
  const currentPortfolio = useSelector(selectPortfolioById(portfolioId))
  const currentOnboardingState = extractOnboardingState(currentUser, currentPortfolio)
  const portfolioFlow = usePortfolioFlow()
  const portfolioIds = useSelector(selectPortfolioIds)
  const matchId = useRouteMatchPortfolioId()
  const redirectPath = useQuery('redirectTo')
  const [storedRedirectPath, setStoredRedirectPath] = useState()

  let portfolioOnboardingState = currentOnboardingState
  if (!portfolioId && newPortfolioAllowedForOnboardingStates.includes(currentOnboardingState)) {
    portfolioOnboardingState = 'user_created'
  }

  useEffect(() => {
    if (!redirectPath) return

    setStoredRedirectPath(redirectPath)
  }, [redirectPath])

  const invalidRoute =
    matchId &&
    !isEmpty(portfolioIds) &&
    (!portfolioIds.includes(matchId) ||
      // @ts-expect-error TS(2339) FIXME: Property 'includes' does not exist on type 'never'... Remove this comment to see the full error message
      (storedRedirectPath && !storedRedirectPath.includes(matchId)))

  return useMemo(() => {
    if (loading) return undefined
    if (storedRedirectPath && !invalidRoute) return storedRedirectPath
    if (!portfolioFlow) return SHARED_PATHS.root()
    if (!portfolioId) return undefined

    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return onboardingStatePathFunctionsMap[portfolioFlow][portfolioOnboardingState](portfolioId)
  }, [
    loading,
    storedRedirectPath,
    invalidRoute,
    portfolioFlow,
    portfolioId,
    portfolioOnboardingState,
  ])
}

export default useRedirectPath
