import BaffleButton from '@nord/ui/src/components/BaffleButton'
import Paginator from '@nord/ui/src/components/Paginator'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link, useHistory } from 'react-router-dom'

import { COMPANY_PATHS } from '../../../../configuration/constants'
import useCachedPages from '../../../../hooks/useCachedPages'
import useRedirectPath from '../../../../hooks/useRedirectPath'
import { selectInitialLoading } from '../../../../store/current/loading'
import { selectCurrentPortfolio } from '../../../../store/current/portfolioId'
import {
  fetchCurrentUser,
  selectCurrentUser,
  updateUserOnboarding,
} from '../../../../store/current/user'
import { progressChanged } from '../../../../store/ui/progressSlice'
import defaultToUndefined from '../../../../utilities/defaultToUndefined'
import { pushEventToDataLayer } from '../../../../utilities/pushToDataLayer'
import CreatePasswordPage from '../../CreatePasswordPage'
import IntroPage from '../../IntroPage'
import OnboardingNavigation from '../../OnboardingNavigation'
import SustainabilityPreferencePage from '../../Shared/SustainabilityPreferencePage'
import SustainabilityPreferenceWarningPage from '../../Shared/SustainabilityPreferenceWarningPage'

import CompanyInformationPage from './CompanyInformationPage'
import ETFPage from './ETFPage'
import InvestmentPage from './InvestmentPage'

const usePages = () => {
  const {
    // @ts-expect-error TS(2339) FIXME: Property 'hasPassword' does not exist on type 'unk... Remove this comment to see the full error message
    hasPassword,
    // @ts-expect-error TS(2339) FIXME: Property 'initialSustainabilityPreferenceLevel' does not exist on type  'unknown'
    initialSustainabilityPreferenceLevel,
    // @ts-expect-error TS(2339) FIXME: Property 'readyForSaxoAt' does not exist on type  'unknown'
    readyForSaxoAt,
    // @ts-expect-error TS(2339) FIXME: Property 'validSuitabilityTest' does not exist on type  'unknown'
    validSuitabilityTest,
  } = useObjectSelector(selectCurrentUser)
  // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'unknown'.
  const { id: currentPortfolioId, sustainabilityPreferenceLevel } =
    useObjectSelector(selectCurrentPortfolio)
  const previouslyOnboarded = !!readyForSaxoAt

  return useCachedPages(
    [
      { page: IntroPage, condition: true },
      { page: CreatePasswordPage, condition: !hasPassword },
      {
        page: SustainabilityPreferencePage,
        condition: initialSustainabilityPreferenceLevel === 'high',
      },
      {
        page: SustainabilityPreferenceWarningPage,
        condition: sustainabilityPreferenceLevel === 'high',
      },
      { page: CompanyInformationPage, condition: !previouslyOnboarded },
      { page: InvestmentPage, condition: !validSuitabilityTest },
      { page: ETFPage, condition: !validSuitabilityTest },
    ],
    // Do not include hasPassword & sustainabilityPreferenceLevel, as this would change the page order
    [currentPortfolioId, initialSustainabilityPreferenceLevel],
  )
}

const initialValues = {
  password: '',
  passwordConfirmation: '',
  acceptLowerSustainabilityPreference: false,
  understandsCompanyEtfs: false,
}

const Onboarding = () => {
  const loading = useSelector(selectInitialLoading)
  const dispatch = useDispatch()
  const {
    // @ts-expect-error TS(2339) FIXME: Property 'riskScore' does not exist on type 'unkno... Remove this comment to see the full error message
    riskScore,
    // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'unknown'.
    id: portfolioId,
    // @ts-expect-error TS(2339) FIXME: Property 'expectedInvestment' does not exist on ty... Remove this comment to see the full error message
    expectedInvestment,
    // @ts-expect-error TS(2339) FIXME: Property 'readyForSaxo' does not exist on type 'un... Remove this comment to see the full error message
    readyForSaxo: portfolioReadyForSaxo,
  } = useObjectSelector(selectCurrentPortfolio)
  const currentUser = useObjectSelector(selectCurrentUser)
  // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'unknown'.
  const { id: userId } = currentUser
  const pages = usePages()

  const returnToResultPageButton = useMemo(
    () => (
      <BaffleButton
        variant="link"
        className="text-secondary"
        as={Link}
        type={undefined}
        to={{
          pathname: COMPANY_PATHS.investmentPlanResult(portfolioId),
          search: `?risk_score=${riskScore}`,
        }}
      >
        Tilbage til investeringsplan
      </BaffleButton>
    ),
    [portfolioId, riskScore],
  )

  const beforePageChange = ({ number, count }: { number: number; count: number }) => {
    const progress = number / count

    dispatch(fetchCurrentUser())
    dispatch(progressChanged(progress))
  }

  const handleComplete = () => {
    pushEventToDataLayer({
      event: 'ready_for_saxo',
      userID: userId,
      expectedInvestment,
      flow: 'company',
    })
  }

  const redirectPath = useRedirectPath()
  const history = useHistory()

  const handleSubmit = useCallback(
    async (
      newValues: Record<string, unknown>,
      _formik: unknown,
      { pagination: { last } }: { pagination: { last: boolean } },
    ) => {
      if (last && !portfolioReadyForSaxo) newValues.readyForSaxo = true
      if (last && portfolioReadyForSaxo && isEmpty(newValues)) history.push(redirectPath)
      if (isEmpty(newValues)) return undefined

      try {
        const { payload }: any = await dispatch(updateUserOnboarding(newValues))

        return payload.response
      } catch {
        // Will redirect to correct page
      }

      return undefined
    },

    [dispatch, history, portfolioReadyForSaxo, redirectPath],
  )

  const NavigationComponent = useCallback(
    (navigationProps: Record<string, unknown>) => (
      <OnboardingNavigation {...navigationProps} returnButton={returnToResultPageButton} />
    ),
    [returnToResultPageButton],
  )

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: any; onComplete: () => void; nav... Remove this comment to see the full error message
    <Paginator.Form.Wrapper.WithQueryParam
      beforePageChange={beforePageChange}
      initialValues={defaultToUndefined({ ...initialValues, ...currentUser, expectedInvestment })}
      loading={loading}
      navigationComponent={NavigationComponent}
      onComplete={handleComplete}
      onSubmit={handleSubmit}
      showPagination
      useOnLoad
    >
      {pages.map((Page) => (
        <Page key={Page.displayName || Page.name} />
      ))}
    </Paginator.Form.Wrapper.WithQueryParam>
  )
}

export default Onboarding
