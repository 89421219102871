import classnames from 'classnames'
import React from 'react'

export interface Props {
  children: React.ReactNode
  className?: string
}

const Container = ({ children, className, ...containerProps }: Props) => (
  <div className={classnames('nested-fields-container', className)} {...containerProps}>
    {children}
  </div>
)

Container.defaultProps = {
  className: '',
}

export default Container
