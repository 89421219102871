import BooleanCollection from '@nord/ui/src/components/BooleanCollection'
import Form from '@nord/ui/src/components/Form'
import React, { useState } from 'react'

import NestedFields from '../../../../../NestedFields'

import options from './options'
import OtherField from './OtherField'
import otherSourceOfAssetsValue from './otherSourceOfAssetsValue'

const SourcesOfAssetsField = () => {
  const [showOther, setShowOther] = useState(false)

  const handleValueChange = (newValue: any) => {
    if (newValue.includes(otherSourceOfAssetsValue)) {
      setShowOther(true)
    } else {
      setShowOther(false)
    }

    return newValue
  }

  return (
    <NestedFields.Container>
      <Form.FieldGroup name="sourcesOfAssets">
        <Form.Field
          // @ts-expect-error TS(2739) FIXME: Type '{ ({ name, options, onChange, onValueChange,... Remove this comment to see the full error message
          inputComponent={BooleanCollection}
          onValueChange={handleValueChange}
          hideErrors={showOther}
          options={options}
        />
      </Form.FieldGroup>
      {showOther && (
        <NestedFields.FormGroup>
          <OtherField />
        </NestedFields.FormGroup>
      )}
    </NestedFields.Container>
  )
}

export { default as otherSourceOfAssetsValue } from './otherSourceOfAssetsValue'
export default SourcesOfAssetsField
