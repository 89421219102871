import Referral from '../Referral'

import ChooseFlowPage from './ChooseFlowPage'
import DocumentsPage from './DocumentsPage'
import ForgotPasswordPage from './ForgotPasswordPage'
import InvestmentPlan from './InvestmentPlan'
import KYCVerification from './KYCVerification'
import NotFoundPage from './NotFoundPage'
import SignInPage from './SignInPage'

const Shared = {
  DocumentsPage,
  InvestmentPlan,
  KYCVerification,
  ForgotPasswordPage,
  SignInPage,
  ChooseFlowPage,
  NotFoundPage,
  ReferralPage: Referral.Page,
}

export default Shared
