import { useSelector } from 'react-redux'

import { selectCurrentPortfolioId } from '../store/current/portfolioId'
import { selectPortfolioById } from '../store/current/portfolios'

import useFlow from './useFlow'
import usePortfolioId from './usePortfolioId'

const usePortfolioFlow = () => {
  const flow = useFlow()
  const redirectPortfolioId = usePortfolioId()
  const redirectPortfolio = useSelector(selectPortfolioById(redirectPortfolioId))
  const portfolioId = useSelector(selectCurrentPortfolioId)

  if (redirectPortfolioId !== portfolioId && redirectPortfolio)
    return redirectPortfolio.onboardingFlow

  return flow
}

export default usePortfolioFlow
