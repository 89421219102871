import isArray from 'lodash/isArray'
import isPlainObject from 'lodash/isPlainObject'
import mapValues from 'lodash/mapValues'
import snakeCase from 'lodash/snakeCase'
import transform from 'lodash/transform'

export const deepMap = (object: any, options = {}) => {
  // @ts-expect-error TS(2339) FIXME: Property 'formatArray' does not exist on type '{}'... Remove this comment to see the full error message
  const { formatArray, formatObject, formatValue } = options

  // @ts-expect-error TS(7023) FIXME: 'mapValue' implicitly has return type 'any' becaus... Remove this comment to see the full error message
  const mapValue = (value: any) => {
    if (isArray(value)) {
      if (formatArray) return formatArray(value)

      return value.map(mapValue)
    }

    if (isPlainObject(value)) {
      if (formatObject) return formatObject(value)

      return mapValues(value, mapValue)
    }

    if (formatValue) return formatValue(value)

    return value
  }

  return mapValue(object)
}

export const deepFreeze = <T extends object>(object: T): Readonly<T> =>
  deepMap(object, { formatArray: Object.freeze, formatObject: Object.freeze })

export const deepMapValues = (object: any, mapFunction: any) =>
  deepMap(object, { formatValue: mapFunction })

export const deepMapKeys = (object: any, mapKey: any) =>
  deepMap(object, {
    formatObject: (currentObject: any) =>
      transform(
        currentObject,
        (result, value, key) => {
          const newKey = mapKey(key, value)
          const newValue = deepMapKeys(value, mapKey)

          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          result[newKey] = newValue
        },
        {},
      ),
  })

export const snakeCaseKeys = (object: any) => deepMapKeys(object, snakeCase)
