import BooleanCollection from '@nord/ui/src/components/BooleanCollection'
import Form from '@nord/ui/src/components/Form'
import Paginator from '@nord/ui/src/components/Paginator'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { useFormikContext } from 'formik'
import React, { useRef, useCallback } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useDispatch } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { object, string } from 'yup'

import { updateCurrentPortfolioOnboarding } from '../../../../store/current/portfolioId'
import { selectCurrentUser } from '../../../../store/current/user'
import ContentTitle from '../../ContentTitle'
import OtherDocumentationField from '../../OtherDocumentationField'
import { useUploadOtherDocumentation } from '../../Shared/upload-hooks'

const validationSchema = object().shape({
  migrationDepotCompanyName: string().required(),
  migrationDepotRegistrationNumber: string(),
  migrationDepotAccountNumber: string(),
  migrationDepotNumber: string(),
  migrationPensionCompanyType: string().oneOf(['bank_company', 'pension_company']).required(),
})

const labels = {
  migrationPensionCompanyType: 'Pensionsselskab eller pengeinstitut?',
  migrationDepotCompanyName: 'Navn på afgivende pensionsselskab/pengeinstitut',
  migrationDepotRegistrationNumber: 'Registreringsnummer',
  migrationDepotAccountNumber: 'Kontonummer',
  migrationPensionPoliceNumber: 'Policenummer',
  migrationDepotNumber: 'Depotnummer',
  documentation: 'Dokumentation',
}

const fields = Object.keys(labels)

const MigrationPage = (otherProps: any) => {
  const dispatch = useDispatch()
  const uploadedFiles = useRef([])
  // @ts-expect-error TS(2339) FIXME: Property 'email' does not exist on type 'unknown'.
  const { email } = useObjectSelector(selectCurrentUser)
  const {
    values: { migrationPensionCompanyType },
  } = useFormikContext<any>()
  const uploadOtherDocumentation = useUploadOtherDocumentation()

  const addUploadedFile = (newFile: any) => {
    // @ts-expect-error TS(2322) FIXME: Type 'any' is not assignable to type 'never'.
    uploadedFiles.current = [newFile, ...uploadedFiles.current]
  }

  const handleSubmit = useCallback(
    async (newValues: any) => {
      const { documentation } = newValues

      await uploadOtherDocumentation({
        documentation,
        onUpload: addUploadedFile,
        uploadedFiles: uploadedFiles.current,
        email,
      })

      newValues.pensionDepot = true
      await dispatch(updateCurrentPortfolioOnboarding(newValues))
    },
    [dispatch, email, uploadOtherDocumentation],
  )

  const renderInformationFields = () => {
    if (migrationPensionCompanyType === 'pension_company') {
      return (
        <Row>
          <Col>
            <Form.FieldGroup name="migrationPensionPoliceNumber">
              <Form.Field />
            </Form.FieldGroup>
          </Col>
        </Row>
      )
    }

    if (migrationPensionCompanyType === 'bank_company') {
      return (
        <>
          <Row>
            <Col xs={6} sm={4}>
              <Form.FieldGroup name="migrationDepotRegistrationNumber">
                <Form.Field />
              </Form.FieldGroup>
            </Col>
            <Col xs={6} sm={8}>
              <Form.FieldGroup name="migrationDepotAccountNumber">
                <Form.Field />
              </Form.FieldGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.FieldGroup name="migrationDepotNumber">
                <Form.Field />
              </Form.FieldGroup>
            </Col>
          </Row>
        </>
      )
    }

    return null
  }

  return (
    <Paginator.Form.Page
      fields={fields}
      labelDescriptions={labels}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      {...otherProps}
    >
      <ContentTitle title="Hvor ligger dit depot i dag?" />
      <Row>
        <Col>
          <p className="text-center">
            For at kunne flytte dit depot, skal vi vide hvor det ligger i dag.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.FieldGroup name="migrationDepotCompanyName">
            <Form.Field />
          </Form.FieldGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.FieldGroup name="migrationPensionCompanyType">
            <Form.Field
              // @ts-expect-error TS(2739) FIXME: Type '{ ({ name, options, onChange, onValueChange,... Remove this comment to see the full error message
              inputComponent={BooleanCollection}
              options={[
                { label: 'Pensionsselskab', value: 'pension_company' },
                {
                  label: 'Pengeinstitut (bank, sparekasse eller andelskasse)',
                  value: 'bank_company',
                },
              ]}
              type="radio"
              className="mb-3"
              value={migrationPensionCompanyType}
              inline
            />
          </Form.FieldGroup>
        </Col>
      </Row>
      <CSSTransition classNames="slide-down" in={!!migrationPensionCompanyType} timeout={500}>
        <div
          className={`slide-down ${
            migrationPensionCompanyType ? 'slide-down-show' : 'slide-down-hide'
          }`}
        >
          {renderInformationFields()}
        </div>
      </CSSTransition>
      <Row>
        <Col>
          <OtherDocumentationField
            email={email}
            help={
              <>
                Vi skal bruge en rapport fra PensionsInfo (
                <a href="https://www.pensionsinfo.dk/" target="_blank" rel="noopener noreferrer">
                  https://www.pensionsinfo.dk
                </a>
                ), for yderligere information om dit depot. Har du ikke dette tilgængelig nu, kan du
                forsætte uden og tilsende dokumentation senere.
              </>
            }
          />
        </Col>
      </Row>
    </Paginator.Form.Page>
  )
}

export default MigrationPage
