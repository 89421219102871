import classNames from 'classnames'
import React from 'react'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import CardTitle from './CardTitle'

export interface Props {
  children: React.ReactNode
  className?: string
  title?: string
}

const Section = ({ children, title, className, ...props }: Props) => (
  <Row className={classNames('result-section', className)} {...props}>
    <Col>
      <Card text="dark">
        <Card.Body className="section-inner" data-cy={title}>
          {title && <CardTitle>{title}</CardTitle>}
          {children}
        </Card.Body>
      </Card>
    </Col>
  </Row>
)

Section.defaultProps = {
  className: undefined,
  title: undefined,
}

export default Section
