const labels = {
  cpr: 'CPR-nummer',
  firstName: 'Fornavn (inkl. mellemnavne)',
  lastName: 'Efternavn',
  address: 'Adresse',
  city: 'By',
  postalCode: 'Postnummer',
  country: 'Bopælsland',
  nationality: 'Nationalitet',
  pep: 'Jeg er en politisk eksponeret person (PEP)',
}

export const fields = ['age', 'birthdate', ...Object.keys(labels)]

export default labels
