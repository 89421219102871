const positionToRatio = ({ clientX, clientY, sliderWidth, sliderX, sliderY, round }: any) => {
  const relativeX = clientX - sliderX

  if (round) {
    const relativeY = clientY - sliderY

    const angle = Math.atan2(relativeY - sliderWidth / 2, relativeX - sliderWidth / 2)
    const circleRatio = angle / Math.PI + 1

    if (circleRatio < 1) return circleRatio
    if (circleRatio < 1.5) return 1

    return 0
  }

  return relativeX / sliderWidth
}

export default positionToRatio
