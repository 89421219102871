import Paginator from '@nord/ui/src/components/Paginator'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { object, string } from 'yup'

import { selectCurrentUser } from '../../../../store/current/user'
import ImageField from '../../../ImageField'
import ContentTitle from '../../ContentTitle'

const labels = {
  driversLicense: 'Kørekort',
}

const validationSchema = object().shape({
  driversLicense: string().required(),
})

const ProofOfIdentityPage = (otherProps: any) => {
  // @ts-expect-error TS(2339) FIXME: Property 'email' does not exist on type 'unknown'.
  const { email } = useObjectSelector(selectCurrentUser)

  return (
    <Paginator.Form.Page
      validationSchema={validationSchema}
      labelDescriptions={labels}
      {...otherProps}
    >
      <ContentTitle title="Kørekort eller pas" />
      <Row>
        <Col>
          <p>
            Vi har brug for dit kørekort eller pas til at identificere dig. Tag et billede af dit
            pas eller kørekort og upload her. Vær opmærksom på, at det digitale kørekort fra
            kørekort app'en ikke kan godkendes som dokumentation for identitet. Det skal være et
            billede af dit fysiske kørekort eller pas.
          </p>
          <p>
            <strong>
              Sørg for at hele kørekortet eller passet er med på billedet og er tydelig læsbart.
            </strong>
          </p>
        </Col>
      </Row>
      <ImageField name="driversLicense" fileName="ProofOfIdentity" email={email} />
    </Paginator.Form.Page>
  )
}

export default ProofOfIdentityPage
