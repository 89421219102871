import CountrySelect from '@nord/ui/src/components/CountrySelect'
import Form from '@nord/ui/src/components/Form'
import Paginator from '@nord/ui/src/components/Paginator'
import React, { useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import AddressField from '../../../AddressField'
import ContentTitle from '../../../ContentTitle'

import CPRField from './CPRField'
import labels, { fields } from './labels'
import NameFields from './NameFields'
import PepField from './PepField'
import validationSchema from './validationSchema'

const BasicInformationPage = (otherProps: any) => {
  const [loadingCPR, setLoadingCPR] = useState(false)

  return (
    <Paginator.Form.Page
      validationSchema={validationSchema}
      labelDescriptions={labels}
      fields={fields}
      {...otherProps}
    >
      <ContentTitle title="Fortæl os lidt mere om dig og hvor du bor" />
      <CPRField loadingCPR={loadingCPR} onLoading={setLoadingCPR} />
      <NameFields disabled={loadingCPR} />
      <AddressField disabled={loadingCPR} />
      <Row>
        <Col xs={12}>
          <Form.FieldGroup name="country">
            <Form.Field
              // @ts-expect-error TS(2739) FIXME: Type '{ ({ components, optionFormat, excludedCount... Remove this comment to see the full error message
              inputComponent={CountrySelect}
              isDisabled={loadingCPR}
              excludedCountries={[
                'BY', // Belarus
                'CU', // Cuba
                'IR', // Iran
                'KP', // North Korea
                'MM', // Myanmar
                'RU', // Russia
                'SD', // Sudan
                'SY', // Syria
                'US', // United States
                'YE', // Yemen
              ]}
            />
          </Form.FieldGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Form.FieldGroup name="nationality">
            <Form.Field
              // @ts-expect-error TS(2322) FIXME: Type '{ ({ components, optionFormat, excludedCount... Remove this comment to see the full error message
              inputComponent={CountrySelect}
              optionFormat={(item: any) => ({
                value: item.code,
                label: item.nationality,
                ...item,
              })}
              excludedCountries={[
                'RU', // Russia
              ]}
            />
          </Form.FieldGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <PepField />
        </Col>
      </Row>
    </Paginator.Form.Page>
  )
}

export default BasicInformationPage
