import IconButton from '@nord/ui/src/components/IconButton'
import React, { useContext } from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ArrowRight from 'react-feather/dist/icons/arrow-right'

import ResultPageContext from '../../ResultPageContext'

const SignUp = ({ ...otherProps }) => {
  // @ts-expect-error TS(2339) FIXME: Property 'setShowSignupModal' does not exist on ty... Remove this comment to see the full error message
  const { setShowSignupModal } = useContext(ResultPageContext)

  const handleClick = () => setShowSignupModal(true)

  return (
    <IconButton variant="primary" icon={ArrowRight} onClick={handleClick} filled {...otherProps}>
      Opret dig og gem
    </IconButton>
  )
}

export default SignUp
