const da = {
  'Use a few words, avoid common phrases': 'Brug er par ord, undgå almindelige udtryk',
  'No need for symbols, digits, or uppercase letters':
    'Der er intet behov for symboler, tal eller store bogstaver',
  'Add another word or two. Uncommon words are better.':
    'Tilføj et ord eller to til. Ualmindelige ord er bedre',
  'Straight rows of keys are easy to guess': 'Rækker af taster, som "qwerty", er nemme at gætte',
  'Short keyboard patterns are easy to guess':
    'Korte sekvenser som "qwerty" eller "12345" er nemme at gætte',
  'Use a longer keyboard pattern with more turns': 'Brug længere sekvenser med flere hop',
  'Repeats like "aaa" are easy to guess': 'Gentagelser som "aaa" er nemme at gætte',
  'Repeats like "abcabcabc" are only slightly harder to guess than "abc"':
    'Gentagelser som "abcabcabc" er kun lidt bedre end "abc"',
  'Avoid repeated words and characters': 'Undgå gentagende ord og bogstaver',
  'Sequences like abc or 6543 are easy to guess':
    'Sekvenser som "abc" eller "6543" er nemme at gætte',
  'Avoid sequences': 'Undgå sekvenser',
  'Recent years are easy to guess': 'Seneste årstal er nemme at gætte',
  'Avoid recent years': 'Undgå at bruge seneste årstal',
  'Avoid years that are associated with you': 'Undgå at bruge årstal med nær tilknytning til dig',
  'Dates are often easy to guess': 'Datoer er ofte nemme at gætte',
  'Avoid dates and years that are associated with you': 'Undgå datoer og år du har tilknytning til',
  'This is a top-10 common password': 'Dette er en af de top 10 mest almindelig adgangskoder',
  'This is a top-100 common password': 'Dette er en af de top 100 mest almindelig adgangskoder',
  'This is a very common password': 'Dette er en meget almindeligt adgangskode',
  'This is similar to a commonly used password':
    'Dette ligner en af de mest almindelige adgangskoder',
  'A word by itself is easy to guess': 'Et enkelt ord er meget nemt at gætte',
  'Names and surnames by themselves are easy to guess':
    'Navne og efternavne er nemme at gætte alene',
  'Common names and surnames are easy to guess':
    'Almindelige navne og efternavne er nemme at gætte',
  "Capitalization doesn't help very much": 'Store bogstaver hjælper ikke meget',
  'All-uppercase is almost as easy to guess as all-lowercase':
    'Kun store bogstaver er lige så nemme at gætte som kun små bogstaver',
  "Reversed words aren't much harder to guess": 'Ord, skrevet baglens, er ikke svære at gætte',
  "Predictable substitutions like '@' instead of 'a' don't help very much":
    'Det hjælper ikke meget at erstatte bogstaver som a med @',
}

const locale = {
  da,
}

export default locale
