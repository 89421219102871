import { getConfig } from '@nord/ui/src/configuration'
import useApi from '@nord/ui/src/hooks/useApi'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import isEmpty from 'lodash/isEmpty'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { acceptCurrentPortfolioDocuments } from '../../../../../store/current/portfolioId/actions'

import Content from './Content'
import DocumentModal from './DocumentModal'
import styles from './index.module.scss'

export const timeout = parseInt(styles.timeout, 10)

const docsForSigningFolder = getConfig('documentationFolders.docsForSigning.key')

const DocumentsAcceptPage = () => {
  const dispatch = useDispatch()
  const [documents, setDocuments] = useState({})
  const [currentDocumentKey, setCurrentDocumentKey] = useState(null)
  const [allDocumentsRead, setAllDocumentsRead] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [transitionReverse, setTransitionReverse] = useState(false)
  const [loading, setLoading] = useState(false)
  // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'unknown'.
  const { id: portfolioId, onboardingState } = useObjectSelector(selectCurrentPortfolio)

  const getDocuments = useApi('/onboarding/documents', {
    withCredentials: true,
  })

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getDocuments({
        folder: docsForSigningFolder,
      })

      if (isEmpty(data) && onboardingState === 'documents_ready_to_sign')
        throw new Error('Documents for signing do not exist')

      const filteredDocuments = data.filter(
        ({ portfolio }: any) => portfolio === null || portfolio === portfolioId,
      )

      const newDocuments = filteredDocuments.reduce((result: any, item: any) => {
        result[item.filename] = {
          ...item,
          read: false,
        }

        return result
      }, {})

      setDocuments(newDocuments)
    }

    fetchData()
  }, [getDocuments, onboardingState, portfolioId])

  const handleResetCurrentDocument = () => setCurrentDocumentKey(null)

  const nextDocumentKey = ({ reverse = false } = {}) => {
    const documentKeys = Object.keys(documents)
    const { length: keysCount } = documentKeys
    // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    const currentIndex = documentKeys.indexOf(currentDocumentKey)
    let nextIndex
    let currentKey

    for (let count = 1; count < keysCount; count += 1) {
      nextIndex = currentIndex + count * (reverse ? -1 : 1)
      if (nextIndex < 0) nextIndex += keysCount
      if (nextIndex >= keysCount) nextIndex -= keysCount

      currentKey = documentKeys[nextIndex]
    }

    return currentKey
  }

  const handleReadDocument = (documentKey: any) => {
    const newDocuments = {
      ...documents,
      [documentKey]: {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        ...documents[documentKey],
        read: true,
      },
    }

    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    const newAllDocumentsRead = Object.values(newDocuments).every((document) => document.read)

    setDocuments(newDocuments)
    setAllDocumentsRead(newAllDocumentsRead)
  }

  const handleShowModal = (documentKey: any, event: any) => {
    event.preventDefault()

    handleReadDocument(documentKey)
    setShowModal(true)
    setCurrentDocumentKey(documentKey)
  }

  const handleHideModal = () => setShowModal(false)

  const handleChangeDocument = (options = {}) => {
    const nextKey = nextDocumentKey(options)

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    setCurrentDocumentKey(nextKey)
    // @ts-expect-error TS(2339) FIXME: Property 'reverse' does not exist on type '{}'.
    setTransitionReverse(options.reverse || false)

    handleReadDocument(nextKey)
  }

  const handleOnNext = () => handleChangeDocument()

  const handleOnPrevious = () => handleChangeDocument({ reverse: true })

  const handleConfirmDocuments = () => {
    setLoading(true)

    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
    dispatch(acceptCurrentPortfolioDocuments())
  }

  // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
  const { [currentDocumentKey]: currentDocument } = documents

  return (
    <div className="documents-container">
      {currentDocument !== undefined && (
        <DocumentModal
          show={showModal}
          document={currentDocument}
          onHide={handleHideModal}
          onClosed={handleResetCurrentDocument}
          onNext={handleOnNext}
          onPrevious={handleOnPrevious}
          transitionReverse={transitionReverse}
          allDocumentsRead={allDocumentsRead}
        />
      )}
      <Content
        documents={documents}
        onConfirm={handleConfirmDocuments}
        onShowDocument={handleShowModal}
        loading={loading}
      />
    </div>
  )
}

export default DocumentsAcceptPage
