import { buildCurrentPortfolioAction } from '@nord/ui/src/store/current/portfolioId'

import { pushToDataLayer } from '../../../utilities'
import { pushEventToDataLayer } from '../../../utilities/pushToDataLayer'
import { selectCurrentInvestmentProfile } from '../investmentProfile'
import {
  createPortfolioOnboarding,
  updatePortfolioOnboarding,
  upsertPortfolioOnboarding,
  createPortfolioSaxoClient,
  createPortfolioDocuments,
  acceptPortfolioDocuments,
  completePortfolioOnboarding,
  completePortfolioKyc,
  changePortfolioDepotType,
} from '../portfolios/actions'
import { selectPortfolioIds } from '../portfolios/selectors'
import { changePortfolio } from '../portfolios/slice'
import { selectIsSignedIn } from '../user/selectors'
import { changeCurrentUser } from '../user/slice'

import { selectCurrentPortfolio, selectFlow } from './selectors'

export const changeCurrentPortfolio = buildCurrentPortfolioAction(changePortfolio, {
  includePortfolioId: false,
  buildPayload: ({ portfolioId, inputValue }: any) => ({
    portfolioId: portfolioId || 'new',
    attributes: inputValue,
  }),
})

export const createCurrentPortfolioOnboarding = buildCurrentPortfolioAction(
  createPortfolioOnboarding,
  {
    includePortfolioId: false,
    buildPayload: ({ state, inputValue }: any) => {
      const currentPortfolio = selectCurrentPortfolio(state)

      if (inputValue.portfolio) {
        return {
          ...inputValue,
          portfolio: { ...currentPortfolio, ...inputValue.portfolio },
        }
      }

      return { ...currentPortfolio, ...inputValue }
    },
  },
)

export const updateCurrentPortfolioOnboarding = buildCurrentPortfolioAction(
  updatePortfolioOnboarding,
  { buildPayload: true },
)

export const upsertCurrentPortfolioOnboarding = buildCurrentPortfolioAction(
  upsertPortfolioOnboarding,
  {
    buildPayload: ({ state, inputValue }: any) => {
      const currentPortfolio = selectCurrentPortfolio(state)
      const { investmentProfileId, riskScore } = selectCurrentInvestmentProfile(state)

      return {
        ...currentPortfolio,
        investmentProfileId,
        advisedRiskScore: riskScore,
        ...inputValue,
      }
    },
  },
)

export const completeCurrentPortfolioInvestmentPlan =
  (attributes: any) => (dispatch: any, getState: any) => {
    const state = getState()
    const isSignedIn = selectIsSignedIn(state)
    const flow = selectFlow(state)
    const portfolioIds = selectPortfolioIds(state)
    const persistedPortfolioIds = portfolioIds.filter((item: any) => item !== 'new')
    const firstOnboarding = persistedPortfolioIds.length === 0
    const currentPortfolio = selectCurrentPortfolio(state) || {}
    const advisedRiskScore = attributes.advisedRiskScore || currentPortfolio.advisedRiskScore
    const expectedInvestment = attributes.expectedInvestment || currentPortfolio.expectedInvestment
    const { user: userAttributes } = attributes

    if (advisedRiskScore)
      pushToDataLayer({
        advisedRiskScore: parseInt(advisedRiskScore, 10),
      })

    if (isSignedIn && firstOnboarding) {
      pushEventToDataLayer({
        event: 'investment_plan_completed',
        expectedInvestment,
        flow,
      })
    }

    if (userAttributes) dispatch(changeCurrentUser(userAttributes))

    if (isSignedIn) return dispatch(upsertCurrentPortfolioOnboarding(attributes))

    return dispatch(changeCurrentPortfolio(attributes))
  }

export const createCurrentPortfolioSaxoClient =
  buildCurrentPortfolioAction(createPortfolioSaxoClient)

export const createCurrentPortfolioDocuments = buildCurrentPortfolioAction(createPortfolioDocuments)

export const acceptCurrentPortfolioDocuments = buildCurrentPortfolioAction(acceptPortfolioDocuments)

export const completeCurrentPortfolioKyc = buildCurrentPortfolioAction(completePortfolioKyc)

export const completeCurrentPortfolioOnboarding = buildCurrentPortfolioAction(
  completePortfolioOnboarding,
  {
    includePortfolioId: false,
    buildPayload: ({ state, portfolioId }: any) => {
      const flow = selectFlow(state)

      return { portfolioId, flow }
    },
  },
)

export const changeCurrentPortfolioDepotType = buildCurrentPortfolioAction(
  changePortfolioDepotType,
  {
    buildPayload: ({ inputValue }: any) => ({ depotType: inputValue }),
  },
)

export const changeOnboardingState = (newOnboardingState: any) => (dispatch: any) =>
  dispatch(changeCurrentPortfolio({ userOnboardingState: newOnboardingState }))

export * from '@nord/ui/src/store/current/portfolioId/actions'
