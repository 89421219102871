import { useState } from 'react'

export interface Props {
  children: React.ReactNode
  currentPath: string
  currentTitle: string
}

const ChangePage = ({ children, currentPath, currentTitle }: Props) => {
  const [previousPath, setPreviousPath] = useState(currentPath)

  if (currentPath !== previousPath) {
    setPreviousPath(currentPath)
    window.scrollTo(0, 0)
    document.title = `${currentTitle} - NORD investments`
  }

  return children || null
}

export default ChangePage
