const selectOptions = {
  currentOccupation: [
    {
      value: 'Employed, public sector',
      label: 'Ansat, offentlige sektor',
    },
    {
      value: 'Employed, private company',
      label: 'Ansat, private sektor',
    },
    {
      value: 'Executive management/board, public sector',
      label: 'Direktion / bestyrelse, offentlig sektor',
    },
    {
      value: 'Executive management/board, private company',
      label: 'Direktion / bestyrelse, private sektor',
    },
    {
      value: 'Self employed',
      label: 'Selvstændig',
    },
    {
      value: 'Political office',
      label: 'Politisk embed',
    },
    {
      value: 'Student',
      label: 'Studerende',
    },
    {
      value: 'Retired',
      label: 'Pensioneret',
    },
    {
      value: 'Unemployed',
      label: 'Arbejdssøgende',
    },
  ],
  jobIndustry: [
    {
      value: 'None of the above',
      label: 'Ingen af nedenstående',
    },
    {
      value: 'Antique / Art dealers / Auction houses',
      label: 'Antikvitetshandlere / Kunsthandlere / Auktionshuse',
    },
    {
      value: 'Attorney at law',
      label: 'Advokat',
    },
    {
      value: 'Automotive',
      label: 'Bilforhandler',
    },
    {
      value: 'Catering / Restaurants',
      label: 'Catering / Restaurant',
    },
    {
      value: 'Cleaning services',
      label: 'Rengøring',
    },
    {
      value: 'Construction / Demolition',
      label: 'Bygge og anlæg / Nedrivning',
    },
    {
      value: 'Digital currencies',
      label: 'Digitale valutaer',
    },
    {
      value: 'Gambling / Betting',
      label: 'Spil / Ods og livebetting',
    },
    {
      value: 'Money remittance / Currency exchange providers',
      label: 'Pengeoverførsel / Valutaveksling',
    },
    {
      value: 'Non-profit / Charity',
      label: 'Almennyttig / Velgørenhed',
    },
    {
      value: 'Oil and gas exploration and production',
      label: 'Olie - og gasudvinding og produktion',
    },
    {
      value: 'Precious metals and stones',
      label: 'Ædel- metaller og stene',
    },
    {
      value: 'Private military firms',
      label: 'Privat militærvirksomhed',
    },
    {
      value: 'Real estate',
      label: 'Fast Ejendom',
    },
  ],
}

export default selectOptions
