import Card from '@nord/ui/src/components/Card'
import NordLogo from '@nord/ui/src/components/NordLogo'
import Paginator from '@nord/ui/src/components/Paginator'
import SaxoLogo from '@nord/ui/src/components/SaxoLogo'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { MINIMUM_PENSION_INVESTMENT } from '../../../../configuration/constants'
import ContentTitle from '../../ContentTitle'

const MigrationRequirementsPage = (otherProps: any) => (
  <Paginator.Form.Page {...otherProps}>
    <ContentTitle title="Flytning af pensionsdepot til NORD" />
    <Row>
      <Col>
        <p className="text-center">
          Du er igang med at starte overflytningen af et pensionsdepot til Saxo Bank og NORD.
        </p>
      </Col>
    </Row>
    <Row>
      <Col sm={{ offset: 1, span: 10 }} md={{ offset: 2, span: 8 }}>
        <p className="text-center">
          Før vi starter, er det dog vigtigt, at du er opmærksom på følgende:
        </p>
        <Card>
          <Card.Body data-cy="section-body">
            <ul>
              <li>Du kan kun flytte hele depoter - delvise flytninger er ikke mulige.</li>
              <li>Kun kontanter kan flyttes. Værdipapirer skal sælges inden flytning.</li>
              <li>
                Pensioner under eller med forventet udbetaling før 2. halvår 2021 kan ikke flyttes
              </li>
              <li>Pensionen skal være på minimum {numberToCurrency(MINIMUM_PENSION_INVESTMENT)}</li>
              <li>Det er ikke muligt at have forsikringer tilknyttet.</li>
              <li>
                Din nuværende bank/pensionsselskab kan opkræve gebyrer i forbindelse med flytningen.
              </li>
            </ul>
          </Card.Body>
        </Card>
        <p className="text-center mt-3">
          I takt med at vi forbedrer løsningen vil flere af ovenstående punkter ændres, så flere
          fremover vil få mulighed for at investere deres pension hos Norm invest og Saxo Bank.
        </p>
        <p className="text-center mt-3">
          Kan du sætte flueben ved ovenstående? Så lad os komme i gang.
        </p>
      </Col>
    </Row>
    <Row>
      <Col>
        <div className="logo-wrapper">
          <div className="logo-inner">
            <NordLogo />
          </div>
          <div className="logo-inner d-none d-sm-block text-center">
            <div>i samarbejde med</div>
          </div>
          <div className="logo-inner">
            <SaxoLogo height={70} />
          </div>
        </div>
      </Col>
    </Row>
  </Paginator.Form.Page>
)

export default MigrationRequirementsPage
