import { deepFreeze } from '../../../utilities/deepIterate'

const development = deepFreeze({
  debug: true,
  domains: {
    website: 'https://staging.www.nord.investments',
    backend: 'https://staging.api.nord.investments',
    webSocket: 'wss://staging.api.nord.investments/cable',
    app: 'https://staging.app.nord.investments',
    onboarding: 'https://staging.signup.nord.investments',
    blog: 'https://staging.blog.nord.investments',
    suitabilityFlow: 'https://staging.egnethed.nord.investments',
  },
  keys: {
    googleAnalyticsId: 'UA-88580223-5',
    googleTagManagerId: 'GTM-NN9Z52T',
    googleOptimizeId: 'GTM-545HD6',
    intercomId: 'gru1ltiv',
  },
  urls: {
    external: {
      expectedReturn: 'https://expected-return-stag.herokuapp.com/expected',
      historicalReturn: {
        privateAndCompany: 'https://expected-return-stag.herokuapp.com/historical',
        pension: 'https://expected-return-stag.herokuapp.com/pension_historical',
      },
      kreditDataConsent: 'https://stage.kreditdata.dk/consent/827sgs1ds0/3csl7hcw01',
    },
  },
})

export default development
