import anime from 'animejs'
import classNames from 'classnames'
import React, { useState, useEffect, useRef } from 'react'
import { Button } from 'react-bootstrap'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Camera from 'react-feather/dist/icons/camera'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Check from 'react-feather/dist/icons/check'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Upload from 'react-feather/dist/icons/upload'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import X from 'react-feather/dist/icons/x'
import { useResizeDetector } from 'react-resize-detector'
import { CSSTransition } from 'react-transition-group'

import chainEventHandler from '../utilities/chainEventHandler'
import extractTransitionClasses from '../utilities/extractTransitionClasses'

import BaffleButton from './BaffleButton'
import Card from './Card'
import Circle from './Circle'
import FileInput from './FileInput'
import Icon from './Icon'
import IconButton from './IconButton'
import styles from './ImageInput.module.scss'
import WebcamInput from './Webcam'

const timeout = parseInt(styles.timeout, 10)
const borderWidth = parseInt(styles.borderWidth, 10)

export interface ContainerProps {
  children?: React.ReactNode
  className?: string
}

const Container = ({ className, children, ...otherProps }: ContainerProps) => (
  <div className={classNames(styles.container, className)} {...otherProps}>
    {children}
  </div>
)

Container.defaultProps = {
  children: undefined,
  className: undefined,
}

Container.displayName = 'ImageInput.Container'

const supportWebcam = !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)

export interface ImageInputProps {
  name?: any // TODO: requireAtLeastOneOf(PropTypes.string, 'id')
  id?: string
  aspectRatio?: number
  width?: number
  onValueChange?: (...args: any[]) => any
  onFileChange?: (...args: any[]) => any
  onCapture?: (...args: any[]) => any
}

const ImageInput = ({
  name,
  id,
  aspectRatio,
  onValueChange,
  onFileChange,
  onCapture,
  ...otherProps
}: ImageInputProps) => {
  const [file, setFile] = useState(undefined)
  const [view, setView] = useState('overview')
  const webcamRef = useRef()
  const { width, ref } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'debounce',
    refreshRate: 100,
  })

  const height = width && aspectRatio ? Math.round(width * aspectRatio) : undefined

  // Make sure to revoke the data uris to avoid memory leaks
  useEffect(
    () => () => {
      // @ts-expect-error TS(2339) FIXME: Property 'preview' does not exist on type 'never'.
      if (file) URL.revokeObjectURL(file.preview)
    },
    [file],
  )

  const handleFileChange = chainEventHandler((newFile: any) => {
    setFile({
      ...newFile,
      preview: URL.createObjectURL(newFile),
    })
    setView('preview')

    return newFile
  }, onValueChange)

  const handleValueChange = (newFiles: any) => {
    const newFile = newFiles[0]

    return handleFileChange(newFile)
  }

  const handleCancel = () => {
    setView('overview')
    setFile(undefined)
  }

  const handleShowWebcam = () => setView('webcam')
  const handleWebcamError = () => setView('webcam-error')

  const handleCapture = () => {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    const dataUrl = webcamRef.current.capture()

    fetch(dataUrl)
      .then((response) => response.blob())
      .then((blob) => new File([blob], 'webcam.jpg', { type: 'image/jpeg' }))
      .then((newFile) => {
        handleFileChange(newFile)

        if (onCapture) onCapture(newFile)
      })
  }

  const animationTargets = '#animated-icon > polyline'

  useEffect(() => () => anime.remove(animationTargets), [view])

  let content
  switch (view) {
    case 'preview':
      content = (
        <>
          {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
          <Card.Img src={file.preview} className={styles.imagePreview} />
          <Card.ImgOverlay>
            <CSSTransition
              classNames={extractTransitionClasses({
                styles,
                className: 'btnCapture',
              })}
              timeout={timeout}
              enter={false}
              exit={false}
              onEnter={() => {
                anime.remove(animationTargets)

                anime({
                  targets: animationTargets,
                  strokeDashoffset: (el: any) => {
                    let start
                    try {
                      start = -anime.setDashoffset(el)
                    } catch {
                      start = 0
                    }

                    return [start, 0]
                  },
                  easing: styles.easing,
                  duration: timeout,
                })
              }}
              onEntered={() => {
                anime.remove(animationTargets)

                anime({
                  targets: '#animated-button',
                  opacity: [1, 0],
                  easing: styles.easing,
                  delay: 100,
                  duration: 200,
                })
              }}
              appear
              in
            >
              {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; size: string; variant: ... Remove this comment to see the full error message */}
              <Circle
                size="sm"
                variant="primary"
                className={styles.btnCapture}
                id="animated-button"
                filled
                borderless
              >
                {/* @ts-expect-error TS(2322) FIXME: Type '{ icon: any; id: string; }' is not assignabl... Remove this comment to see the full error message */}
                <Icon icon={Check} id="animated-icon" />
              </Circle>
            </CSSTransition>
            {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; size: string; variant: ... Remove this comment to see the full error message */}
            <Circle size="xs" variant="dark" className={styles.btnCancel} filled borderless>
              <Button variant="dark" className="btn-filled" onClick={handleCancel}>
                <Icon icon={X} />
              </Button>
            </Circle>
          </Card.ImgOverlay>
        </>
      )
      break
    case 'webcam':
      content = (
        <div>
          <WebcamInput
            // @ts-expect-error TS(2322) FIXME: Type '{ className: string; ref: MutableRefObject<u... Remove this comment to see the full error message
            className="card-img"
            ref={webcamRef}
            onError={handleWebcamError}
            // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
            height={height - 2 * borderWidth}
            // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
            width={width - 2 * borderWidth}
          />
          <Card.ImgOverlay>
            {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; size: string; variant: ... Remove this comment to see the full error message */}
            <Circle size="sm" variant="primary" className={styles.btnCapture} filled borderless>
              <Button variant="primary" className="btn-filled" onClick={handleCapture}>
                <Icon icon={Check} />
              </Button>
            </Circle>
            {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; size: string; variant: ... Remove this comment to see the full error message */}
            <Circle size="xs" variant="dark" className={styles.btnCancel} filled borderless>
              <Button variant="dark" className="btn-filled" onClick={handleCancel}>
                <Icon icon={X} />
              </Button>
            </Circle>
          </Card.ImgOverlay>
        </div>
      )
      break
    case 'webcam-error':
      content = (
        <Card.Body>
          <div className="d-flex flex-column justify-content-center align-items-center text-center h-100">
            <div>
              Der skete en fejl med dit webcam. Har du givet tilladt adgang til dit webcam? Du kan
              også gå tilbage og uploade en fil istedet.
            </div>
            <BaffleButton onClick={() => setView('overview')} className="mt-2">
              Gå tilbage
            </BaffleButton>
          </div>
        </Card.Body>
      )
      break
    case 'overview':
    default:
      content = (
        <Card.Body className={styles.imageInput}>
          {supportWebcam && (
            <IconButton icon={Camera} onClick={handleShowWebcam}>
              Tag billede
            </IconButton>
          )}

          <div className={styles.imageUpload}>
            <FileInput
              // @ts-expect-error TS(2322) FIXME: Type '{ onValueChange: (newFiles: any) => any; onC... Remove this comment to see the full error message
              accept="image/*|application/pdf"
              custom={false}
              id={id || name}
              name={name}
              className={styles.imageUploadInput}
              {...otherProps}
              onValueChange={handleValueChange}
              onChange={onFileChange}
            />
            <IconButton
              icon={Upload}
              buttonAs="label"
              htmlFor={id || name}
              className={styles.imageUploadLabel}
            >
              Upload billede
            </IconButton>
          </div>
        </Card.Body>
      )
      break
  }

  return (
    <div ref={ref}>
      <Card className="text-dark" style={{ height, width }}>
        {content}
      </Card>
    </div>
  )
}

ImageInput.defaultProps = {
  aspectRatio: 480 / 640,
  id: undefined,
  name: undefined,
  onCapture: undefined,
  onFileChange: undefined,
  onValueChange: undefined,
  width: undefined,
}

ImageInput.displayName = 'ImageInput.Inner'

export interface ImageInputWithContainerProps {
  className?: string
}

const ImageInputWithContainer = ({ className, ...otherProps }: ImageInputWithContainerProps) => (
  <Container className={className}>
    <ImageInput {...otherProps} />
  </Container>
)

ImageInputWithContainer.defaultProps = {
  className: undefined,
}

ImageInputWithContainer.displayName = 'ImageInput'

export default ImageInputWithContainer
