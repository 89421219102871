import Form from '@nord/ui/src/components/Form'
import React, { useRef, useEffect } from 'react'

const OtherField = () => {
  const textField = useRef()

  useEffect(() => {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    textField.current.focus()
  }, [])

  return (
    <Form.FieldGroup name="sourceOfAssetsOther" label="Andet">
      <Form.Field ref={textField} />
    </Form.FieldGroup>
  )
}

export default OtherField
