import classNames from 'classnames'
import React, { useEffect } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useSelector, useDispatch } from 'react-redux'

import { selectInitialLoading } from '../../store/current/loading'
import { stageChanged } from '../../store/ui/progressSlice'
import LoadingIcon from '../LoadingIcon'

import styles from './Page.module.scss'
import { useReferralContext } from './Referral/ReferralContext'

export interface PageProps {
  page: {
    stage: number
    component: React.FC
    requireSignIn: boolean
  }
}

const Page = ({ page }: PageProps) => {
  const dispatch = useDispatch()
  const { referralIsValid } = useReferralContext()
  const loading = useSelector(selectInitialLoading)
  const { stage, component: CurrentPage, requireSignIn } = page

  useEffect(() => {
    dispatch(stageChanged(stage))
  }, [dispatch, stage])

  let content
  if (loading && requireSignIn) {
    content = (
      <Row>
        <Col>
          <LoadingIcon>Vi henter data, vent et øjeblik...</LoadingIcon>
        </Col>
      </Row>
    )
  } else {
    content = (
      // TODO: Remove this hacky styling when HeadersWrapper is done
      <div
        className={classNames(styles.page, {
          [styles.referralBannerOffset]: referralIsValid,
        })}
      >
        <CurrentPage />
      </div>
    )
  }

  return content
}

export default Page
