import ConfirmationPage from './ConfirmationPage'
import Onboarding from './Onboarding'
import OnboardingCompletePage from './OnboardingCompletePage'
import StartPage from './StartPage'

const Company = {
  ConfirmationPage,
  Onboarding,
  OnboardingCompletePage,
  StartPage,
}

export default Company
