import AlertModal from '@nord/ui/src/components/AlertModal'
import CurrencyInput from '@nord/ui/src/components/CurrencyInput'
import Form from '@nord/ui/src/components/Form'
import Text from '@nord/ui/src/components/Text'
import useInitial from '@nord/ui/src/hooks/useInitial'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import { useFormikContext } from 'formik'
import React, { useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { MINIMUM_INVESTMENT, MINIMUM_PENSION_INVESTMENT } from '../../configuration/constants'
import useFlow from '../../hooks/useFlow'

const INVESTMENT_AMOUNT_FOR_EXPLANATION = 100000

const DebtTestFields = () => {
  const {
    values: { investableAssets, debt, expectedInvestment },
  } = useFormikContext<any>()
  const flow = useFlow()
  const [showDebtWarning, setShowDebtWarning] = useState(false)
  const [showExpectedInvestmentWarning, setShowExpectedInvestmentWarning] = useState(false)
  const initialExpectedInvestment = useInitial(expectedInvestment)

  const checkDebt = () => {
    if (!investableAssets || !debt) {
      return true
    }

    const assetsAfterDebt = parseInt(investableAssets, 10) - parseInt(debt, 10)

    if (assetsAfterDebt < 0) {
      setShowDebtWarning(true)

      return false
    }

    return true
  }

  let minimumInvestment: any
  if (flow === 'pension') {
    minimumInvestment = MINIMUM_PENSION_INVESTMENT
  } else {
    minimumInvestment = MINIMUM_INVESTMENT
  }

  const checkExpectedInvestment = () => {
    if (!expectedInvestment) {
      return true
    }

    if (parseInt(expectedInvestment, 10) < minimumInvestment) {
      setShowExpectedInvestmentWarning(true)

      return false
    }

    return true
  }

  const handleBlurExpectedInvestment = () => {
    checkExpectedInvestment()

    return true
  }

  const handleBlurDebt = () => {
    checkDebt()

    return true
  }

  const handleBlurInvestableAssets = () => {
    checkDebt()

    return true
  }

  const handleHideDebtAlert = () => setShowDebtWarning(false)
  const handleHideExpectedInvestmentAlert = () => setShowExpectedInvestmentWarning(false)
  const minimumInvestmentText = numberToCurrency(minimumInvestment)

  return (
    <>
      <Row>
        <Col xs={12}>
          <Form.FieldGroup name="expectedInvestment">
            {/* @ts-expect-error TS(2739) FIXME: Type '{ ({ maskOptions, value, ...otherProps }: Cu... Remove this comment to see the full error message */}
            <Form.Field as={CurrencyInput} onBlur={handleBlurExpectedInvestment} />
          </Form.FieldGroup>
          {initialExpectedInvestment <= INVESTMENT_AMOUNT_FOR_EXPLANATION && (
            <Text as="p" size="sm" variant="secondary">
              Vær opmærksom på, at Norm Invests minimumsindskud er på {minimumInvestmentText}{' '}
              Det er ikke muligt med den brede aktivallokering som porteføljerne har, at konstruere
              en veldiversificeret portefølje for under {minimumInvestmentText}
            </Text>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Form.FieldGroup name="investableAssets">
            {/* @ts-expect-error TS(2322) FIXME: Type '{ ({ maskOptions, value, ...otherProps }: Cu... Remove this comment to see the full error message */}
            <Form.Field as={CurrencyInput} onBlur={handleBlurInvestableAssets} />
          </Form.FieldGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Form.FieldGroup name="debt">
            {/* @ts-expect-error TS(2322) FIXME: Type '{ ({ maskOptions, value, ...otherProps }: Cu... Remove this comment to see the full error message */}
            <Form.Field as={CurrencyInput} onBlur={handleBlurDebt} />
          </Form.FieldGroup>
        </Col>
      </Row>
      <AlertModal
        onHide={handleHideDebtAlert}
        onAccept={handleHideDebtAlert}
        title="Desværre, du har for meget forbrugsgæld"
        text="Du er desværre ikke egnet til at investere i øjeblikket, da din forbrugsgæld overstiger din formue."
        show={showDebtWarning}
      />
      <AlertModal
        onHide={handleHideExpectedInvestmentAlert}
        onAccept={handleHideExpectedInvestmentAlert}
        title={`Minimumsindskud er ${minimumInvestmentText}`}
        text={`Vær opmærksom på, at Norm Invests minimumsindskud er på ${minimumInvestmentText} Det er ikke muligt med den brede aktivallokering som porteføljerne har, at konstruere en veldiversificeret portefølje for under ${minimumInvestmentText}`}
        show={showExpectedInvestmentWarning}
      />
    </>
  )
}

export default DebtTestFields
