import React from 'react'

import { ReactComponent as AppleAppStoreBadge } from './AppleAppStoreBadgeBlack.inline.svg'
import Basic from './Basic'

const appStoreUrl = 'https://itunes.apple.com/dk/app/nord/id1182947007?mt=8'
const defaultAlt = 'Hent i Apple Store'

export interface Props {
  alt?: string
}

const Apple = (props: Props) => (
  <Basic href={appStoreUrl} {...props}>
    <AppleAppStoreBadge />
  </Basic>
)

Apple.defaultProps = {
  alt: defaultAlt,
}

export default Apple
