import Paginator from '@nord/ui/src/components/Paginator'
import useWaitForStateChange from '@nord/ui/src/hooks/useWaitForStateChange'
import React, { useState, useEffect, useCallback } from 'react'
import Alert from 'react-bootstrap/Alert'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useSelector, useDispatch } from 'react-redux'

import {
  selectCurrentPortfolioId,
  createCurrentPortfolioDocuments,
  createCurrentPortfolioSaxoClient,
  selectOnboardingState,
} from '../../store/current/portfolioId'
import extractOnboardingState from '../../utilities/extractOnboardingState'

import ContentContainer from './ContentContainer'
import ContentTitle from './ContentTitle'
import ReturnNavigation from './ReturnNavigation'
import TimeoutProgressBar from './TimeoutProgressBar'

export interface Props {
  children: React.ReactNode
  actionContent: React.ReactNode
}

const ConfirmationPageContainer = ({ children, actionContent }: Props) => {
  const dispatch = useDispatch()
  const currentOnboardingState = useSelector(selectOnboardingState)
  const [timedOut, setTimedOut] = useState(false)
  const portfolioId = useSelector(selectCurrentPortfolioId)

  const handleTimeout = useCallback(() => setTimedOut(true), [])

  useWaitForStateChange(
    currentOnboardingState === 'ready_for_saxo'
      ? 'saxo_client_created'
      : ['ready_for_verification', 'documents_ready_to_sign'],
    handleTimeout,
    { currentState: currentOnboardingState, extractNewState: extractOnboardingState },
  )

  useEffect(() => {
    if (!portfolioId) return

    if (currentOnboardingState === 'ready_for_saxo') {
      // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
      dispatch(createCurrentPortfolioSaxoClient())
    } else if (currentOnboardingState === 'saxo_client_created') {
      // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
      dispatch(createCurrentPortfolioDocuments())
    }
  }, [currentOnboardingState, dispatch, portfolioId])

  const renderContent = () => {
    if (timedOut) {
      return (
        <p>
          <Alert variant="warning">
            Vi kan ikke oprette dig hos Saxo Bank. Prøv at genindlæs siden. Hvis dette ikke virker,
            så kontakt os venligst, så kan vi hjælpe dig videre.
          </Alert>
        </p>
      )
    }

    if (
      currentOnboardingState !== 'ready_for_verification' &&
      currentOnboardingState !== 'documents_ready_to_sign' &&
      currentOnboardingState !== 'investment_plan_completed'
    ) {
      return (
        <>
          <span>Vi er ved at gøre dokumenterne klar til dig, vent et øjeblik...</span>
          <TimeoutProgressBar />
        </>
      )
    }

    return actionContent
  }

  return (
    <>
      <ContentContainer>
        <ContentTitle title="Oversigt over din oprettelse" />
        {children}
        <Row>
          <Col className="mt-4">{renderContent()}</Col>
        </Row>
      </ContentContainer>
      <Paginator.Navigation.Container>
        <ReturnNavigation />
      </Paginator.Navigation.Container>
    </>
  )
}

export default ConfirmationPageContainer
