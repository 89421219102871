import { isArray, isObject, isNaN } from 'lodash'

import { calculateStepRatiosFromStepsObject, calculateBounds } from './utilities'

const calculateStepRatio = ({ lowerValue, upperValue, value }: any) => {
  const stepRange = upperValue - lowerValue
  const stepRatio = (value - lowerValue) / stepRange

  return stepRatio
}

const calculateWithObjectSteps = ({ steps, value }: any) => {
  const stepRatios = calculateStepRatiosFromStepsObject(steps)
  // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
  const stepValues = Object.values(steps).sort((a, b) => a - b)

  const [lowerValue, upperValue] = calculateBounds(stepValues, value)

  const lowerIndex = stepValues.indexOf(lowerValue)
  const lowerRatio = stepRatios[lowerIndex]
  const upperRatio = stepRatios[lowerIndex + 1] || lowerRatio
  const range = upperRatio - lowerRatio

  const stepRatio = calculateStepRatio({
    lowerValue,
    upperValue,
    value,
  })
  const ratio = lowerRatio + range * stepRatio

  return ratio
}

const calculateWithArraySteps = ({ steps, value }: any) => {
  const stepsCount = steps.length - 1

  const [lowerValue, upperValue] = calculateBounds(steps, value)
  const lowerIndex = steps.indexOf(lowerValue)

  const lowerRatio = lowerIndex <= 0 ? 0 : lowerIndex / stepsCount
  const upperRatio = lowerIndex > stepsCount ? 1 : (lowerIndex + 1) / stepsCount

  const stepRatio = calculateStepRatio({
    lowerValue,
    upperValue,
    value,
  })

  return lowerRatio + stepRatio * (upperRatio - lowerRatio)
}

const calculateWithoutSteps = ({ max, min, value }: any) => {
  const range = max - min
  const ratio = (value - min) / range

  return ratio
}

const generalValueToRatio =
  ({ min, max, steps, snapRatioToSteps }: any) =>
  (value: any) => {
    const parsedValue = parseFloat(value)
    if (isNaN(parsedValue)) return 0

    let ratio
    if (isArray(steps)) {
      ratio = calculateWithArraySteps({ value: parsedValue, steps })
    } else if (isObject(steps)) {
      ratio = calculateWithObjectSteps({ value: parsedValue, steps })
    } else {
      ratio = calculateWithoutSteps({ value: parsedValue, max, min })
    }

    return snapRatioToSteps(ratio)
  }

export default generalValueToRatio
