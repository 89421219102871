const privateTexts = {
  accountText: 'privat konto',
  onboardingText: 'privat oprettelse',
  accountType: 'private_account',
  flow: 'private',
  other: 'company',
}

const accountTypeTextMap = {
  private: privateTexts,
  pension: privateTexts,
  company: {
    accountText: 'virksomhedskonto',
    onboardingText: 'virksomhedsoprettelse',
    accountType: 'company_account',
    flow: 'company',
    other: 'private',
  },
}

export default accountTypeTextMap
