import LoadingButton from '@nord/ui/src/components/LoadingButton'
import NordLogo from '@nord/ui/src/components/NordLogo'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import { captureMessage } from '@nord/ui/src/utilities/sentry'
import classNames from 'classnames'
import isNil from 'lodash/isNil'
import React from 'react'

import companyTypeMap from './companyTypeMap'
import logos from './logos'
import PensionDepotEntry from './PensionDepotEntry'
import styles from './styles.module.scss'
import TableColumn from './TableColumn'

export interface Props {
  id: number
  mainPension?: any // TODO: PropTypes.shape(pensionPropTypes)
  name: string
  webSiteUrl: string
  kreditdataId: string
  pensions: any[] // TODO: PropTypes.shape(pensionPropTypes)
  submitting: boolean
  transferable: {
    status: boolean
    reason: string
    multiple: boolean
  }
  totalValue?: number
  className?: string
  onSelect: (...args: any[]) => any
  referenceNumber: string | number
  providerKey: string
  type?: string
  typeName: string
}

/** TODO
- add NORD
- Fix baffle button
- Fix button link variant
*/

const PensionProviderEntry = ({
  id,
  providerKey,
  name,
  webSiteUrl,
  totalValue,
  referenceNumber,
  pensions,
  mainPension,
  kreditdataId,
  transferable: { status: transferableStatus, reason: transferableReason, multiple },
  onSelect,
  submitting,
  ...otherProps
}: Props) => {
  const { type } = mainPension || {}
  const isNORD = name === 'NORD.investments'
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const logo = logos[id]
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const companyType = companyTypeMap[id]
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const migrationPensionCompanyType = companyTypeMap[id] || 'bank_company'

  // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
  if (!logo && !isNORD) captureMessage(`Can not find logo for ${name}`, { kreditdataId })
  if (!companyType && companyType !== null && !isNORD)
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
    captureMessage(`Can not find company type for ${name}`, { kreditdataId })

  const handleClick = ({ depotType, expectedInvestment, key }: any) => {
    const portfolioAttributes = {
      expectedInvestment,
      depotType,
      migrationDepot: true,
      migrationDepotCompanyName: name,
      migrationPensionCompanyType,
      pensionsInfoMigration: true,
      kreditdataId,
      key: key || providerKey,
    }

    if (migrationPensionCompanyType === 'pension_company')
      // @ts-expect-error TS(2339) FIXME: Property 'migrationPensionPoliceNumber' does not e... Remove this comment to see the full error message
      portfolioAttributes.migrationPensionPoliceNumber = referenceNumber

    if (migrationPensionCompanyType === 'bank_company') {
      // @ts-expect-error TS(2339) FIXME: Property 'migrationDepotRegistrationNumber' does n... Remove this comment to see the full error message
      portfolioAttributes.migrationDepotRegistrationNumber = referenceNumber.slice(0, 4)

      // @ts-expect-error TS(2339) FIXME: Property 'migrationDepotAccountNumber' does not ex... Remove this comment to see the full error message
      portfolioAttributes.migrationDepotAccountNumber = referenceNumber.slice(4)
    }

    onSelect(portfolioAttributes)
  }

  return (
    <tbody
      className={classNames({
        'bg-light': !transferableStatus,
      })}
    >
      <tr
        className={classNames(styles.providerRow, {
          [styles.disabledPensionProviderRow]: !transferableStatus,
          'border-dark': !transferableStatus,
        })}
      >
        {/* @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'ReactElem... Remove this comment to see the full error message */}
        <TableColumn rowspan={pensions.length + 1} as="th">
          <div className="d-flex justify-content-between flex-column align-items-center">
            <a
              href={webSiteUrl.startsWith('http') ? webSiteUrl : `http://${webSiteUrl}`}
              target="_blank"
              rel="noopener noreferrer"
              className={classNames({ 'link-dark': !transferableStatus })}
            >
              {name}
            </a>
            <div className={classNames('mb-3', styles.referenceNumber)}>
              {!isNORD && `Aftale nr: ${referenceNumber}`}
            </div>
            <div className={styles.imgContainer}>
              {isNORD && <NordLogo />}
              {logo && (
                <img
                  src={logo}
                  alt={name}
                  className={classNames('img-fluid', styles.imgResponsive, {})}
                />
              )}
            </div>
          </div>
        </TableColumn>
        {/* @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'ReactElem... Remove this comment to see the full error message */}
        <TableColumn as="th">Samlet</TableColumn>
        {/* @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'ReactElem... Remove this comment to see the full error message */}
        <TableColumn as="th">{isNil(totalValue) ? '-' : numberToCurrency(totalValue)}</TableColumn>
        {/* @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'ReactElem... Remove this comment to see the full error message */}
        <TableColumn as="th">
          {isNORD ? 'Din nuværende pension hos NORD' : transferableReason}
        </TableColumn>
        {multiple ? (
          <TableColumn fit>
            {!transferableStatus && !isNORD && <div>Kan ikke flyttes</div>}
          </TableColumn>
        ) : (
          // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; rowspan: number; fit: t... Remove this comment to see the full error message
          <TableColumn rowspan={pensions.length + 1} fit>
            {transferableStatus ? (
              <div className="d-flex justify-content-center align-items-center flex-column text-wrap">
                <LoadingButton
                  // @ts-expect-error TS(2322) FIXME: Type '{ children: string; size: string; variant: s... Remove this comment to see the full error message
                  size="sm"
                  variant="primary"
                  // @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
                  loading={submitting && submitting === providerKey}
                  disabled={!!submitting}
                  onClick={() =>
                    handleClick({
                      expectedInvestment: totalValue,
                      depotType: type,
                    })
                  }
                >
                  Se investeringsplan
                </LoadingButton>
              </div>
            ) : (
              <div>{isNORD ? '' : 'Kan ikke flyttes'}</div>
            )}
          </TableColumn>
        )}
      </tr>
      {pensions.map((pension) => {
        const key = `${providerKey}-${pension.typeName}`

        return (
          <PensionDepotEntry
            {...otherProps}
            {...pension}
            key={key}
            depotKey={key}
            transferable={{
              ...pension.transferable,
              status: !transferableStatus ? 'no' : pension.transferable.status,
            }}
            providerName={name}
            providerId={id}
            providerWebSiteUrl={webSiteUrl}
            referenceNumber={referenceNumber}
            multiple={multiple}
            onSelect={handleClick}
            submitting={submitting}
          />
        )
      })}
    </tbody>
  )
}

PensionProviderEntry.defaultProps = {
  className: undefined,
  mainPension: undefined,
  totalValue: undefined,
  type: undefined,
}

PensionProviderEntry.TableColumn = TableColumn

export default PensionProviderEntry
