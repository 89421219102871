import BaffleButton from '@nord/ui/src/components/BaffleButton'
import Card from '@nord/ui/src/components/Card'
import DescriptionList from '@nord/ui/src/components/DescriptionList'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { capitalize } from 'lodash'
import React from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import { PENSION_PATHS } from '../../../configuration/constants'
import { selectCurrentPortfolio } from '../../../store/current/portfolioId'
import { selectCurrentUser } from '../../../store/current/user'
import ConfirmationPageContainer from '../ConfirmationPageContainer'

const ConfirmationPage = () => {
  const {
    // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'unknown'.
    id: portfolioId,
    // @ts-expect-error TS(2339) FIXME: Property 'depotTypeTitle' does not exist on type '... Remove this comment to see the full error message
    depotTypeTitle,
    // @ts-expect-error TS(2339) FIXME: Property 'fullRiskScoreTitle' does not exist on ty... Remove this comment to see the full error message
    fullRiskScoreTitle,
    // @ts-expect-error TS(2339) FIXME: Property 'migrationDepot' does not exist on type '... Remove this comment to see the full error message
    migrationDepot,
    // @ts-expect-error TS(2339) FIXME: Property 'migrationPensionCompanyType' does not ex... Remove this comment to see the full error message
    migrationPensionCompanyType,
    // @ts-expect-error TS(2339) FIXME: Property 'migrationDepotCompanyName' does not exis... Remove this comment to see the full error message
    migrationDepotCompanyName,
    // @ts-expect-error TS(2339) FIXME: Property 'migrationDepotRegistrationNumber' does n... Remove this comment to see the full error message
    migrationDepotRegistrationNumber,
    // @ts-expect-error TS(2339) FIXME: Property 'migrationDepotAccountNumber' does not ex... Remove this comment to see the full error message
    migrationDepotAccountNumber,
    // @ts-expect-error TS(2339) FIXME: Property 'migrationDepotNumber' does not exist on ... Remove this comment to see the full error message
    migrationDepotNumber,
    // @ts-expect-error TS(2339) FIXME: Property 'migrationPensionPoliceNumber' does not e... Remove this comment to see the full error message
    migrationPensionPoliceNumber,
  } = useObjectSelector(selectCurrentPortfolio)
  // @ts-expect-error TS(2339) FIXME: Property 'email' does not exist on type 'unknown'.
  const { email } = useObjectSelector(selectCurrentUser)

  return (
    <ConfirmationPageContainer
      actionContent={
        <>
          <p>Klik nedenfor for at færdiggøre din oprettelse.</p>
          <p>
            Du accepterer samtidigt, at Norm Invest må videregive dit ønske til Saxo Bank, om
            at blive handlet i henhold til din risikoprofil og investeringsplan.{' '}
            {migrationDepot
              ? 'Dette vil først blive videreformidlet, når dit depot er modtaget hos Saxo Bank fra din nuværende bank.'
              : 'Dette vil først blive videreformidlet, når der er blevet indbetalt penge på din konto.'}
          </p>
          <BaffleButton
            variant="primary"
            type={undefined}
            as={Link}
            to={PENSION_PATHS.documents(portfolioId)}
          >
            Bekræft og fortsæt
          </BaffleButton>
        </>
      }
    >
      <p className="text-center">
        Dit pensionsdepot vil blive oprettet med følgende oplysninger. Bekræft venligst, at de er
        korrekte.
      </p>
      <Card className="shadow">
        <Card.Body>
          <DescriptionList>
            <DescriptionList.Item label="Depot type" value={capitalize(depotTypeTitle)} />
            <DescriptionList.Item label="Risikoprofil" value={fullRiskScoreTitle} />
            {migrationDepot && (
              <DescriptionList.Item
                label="Overflyttes fra"
                value={
                  <>
                    <div>{migrationDepotCompanyName}</div>
                    {migrationPensionCompanyType === 'bank_company' && (
                      <>
                        {migrationDepotRegistrationNumber && migrationDepotAccountNumber && (
                          <div>
                            {`Konto: ${migrationDepotRegistrationNumber} - ${migrationDepotAccountNumber}`}
                          </div>
                        )}
                        {migrationDepotNumber && <div>Depot: {migrationDepotNumber}</div>}
                      </>
                    )}
                    {migrationPensionCompanyType === 'pension_company' && (
                      <div>{migrationPensionPoliceNumber}</div>
                    )}
                  </>
                }
              />
            )}
            <DescriptionList.Item label="E-mail" value={email} />
          </DescriptionList>
        </Card.Body>
      </Card>
    </ConfirmationPageContainer>
  )
}

export default ConfirmationPage
