import CompletePage from './CompletePage'
import ConfirmationPage from './ConfirmationPage'
import InvestmentPlan from './InvestmentPlan'
import Onboarding from './Onboarding'
import PensionsInfoPage from './PensionsInfoPage'
import ResultPage from './ResultPage'
import StartPage from './StartPage'

const Pension = {
  InvestmentPlan,
  ResultPage,
  Onboarding,
  CompletePage,
  ConfirmationPage,
  StartPage,
  PensionsInfoPage,
}

export default Pension
