import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'

import { deepFreeze } from '../../../utilities/deepIterate'

import development from './development'

const test = deepFreeze(
  merge(cloneDeep(development), {
    domains: merge(cloneDeep(development.domains), {
      backend: 'https://test.api.nord.investments',
      webSocket: 'wss://test.api.nord.investments/cable',
    }),
  }),
)

export default test
