import Text from '@nord/ui/src/components/Text'
import { getConfig } from '@nord/ui/src/configuration'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ArrowRight from 'react-feather/dist/icons/arrow-right'

import IconButtonLink from '../../IconButtonLink'
import ContentTitle from '../ContentTitle'

const memberReferralAmount = getConfig('numbers.memberReferralAmount')
const memberReferralUrl = getConfig('urls.app.referrals')

export interface Props {
  pathToRedirect: string
}

const NotSuitableReferralsContent = ({ pathToRedirect }: Props) => (
  <>
    <ContentTitle title="Du er blevet inviteret til Norm Invest" />
    <Row>
      <Col xs={12} className="mb-3">
        <Text as="p" align="center">
          Du er allerede oprettet hos Norm Invest, og kan derfor ikke længere gøre brug af en henvisning.
          Du kan i stedet{' '}
          <Text as="a" href={memberReferralUrl}>
            henvise en ven
          </Text>
          , hvorefter din ven vil modtage {numberToCurrency(memberReferralAmount)}
        </Text>
      </Col>
    </Row>
    <Row>
      <Col xs={12} className="d-flex justify-content-center">
        <IconButtonLink icon={ArrowRight} to={pathToRedirect}>
          Gå til investeringsplan
        </IconButtonLink>
      </Col>
    </Row>
  </>
)

export default NotSuitableReferralsContent
