import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'

import { deepFreeze } from '../../../utilities/deepIterate'

import development from './development'

const saxo = deepFreeze(
  merge(cloneDeep(development), {
    domains: merge(cloneDeep(development.domains), {
      backend: 'https://saxo.api.nord.investments',
      onboarding: 'https://saxo.signup.nord.investments',
      app: 'https://saxo.app.nord.investments',
    }),
    urls: {
      external: {
        kreditDataConsent: 'https://app.kreditdata.dk/consent/323pv7q049/ixcc6i9nct',
      },
    },
  }),
)

export default saxo
