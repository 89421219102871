import { selectInitialLoading } from '@nord/ui/src/store/current/loading'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useHistory } from 'react-router-dom'

import { PENSION_PATHS } from '../../../../configuration/constants'

import useKreditDataId from './useKreditdataId'

const useInvestmentplanRedirect = () => {
  const history = useHistory()
  const loading = useSelector(selectInitialLoading)
  const kreditdataId = useKreditDataId()

  useEffect(() => {
    if (loading) return
    if (kreditdataId) return
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
    if (history.location.pathname !== PENSION_PATHS.pensionsInfo()) return

    history.push(PENSION_PATHS.investmentPlan('ny'))
  }, [history, kreditdataId, loading])
}

export default useInvestmentplanRedirect
