import Paginator from '@nord/ui/src/components/Paginator'
import Sustainability from '@nord/ui/src/components/Sustainability'
import React from 'react'

import ContentTitle from '../../ContentTitle'

const Header = () => <ContentTitle title="NORDs bæredygtige porteføljer" />

const SustainabilityPreferenceWarningPage = (otherProps: any) => (
  <Sustainability.Warning
    wrapperComponent={Paginator.Form.Page}
    headerComponent={Header}
    {...otherProps}
  />
)

export default SustainabilityPreferenceWarningPage
