import { deepFreeze } from '../../../utilities/deepIterate'

const documentationFolders = deepFreeze({
  docsForSigning: {
    key: 'docs-for-signing',
    name: 'Dokumenter til underskrift',
  },
  signedDocs: {
    key: 'signed-docs',
    name: 'Underskrevne dokumenter',
  },
  other: {
    key: 'documentation',
    name: 'Anden dokumentation',
  },
  sourceOfFunds: {
    key: 'source-of-funds',
    name: 'Kilde til formue',
  },
  kyc: {
    key: 'kyc',
    name: 'Adresse og identitet',
  },
  saxoReports: {
    key: 'saxo-reports',
    name: 'Porteføljerapporter',
  },
})

export default documentationFolders
