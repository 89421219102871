import BooleanInput from '@nord/ui/src/components/BooleanInput'
import { getConfig } from '@nord/ui/src/configuration'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import React, { useContext } from 'react'

import ResultPageContext from './ResultPageContext'

const freeAssetsMinimumInvestment = getConfig('numbers.minimumInvestment.freeAssets')

const SimpleAllocationSwitch = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'simple' does not exist on type 'unknown'... Remove this comment to see the full error message
  const { simple, onSimpleChange, pension, stocksOnly } = useContext(ResultPageContext)

  if (pension || stocksOnly) return null

  return (
    <div className="d-flex justify-content-center mt-4">
      <BooleanInput
        type="switch"
        label={`Jeg vil investere mindre end ${numberToCurrency(freeAssetsMinimumInvestment)}`}
        name="simplePortfolio"
        id="simplePortfolio"
        value={simple}
        onCheckedChange={onSimpleChange}
      />
    </div>
  )
}

export default SimpleAllocationSwitch
