import React from 'react'

export interface Props {
  children: React.ReactNode
}

const NestedField = ({ children }: Props) => (
  <div className="nested-field">
    <div className="nested-field-line visible-xs" />
    {children}
  </div>
)

export default NestedField
