import { object, string, number, array } from 'yup'

import { MINIMUM_INVESTMENT } from '../../../../../configuration/constants'

const validationSchema = object().shape({
  investableAssets: number().required().safeInteger().positive(),
  expectedInvestment: number()
    .required()
    .safeInteger()
    .min(MINIMUM_INVESTMENT)
    .when('investableAssets', (investableAssetsValue: any, schema: any) => {
      if (!investableAssetsValue) return schema

      return schema.max(investableAssetsValue, 'Beløbet kan ikke overstige din investerbare formue')
    }),
  sourceOfFunds: array().of(string()).nullable(),
})

export default validationSchema
