import React, { useEffect } from 'react'
import { Popover } from 'react-bootstrap'

export interface Props {
  popper: {
    scheduleUpdate: (...args: any[]) => any
  }
  children?: React.ReactNode
  triggerValue?: any
}

const MovingPopover = React.forwardRef<any, Props>(
  ({ popper: { scheduleUpdate }, children, triggerValue, ...otherProps }, ref) => {
    useEffect(() => {
      scheduleUpdate()
    }, [scheduleUpdate, triggerValue])

    return (
      // @ts-expect-error TS(2741) FIXME: Property 'id' is missing in type '{ children: Reac... Remove this comment to see the full error message
      <Popover ref={ref} {...otherProps}>
        {children}
      </Popover>
    )
  },
)

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type '(p... Remove this comment to see the full error message
MovingPopover.defaultProps = {
  children: undefined,
  triggerValue: undefined,
}

// @ts-expect-error TS(2339) FIXME: Property 'displayName' does not exist on type '(pr... Remove this comment to see the full error message
MovingPopover.displayName = 'MovingPopover'

export default MovingPopover
