import React, { useState, useCallback } from 'react'
import Alert from 'react-bootstrap/Alert'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import useWindowEventHandler from '../../hooks/useWindowEventHandler'
import BaffleButton from '../BaffleButton'
import TimeoutProgressBar from '../TimeoutProgressBar'

type PenneoSigningLinkProps = {
  penneoSigningUrl: string
  description: React.ReactNode
  onClick: React.MouseEventHandler<HTMLElement>
  onWindowFocus: () => void
  timedOut: boolean
}

const PenneoSigningLink = ({
  penneoSigningUrl,
  description,
  onClick,
  onWindowFocus,
  timedOut,
}: PenneoSigningLinkProps) => {
  const [linkIsClicked, setLinkIsClicked] = useState(false)

  const handleFocusWindow = useCallback(() => {
    if (!linkIsClicked) return

    if (onWindowFocus) onWindowFocus()
  }, [linkIsClicked, onWindowFocus])
  useWindowEventHandler('focus', handleFocusWindow)

  const handleClickLink = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (onClick) onClick(event)
    setLinkIsClicked(true)
  }

  const renderMessage = () => {
    if (timedOut) {
      return (
        <Alert variant="warning">
          Vi kunne ikke hente dine underskrift fra Penneo. Hvis du allerede har underskrevet, så
          prøv at genindlæs siden. Ellers så følg linket nedenfor for at underskrive dine
          dokumenter.
        </Alert>
      )
    }

    if (linkIsClicked) {
      return (
        <>
          <p>Vi henter din underskrift fra penneo, vent et øjeblik...</p>
          <TimeoutProgressBar />
        </>
      )
    }

    return description
  }

  return (
    <>
      <Row>
        <Col className="text-center">{renderMessage()}</Col>
      </Row>

      {(!linkIsClicked || timedOut) && (
        <Row>
          <Col className="text-center mt-4">
            <BaffleButton
              variant="primary"
              type={undefined}
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              href={penneoSigningUrl}
              onClick={handleClickLink}
            >
              Fortsæt til underskrift
            </BaffleButton>
          </Col>
        </Row>
      )}
    </>
  )
}

export default PenneoSigningLink
