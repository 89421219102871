const labels = {
  investableAssets:
    'Hvor stor er din investerbare formue (kontanter, indestående i banken, og værdipapirer)?',
  expectedInvestment:
    'Hvor meget forventer du at investere igennem Norm Invest (i løbet af de første 12 måneder)?',
  sourceOfFunds: 'Dokumentation',
}

export const fields = Object.keys(labels)

export default labels
