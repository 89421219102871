import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectCurrentPortfolioId } from '../store/current/portfolioId'
import { selectPortfolioIds } from '../store/current/portfolios'
import { selectOnboardingPortfolioIds } from '../store/current/portfolios/selectors'

import useFlow from './useFlow'
import useRouteMatchPortfolioId from './useRouteMatchPortfolioId'

const usePortfolioId = () => {
  const matchId = useRouteMatchPortfolioId()
  const flow = useFlow()
  const onboardingPortfolioIds = useSelector(selectOnboardingPortfolioIds)
  const portfolioId = useSelector(selectCurrentPortfolioId)
  const portfolioIds = useSelector(selectPortfolioIds)

  const fallbackId = portfolioIds[0]
  const newPortfolio = matchId === 'ny'

  return useMemo(() => {
    const validPortfolioId =
      (!portfolioId &&
        onboardingPortfolioIds[flow] === undefined &&
        (matchId === undefined || newPortfolio)) ||
      (portfolioId !== undefined && portfolioId === matchId)

    if (matchId && portfolioIds.includes(matchId)) return matchId

    if ((!portfolioId || !validPortfolioId) && onboardingPortfolioIds[flow] !== undefined)
      return onboardingPortfolioIds[flow]

    if (!portfolioId && !newPortfolio) return fallbackId

    return portfolioId
  }, [portfolioId, portfolioIds, onboardingPortfolioIds, flow, matchId, newPortfolio, fallbackId])
}

export default usePortfolioId
