import React, { useContext } from 'react'

import RouterContext from './ApplicationWrapper/plugins/Router/RouterContext'
import { PageSwitchContext } from './PageSwitchTransition'

const defaultLinkComponent = 'a'

export interface Props {
  onClick?: (...args: any[]) => any
  as?: React.ReactElement
}

const InternalLink = React.forwardRef<any, Props>(
  ({ onClick, as: Component, ...otherProps }, ref) => {
    const { active: pageTransitionActive } = useContext(PageSwitchContext)
    const RouterLink = useContext(RouterContext)
    const LinkComponent = Component || RouterLink || defaultLinkComponent

    const handleClick = (event: any) => {
      if (pageTransitionActive) {
        event.preventDefault()

        return false
      }

      if (onClick !== undefined) {
        return onClick(event)
      }

      return true
    }

    // @ts-expect-error TS(2604) FIXME: JSX element type 'LinkComponent' does not have any... Remove this comment to see the full error message
    return <LinkComponent ref={ref} onClick={handleClick} {...otherProps} />
  },
)

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type '(p... Remove this comment to see the full error message
InternalLink.defaultProps = {
  as: undefined,
  onClick: undefined,
}

// @ts-expect-error TS(2339) FIXME: Property 'displayName' does not exist on type '(pr... Remove this comment to see the full error message
InternalLink.displayName = 'InternalLink'

// @ts-expect-error TS(2339) FIXME: Property 'linkComponent' does not exist on type '(... Remove this comment to see the full error message
InternalLink.linkComponent = 'a'

export default InternalLink
