import AutocompleteSelect from '@nord/ui/src/components/AutocompleteSelect'
import Form from '@nord/ui/src/components/Form'
import useApi from '@nord/ui/src/hooks/useApi'
import useDebouncedCallback from '@nord/ui/src/hooks/useDebouncedCallback'
import { useFormikContext } from 'formik'
import React, { useCallback } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import AddressField from '../../../AddressField'

const debounceLimit = 800

const useLoadCVRData = () => {
  const searchForCompany = useApi('/', {
    baseURL: 'https://cvrapi.dk/api',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    errorHandling: {
      ignore: {
        client: true,
        server: true,
      },
    },
  })

  return useCallback(
    async (inputValue: any, options = {}) => {
      const defaultOption = {
        value: inputValue,
        label: `${inputValue} (udenlandsk/ukendt virksomhed)`,
        existsInCVR: false,
        data: {},
      }

      const { data: responseData, success } = await searchForCompany({
        search: inputValue,
        country: 'DK',
        ...options,
      })

      if (!success || !responseData) return [defaultOption]
      if (responseData.enddate) return [defaultOption]

      const {
        name: companyName,
        vat: cvr,
        address,
        zipcode: postalCode,
        city,
        industrycode: naceCode,
        industrydesc: naceDescription,
      } = responseData

      const data = {
        companyName,
        cvr,
        address,
        postalCode,
        city,
        naceCode,
        naceDescription,
      }

      const formattedOptions = [
        {
          label: `${companyName} (CVR: ${cvr})`,
          existsInCVR: true,
          data,
        },
        defaultOption,
      ]

      return formattedOptions
    },
    [searchForCompany],
  )
}

const CompanyNameAndCVRFields = () => {
  const { setFieldValue } = useFormikContext()
  const loadCVRData = useLoadCVRData()
  // @ts-expect-error TS(2554) FIXME: Expected 3 arguments, but got 2.
  const debouncedLoadCVRData = useDebouncedCallback(loadCVRData, debounceLimit)

  const handleSelect = (value: any) => {
    if (!value.existsInCVR) return

    const {
      data: { address, postalCode, city, cvr, naceCode, companyName },
    } = value

    setFieldValue('companyName', companyName, true)
    setFieldValue('naceCode', naceCode, true)
    setFieldValue('cvr', cvr, true)
    setFieldValue('address', address, true)
    setFieldValue('city', city, true)
    setFieldValue('postalCode', postalCode, true)
  }

  const handleChangeCompanyName = async (inputValue: any) => {
    const options = await debouncedLoadCVRData(inputValue)

    return options.map((option: any) => {
      if (option.existsInCVR) {
        option.value = option.data.companyName
      }

      return option
    })
  }

  const handleChangeCVR = async (inputValue: any) => {
    const options = await debouncedLoadCVRData(inputValue, { cvr: true })

    return options.map((option: any) => {
      if (option.existsInCVR) {
        option.value = option.data.cvr
      }

      return option
    })
  }

  return (
    <>
      <Row>
        <Col xs={6}>
          <Form.FieldGroup name="companyName">
            <Form.Field
              // @ts-expect-error TS(2739) FIXME: Type '{ ({ onSelect, value, ...otherProps }: Props... Remove this comment to see the full error message
              inputComponent={AutocompleteSelect}
              loadOptions={handleChangeCompanyName}
              onSelect={handleSelect}
              noOptionsMessage={() =>
                'Skriv dit virksomhedsnavn eller CVR nummer for at søge i CVR registret'
              }
            />
          </Form.FieldGroup>
        </Col>
        <Col xs={6}>
          <Form.FieldGroup name="cvr">
            <Form.Field
              // @ts-expect-error TS(2322) FIXME: Type '{ ({ onSelect, value, ...otherProps }: Props... Remove this comment to see the full error message
              inputComponent={AutocompleteSelect}
              name="cvr"
              loadOptions={handleChangeCVR}
              onSelect={handleSelect}
              noOptionsMessage={() => 'Skriv dit CPR nummer for at søge i CVR registret'}
            />
          </Form.FieldGroup>
        </Col>
      </Row>
      <AddressField />
    </>
  )
}

export default CompanyNameAndCVRFields
