import { captureException } from '@nord/ui/src/utilities/sentry'
import { createSlice } from '@reduxjs/toolkit'

const initialState = null
export const errorSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    errorOccurred: (_state, action) => {
      captureException(action.payload)

      return action.payload || true
    },
    errorCleared: () => null,
  },
})

export const { errorOccurred, errorCleared } = errorSlice.actions

export const selectError = (state: any) => state.ui.error

export const errorReducer = errorSlice.reducer

export default errorSlice
