import BaffleButton from '@nord/ui/src/components/BaffleButton'
import Loader from '@nord/ui/src/components/Loader'
import { PageSwitchContext } from '@nord/ui/src/components/PageSwitchTransition'
import React, { useContext } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import { SHARED_PATHS } from '../../../configuration/constants'
import useSignedIn from '../../../hooks/useSignedIn'
import ContentContainer from '../ContentContainer'
import ContentTitle from '../ContentTitle'

const NotFoundPage = () => {
  const { active } = useContext(PageSwitchContext)
  const signedIn = useSignedIn()

  return active ? (
    <Row>
      <Col className="text-center text-white font-size-xxl">
        <Loader />
      </Col>
    </Row>
  ) : (
    <ContentContainer>
      <ContentTitle title="Vi beklager, men vi kan ikke finde den side du leder efter..." />
      <Row>
        <Col className="text-center">
          {!signedIn && (
            <BaffleButton variant="link" as={Link} type={undefined} to={SHARED_PATHS.userSignIn()}>
              Allerede bruger? Log ind her
            </BaffleButton>
          )}
          <BaffleButton variant="link" type={undefined} as={Link} to={SHARED_PATHS.root()}>
            Gå til investeringsplan
          </BaffleButton>
        </Col>
      </Row>
    </ContentContainer>
  )
}

export default NotFoundPage
