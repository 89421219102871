import isNaN from 'lodash/isNaN'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useRouteMatch } from 'react-router-dom'

const useRouteMatchPortfolioId = () => {
  const match = useRouteMatch('/:flow/:id')

  if (!match || !match.params) return undefined

  let {
    params: { id: matchId },
  } = match
  if (matchId !== 'ny') matchId = parseInt(matchId, 10)
  if (isNaN(matchId)) return undefined

  return matchId
}

export default useRouteMatchPortfolioId
