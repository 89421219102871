import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import isBoolean from 'lodash/isBoolean'
import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'

import useSignedIn from '../../../../../../hooks/useSignedIn'
import { selectOnboardingState } from '../../../../../../store/current/portfolioId'
import ResultPageContext from '../ResultPageContext'
import getRiskScoreAdvisedState from '../utilities/getRiskScoreAdvisedState'

const useModalState = () => {
  const {
    // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'unknown'.
    id: portfolioId,
    // @ts-expect-error TS(2339) FIXME: Property 'advisedRiskScore' does not exist on type... Remove this comment to see the full error message
    advisedRiskScore,
    // @ts-expect-error TS(2339) FIXME: Property 'advisedEsg' does not exist on type 'unkn... Remove this comment to see the full error message
    advisedEsg,
  } = useObjectSelector(selectCurrentPortfolio)
  const signedIn = useSignedIn()
  const onboardingState = useSelector(selectOnboardingState)

  const userAlreadyOnboarded = signedIn && onboardingState === 'saxo_onboarded'

  // @ts-expect-error TS(2339) FIXME: Property 'esg' does not exist on type 'unknown'.
  const { esg, riskScore } = useContext(ResultPageContext)

  const esgDiffers = isBoolean(advisedEsg) && advisedEsg !== esg

  const riskScoreAdvisedState = getRiskScoreAdvisedState(riskScore, advisedRiskScore)

  const modalState = useMemo(() => {
    if (signedIn && userAlreadyOnboarded && portfolioId) return 'updateRiskScore'

    if (riskScoreAdvisedState === 'advised' && esgDiffers) return 'esgDiffers'

    if (riskScoreAdvisedState === 'strongly_not_advised') return 'riskScoreOutsideRange'

    // @ts-expect-error TS(2367) FIXME: This condition will always return 'true' since the... Remove this comment to see the full error message
    if (riskScoreAdvisedState !== 'strongly_not_advised') return 'riskScoreDiffers'

    return null
  }, [esgDiffers, portfolioId, riskScoreAdvisedState, signedIn, userAlreadyOnboarded])

  return modalState
}

export default useModalState
