import filter from 'lodash/filter'

// eslint-disable-next-line import/prefer-default-export
export const getTitle = (pensionProviders: any) => {
  const transferablePensionsCount =
    pensionProviders &&
    filter(
      pensionProviders,
      ({ transferable: { status: transferableStatus } }) => transferableStatus,
    ).length

  if (pensionProviders)
    return `Du har ${transferablePensionsCount} pensioner, der kan flyttes til NORD`

  return 'Henter fra PensionsInfo...'
}
