import { selectInitialLoading } from '@nord/ui/src/store/current/loading'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import useFlow from '../../../../../../hooks/useFlow'
import useReadyToOnboard from '../../../../../../hooks/useReadyToOnboard'
import useSignedIn from '../../../../../../hooks/useSignedIn'
import { selectOnboardingState } from '../../../../../../store/current/portfolioId'

const useButtonType = () => {
  const loading = useSelector(selectInitialLoading)
  const signedIn = useSignedIn()
  const flow = useFlow()
  const onboardingState = useSelector(selectOnboardingState)

  const userAlreadyOnboarded = signedIn && onboardingState === 'saxo_onboarded'

  const readyToOnboard = useReadyToOnboard()

  const buttonType = useMemo(() => {
    if (loading) return null
    if (flow && readyToOnboard) return 'retakeInvestmentPlan'
    if (flow && userAlreadyOnboarded) return 'updateRiskAdvice'

    return null
  }, [flow, loading, readyToOnboard, userAlreadyOnboarded])

  return buttonType
}

export default useButtonType
