import Paginator from '@nord/ui/src/components/Paginator'
import React from 'react'

import ExpectedInvestmentPage from './ExpectedInvestmentPage'

const ExpectedInvestmentSection = ({ ...otherProps }) => (
  // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; navigationComponent: bo... Remove this comment to see the full error message
  <Paginator.Form.Wrapper.WithNested.WithQueryParam
    navigationComponent={false}
    useAbsolute
    {...otherProps}
  >
    <ExpectedInvestmentPage />
  </Paginator.Form.Wrapper.WithNested.WithQueryParam>
)

export default ExpectedInvestmentSection
