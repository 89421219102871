import IconButton from '@nord/ui/src/components/IconButton'
import Text from '@nord/ui/src/components/Text'
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Check from 'react-feather/dist/icons/check'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Cancel from 'react-feather/dist/icons/x'
import { CSSTransition } from 'react-transition-group'

import PepInformation from './PepInformation'

export interface Props {
  show: boolean
  onHide: (...args: any[]) => any
  onConfirm: (...args: any[]) => any
}

const PepConfirmationModal = ({ show, onHide, onConfirm }: Props) => {
  const [showPepInformation, setShowPepInformation] = useState(false)

  const handleShowPepInformation = () => setShowPepInformation(true)

  const handleConfirm = () => {
    onHide()
    onConfirm(true)
  }

  const handleReject = () => {
    onHide()
    onConfirm(false)
  }

  return (
    <Modal show={show} onHide={onHide} size="lg" className="text-dark">
      <Modal.Header closeButton>
        <Modal.Title className="w-100 text-center">Hov! Kan det passe?</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          Du har svaret, at du er en politisk eksponeret person, men det er der meget få, der
          faktisk er. Typisk vil det være statschefer, ambassadører, højesteretsdommere eller andre,
          der arbejder med det politiske system.
        </p>
        <p>
          Se alle detaljerne om en politisk eksponeret person{' '}
          <Text as="a" href="#" onClick={handleShowPepInformation}>
            her
          </Text>
          .
        </p>
        <CSSTransition classNames="slide-down" in={showPepInformation} timeout={500}>
          <div
            className={`slide-down ${showPepInformation ? 'slide-down-show' : 'slide-down-hide'}`}
          >
            <PepInformation />
          </div>
        </CSSTransition>
      </Modal.Body>

      <Modal.Footer className="justify-content-center">
        <IconButton
          id="confirm"
          variant="primary"
          icon={Check}
          iconPlacement="first"
          onClick={handleConfirm}
        >
          Ja, jeg er en politisk eksponeret person
        </IconButton>
        <IconButton
          id="reject"
          variant="primary"
          icon={Cancel}
          iconPlacement="first"
          onClick={handleReject}
        >
          Nej, det var en fejl
        </IconButton>
      </Modal.Footer>
    </Modal>
  )
}

export default PepConfirmationModal
