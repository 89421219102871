const labels = {
  currentOccupation: 'Beskæftigelse',
  nameOfEmployee: 'Nuværende arbejdsgiver',
  jobTitle: 'Din stilling',
  jobIndustry: 'Arbejder du i en af følgende høj risiko brancher?',
  monthlyIncome: 'Månedlig indkomst (efter skat)',
}

export const fields = ['yearlyIncome', ...Object.keys(labels)]

export default labels
