import CurrencyInput from '@nord/ui/src/components/CurrencyInput'
import Form from '@nord/ui/src/components/Form'
import { useField } from 'formik'
import React from 'react'

const MonthlyIncomeField = () => {
  const [, , { setValue }] = useField('yearlyIncome')

  const handleChange = (newValue: any) => {
    const yearlyIncome = (parseInt(newValue, 10) || 0) * 12

    setValue(yearlyIncome, true)
  }

  return (
    <Form.FieldGroup name="monthlyIncome">
      {/* @ts-expect-error TS(2739) FIXME: Type '{ ({ maskOptions, value, ...otherProps }: Cu... Remove this comment to see the full error message */}
      <Form.Field as={CurrencyInput} onValueChange={handleChange} />
    </Form.FieldGroup>
  )
}

export default MonthlyIncomeField
