import { object, string, number, array } from 'yup'

import {
  MINIMUM_INVESTMENT,
  MINIMUM_PENSION_INVESTMENT,
} from '../../../../../configuration/constants'

import { otherSourceOfAssetsValue } from './SourcesOfAssetsField'

const validationSchema = (isPension = false) =>
  object().shape({
    expectedInvestment: number()
      .required()
      .safeInteger()
      .min(isPension ? MINIMUM_PENSION_INVESTMENT : MINIMUM_INVESTMENT)
      .when(
        ['expectsPensionDeposits', 'investableAssets'],
        // @ts-expect-error TS(2345)
        (expectsPensionDepositsValue, investableAssetsValue, schema) => {
          if (expectsPensionDepositsValue === false) return schema
          if (!investableAssetsValue) return schema

          return schema.max(
            investableAssetsValue,
            'Beløbet kan ikke overstige din investerbare formue',
          )
        },
      ),
    investableAssets: number().required().safeInteger().positive(),
    debt: number()
      .safeInteger()
      .min(0)
      .required()
      .when('investableAssets', (investableAssetsValue, schema) => {
        if (!investableAssetsValue) return schema

        return schema.max(
          investableAssetsValue,
          'Beløbet kan ikke overstige din investerbare formue',
        )
      }),
    sourcesOfAssets: array().of(string()).min(1),
    sourceOfAssetsOther: string().when('sourcesOfAssets', (value, schema) =>
      value && value.includes(otherSourceOfAssetsValue) ? schema.required() : schema,
    ),
    sourceOfFunds: array().of(string()).nullable(),
  })

export default validationSchema
