import Form from '@nord/ui/src/components/Form'
import MaskedInput from '@nord/ui/src/components/MaskedInput'
import React from 'react'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'

const cprMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

const parser = (inputValue: any) => {
  if (!inputValue) return ''

  const parsedCPR = inputValue.replace('-', '')

  return parsedCPR
}

export interface CPRInputProps {
  isInvalid: boolean
}

const CPRInput = React.forwardRef<any, CPRInputProps>(({ ...otherProps }, ref) => (
  <InputGroup className="mb-3">
    {/* @ts-expect-error TS(2322) FIXME: Type '{ ({ value, defaultValue, onChange, onValueC... Remove this comment to see the full error message */}
    <Form.Control ref={ref} as={MaskedInput} mask={cprMask} parser={parser} {...otherProps} />
    <InputGroup.Append>
      {/* @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'number'. */}
      <Button variant="link" className="text-base btn-anchor" tabIndex="-1">
        Hent data
      </Button>
    </InputGroup.Append>
  </InputGroup>
))

export default CPRInput
