import React, { useRef, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'

import './styles.scss'

export interface Props {
  children: React.ReactNode
  onResize: (...args: any[]) => any
}

const ResponsiveContainer = ({ children, onResize, ...props }: Props) => {
  const containerRef = useRef()
  const [size, setSize] = useState({ width: 0, height: 0 })

  const handleResize = () => {
    if (containerRef.current) {
      const { clientWidth, clientHeight } = containerRef.current
      const newSize = { width: clientWidth, height: clientHeight }

      setSize(newSize)

      if (typeof onResize === 'function') {
        onResize(newSize)
      }
    }
  }

  const { width, height } = size

  const { ref: resizeDetectorRef } = useResizeDetector({
    onResize: handleResize,
    refreshMode: 'debounce',
    refreshRate: 100,
  })

  let child
  if (typeof children === 'function') {
    // @ts-expect-error TS(2349) FIXME: This expression is not callable.
    child = children({ width, height })
  } else {
    child = React.Children.only(children)
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    child = React.cloneElement(child, { width, height })
  }

  return (
    <div ref={resizeDetectorRef}>
      {/* @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message */}
      <div {...props} className="responsive-container" ref={containerRef}>
        {child}
      </div>
    </div>
  )
}

export default ResponsiveContainer
