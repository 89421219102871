import classNames from 'classnames'
import isFunction from 'lodash/isFunction'
import React, { useState, useCallback } from 'react'

import useBreakpointHandler from '../hooks/useBreakpointHandler'
import useInView from '../hooks/useInView'
import type { bootstrapSize } from '../utilities/propTypes'

import Card from './Card'
import styles from './StickyCard.module.scss'

export interface Props {
  bottom?: boolean
  // @ts-expect-error TS(2749) FIXME: 'bootstrapSize' refers to a value, but is being us... Remove this comment to see the full error message
  breakpoint?: bootstrapSize
  children?: React.ReactNode
  className?: string
  content?: React.ReactNode | ((...args: any[]) => any)
  enabled?: boolean
  footer?: React.ReactNode
  header?: React.ReactNode
  onIsBelowInputChange?: (...args: any[]) => any
  withReferralBanner?: boolean
  withTopFixedNavigation?: boolean
}

const StickyCard = ({
  enabled,
  breakpoint,
  children,
  content,
  header,
  footer,
  className,
  bottom,
  withTopFixedNavigation,
  withReferralBanner,
  onIsBelowInputChange,
  ...otherProps
}: Props) => {
  const [windowIsBelowInput, setWindowIsBelowInput] = useState(false)
  const largeAndUp = useBreakpointHandler(breakpoint)

  const handleInView = useCallback(
    ({ inView, direction }: any) => {
      const newWindowIsBelowInput = !inView && direction === 'bottom'

      if (onIsBelowInputChange) onIsBelowInputChange(newWindowIsBelowInput)

      setWindowIsBelowInput(newWindowIsBelowInput)
    },
    [onIsBelowInputChange],
  )

  const refInput = useInView(handleInView)

  const isFixed = windowIsBelowInput && enabled
  const isSticky = enabled && largeAndUp

  const mainContent = content || children

  return (
    <>
      <div
        className={classNames(className, {
          [styles.stickyCard]: isSticky,
          [styles.withTopFixedNavigation]: withTopFixedNavigation,
        })}
        {...otherProps}
      >
        <Card>
          <Card.Body>
            {isFunction(mainContent) ? (
              mainContent(refInput)
            ) : (
              <div ref={refInput}>{mainContent}</div>
            )}
          </Card.Body>
        </Card>
        {footer}
      </div>
      {isFixed && (
        <div
          className={classNames(styles.stickyHeader, `d-${breakpoint}-none`, {
            [styles.bottomPositioned]: bottom,
            [styles.withReferralBanner]: withReferralBanner,
          })}
        >
          {header || children}
        </div>
      )}
    </>
  )
}

StickyCard.defaultProps = {
  bottom: false,
  breakpoint: 'lg',
  children: undefined,
  className: undefined,
  content: undefined,
  enabled: true,
  footer: undefined,
  header: undefined,
  onIsBelowInputChange: undefined,
  withReferralBanner: false,
  withTopFixedNavigation: false,
}

export default StickyCard
