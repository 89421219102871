import { useSelector } from 'react-redux'

import { selectCurrentUserId } from '../store/current/user'

const useSignedIn = () => {
  const id = useSelector(selectCurrentUserId)

  return !!id
}

export default useSignedIn
