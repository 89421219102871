import type yup from 'yup'
import { addMethod, boolean } from 'yup'

function accepted(this: yup.BooleanSchema, message = 'Skal accepteres') {
  return this.test({
    name: 'accepted',
    message,
    params: {},
    exclusive: true,
    test: (value) => value === true,
  })
}

addMethod(boolean, 'accepted', accepted)

declare module 'yup' {
  interface BooleanSchema {
    accepted(message?: string): BooleanSchema
  }
}
