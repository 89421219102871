const companyTypeMap = {
  1: null, // atp
  6: 'pension_company', // industriensPension
  8: 'pension_company', // pensionDanmark
  9: 'pension_company', // skandia
  10: 'pension_company', // pfaPension
  11: 'pension_company', // laerernesPension
  12: null, // ldFonde
  15: 'pension_company', // apPension
  25: 'pension_company', // pka
  30: 'pension_company', // pka
  31: 'pension_company', // pka
  37: null, // tjenestemandspensionStaten
  36: 'pension_company', // pplus
  48: 'pension_company', // penSam
  54: 'pension_company', // danicaPension
  55: 'bank_company', // danskeBank
  56: 'bank_company', // jyskeBank
  57: 'bank_company', // nordea
  58: 'pension_company', // velliv
  59: null, // alka
  63: 'pension_company', // topdanmark
  65: 'pension_company', // sampension
  66: 'pension_company', // sampension
  67: 'pension_company', // sampension
  68: 'pension_company', // paedagogernesPension
  69: 'pension_company', // isp
  70: 'pension_company', // arkitektPension
  71: 'pension_company', // pjd
  72: 'pension_company', // akademikerPension
  73: 'pension_company', // almBrandPension
  75: 'pension_company', // laegernesPension
  78: 'pension_company', // pka
  95: 'bank_company', // sparNordBank
  177: 'bank_company', // vestjyskBank
  180: 'bank_company', // nykredit
  182: 'bank_company', // seb
  226: 'bank_company', // landboBanken
  227: 'bank_company', // skjernBank
  230: 'bank_company', // sydbank
  231: 'bank_company', // fynskeBank
  243: null, // forenedeGruppeliv,
  244: null, // forenedeGruppeliv,
  245: null, // forenedeGruppeliv,
  246: null, // forenedeGruppeliv,
  248: null, // forenedeGruppeliv,
  525: 'pension_company', // pfs
}

export default companyTypeMap
