import cloneDeep from 'lodash/cloneDeep'
import defaultTo from 'lodash/defaultTo'
import get from 'lodash/get'
import merge from 'lodash/merge'

import { deepFreeze } from '../../utilities/deepIterate'

import environment, { getEnvironmentVariable, environmentConfigurations } from './environment'
import shared from './shared'

const config = deepFreeze(merge(cloneDeep(shared), environment))

export type Config = typeof config

export type PathImpl<T, K extends keyof T> = K extends string
  ? T[K] extends Record<string, any>
    ? T[K] extends ArrayLike<any>
      ? K | `${K}.${PathImpl<T[K], Exclude<keyof T[K], keyof any[]>>}`
      : K | `${K}.${PathImpl<T[K], keyof T[K]>}`
    : K
  : never

export type Path<T> = PathImpl<T, keyof T> | keyof T

export type PathValue<T, P extends Path<T>> = P extends `${infer K}.${infer Rest}`
  ? K extends keyof T
    ? Rest extends Path<T[K]>
      ? PathValue<T[K], Rest>
      : never
    : never
  : P extends keyof T
  ? T[P]
  : never

/**
 * Returns a configuration variable based on the `path`.
 * Environment variables with the same name as the path key will override the configuration value.
 * This also works for REACT_APP_ prefixed environment variables, but NOT for GATSBY_ prefixed variables.
 * See `getEnvironmentVariable` for more info.
 *
 * @example
 *
 * // .env
 * ENABLE_SOMETHING=false
 *
 * // index.js
 * getConfig('setup.enableSentry') #=> true
 * getConfig('setup.enableSomething') #=> false
 */
export const getConfig = <P extends Path<Config>>(path: P, currentEnvironment = undefined) => {
  const parts = path.split('.')
  const key = parts[parts.length - 1]

  let currentConfig = config
  if (currentEnvironment)
    currentConfig = deepFreeze(
      merge(cloneDeep(shared), environmentConfigurations[currentEnvironment]),
    )

  return defaultTo(getEnvironmentVariable(key), get(currentConfig, path)) as unknown as PathValue<
    Config,
    P
  >
}

export { currentEnvironment as environment, getEnvironmentVariable } from './environment'
export default config
