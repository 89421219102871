import Form from '@nord/ui/src/components/Form'
import Paginator from '@nord/ui/src/components/Paginator'
import Select from '@nord/ui/src/components/Select'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { object, string } from 'yup'

import ContentTitle from '../../ContentTitle'

export const migrationOptions = [
  { label: 'Ratepension', value: 'ratepension' },
  { label: 'Aldersopsparing (kun flytning)', value: 'aldersopsparing' },
  { label: 'Kapitalpension (kun flytning)', value: 'kapitalpension' },
  { label: 'Livrente (kan ikke vælges)', value: 'livrente', isDisabled: true },
]

const validationSchema = object().shape({
  depotType: string().when('migrationDepot', {
    is: false,
    then: string()
      .oneOf(
        ['ratepension'],
        'I øjeblikket kan du kun oprette en ny ratepension. Du kan dog flytte andre pensioner til Norm Invest.',
      )
      .required(),
    otherwise: string().oneOf(['ratepension', 'kapitalpension', 'aldersopsparing']).required(),
  }),
})

const labels = {
  depotType: 'Pensionstype',
}

const fields = Object.keys(labels)

const SelectTypePage = (otherProps: any) => (
  <Paginator.Form.Page
    validationSchema={validationSchema}
    fields={fields}
    labelDescriptions={labels}
    {...otherProps}
  >
    <ContentTitle title="Vælg pensionsdepot" />
    <Row>
      <Col>
        <p>Vælg den type af pension du ønsker at få en investeringsplan for.</p>
      </Col>
    </Row>
    <Row>
      <Col>
        <Form.FieldGroup name="depotType">
          {/* @ts-expect-error TS(2739) FIXME: Type '{ ({ onChange, onValueChange, onSelect, opti... Remove this comment to see the full error message */}
          <Form.Field inputComponent={Select} options={migrationOptions} />
        </Form.FieldGroup>
      </Col>
    </Row>
    <Row>
      <Col>
        {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; className: string; varia... Remove this comment to see the full error message */}
        <Form.SubmitButton className="mr-2" variant="primary" data-cy="next-button">
          Vælg og fortsæt
        </Form.SubmitButton>
      </Col>
    </Row>
  </Paginator.Form.Page>
)

export default SelectTypePage
