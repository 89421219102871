import { ExtraErrorData } from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import z from 'zod'

import { getConfig, getEnvironmentVariable, environment } from '../config'

const { name: environmentName } = environment

const debug = getConfig('debug')

const enableSentry = getConfig('setup.enableSentry')

const dsn = z
  .string()
  .optional()
  .parse(process.env.GATSBY_SENTRY_DSN || getEnvironmentVariable('sentryDSN'))

const commitRef = z
  .string()
  .optional()
  .parse(process.env.GATSBY_COMMIT_REF || getEnvironmentVariable('commitRef'))

if (enableSentry)
  Sentry.init({
    attachStacktrace: true,
    debug,
    dsn,
    environment: environmentName,
    integrations: [new Integrations.BrowserTracing(), new ExtraErrorData()],
    release: commitRef,
    tracesSampleRate: 0.2,
  })
