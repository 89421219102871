import BooleanInput from '@nord/ui/src/components/BooleanInput'
import Form from '@nord/ui/src/components/Form'
import IconButton from '@nord/ui/src/components/IconButton'
import { useFormikContext } from 'formik'
import React, { useState } from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Info from 'react-feather/dist/icons/info'

import PepConfirmationModal from './PepConfirmationModal'
import PepInformationModal from './PepInformationModal'

const PepField = () => {
  const [modalContent, setModalContent] = useState()

  const { values, setFieldValue } = useFormikContext()

  const handleCheckPep = (userIsPep: any) => {
    if (userIsPep) {
      // @ts-expect-error TS(2345) FIXME: Argument of type '"confirmation"' is not assignabl... Remove this comment to see the full error message
      setModalContent('confirmation')

      // Return false to prevent default behavior (toggling it)
      return false
    }

    return undefined
  }

  // @ts-expect-error TS(2345) FIXME: Argument of type '"information"' is not assignable... Remove this comment to see the full error message
  const handleClickInfoIcon = () => setModalContent('information')

  // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
  const handleHideModal = () => setModalContent()

  const handleClickConfirmButton = (status: any) => {
    handleHideModal()
    setFieldValue('pep', status)
  }

  const label = (
    <>
      Jeg er en politisk eksponeret person (PEP){' '}
      <IconButton icon={Info} variant="link" className="p-0" onClick={handleClickInfoIcon} />
    </>
  )

  return (
    <>
      <Form.FieldGroup name="pep" label={false}>
        <Form.Field
          // @ts-expect-error TS(2739) FIXME: Type '{ ({ id, name, label, type, onChange, onValu... Remove this comment to see the full error message
          inputComponent={BooleanInput}
          label={label}
          // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
          value={values.pep}
          onValueChange={handleCheckPep}
          asFormControl
        />
      </Form.FieldGroup>
      <PepConfirmationModal
        show={modalContent === 'confirmation'}
        onHide={handleHideModal}
        onConfirm={handleClickConfirmButton}
      />
      <PepInformationModal show={modalContent === 'information'} onHide={handleHideModal} />
    </>
  )
}

export default PepField
