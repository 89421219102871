import Paginator, { usePaginationContext } from '@nord/ui/src/components/Paginator'
import AnswerField from '@nord/ui/src/components/QuestionsSection/QuestionPage/AnswerField'
import InfoModal from '@nord/ui/src/components/QuestionsSection/QuestionPage/InfoModal'
import useApi, { useApiRequest } from '@nord/ui/src/hooks/useApi'
import isEmpty from 'lodash/isEmpty'
import React, { useContext } from 'react'
import Row from 'react-bootstrap/Row'

import LoadingIcon from '../../../LoadingIcon'
import ContentTitle from '../../ContentTitle'

import InvestmentPlanContext from './InvestmentPlanContext'

const getQuestionData = (data: any) => {
  if (!data) return {}
  if (isEmpty(data)) return {}

  const { data: questions } = data

  if (isEmpty(questions)) return {}

  const question = questions[0]

  return question
}

const SustainabilityPreferenceQuestion = (otherProps: any) => {
  const { onNext } = usePaginationContext(false)
  // @ts-expect-error TS(2339) FIXME: Property 'investmentProfileAttributes' does not ex... Remove this comment to see the full error message
  const { investmentProfileAttributes, setInvestmentProfileAttributes } =
    useContext(InvestmentPlanContext)

  const fetchInvestmentPlanQuestions = useApi('/investment_profiles')

  const { data, loading } = useApiRequest(fetchInvestmentPlanQuestions, {
    payload: {
      type: 'sustainability_preferences',
    },
    autoCall: true,
  })

  const { version } = data || {}

  const questionData = getQuestionData(data)

  const { question, answers, infoText } = questionData

  const handleAnswer = async (answerString: any) => {
    const answerIndex = answers.indexOf(answerString)

    setInvestmentProfileAttributes({
      version,
      answers: [{ ...questionData, answer: answerString, answerIndex }],
    })

    onNext()
  }

  const { answers: currentAnswers } = investmentProfileAttributes

  const currentAnswer = !isEmpty(currentAnswers) && currentAnswers[0].answer

  return (
    <Paginator.Form.Page {...otherProps}>
      {loading && isEmpty(questionData) ? (
        // @ts-expect-error TS(2741) FIXME: Property 'children' is missing in type '{}' but re... Remove this comment to see the full error message
        <LoadingIcon />
      ) : (
        <>
          <ContentTitle title={question} />
          <InfoModal {...infoText} />
          <Row xs={12} data-cy="answers">
            {answers.map((answer: any, index: any) => (
              <AnswerField
                key={answer}
                index={index}
                answer={answer}
                onAnswer={handleAnswer}
                selected={answer === currentAnswer}
              />
            ))}
          </Row>
        </>
      )}
    </Paginator.Form.Page>
  )
}

export default SustainabilityPreferenceQuestion
