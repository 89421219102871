import Loader from '@nord/ui/src/components/Loader'
import useApi, { useApiRequest } from '@nord/ui/src/hooks/useApi'
import React, { useState, useMemo, useContext } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import ResultPageContext from '../ResultPageContext'
import Section from '../Section'
import SimpleAllocationSwitch from '../SimpleAllocationSwitch'

import Chart from './Chart'
import Legend from './Legend'

const formatData = (data: any) => {
  if (!data) return []

  const formattedData = data.map(({ label, value }: any) => ({
    name: label,
    value,
  }))

  return formattedData
}

const Allocation = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'riskScore' does not exist on type 'unkno... Remove this comment to see the full error message
  const { riskScore, pension, simple } = useContext(ResultPageContext)

  const [currentHover, setCurrentHover] = useState(null)

  const fetchData = useApi('/allocation')

  const { data: rawData, loading } = useApiRequest(fetchData, {
    autoCall: true,
    enableDebounce: true,
    payload: {
      pension,
      riskScore,
      simple,
      categories: true,
    },
  })

  const data = useMemo(() => formatData(rawData), [rawData])

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; title: string; id: st... Remove this comment to see the full error message
    <Section title="Fordeling af aktivklasser" id="fordeling-af-aktivklasser">
      <Row>
        <Col xs={12}>
          <p>
            Ens fordeling af aktivklasser er med til at afgøre ens risikoprofil. Vi anbefaler en
            kombination af aktier og obligationer
            {pension && ' og ejendomme'}.
          </p>
        </Col>
      </Row>
      <Row className="flex-column-reverse flex-lg-row position-relative">
        {loading && <Loader className="font-size-xl text-secondary" centeredInParent />}
        <Col xs={12} lg={7}>
          {/* @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'number | un... Remove this comment to see the full error message */}
          <Chart data={data} currentHover={currentHover} onCurrentHoverChange={setCurrentHover} />
        </Col>
        <Col xs={12} lg={{ span: 4, offset: 1 }} className="my-auto">
          {/* @ts-expect-error TS(2322) FIXME: Type '{ data: any; loading: any; onCurrentHoverCha... Remove this comment to see the full error message */}
          <Legend data={data} loading={loading} onCurrentHoverChange={setCurrentHover} />
        </Col>
      </Row>
      <SimpleAllocationSwitch />
    </Section>
  )
}

export default Allocation
