import './styles.scss'

import Container from './Container'
import NestedField from './NestedField'

const NestedFields = {
  Container,
  FormGroup: NestedField,
}

export default NestedFields
