import { useRef, useEffect } from 'react'

const useEventOutside = (eventType: any, onOutsideEvent: any) => {
  const elementRef = useRef(null)

  useEffect(() => {
    const handleOutsideEvent = (event: any) => {
      const element = elementRef.current
      if (element === null) return

      // @ts-expect-error TS(2339) FIXME: Property 'contains' does not exist on type 'never'... Remove this comment to see the full error message
      if (!element.contains(event.target) && typeof onOutsideEvent === 'function') {
        onOutsideEvent(event)
      }
    }

    document.addEventListener(eventType, handleOutsideEvent, true)

    return () => document.removeEventListener(eventType, handleOutsideEvent, true)
  })

  return elementRef
}

export default useEventOutside
