import EsgDiffers from './EsgDiffers'
import RiskScoreDiffers from './RiskScoreDiffers'
import RiskScoreOutsideRange from './RiskScoreOutsideRange'

const ModalContent = {
  EsgDiffers,
  RiskScoreDiffers,
  RiskScoreOutsideRange,
}

export default ModalContent
