import React from 'react'

import Switch from './Switch'

const StocksOnlySwitch = ({ ...otherProps }) => (
  <Switch
    name="stocksOnly"
    label="Ekstra høj risiko"
    tooltipText={
      <>
        Hvis du vælger en ansvarlig portefølje med ekstra høj risiko, bliver du kun investeret i
        aktier - du får altså ingen obligationer.
      </>
    }
    {...otherProps}
  />
)

export default StocksOnlySwitch
