import BooleanInput from '@nord/ui/src/components/BooleanInput'
import CountrySelect from '@nord/ui/src/components/CountrySelect'
import Form from '@nord/ui/src/components/Form'
import IconButton from '@nord/ui/src/components/IconButton'
import useBreakpointHandler from '@nord/ui/src/hooks/useBreakpointHandler'
import classNames from 'classnames'
import { useFormikContext, FieldArray } from 'formik'
import isEmpty from 'lodash/isEmpty'
import React, { useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Plus from 'react-feather/dist/icons/plus'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import XCircle from 'react-feather/dist/icons/x-circle'

import NestedFields from '../../../../NestedFields'

export interface TaxableCountriesProps {
  onAddCountry: (...args: any[]) => any
  onRemoveCountry: (...args: any[]) => any
}

const TaxableCountries = ({ onAddCountry, onRemoveCountry }: TaxableCountriesProps) => {
  const {
    values: { additionalTaxableCountries },
  } = useFormikContext<any>()

  const largeAndUp = useBreakpointHandler('lg')

  const usedCountryCodes = additionalTaxableCountries.map(({ countryCode }: any) => countryCode)

  const disabledOption = (countryCode: any) =>
    countryCode === 'DK' || countryCode === 'US' || usedCountryCodes.includes(countryCode)

  const formatOptions = ({ country, code: countryCode }: any) => ({
    value: countryCode,
    code: countryCode,
    label: country,
    isDisabled: disabledOption(countryCode),
  })

  const handleRemoveCountry = (index: any) => onRemoveCountry(index)
  const handleAddNewCountry = () => onAddCountry({ countryCode: '', taxId: '' })

  return (
    <>
      {additionalTaxableCountries.map((_data: any, index: any) => (
        <>
          <Row className="mb-3">
            <Col xs={12} lg={4} className="mb-3 mb-lg-0">
              <Form.FieldGroup
                name={`additionalTaxableCountries[${index}].countryCode`}
                label="Land"
              >
                {/* @ts-expect-error TS(2739) FIXME: Type '{ ({ components, optionFormat, excludedCount... Remove this comment to see the full error message */}
                <Form.Field inputComponent={CountrySelect} optionFormat={formatOptions} />
              </Form.FieldGroup>
            </Col>
            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
              <Form.FieldGroup
                name={`additionalTaxableCountries[${index}].taxId`}
                label="Skatte identifikationsnummer (TIN)"
              >
                <Form.Field />
              </Form.FieldGroup>
            </Col>
            {index !== 0 && (
              <Col
                xs={12}
                lg={2}
                className="d-flex justify-content-center align-items-center mt-lg-5"
              >
                <IconButton
                  variant="link"
                  onClick={() => handleRemoveCountry(index)}
                  icon={XCircle}
                  className={classNames('text-danger p-0', {
                    'font-size-xl': largeAndUp,
                  })}
                >
                  {!largeAndUp && 'Fjern'}
                </IconButton>
              </Col>
            )}
          </Row>
          <hr />
        </>
      ))}
      <IconButton onClick={handleAddNewCountry} icon={Plus} size="sm">
        Tilføj et andet land
      </IconButton>
    </>
  )
}

const AdditionalTaxableCountries = () => {
  const {
    // @ts-expect-error TS(2339) FIXME: Property 'additionalTaxableCountries' does not exi... Remove this comment to see the full error message
    values: { additionalTaxableCountries },
    setFieldValue,
  } = useFormikContext()

  const [show, setShow] = useState(!isEmpty(additionalTaxableCountries))

  const handleShow = (value: any) => {
    const defaultAdditionalTaxableCountriesValue = value ? [{ countryCode: '', taxId: '' }] : []

    setFieldValue('additionalTaxableCountries', defaultAdditionalTaxableCountriesValue, true)
    setShow(value)
  }

  return (
    <Row>
      <Col xs={12}>
        <NestedFields.Container>
          <Form.FieldGroup name="taxpayerInOtherCountry" label={false}>
            {/* @ts-expect-error TS(2739) FIXME: Type '{ ({ id, name, label, type, onChange, onValu... Remove this comment to see the full error message */}
            <Form.Field inputComponent={BooleanInput} onCheckedChange={handleShow} value={show} />
          </Form.FieldGroup>
          {show && (
            <FieldArray
              name="additionalTaxableCountries"
              render={({ push: onAddCountry, remove: onRemoveCountry }) => (
                <TaxableCountries onAddCountry={onAddCountry} onRemoveCountry={onRemoveCountry} />
              )}
            />
          )}
        </NestedFields.Container>
      </Col>
    </Row>
  )
}

export default AdditionalTaxableCountries
