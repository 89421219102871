import AutocompleteSelect from '@nord/ui/src/components/AutocompleteSelect'
import Form from '@nord/ui/src/components/Form'
import useApi from '@nord/ui/src/hooks/useApi'
import { useFormikContext } from 'formik'
import compact from 'lodash/compact'
import isFinite from 'lodash/isFinite'
import React, { useCallback } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

const baseURL = 'https://dawa.aws.dk'

const useLoadAddressOptions = () => {
  const searchForAddress = useApi('/adresser/autocomplete', {
    baseURL,
    errorHandling: {
      ignore: {
        client: true,
        server: true,
      },
    },
  })

  return useCallback(
    async (inputValue: any) => {
      const { data, success } = await searchForAddress({
        q: inputValue,
      })

      if (success) {
        const options = data.map(({ tekst, adresse }: any) => {
          const { husnr, vejnavn, etage, dør } = adresse
          const baseAddress = `${vejnavn} ${husnr}`
          const apartmentAddress = compact([etage && `${etage}.`, dør]).join(' ')
          const value =
            apartmentAddress.length > 0 ? `${baseAddress}, ${apartmentAddress}` : baseAddress

          return {
            value,
            label: tekst,
            outsideDenmark: false,
            adresse,
          }
        })

        options.push({
          value: inputValue,
          label: `${inputValue} (udenlandsk/ukendt adresse)`,
          outsideDenmark: true,
        })

        return options
      }

      return [
        {
          value: inputValue,
          label: `${inputValue} (udenlandsk/ukendt adresse)`,
          outsideDenmark: true,
        },
      ]
    },
    [searchForAddress],
  )
}

export interface AddressFieldProps {
  disabled?: boolean
}

const AddressField = ({ disabled }: AddressFieldProps) => {
  const { setFieldValue, setValues, values } = useFormikContext()
  const loadAddressOptions = useLoadAddressOptions()
  const getCityForPostalCode = useApi(`/postnumre/:postalCode`, {
    baseURL,
    errorHandling: {
      ignore: {
        client: true,
        server: true,
      },
    },
  })

  const handleChangePostalCode = async (event: any) => {
    const {
      target: { value },
    } = event

    if (isFinite(value)) {
      const { data: city } = await getCityForPostalCode(undefined, {
        pathParams: { postalCode: value },
      })

      if (city && city.length > 0) {
        setFieldValue('city', city, true)
      }
    }
  }

  const handleChangeAddressField = (selectedOption: any) => {
    const { value, outsideDenmark, adresse } = selectedOption

    if (outsideDenmark) {
      setFieldValue('address', value, true)

      return
    }

    const { postnr: postalCode, postnrnavn: city } = adresse

    setValues(
      {
        // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
        ...values,
        address: value,
        city,
        postalCode,
        country: 'DK',
      },
      true,
    )
  }

  const handleChange = (event: any) => {
    event.preventDefault()
  }

  return (
    <>
      <Row>
        <Col xs={12}>
          <Form.FieldGroup name="address">
            <Form.Field
              // @ts-expect-error TS(2739) FIXME: Type '{ ({ onSelect, value, ...otherProps }: Props... Remove this comment to see the full error message
              inputComponent={AutocompleteSelect}
              loadOptions={loadAddressOptions}
              noOptionsMessage={() => 'Skriv for søge efter din adresse'}
              onSelect={handleChangeAddressField}
              onChange={handleChange}
              isDisabled={disabled}
              useOnChange
            />
          </Form.FieldGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={4}>
          <Form.FieldGroup name="postalCode">
            {/* @ts-expect-error TS(2322) FIXME: Type '{ onBlur: (event: any) => Promise<void>; dis... Remove this comment to see the full error message */}
            <Form.Field onBlur={handleChangePostalCode} disabled={disabled} />
          </Form.FieldGroup>
        </Col>
        <Col xs={12} sm={8}>
          <Form.FieldGroup name="city">
            {/* @ts-expect-error TS(2322) FIXME: Type '{ disabled: boolean | undefined; }' is not a... Remove this comment to see the full error message */}
            <Form.Field disabled={disabled} />
          </Form.FieldGroup>
        </Col>
      </Row>
    </>
  )
}

AddressField.defaultProps = {
  disabled: false,
}

export default AddressField
