import defaultTo from 'lodash/defaultTo'
import { z } from 'zod'

import { deepFreeze } from '../../../utilities/deepIterate'
import { getEnvironmentVariable } from '../environment'

const Boolean = z
  .union([z.boolean(), z.enum(['true', 'false']).transform((value) => !!JSON.parse(value))])
  .optional()

const enableSentryEnvValue = Boolean.parse(
  process.env.GATSBY_ENABLE_SENTRY ||
    process.env.STORYBOOK_ENABLE_SENTRY ||
    getEnvironmentVariable('enableSentry'),
)

const enableGoogleTagManagerEnvValue = Boolean.parse(
  process.env.GATSBY_ENABLE_GOOGLE_TAG_MANAGER ||
    process.env.STORYBOOK_ENABLE_GOOGLE_TAG_MANAGER ||
    getEnvironmentVariable('enableGoogleTagManager'),
)

const setup = deepFreeze({
  enableSentry: defaultTo(enableSentryEnvValue, true),
  enableGoogleTagManager: defaultTo(enableGoogleTagManagerEnvValue, true),
})

export default setup
