import Paginator from '@nord/ui/src/components/Paginator'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import defaultTo from 'lodash/defaultTo'
import isEmpty from 'lodash/isEmpty'
import mapValues from 'lodash/mapValues'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Redirect, useHistory } from 'react-router-dom'

import { PENSION_PATHS } from '../../../../configuration/constants'
import useCachedPages from '../../../../hooks/useCachedPages'
import useRedirectPath from '../../../../hooks/useRedirectPath'
import { selectInitialLoading } from '../../../../store/current/loading'
import { selectCurrentPortfolio } from '../../../../store/current/portfolioId'
import { updateCurrentPortfolioOnboarding } from '../../../../store/current/portfolioId/actions'
import {
  fetchCurrentUser,
  selectCurrentUser,
  updateUserOnboarding,
} from '../../../../store/current/user'
import { progressChanged } from '../../../../store/ui/progressSlice'
import { pushEventToDataLayer } from '../../../../utilities/pushToDataLayer'
import LoadingIcon from '../../../LoadingIcon'
import CreatePasswordPage from '../../CreatePasswordPage'
import OnboardingNavigation from '../../OnboardingNavigation'
import BasicInformationPage from '../../Private/Onboarding/BasicInformationPage'
import IncomePage from '../../Private/Onboarding/IncomePage'
import InvestmentPage from '../../Private/Onboarding/InvestmentPage'
import TaxableCountriesPage from '../../Private/Onboarding/TaxableCountriesPage'
import ReturnNavigation from '../../ReturnNavigation'
import SustainabilityPreferencePage from '../../Shared/SustainabilityPreferencePage'
import SustainabilityPreferenceWarningPage from '../../Shared/SustainabilityPreferenceWarningPage'

import ChooseMigrationOrNewPage from './ChooseMigrationOrNewPage'
import IntroPage from './IntroPage'
import MigrationPage from './MigrationPage'
import MigrationRequirementsPage from './MigrationRequirementsPage'
import ProductAndTaxPage from './ProductAndTaxPage'
import SuitabilityTestPage from './SuitabilityTestPage'

const useIntroPages = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'initialSustainabilityPreferenceLevel' do... Remove this comment to see the full error message
  const { initialSustainabilityPreferenceLevel, hasPassword, sustainabilityPreferencesOrder } =
    useObjectSelector(selectCurrentUser)

  const {
    // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'unknown'.
    id: currentPortfolioId,
    // @ts-expect-error TS(2339) FIXME: Property 'pensionsInfoMigration' does not exist on... Remove this comment to see the full error message
    pensionsInfoMigration,
    // @ts-expect-error TS(2339) FIXME: Property 'sustainabilityPreferenceLevel' does not ... Remove this comment to see the full error message
    sustainabilityPreferenceLevel,
  } = useObjectSelector(selectCurrentPortfolio)

  const showSustainabilitySortingPage =
    initialSustainabilityPreferenceLevel === 'high' && isEmpty(sustainabilityPreferencesOrder)

  return useCachedPages(
    [
      { page: IntroPage, condition: true },
      { page: CreatePasswordPage, condition: !hasPassword },
      {
        page: SustainabilityPreferencePage,
        condition: showSustainabilitySortingPage,
      },
      {
        page: SustainabilityPreferenceWarningPage,
        condition: sustainabilityPreferenceLevel === 'high',
      },
      { page: ChooseMigrationOrNewPage, condition: !pensionsInfoMigration },
    ],
    // Do not include hasPassword & sustainabilityPreferenceLevel, as this would change the page order
    [pensionsInfoMigration, initialSustainabilityPreferenceLevel, currentPortfolioId],
  )
}

const useMigrationPages = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'migrationDepot' does not exist on type '... Remove this comment to see the full error message
  const { migrationDepot, pensionsInfoMigration } = useObjectSelector(selectCurrentPortfolio)

  return useCachedPages(
    [
      { page: MigrationRequirementsPage, condition: migrationDepot === true },
      {
        page: MigrationPage,
        condition: migrationDepot === true && !pensionsInfoMigration,
      },
    ],
    [migrationDepot, pensionsInfoMigration],
  )
}

const useOnboardingPages = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'readyForSaxoAt' does not exist on type '... Remove this comment to see the full error message
  const { readyForSaxoAt, validSuitabilityTest, validPensionSuitabilityTest } =
    useObjectSelector(selectCurrentUser)
  const previouslyOnboarded = !!readyForSaxoAt

  return useCachedPages(
    [
      {
        page: SuitabilityTestPage,
        condition: previouslyOnboarded && !validSuitabilityTest,
      },
      { page: BasicInformationPage, condition: !previouslyOnboarded },
      { page: TaxableCountriesPage, condition: !previouslyOnboarded },
      { page: IncomePage, condition: !previouslyOnboarded },
      { page: InvestmentPage, condition: !previouslyOnboarded },
      { page: ProductAndTaxPage, condition: !validPensionSuitabilityTest },
    ],
    [validSuitabilityTest, validPensionSuitabilityTest, readyForSaxoAt],
  )
}

const initialValues = {
  password: '',
  passwordConfirmation: '',
  age: undefined,
  migrationDepot: undefined,
  pep: false,
  americanCitizen: false,
  americanTaxpayer: false,
  americanTaxNumber: '',
  acceptLowerSustainabilityPreference: false,
  understandsPensionAndTaxation: false,
  monthlyIncome: '',
  yearlyIncome: '',
  expectsPensionDeposits: false,
}

const portfolioAttributeNames = [
  'depotType',
  'migrationDepot',
  'migrationDepotCompanyName',
  'migrationPensionCompanyType',
  'migrationDepotAccountNumber',
  'migrationDepotRegistrationNumber',
  'migrationDepotNumber',
  'migrationPensionPoliceNumber',
]

const Onboarding = () => {
  const loading = useSelector(selectInitialLoading)
  const dispatch = useDispatch()
  const currentPortfolio = useObjectSelector(selectCurrentPortfolio)
  const portfolioAttributes = pick(currentPortfolio, [
    ...portfolioAttributeNames,
    'expectedInvestment',
  ])
  const {
    // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'unknown'.
    id: portfolioId,
    // @ts-expect-error TS(2339) FIXME: Property 'depotType' does not exist on type 'unkno... Remove this comment to see the full error message
    depotType,
    // @ts-expect-error TS(2339) FIXME: Property 'expectedInvestment' does not exist on ty... Remove this comment to see the full error message
    expectedInvestment,
    // @ts-expect-error TS(2339) FIXME: Property 'readyForSaxo' does not exist on type 'un... Remove this comment to see the full error message
    readyForSaxo: portfolioReadyForSaxo,
  } = currentPortfolio
  const currentUser = useObjectSelector(selectCurrentUser)
  // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'unknown'.
  const { id: userId } = currentUser
  const migrationPages = useMigrationPages()
  const onboardingPages = useOnboardingPages()
  const introPages = useIntroPages()
  const pages = [...migrationPages, ...onboardingPages]

  const beforePageChange = ({ absolute: { number, count } }: any) => {
    const progress = number / count

    dispatch(fetchCurrentUser())
    dispatch(progressChanged(progress))
  }

  const redirectPath = useRedirectPath()
  const history = useHistory()

  const handleSubmit = useCallback(
    async (newValues: any, _formik: any, { pagination }: any) => {
      const {
        absolute: { last },
      } = pagination
      if (last && !portfolioReadyForSaxo) newValues.readyForSaxo = true
      if (last && portfolioReadyForSaxo && isEmpty(newValues)) history.push(redirectPath)
      if (isEmpty(newValues)) return
      let newUserAttributes = newValues
      const newPortfolioAttributes = pick(newValues, portfolioAttributeNames)
      const hasPortfolioAttributes = !isEmpty(newPortfolioAttributes)

      if (hasPortfolioAttributes) {
        newPortfolioAttributes.pensionDepot = true
        newUserAttributes = omit(newValues, portfolioAttributeNames)
        await dispatch(updateCurrentPortfolioOnboarding(newPortfolioAttributes))
      }

      if (isEmpty(newUserAttributes)) return

      await dispatch(updateUserOnboarding(newUserAttributes))
    },
    [dispatch, history, portfolioReadyForSaxo, redirectPath],
  )

  const handleCompleteOnboarding = () => {
    pushEventToDataLayer({
      event: 'ready_for_saxo',
      userID: userId,
      expectedInvestment,
      flow: 'pension',
    })
  }

  const ReturnNavigationComponent = useCallback(
    (navigationProps: Record<string, unknown>) => (
      <OnboardingNavigation {...navigationProps} returnButton={<ReturnNavigation />} />
    ),
    [],
  )

  const NavigationComponent = useCallback(
    (navigationProps: Record<string, unknown>) => <OnboardingNavigation {...navigationProps} />,
    [],
  )

  if (!depotType || depotType === 'frie_midler')
    return (
      <>
        <Redirect to={PENSION_PATHS.investmentPlan(portfolioId)} />
        {/* @ts-expect-error TS(2741) FIXME: Property 'children' is missing in type '{}' but re... Remove this comment to see the full error message */}
        <LoadingIcon />
      </>
    )

  const finalInitialValues = {
    ...mapValues({ ...initialValues, ...currentUser, ...portfolioAttributes }, (value, attribute) =>
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      defaultTo(value, initialValues[attribute], undefined),
    ),
    // @ts-expect-error TS(2339) FIXME: Property 'migrationDepot' does not exist on type '... Remove this comment to see the full error message
    migrationDepot: portfolioAttributes.migrationDepot || null,
  }

  return (
    <Paginator.Nested.Wrapper.WithQueryParam>
      {/* @ts-expect-error TS(2322) FIXME: Type '{ children: any; beforePageChange: ({ absolu... Remove this comment to see the full error message */}
      <Paginator.Form.Wrapper.WithNested.WithQueryParam
        beforePageChange={beforePageChange}
        navigationComponent={ReturnNavigationComponent}
        onSubmit={handleSubmit}
        initialValues={finalInitialValues}
        loading={loading}
      >
        {introPages.map((Page) => (
          <Page key={Page.displayName || Page.name} />
        ))}
      </Paginator.Form.Wrapper.WithNested.WithQueryParam>
      {pages.length > 0 ? (
        // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; beforePageChange: ({ ... Remove this comment to see the full error message
        <Paginator.Form.Wrapper.WithNested.WithQueryParam
          beforePageChange={beforePageChange}
          navigationComponent={NavigationComponent}
          onSubmit={handleSubmit}
          onComplete={handleCompleteOnboarding}
          initialValues={finalInitialValues}
          loading={loading}
          showPagination="relative"
        >
          {pages.map((Page) => (
            <Page key={Page.displayName || Page.name} />
          ))}
        </Paginator.Form.Wrapper.WithNested.WithQueryParam>
      ) : null}
    </Paginator.Nested.Wrapper.WithQueryParam>
  )
}

Onboarding.IntroPage = IntroPage
Onboarding.MigrationPage = MigrationPage
Onboarding.SuitabilityTestPage = SuitabilityTestPage
Onboarding.ProductAndTaxPage = ProductAndTaxPage

export default Onboarding
