import PageBanner from '@nord/ui/src/components/PageBanner'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import classNames from 'classnames'
import React from 'react'

import styles from './ReferralBanner.module.scss'
import { useReferralContext } from './ReferralContext'

const ReferralBanner = () => {
  const { referralData, referralIsValid } = useReferralContext()

  const { name, amount, depositLimit } = referralData || {}

  return (
    <PageBanner.Container
      // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; className: string; }' i... Remove this comment to see the full error message
      className={classNames(styles.referralWrapper, {
        [styles.invalidReferral]: !referralIsValid,
      })}
    >
      <PageBanner.Banner className={styles.referralBanner} data-cy="referralBanner">
        <div className="text-dark">
          Du er blevet inviteret af <strong>{name}</strong> og vil modtage{' '}
          <strong>{numberToCurrency(amount)}</strong>, når du investerer for minimum{' '}
          {numberToCurrency(depositLimit)}
        </div>
      </PageBanner.Banner>
    </PageBanner.Container>
  )
}

export default ReferralBanner
