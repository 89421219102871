import { getConfig } from '@nord/ui/src/configuration'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import { numberToPercent } from '@nord/ui/src/utilities/numberFormatter'
import React from 'react'

const esgStandardFeeDifference =
  getConfig('numbers.totalFees.freeAssets.esg.base') -
  getConfig('numbers.totalFees.freeAssets.standard.base')

const EsgDiffers = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'advisedEsg' does not exist on type 'unkn... Remove this comment to see the full error message
  const { advisedEsg } = useObjectSelector(selectCurrentPortfolio)

  return (
    <>
      {advisedEsg === true && (
        <>
          <p>
            På baggrund af dine besvarelser i investeringsplanen, anbefales du Norm Invests
            ansvarlige portefølje. Administrationsomkostningerne til ETF’erne og omkostningerne til
            Norm Invest er højere ved investering i den ansvarlige portefølje sammenlignet med
            investering i Norm Invests standardportefølje. De højere omkostninger ved investering i
            den ansvarlige portefølje svarer til {numberToPercent(esgStandardFeeDifference)} årligt
            af din porteføljes værdi.
          </p>
          <p className="font-weight-bold">
            Jeg bekræfter at jeg - trods mine præferencer i forhold til bæredygtig investering og
            Norm Invests anbefaling til mig - ikke ønsker min portefølje investeret i Norm Invests
            ansvarlige portefølje, idet lave omkostninger ved investering er vigtigere for mig end
            at min portefølje investeres bæredygtigt.
          </p>
        </>
      )}
      {advisedEsg === false && (
        <>
          <p>
            På baggrund af dine besvarelser i investeringsplanen, anbefales du Norm Invests standard
            portefølje, og ved at vælge den ansvarlige portefølje følger du således ikke Norm
            Invests anbefaling til dig. Er baggrunden for dit valg af den ansvarlige portefølje, at
            du er interesseret i bæredygtig investering, opfordres du til at gennemgå
            investeringsplanen på ny, så vi kan rådgive dig om dine muligheder.
          </p>
          <p className="font-weight-bold">
            Jeg bekræfter at jeg - trods mine præferencer i forhold til bæredygtig investering og
            Norm Invests anbefaling til mig - ønsker min portefølje investeret i Norm Invests
            ansvarlige portefølje.
          </p>
        </>
      )}
    </>
  )
}

export default EsgDiffers
