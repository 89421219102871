import inRange from 'lodash/inRange'

const getRiskScoreAdvisedState = (riskScore: any, advisedRiskScore: any) => {
  if (!advisedRiskScore) return undefined

  const normalizeRiskScore = (score: any) => (score === 100 ? 21 : score)

  const normalizedAdvisedRiskScore = normalizeRiskScore(advisedRiskScore)
  const normalizedRiskScore = normalizeRiskScore(riskScore)

  const minimumAdvisedRiskScore = normalizedAdvisedRiskScore - 10
  const maximumAdvisedRiskScore = normalizedAdvisedRiskScore + 10

  const isStronglyNotAdvised = !inRange(
    normalizedRiskScore,
    minimumAdvisedRiskScore,
    maximumAdvisedRiskScore + 1,
  )

  if (riskScore === advisedRiskScore) return 'advised'
  if (isStronglyNotAdvised) return 'strongly_not_advised'

  return 'not_advised'
}

export default getRiskScoreAdvisedState
