import Form from '@nord/ui/src/components/Form'
import Select from '@nord/ui/src/components/Select'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import React, { useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { CSSTransition } from 'react-transition-group'

import { selectCurrentUser } from '../../../../../../store/current/user'

import selectOptions from './selectOptions'

import './styles.scss'

const checkOccupation = (occupation: any) =>
  !['Student', 'Retired', 'Unemployed'].includes(occupation)

const OccupationFields = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'currentOccupation' does not exist on typ... Remove this comment to see the full error message
  const { currentOccupation } = useObjectSelector(selectCurrentUser)

  const [showExtraFields, setShowExtraFields] = useState(checkOccupation(currentOccupation))

  const handleChangeOccupation = (newOccupation: any) => {
    const newShowExtraFields = checkOccupation(newOccupation)

    setShowExtraFields(newShowExtraFields)

    return newOccupation
  }

  return (
    <>
      <Row>
        <Col xs={12}>
          <Form.FieldGroup name="currentOccupation">
            <Form.Field
              // @ts-expect-error TS(2739) FIXME: Type '{ ({ onChange, onValueChange, onSelect, opti... Remove this comment to see the full error message
              inputComponent={Select}
              onValueChange={handleChangeOccupation}
              options={selectOptions.currentOccupation}
            />
          </Form.FieldGroup>
        </Col>
      </Row>
      <CSSTransition classNames="slide-down" in={showExtraFields} timeout={500}>
        <div className={`slide-down ${showExtraFields ? 'slide-down-show' : 'slide-down-hide'}`}>
          <Row>
            <Col xs={12}>
              <Form.FieldGroup name="nameOfEmployee">
                <Form.Field />
              </Form.FieldGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Form.FieldGroup name="jobTitle">
                <Form.Field />
              </Form.FieldGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Form.FieldGroup name="jobIndustry">
                {/* @ts-expect-error TS(2322) FIXME: Type '{ ({ onChange, onValueChange, onSelect, opti... Remove this comment to see the full error message */}
                <Form.Field inputComponent={Select} options={selectOptions.jobIndustry} />
              </Form.FieldGroup>
            </Col>
          </Row>
        </div>
      </CSSTransition>
    </>
  )
}

export default OccupationFields
