import Card from '@nord/ui/src/components/Card'
import HistoricalReturn from '@nord/ui/src/components/HistoricalReturn'
import React, { useContext } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import useFlow from '../../../../../hooks/useFlow'

import ResultPageContext from './ResultPageContext'

const HistoricalReturnSection = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'riskScore' does not exist on type 'unkno... Remove this comment to see the full error message
  const { riskScore } = useContext(ResultPageContext)
  const flow = useFlow()
  const depotType = flow === 'private' ? 'free_assets' : flow

  return (
    <Row className="result-section">
      <Col>
        <HistoricalReturn depotType={depotType} riskScore={riskScore}>
          <Card>
            <Card.Body className="px-2 px-md-4 px-lg-6 mx-lg-4">
              <HistoricalReturn.Header />
              <HistoricalReturn.Chart />
              <HistoricalReturn.Brush />
            </Card.Body>
          </Card>
        </HistoricalReturn>
      </Col>
    </Row>
  )
}

export default HistoricalReturnSection
