import slice from './slice'

const { reducer } = slice

export * from './actions'
// eslint-disable-next-line import/export
export * from './selectors'
export * from './slice'

export default reducer
