import classNames from 'classnames'
import React, { useRef, useEffect } from 'react'
import { Overlay } from 'react-bootstrap'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'zxcv... Remove this comment to see the full error message
import zxcvbn from 'zxcvbn'

import locale from './locale'
import type { OverlayPlacementPropType } from './overlayPlacementPropType'

export const defaultUserInputs = ['nord', 'investments', 'nord.investments']

const scoreStyles = {
  0: 'danger',
  1: 'warning',
  2: 'warning',
  3: 'info',
  4: 'success',
  default: 'danger',
}

const scoreDescriptions = {
  0: 'Meget svag',
  1: 'Svag',
  2: 'Middle',
  3: 'Stærk',
  4: 'Meget stærk',
  default: 'Meget svag',
}

const getFeedbackText = (feedback: any, score: any) => {
  const { warning, suggestions } = feedback
  const texts = []
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  texts.push(locale.da[warning])
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  suggestions.forEach((suggestion: any) => texts.push(locale.da[suggestion]))

  const filteredTexts = texts.filter((text) => !!text)

  if (filteredTexts.length > 0) {
    return filteredTexts.join('. ')
  }

  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return scoreDescriptions[score] || scoreDescriptions.default
}

export interface StrengthCheckerPopoverProps {
  arrowProps?: {
    style?: React.CSSProperties
  }
  className?: string
  feedback?: string
  placement?: OverlayPlacementPropType
  popper: {
    scheduleUpdate: (...args: any[]) => any
  }
  show: boolean
  variant?: any // TODO: bootstrapVariant()
}

export const StrengthCheckerPopover = React.forwardRef<any, StrengthCheckerPopoverProps>(
  (
    {
      variant,
      feedback,
      placement,
      arrowProps,
      className,
      popper: { scheduleUpdate },
      show: _show,
      ...otherProps
    },
    ref,
  ) => {
    const feedbackRef = useRef()

    useEffect(() => {
      const previousFeedback = feedbackRef.current
      // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      feedbackRef.current = feedback

      if (previousFeedback !== feedback) {
        scheduleUpdate()
      }
    }, [feedback, scheduleUpdate])

    return (
      <div
        role="tooltip"
        // eslint-disable-next-line react/no-unknown-property
        x-placement={placement}
        ref={ref}
        className={classNames(
          'popover',
          `bs-popover-${placement}`,
          'text-center',
          'text-dark',
          className,
        )}
        {...otherProps}
      >
        <div className="arrow" {...arrowProps} />

        <div
          className={classNames(
            'popover-header',
            'font-size-lg',
            'text-uppercase',
            `bg-${variant}`,
          )}
        >
          Adgangskodestyrke
        </div>

        <div className="popover-body text-dark">{feedback}</div>
      </div>
    )
  },
)

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type '(p... Remove this comment to see the full error message
StrengthCheckerPopover.defaultProps = {
  arrowProps: undefined,
  className: undefined,
  feedback: undefined,
  placement: undefined,
  variant: undefined,
}

// @ts-expect-error TS(2339) FIXME: Property 'displayName' does not exist on type '(pr... Remove this comment to see the full error message
StrengthCheckerPopover.displayName = 'StrengthCheckerPopover'

export interface StrengthCheckerProps {
  password?: string
  userInputs?: string[]
  show?: boolean
  target?: any // TODO: PropTypes.instanceOf(Element)
}

const StrengthChecker = ({
  password,
  target,
  show,
  userInputs,
  ...otherProps
}: StrengthCheckerProps) => {
  const { score, feedback } = zxcvbn(password || (target && target.value) || '', userInputs)

  return (
    <Overlay show={show} target={target} placement="top" container={this} {...otherProps}>
      {({ outOfBoundaries: _, ...popoverProps }) => (
        <StrengthCheckerPopover
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          variant={scoreStyles[score] || scoreStyles.default}
          feedback={getFeedbackText(feedback, score)}
          {...popoverProps}
        />
      )}
    </Overlay>
  )
}

StrengthChecker.defaultProps = {
  password: undefined,
  show: undefined,
  target: undefined,
  userInputs: defaultUserInputs,
}

export default StrengthChecker
