import Card from '@nord/ui/src/components/Card'
import IconButton from '@nord/ui/src/components/IconButton'
import Text from '@nord/ui/src/components/Text'
import { getConfig } from '@nord/ui/src/configuration'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ArrowRight from 'react-feather/dist/icons/arrow-right'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import useSignedIn from '../../../hooks/useSignedIn'
import ContentTitle from '../ContentTitle'

import { useReferralContext } from './ReferralContext'
import TermsModal from './TermsModal'

const { email, emailLink } = getConfig('contactInformation')
const privacyPolicyUrl = getConfig('urls.website.privacyPolicy')

export interface Props {
  loginPath: string
  onAccept(): void
}

const DjofContent = ({ loginPath, onAccept }: Props) => {
  const isSignedIn = useSignedIn()
  const { referralData, onPageLeave } = useReferralContext()
  const { name, amount, depositLimit } = referralData || {}

  const [showTermsModal, setShowTermsModal] = useState(false)

  const handleShowModal = () => setShowTermsModal(true)
  const handleHideModal = () => setShowTermsModal(false)

  return (
    <>
      <TermsModal show={showTermsModal} onHide={handleHideModal} referralData={referralData} />
      <ContentTitle title={`${name} har inviteret dig til NORD`} />
      <Card border="0" className="shadow">
        <Card.Body className="text-center px-4">
          NORD.investments er en digital investeringsløsning til dig, der vil investere pension-,
          frie- og/eller virksomhedsmidler. Som medlem af {name} vil du få sat{' '}
          <strong>{numberToCurrency(amount)}</strong> ind på din konto, når du har haft{' '}
          {numberToCurrency(depositLimit)} investeret i mindst en måned. Derudover vil du få 10%
          rabat på NORD.investments’ løbende rådgivningsgebyr på vores ansvarlige porteføljer.{' '}
          <Button variant="link" className="text-base btn-anchor p-0" onClick={handleShowModal}>
            Læs betingelserne for tilbuddet her.
          </Button>
        </Card.Body>
      </Card>
      <Row>
        <Col xs={12} className="mt-5 px-4">
          <Text as="h4" align="center">
            Samtykke til behandling af personoplysninger
          </Text>
          <Text as="p" align="center">
            For at kunne tilbyde dig {name} medlemsfordele, har NORD.investments A/S (“NORD”) behov
            for at behandle personoplysninger om dig, herunder oplysninger om hvorvidt du er, eller
            tidligere har været, medlem af {name}. Med dit samtykke giver du tilladelse til, at NORD
            må registrere oplysninger om dit medlemsforhold til {name}, for at kunne tildele dig{' '}
            {name} medlemsfordele. Samtidig må NORD, med passende interval, videregive dit navn og
            CPR-nr. til {name}, med det formål at kontrollere din aktuelle medlemsstatus. NORD er
            ansvarlig for behandling af dine personoplysninger. Du kan altid trække dit samtykke
            tilbage ved at kontakte NORD på{' '}
            <Text as="a" href={emailLink}>
              {email}
            </Text>
            . En tilbagetrækning af dit samtykke får alene betydning for NORDs behandling og deling
            af dine personoplysninger fremadrettet, og vil medføre, at dine fordele hos NORD
            ophører, uanset om du fortsat er medlem af {name}.{' '}
            <Button
              variant="link"
              className="text-base btn-anchor p-0"
              href={privacyPolicyUrl}
              target="_blank"
            >
              Læs hele vores privatlivspolitik her.
            </Button>
          </Text>
        </Col>
        <Col xs={12} className="d-flex justify-content-center mb-4">
          <IconButton icon={ArrowRight} onClick={onAccept}>
            Jeg accepter
          </IconButton>
        </Col>
      </Row>
      {!isSignedIn && (
        <Row>
          <Col className="text-center mt-2">
            <Button
              variant="link"
              className="text-base"
              type={undefined}
              as={Link}
              to={loginPath}
              onClick={onPageLeave}
            >
              Er du eksisterende bruger? Log ind her.
            </Button>
          </Col>
        </Row>
      )}
    </>
  )
}

export default DjofContent
