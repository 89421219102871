export const calculateStepRatiosFromStepsObject = (steps: any) =>
  Object.keys(steps)
    .map((stepRatio) => parseFloat(stepRatio))
    .sort((a, b) => a - b)

export const calculateBounds = (steps: any, target: any) => {
  let upperIndex = steps.findIndex((stepValue: any) => stepValue >= target)
  if (upperIndex <= 0) upperIndex = 1
  const upperRatio = steps[upperIndex]
  const lowerRatio = steps[upperIndex - 1] || steps[0]

  return [parseFloat(lowerRatio), parseFloat(upperRatio)]
}

export const calculateRatioBoundsFromValueWithObject = ({ steps, ratio }: any) => {
  const stepRatios = Object.keys(steps)
    .sort()
    .map((stepRatio) => parseFloat(stepRatio))

  const upperIndex = stepRatios.findIndex((stepRatio) => stepRatio >= ratio)
  const upperRatio = stepRatios[upperIndex]
  const lowerRatio = stepRatios[upperIndex - 1] || stepRatios[0]

  return [lowerRatio, upperRatio]
}

export const calculateNearest = (steps: any, ratio: any) => {
  const [lowerRatio, upperRatio] = calculateBounds(steps, ratio)

  if (ratio - lowerRatio < upperRatio - ratio) return lowerRatio

  return upperRatio
}
