import React from 'react'

import CompletePageContainer from '../CompletePageContainer'

const OnboardingCompletePage = () => (
  <CompletePageContainer>
    <p>
      Din virksomhed er nu oprettet hos NORD. Vi kontakter dig snarest for at færdiggøre oprettelsen
      af dit depot hos Saxo Bank.
    </p>
  </CompletePageContainer>
)

export default OnboardingCompletePage
