import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { useSelector } from 'react-redux'

import { selectCurrentPortfolio, selectOnboardingState } from '../store/current/portfolioId'

import useSignedIn from './useSignedIn'

const useReadyToOnboard = () => {
  const signedIn = useSignedIn()
  const onboardingState = useSelector(selectOnboardingState)

  // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'unknown'.
  const { id: portfolioId, advisedRiskScore } = useObjectSelector(selectCurrentPortfolio)

  const userAlreadyOnboarded = signedIn && onboardingState === 'saxo_onboarded'

  const readyToOnboard =
    portfolioId && advisedRiskScore && (!signedIn || (signedIn && !userAlreadyOnboarded))

  return readyToOnboard
}

export default useReadyToOnboard
