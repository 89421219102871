import React from 'react'

import InternalLink from './InternalLink'

export interface Props {
  as?: React.ReactElement
  external?: boolean
  internal?: boolean
  openInNewTab?: boolean
}

const Link = React.forwardRef<any, Props>(
  ({ external, internal, openInNewTab, as, ...otherProps }, ref) => {
    if (internal) return <InternalLink {...otherProps} as={as} ref={ref} />

    const Component = as || 'a'

    return (
      // @ts-expect-error TS(2604) FIXME: JSX element type 'Component' does not have any con... Remove this comment to see the full error message
      <Component
        {...otherProps}
        target={openInNewTab ? '_blank' : undefined}
        rel={openInNewTab && external ? 'noopener noreferrer' : undefined}
        ref={ref}
      />
    )
  },
)

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type '(p... Remove this comment to see the full error message
Link.defaultProps = {
  as: undefined,
  external: undefined,
  internal: undefined,
  openInNewTab: undefined,
}

export default Link
