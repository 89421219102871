import CompletePage from './CompletePage'
import FailurePage from './FailurePage'
import StartPage from './StartPage'

const Verification = {
  StartPage,
  FailurePage,
  CompletePage,
}

export default Verification
