import { createSlice } from '@reduxjs/toolkit'

import { createPortfolioOnboarding } from '../portfolios/actions'

const initialState = null

// @ts-expect-error TS(2345) FIXME: Argument of type '{ name: "current.portfolioId"; i... Remove this comment to see the full error message
export const portfolioIdSlice = createSlice({
  name: 'current.portfolioId',
  initialState,
  extraReducers: {
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [createPortfolioOnboarding.fulfilled]: (_state: any, { payload: { id } }: any) => id,
  },
})

export default portfolioIdSlice
export * from '@nord/ui/src/store/current/portfolioId/slice'
