import 'intersection-observer'
import { useRef, useEffect, useCallback, useMemo } from 'react'

import useCombinedRefs from './useCombinedRefs'
import useDeepCompareMemoize from './useDeepCompareMemoize'
import useElementSize from './useElementSize'
import useWindowSize from './useWindowSize'

const calculateMarginFromOffset = ({
  elementHeight,
  offsetRatio,
  windowOffsetRatio,
  windowHeight,
}: any) => {
  const offset = Math.round(elementHeight * offsetRatio)
  let finalOffset = offset

  if (windowOffsetRatio !== undefined) {
    const windowOffset = windowHeight * windowOffsetRatio
    finalOffset = Math.min(offset, windowOffset)
  }

  return `${finalOffset}px 0px -${finalOffset}px 0px`
}

const buildOnChange = (onChangeRef: any) => (entries: any) => onChangeRef.current(entries)

const useInView = (onReveal: any, options: Record<string, unknown> = {}) => {
  const { height: windowHeight } = useWindowSize()
  const observer = useRef()
  const onChangeRef = useRef()
  // @ts-expect-error TS(2339) FIXME: Property 'height' does not exist on type '{}'.
  const [sizeRef, { height: elementHeight }] = useElementSize()
  const elementRef = useRef()

  const memoizedOptions = useDeepCompareMemoize(options)

  const callbackRef = useCallback((element: any) => {
    const previousElement = elementRef.current
    elementRef.current = element
    if (!element) return

    elementRef.current = element

    if (!observer.current) return

    if (previousElement) {
      // @ts-expect-error TS(2339) FIXME: Property 'unobserve' does not exist on type 'never... Remove this comment to see the full error message
      observer.current.unobserve(previousElement)
    }

    // @ts-expect-error TS(2339) FIXME: Property 'observe' does not exist on type 'never'.
    observer.current.observe(element)
  }, [])

  const onChange = useMemo(
    () => (entries: any) => {
      entries.forEach((entry: any) => {
        const { isIntersecting, intersectionRatio, boundingClientRect, intersectionRect } = entry
        const inView = isIntersecting || intersectionRatio > 0
        const direction = boundingClientRect.top < intersectionRect.top ? 'bottom' : 'top'

        onReveal({ inView, entry, direction })
      })
    },
    [onReveal],
  )
  // @ts-expect-error TS(2322) FIXME: Type '(entries: any) => void' is not assignable to... Remove this comment to see the full error message
  onChangeRef.current = onChange

  useEffect(() => {
    if (typeof IntersectionObserver === 'undefined') return undefined

    const { offsetRatio, windowOffsetRatio, ...formattedOptions } = memoizedOptions

    if (offsetRatio) {
      if (!elementHeight) return undefined

      formattedOptions.rootMargin = calculateMarginFromOffset({
        elementHeight,
        windowHeight,
        offsetRatio,
        windowOffsetRatio,
      })
      formattedOptions.threshold = formattedOptions.threshold || 0
    }

    const newObserver = new IntersectionObserver(buildOnChange(onChangeRef), formattedOptions)
    // @ts-expect-error TS(2322) FIXME: Type 'IntersectionObserver' is not assignable to t... Remove this comment to see the full error message
    observer.current = newObserver

    callbackRef(elementRef.current)

    return () => newObserver.disconnect()
  }, [callbackRef, elementHeight, memoizedOptions, windowHeight])

  const elementRefs = [callbackRef]
  // @ts-expect-error TS(2345) FIXME: Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
  if (memoizedOptions.offsetRatio) elementRefs.push(sizeRef)

  return useCombinedRefs(...elementRefs)
}

export default useInView
