import pushToDataLayer from './pushToDataLayer'

const getABTestIds = () => ({
  experimentId:
    process.env.GATSBY_GOOGLE_OPTIMIZE_EXPERIMENT_ID ||
    process.env.REACT_GOOGLE_OPTIMIZE_EXPERIMENT_ID,
  variationId:
    process.env.GATSBY_GOOGLE_OPTIMIZE_VARIATION_ID ||
    process.env.REACT_GOOGLE_OPTIMIZE_VARIATION_ID,
})

const setupABTest = () => {
  const { experimentId, variationId } = getABTestIds()

  const shouldSetupABTest = experimentId && variationId

  if (!shouldSetupABTest) return

  pushToDataLayer({
    experimentId,
    variationId,
  })
}

export default setupABTest
