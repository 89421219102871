import classNames from 'classnames'
import React from 'react'
import { useResizeDetector } from 'react-resize-detector'

import PDFViewer from '../../../../PDFViewer'

import styles from './index.module.scss'

export interface Props {
  className?: string
  document: any // TODO: documentPropType()
}

const DocumentWrapper = ({ document, className, ...props }: Props) => {
  const { height, width, ref } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 100,
  })

  return (
    <div ref={ref} className={classNames(styles.documentViewer, className, 'overflow-hidden')}>
      <PDFViewer
        src={document.url}
        // @ts-expect-error TS(2322) FIXME: Type '{ src: any; className: string | undefined; h... Remove this comment to see the full error message
        className={className}
        height={height}
        width={width}
        {...props}
      />
    </div>
  )
}

DocumentWrapper.defaultProps = {
  className: undefined,
}

export default DocumentWrapper
