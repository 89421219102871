import { useStoreDocumentsApi } from '@nord/ui/src/components/DocumentsForm'
import Paginator from '@nord/ui/src/components/Paginator'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { useFormikContext } from 'formik'
import React, { useCallback, useMemo } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import useFlow from '../../../../../hooks/useFlow'
import { selectCurrentUser } from '../../../../../store/current/user'
import ContentTitle from '../../../ContentTitle'
import DebtTestFields from '../../../DebtTestFields'
import SourceOfFundsField from '../../../SourceOfFundsField'

import labels, { fields } from './labels'
import SourcesOfAssetsField from './SourcesOfAssetsField'
import validationSchema from './validationSchema'

export const investmentLimitForDocumentation = 1500000

const InvestmentPage = ({ ...otherProps }) => {
  const {
    values: { expectedInvestment },
  } = useFormikContext<any>()
  // @ts-expect-error TS(2339) FIXME: Property 'email' does not exist on type 'unknown'.
  const { email } = useObjectSelector(selectCurrentUser)
  const { storeDocuments } = useStoreDocumentsApi(email)
  const flow = useFlow()

  const isPension = flow === 'pension'

  const handleSubmit = useCallback(
    async ({ sourceOfFunds }: any) => storeDocuments(sourceOfFunds),
    [storeDocuments],
  )

  const memoizedValidationSchema = useMemo(() => validationSchema(isPension), [isPension])

  return (
    <Paginator.Form.Page
      validationSchema={memoizedValidationSchema}
      labelDescriptions={labels}
      fields={fields}
      onSubmit={handleSubmit}
      {...otherProps}
    >
      <ContentTitle title="Vi skal bruge lidt information om din økonomi" />
      <DebtTestFields />
      <Row>
        <Col xs={12}>
          <SourcesOfAssetsField />
        </Col>
      </Row>

      {expectedInvestment > investmentLimitForDocumentation && (
        <Row>
          <Col xs={12}>
            <SourceOfFundsField path={`/${email}/temp`} />
          </Col>
        </Row>
      )}
    </Paginator.Form.Page>
  )
}

export default InvestmentPage
