import IconButton from '@nord/ui/src/components/IconButton'
import React from 'react'
import Modal from 'react-bootstrap/Modal'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ArrowLeft from 'react-feather/dist/icons/arrow-left'

import PepInformation from './PepInformation'

export interface Props {
  show: boolean
  onHide: (...args: any[]) => any
}

const PepInformationModal = ({ show, onHide }: Props) => (
  <Modal show={show} onHide={onHide} size="lg" className="text-dark">
    <Modal.Header closeButton>
      <Modal.Title className="w-100 text-center">Politisk Eksponeret Person</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <PepInformation />
    </Modal.Body>

    <Modal.Footer className="justify-content-center">
      <IconButton variant="primary" icon={ArrowLeft} iconPlacement="first" onClick={onHide}>
        Tilbage
      </IconButton>
    </Modal.Footer>
  </Modal>
)

export default PepInformationModal
