import { createSelector } from '@reduxjs/toolkit'

import extractOnboardingState from '../../../utilities/extractOnboardingState'
import { selectCurrentUser } from '../user/selectors'

export const selectCurrentPortfolioId = (state: any) => state.current.portfolioId

export const selectCurrentPortfolio = (state: any) =>
  state.current.portfolios[selectCurrentPortfolioId(state) || 'new']

export const selectFlow = (state: any) => {
  const { accountType } = selectCurrentUser(state) || {}
  const { depotType, onboardingFlow } = selectCurrentPortfolio(state) || {}

  if (onboardingFlow) return onboardingFlow
  if (accountType === 'company_account') return 'company'
  if (depotType === undefined || depotType === 'frie_midler') return 'private'

  return 'pension'
}

export const selectOnboardingState = createSelector(
  [selectCurrentUser, selectCurrentPortfolio],
  (currentUser, currentPortfolio) => extractOnboardingState(currentUser, currentPortfolio),
)

export * from '@nord/ui/src/store/current/portfolioId/selectors'
