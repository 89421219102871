import { getAttributesFromApiResponse, updateFromApiResponse } from '@nord/ui/src/store/helpers'
import { createSlice } from '@reduxjs/toolkit'

import {
  completePortfolioKyc,
  createPortfolioOnboarding,
  updatePortfolioOnboarding,
} from '../portfolios/actions'

import {
  createUser,
  updateUserOnboarding,
  checkUserPEPAndAML,
  changeUserAccountType,
} from './actions'

const initialState = null

// @ts-expect-error TS(2345) FIXME: Argument of type '{ name: "current.user"; initialS... Remove this comment to see the full error message
export const userSlice = createSlice({
  name: 'current.user',
  initialState,
  extraReducers: {
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [createUser.fulfilled]: updateFromApiResponse,
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [createPortfolioOnboarding.fulfilled]: (state: any, action: any) => {
      const { user } = getAttributesFromApiResponse(action)

      return updateFromApiResponse(state, { payload: user })
    },
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [updatePortfolioOnboarding.fulfilled]: (state: any, action: any) => {
      const { user } = getAttributesFromApiResponse(action)

      return updateFromApiResponse(state, { payload: user })
    },
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [updateUserOnboarding.fulfilled]: updateFromApiResponse,
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [completePortfolioKyc.fulfilled]: updateFromApiResponse,
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [checkUserPEPAndAML.fulfilled]: updateFromApiResponse,
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [changeUserAccountType]: updateFromApiResponse,
  },
})

export default userSlice
export * from '@nord/ui/src/store/current/user/slice'
