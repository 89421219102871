export const userCreateAttributesWhitelist = [
  'accountType',
  'firstName',
  'lastName',
  'email',
  'phone',
  'phoneCode',
  'password',
  'utm',
  'birthdate',
  'kreditdataId',
  'gaClientId',
] as const

const userUpdateBaseAttributesWhitelist = [
  'firstName',
  'lastName',
  'password',
  'passwordConfirmation',
  'postalCode',
  'city',
  'address',
  'country',
  'nationality',
  'citizenship',
  'investableAssets',
  'debt',
  'expectedInvestment',
  'readyForSaxo',
  'sustainabilityPreferencesOrder',
  'acceptLowerSustainabilityPreference',
] as const

const userUpdatePrivateAttributesWhitelist = [
  ...userUpdateBaseAttributesWhitelist,
  'cpr',
  'birthdate',
  'pep',
  'additionalTaxableCountries',
  'taxableInDk',
  'americanCitizen',
  'americanTaxpayer',
  'americanTaxNumber',
  'currentOccupation',
  'nameOfEmployee',
  'jobTitle',
  'jobIndustry',
  'sourcesOfAssets',
  'sourceOfAssetsOther',
  'yearlyIncome',
  'understandsEtfsAndTaxation',
] as const

const userUpdateCompanyAttributesWhitelist = [
  ...userUpdateBaseAttributesWhitelist,
  'companyName',
  'cvr',
  'naceCode',
  'understandsCompanyEtfs',
] as const

const userUpdatePensionAttributesWhitelist = [
  ...userUpdatePrivateAttributesWhitelist,
  'migrationDepot',
  'migrationDepotCompanyName',
  'migrationDepotRegistrationNumber',
  'migrationDepotAccountNumber',
  'migrationDepotNumber',
  'migrationPensionPoliceNumber',
  'migrationPensionCompanyType',
  'understandsPensionAndTaxation',
] as const

export const userUpdateAttributeWhitelistsByType = {
  private: userUpdatePrivateAttributesWhitelist,
  company: userUpdateCompanyAttributesWhitelist,
  pension: userUpdatePensionAttributesWhitelist,
} as const
