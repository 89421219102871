import Cookies from 'js-cookie'
import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'

import { UTM_COOKIE_EXPIRY } from '../configuration/constants'

const getUTMParameters = () => {
  let utmParameters = Cookies.getJSON('utm')

  if (utmParameters === undefined || isEmpty(utmParameters)) {
    const queryParams = queryString.parse(window.location.search) || {}
    const utmParamPrefix = 'utm_'
    const queryUTMParameters = Object.entries(queryParams).reduce((result, [key, value]) => {
      if (key.startsWith(utmParamPrefix)) {
        const formattedKey = key.replace(utmParamPrefix, '')
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        result[formattedKey] = value
      }

      return result
    }, {})

    Cookies.set('utm', queryUTMParameters, {
      expires: UTM_COOKIE_EXPIRY,
      secure: true,
      sameSite: 'strict',
    })
    utmParameters = queryUTMParameters
  }

  return utmParameters
}

export default getUTMParameters
