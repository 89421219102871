import { object, string, boolean, array } from 'yup'

const validationSchema = object().shape({
  americanCitizen: boolean(),
  americanTaxpayer: boolean(),
  americanTaxNumber: string().when('americanTaxpayer', {
    is: true,
    then: string().numerical().required().length(9),
  }),
  taxpayerInOtherCountry: boolean(),
  additionalTaxableCountries: array().when('taxpayerInOtherCountry', {
    is: true,
    then: array().of(
      object().shape({
        countryCode: string().required(),
        taxId: string().required(),
      }),
    ),
  }),
})

export default validationSchema
