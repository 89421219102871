import Cookies from 'js-cookie'

import { environment } from '../configuration/config'

const { custom: customEnvironment } = environment

const cookieDomain = customEnvironment === 'local' ? undefined : '.nord.investments'
const cookieKey = 'cookiesAcceptance'
const expireIn = {
  all: 365,
  necessary: 1 / 48,
}

export const getCookieConsent = () => Cookies.get(cookieKey)

export const setCookieConsent = (newCookieConsent: any) => {
  Cookies.set(cookieKey, newCookieConsent, {
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    expires: expireIn[newCookieConsent],
    domain: cookieDomain,
  })
}

export const clearCookieConsent = () => {
  Cookies.remove(cookieKey)
}
