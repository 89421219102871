/* eslint-disable @typescript-eslint/no-explicit-any */
import delay from 'lodash/delay'
import isArray from 'lodash/isArray'
import { useState, useRef, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { changeCurrentPortfolio, fetchCurrentPortfolio } from '../store/current/portfolioId'
import { changeCurrentUser, fetchCurrentUser } from '../store/current/user'
import { buildDebugLog } from '../utilities/debugLog'

import useDeepCompareMemoize from './useDeepCompareMemoize'

const debugLog = buildDebugLog('useWaitForStateChange')

const defaultTickTime = 1000
export const defaultTimeoutLimit = defaultTickTime * 30

// TODO: handle request errors
const useWaitForStateChange = (goalState: any, onTimeout: any, rawOptions = {}) => {
  const goalStates = useDeepCompareMemoize(isArray(goalState) ? goalState : [goalState])

  const options = useDeepCompareMemoize(rawOptions) as any
  const { currentState, extractNewState } = options

  const [ticks, setTicks] = useState(0)
  const timeoutId = useRef()
  const cancel = useRef()
  const dispatch = useDispatch()
  // const currentState = useSelector(selectOnboardingState)

  const fetchUserData = useCallback(async () => {
    // @ts-expect-error TS(2339) FIXME: Property 'payload' does not exist on type 'AsyncTh... Remove this comment to see the full error message
    const { payload: userData } = await dispatch(fetchCurrentUser())
    debugLog.log('userUpdate', userData)

    return userData
  }, [dispatch])

  const fetchPortfolioData = useCallback(async () => {
    // @ts-expect-error TS(2339) FIXME: Property 'payload' does not exist on type '(dispat... Remove this comment to see the full error message
    const { payload: portfolioData } = await dispatch(fetchCurrentPortfolio())
    debugLog.log('portfolioUpdate', portfolioData)

    return portfolioData
  }, [dispatch])

  const checkState = useCallback((newState: any) => goalStates.includes(newState), [goalStates])

  const delayedFunction = useCallback(
    async (currentTicks: any) => {
      timeoutId.current = undefined

      if (checkState(currentState)) return

      const [newUserData, newPortfolioData] = await Promise.all([
        fetchUserData(),
        fetchPortfolioData(),
      ])

      // const newState = extractOnboardingState(newUserData, newPortfolioData)
      const newState = extractNewState(newUserData, newPortfolioData)

      if (!checkState(newState)) {
        debugLog.log('tick', {
          currentTicks,
        })
        setTicks(currentTicks + 1)

        return
      }

      debugLog.log('complete', {
        newUserData,
        newPortfolioData,
      })

      dispatch(changeCurrentUser(newUserData))
      if (newPortfolioData) dispatch(changeCurrentPortfolio(newPortfolioData))
    },
    [checkState, currentState, fetchUserData, fetchPortfolioData, extractNewState, dispatch],
  )

  useEffect(() => {
    if (goalStates[0] === false) return undefined

    const { timeoutLimit = defaultTimeoutLimit, tickTime = defaultTickTime } = options

    const elapsedTime = ticks * tickTime

    debugLog.logIf(elapsedTime >= timeoutLimit, 'timeout', {
      elapsedTime,
      timeoutLimit,
    })
    if (elapsedTime >= timeoutLimit) return onTimeout()

    // @ts-expect-error TS(2322) FIXME: Type 'number' is not assignable to type 'undefined... Remove this comment to see the full error message
    timeoutId.current = delay(delayedFunction, tickTime, ticks)
    debugLog.log('set delayed call', {
      timeoutId: timeoutId.current,
      elapsedTime,
      timeoutLimit,
      tickTime,
    })

    // @ts-expect-error TS(2322) FIXME: Type '() => void' is not assignable to type 'undef... Remove this comment to see the full error message
    cancel.current = () => {
      if (timeoutId.current === undefined) return

      debugLog.log('cancel', timeoutId.current)
      clearTimeout(timeoutId.current)
    }

    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    return () => cancel.current()
  }, [delayedFunction, goalStates, onTimeout, options, ticks])

  return cancel.current
}

export const STATE_CHANGE_TIMEOUT = defaultTimeoutLimit

export default useWaitForStateChange
