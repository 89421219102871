import apiRequestCall from '@nord/ui/src/store/api/apiRequestCall'
import { selectCurrentUser } from '@nord/ui/src/store/current/user'
import { createAsyncThunk } from '@reduxjs/toolkit'
import pick from 'lodash/pick'

import getGaClientId from '../../../utilities/getGaClientId'
import { selectCurrentInvestmentProfile } from '../investmentProfile'

import {
  portfolioSharedCreateAttributeWhitelist,
  portfolioPensionCreateAttributeWhitelist,
  portfolioUpdateAttributeWhitelist,
} from './attributeWhitelist'

export const createPortfolioOnboarding = createAsyncThunk(
  'current.portfolios/onboarding/create',
  async (attributes, { dispatch, getState }) => {
    const state = getState()
    const investmentProfileAttributes = selectCurrentInvestmentProfile(state)

    // @ts-expect-error TS(2339) FIXME: Property 'user' does not exist on type 'void'.
    const { user: userAttributes, portfolio: portfolioAttributes = attributes } = attributes
    const { pensionDepot } = portfolioAttributes
    const portfolioAttributesWithInvestmentProfile = {
      ...investmentProfileAttributes,
      ...portfolioAttributes,
    }

    const url = `/onboarding${pensionDepot ? '/pension' : ''}/portfolios`
    const data = {}

    const portfolioCreateAttributesWhiteList = pensionDepot
      ? portfolioPensionCreateAttributeWhitelist
      : portfolioSharedCreateAttributeWhitelist

    // @ts-expect-error TS(2339) FIXME: Property 'portfolio' does not exist on type '{}'.
    data.portfolio = pick(
      portfolioAttributesWithInvestmentProfile,
      portfolioCreateAttributesWhiteList,
    )
    // @ts-expect-error TS(2339) FIXME: Property 'user' does not exist on type '{}'.
    if (userAttributes) data.user = pick(userAttributes, ['birthdate', 'kreditdataId', 'cpr'])

    return dispatch(
      apiRequestCall({
        method: 'POST',
        url,
        data,
        withCredentials: true,
      }),
    ).unwrap()
  },
)

export const updatePortfolioOnboarding = createAsyncThunk(
  'current.portfolios/onboarding/update',
  // @ts-expect-error TS(2339) FIXME: Property 'portfolioId' does not exist on type 'voi... Remove this comment to see the full error message
  async ({ portfolioId, attributes }, { dispatch }) => {
    const { pensionDepot } = attributes
    const url = `/onboarding${pensionDepot ? '/pension' : ''}/${portfolioId}/portfolios`

    return dispatch(
      apiRequestCall({
        method: 'PATCH',
        url,
        data: {
          portfolio: pick(attributes, portfolioUpdateAttributeWhitelist),
        },
        withCredentials: true,
      }),
    ).unwrap()
  },
)

export const upsertPortfolioOnboarding =
  ({ portfolioId, attributes }: any) =>
  (dispatch: any) => {
    // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
    if (portfolioId) return dispatch(updatePortfolioOnboarding({ portfolioId, attributes }))

    // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
    return dispatch(createPortfolioOnboarding(attributes))
  }

export const createPortfolioSaxoClient = createAsyncThunk(
  'current.portfolios/createSaxoClient',
  async (portfolioId, { dispatch }) =>
    dispatch(
      apiRequestCall({
        method: 'POST',
        url: `/onboarding/${portfolioId}/saxo_client`,
        withCredentials: true,
        errorHandling: {
          ignore: {
            client: true,
          },
        },
      }),
    ).unwrap(),
)

export const createPortfolioDocuments = createAsyncThunk(
  'current.portfolios/createDocuments',
  async (portfolioId, { dispatch }) =>
    dispatch(
      apiRequestCall({
        method: 'POST',
        url: `/onboarding/${portfolioId}/documents`,
        withCredentials: true,
        errorHandling: {
          ignore: {
            client: true,
          },
        },
      }),
    ).unwrap(),
)

export const acceptPortfolioDocuments = createAsyncThunk(
  'current.portfolios/acceptDocuments',
  async (portfolioId, { dispatch }) =>
    dispatch(
      apiRequestCall({
        method: 'POST',
        url: `/onboarding/${portfolioId}/documents/accept`,
        withCredentials: true,
      }),
    ).unwrap(),
)

export const completePortfolioKyc = createAsyncThunk(
  'current.portfolios/kycComplete',
  (portfolioId, { dispatch }) =>
    dispatch(
      apiRequestCall({
        method: 'PATCH',
        url: `/onboarding/${portfolioId}/kyc`,
        withCredentials: true,
      }),
    ).unwrap(),
)

export const completePortfolioOnboarding = createAsyncThunk(
  'current.portfolios/onboardingComplete',
  // @ts-expect-error TS(2339) FIXME: Property 'portfolioId' does not exist on type 'voi... Remove this comment to see the full error message
  async ({ portfolioId, flow }, { dispatch, getState }) => {
    const state = getState()
    const { submittedToSaxoAt } = selectCurrentUser(state)
    const gaClientId = getGaClientId()

    let data
    if (gaClientId) data = { user: { gaClientId } }

    const updatedValues = await dispatch(
      apiRequestCall({
        method: 'POST',
        url: `/onboarding/${portfolioId}/portfolios/complete`,
        withCredentials: true,
      }),
    ).unwrap()

    if (!submittedToSaxoAt && flow !== 'company') {
      return dispatch(
        apiRequestCall({
          method: 'PATCH',
          url: `/onboarding/${portfolioId}/saxo_client`,
          withCredentials: true,
          data,
        }),
      ).unwrap()
    }

    return updatedValues
  },
)

export const changePortfolioDepotType = createAsyncThunk(
  'current.portfolios/changeType',
  // @ts-expect-error TS(2339) FIXME: Property 'portfolioId' does not exist on type 'voi... Remove this comment to see the full error message
  ({ portfolioId, attributes: { depotType } }, { dispatch }) =>
    dispatch(
      apiRequestCall({
        method: 'PATCH',
        url: `/onboarding/pension/${portfolioId}/portfolios/change_type`,
        withCredentials: true,
        data: { portfolio: { depotType } },
      }),
    ),
)

export * from '@nord/ui/src/store/current/portfolios/actions'
