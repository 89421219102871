import BaffleButton from '@nord/ui/src/components/BaffleButton'
import { getConfig } from '@nord/ui/src/configuration'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import React from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link, Redirect } from 'react-router-dom'

import { PATHS } from '../../../configuration/constants'
import useSignedIn from '../../../hooks/useSignedIn'
import { selectCurrentUser } from '../../../store/current/user'
import ContentContainer from '../ContentContainer'
import ContentTitle from '../ContentTitle'

const blogUrl = getConfig('domains.blog')
const vsoBlogUrl = `${blogUrl}/investering-af-vso-midler/`

const ChooseFlowPage = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'onboardingFlows' does not exist on type ... Remove this comment to see the full error message
  const { onboardingFlows } = useObjectSelector(selectCurrentUser)
  const signedIn = useSignedIn()

  return (
    <>
      {onboardingFlows && onboardingFlows.length === 1 && (
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        <Redirect to={PATHS[onboardingFlows[0]].root()} />
      )}
      <ContentContainer>
        <ContentTitle
          fontSize="xxxl"
          title="Hvilken type investering ønsker du at få en investeringsplan for?"
        />
        <p className="subheader">Dit svar vil påvirke din anbefaling.</p>
        <Row>
          <Col
            xs={12}
            className="d-flex flex-column align-items-center children-my-2 flex-md-row justify-content-md-center children-my-md-0 children-mx-md-2"
          >
            {(onboardingFlows === undefined || onboardingFlows.includes('private')) && (
              <BaffleButton variant="primary" as={Link} type={undefined} to={PATHS.private.root()}>
                Private frie midler
              </BaffleButton>
            )}
            {(onboardingFlows === undefined || onboardingFlows.includes('pension')) && (
              <BaffleButton variant="primary" as={Link} type={undefined} to={PATHS.pension.root()}>
                Pensionsmidler
              </BaffleButton>
            )}
            {(onboardingFlows === undefined || onboardingFlows.includes('company')) && (
              <BaffleButton variant="primary" as={Link} type={undefined} to={PATHS.company.root()}>
                Virksomhedsmidler
              </BaffleButton>
            )}
          </Col>
        </Row>
        <Row>
          <Col className="mt-4">
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a href={vsoBlogUrl} target="_blank">
              Er du under virksomhedsskatteordningen (VSO)? Læs mere her.
            </a>
          </Col>
        </Row>
        {!signedIn && (
          <Row>
            <Col className="mt-2">
              <Link
                variant="link"
                className="text-base"
                type={undefined}
                to={PATHS.shared.userSignIn()}
              >
                Er du eksisterende bruger? Log ind her.
              </Link>
            </Col>
          </Row>
        )}
      </ContentContainer>
    </>
  )
}

export default ChooseFlowPage
