import Form from '@nord/ui/src/components/Form'
import Loader from '@nord/ui/src/components/Loader'
import Text from '@nord/ui/src/components/Text'
import useApi from '@nord/ui/src/hooks/useApi'
import useAsyncInterval from '@nord/ui/src/hooks/useAsyncInterval'
import { changeCurrentUser } from '@nord/ui/src/store/current/user'
import { useFormikContext } from 'formik'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useDispatch } from 'react-redux'

import { checkUserPEPAndAML } from '../../../../../store/current/user/actions'

import CPRInput from './CPRInput'

const timeoutLimit = 5000
const intervalDuration = 1000

export interface Props {
  loadingCPR: boolean
  onLoading(value: boolean): void
}

const CPRField = ({ loadingCPR, onLoading }: Props) => {
  const dispatch = useDispatch()
  const [loadedCPRNumber, setLoadedCPRNumber] = useState()
  const [cprResponseError, setCprResponseError] = useState()
  const [intervalEnabled, setIntervalEnabled] = useState(false)

  const { errors, values, touched, setFieldError, setFieldValue, setFieldTouched } =
    useFormikContext()
  // @ts-expect-error TS(2339) FIXME: Property 'cpr' does not exist on type 'unknown'.
  const { cpr: cprNumber } = values
  // @ts-expect-error TS(2339) FIXME: Property 'cpr' does not exist on type 'FormikTouch... Remove this comment to see the full error message
  const { cpr: fieldIsTouched } = touched

  const fetchCprData = useApi('/onboarding/pep_checks', {
    withCredentials: true,
    errorHandling: {
      ignore: {
        client: true,
        server: true,
      },
    },
  })

  const handleResponse = useCallback(
    async (data: {
      data: { cpr: number; age: number; gender: 'female' | 'male'; birthdate: string }
      errors: Record<string, string[]>
    }) => {
      const { data: responseData, errors: responseErrors } = data

      if (!isEmpty(responseData)) await dispatch(changeCurrentUser(responseData))

      if (responseData && isEmpty(responseErrors)) {
        Object.entries(responseData).forEach(([attribute, value]) => {
          if (value) setFieldValue(attribute, value, true)
        })
        // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
        setCprResponseError()
      } else {
        const validationError = Object.values(responseErrors)[0][0]

        setFieldError('cpr', validationError)
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        setCprResponseError(validationError)
      }

      setLoadedCPRNumber(cprNumber)
      onLoading(false)
      setIntervalEnabled(false)
    },
    [cprNumber, dispatch, onLoading, setFieldError, setFieldValue],
  )

  const handleCallCprData = useCallback(async () => {
    // @ts-expect-error TS(2554) FIXME: Expected 1-2 arguments, but got 0.
    const { status, data } = await fetchCprData()

    if (status === 202) return

    handleResponse(data)
  }, [fetchCprData, handleResponse])

  const handleTimeout = useCallback(() => {
    onLoading(false)
  }, [onLoading])

  useAsyncInterval(handleCallCprData, {
    onTimeout: handleTimeout,
    enabled: intervalEnabled,
    duration: intervalDuration,
    timeout: timeoutLimit,
  })

  const setFullCPR = useCallback(async () => {
    if (cprNumber && cprNumber.length === 10 && cprNumber !== loadedCPRNumber) {
      setFieldTouched('cpr', true)
      onLoading(true)

      // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
      await dispatch(checkUserPEPAndAML(cprNumber))

      setTimeout(() => setIntervalEnabled(true), intervalDuration)
    }

    return true
  }, [cprNumber, loadedCPRNumber, setFieldTouched, onLoading, dispatch])

  const handleBlur = () => setFullCPR()

  useEffect(() => {
    setFullCPR()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // @ts-expect-error TS(2339) FIXME: Property 'cpr' does not exist on type 'FormikError... Remove this comment to see the full error message
  const cprError = !loadingCPR && (errors.cpr || cprResponseError)
  const isInvalid = !!cprError && fieldIsTouched

  return (
    <Row>
      <Col xs={12}>
        <Form.FieldGroup name="cpr" error={cprError} isInvalid={isInvalid}>
          {/* @ts-expect-error TS(2322) FIXME: Type '(props: CPRInputProps & RefAttributes<any>) ... Remove this comment to see the full error message */}
          <Form.Field as={CPRInput} guide={false} type="text" onBlur={handleBlur} />
        </Form.FieldGroup>
        {loadingCPR ? (
          <div className="d-flex flex-row mb-3">
            <Loader className="mr-2" />
            <Text size="sm">Vi henter data, vent et øjeblik...</Text>
          </div>
        ) : (
          <Text as="p" size="sm" variant="secondary">
            Vi henter dit navn og adresse fra CPR registeret når du udfylder dit CPR nummer.
          </Text>
        )}
      </Col>
    </Row>
  )
}

export default CPRField
