import Form from '@nord/ui/src/components/Form'
import type { ReactNode } from 'react'
import React from 'react'

export interface OtherDocumentationFieldProps {
  email: string
  help: ReactNode
}

const OtherDocumentationField = ({ help, email, ...props }: OtherDocumentationFieldProps) => (
  <Form.FieldGroup name="documentation" help={help}>
    <Form.Fields.File {...props} name="email" email={email} dropzone />
  </Form.FieldGroup>
)

export default OtherDocumentationField
