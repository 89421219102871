import sortBy from 'lodash/sortBy'
import React, { useContext, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'
import { ReferenceArea } from 'recharts'

import useEventOutside from '../../hooks/useEventOutside'
import useLockBodyScroll from '../../hooks/useLockBodyScroll'
import useWindowSize from '../../hooks/useWindowSize'
import Text from '../Text'

import Chart from './Chart'
import styles from './ChartWrapper.module.scss'
import HistoricalReturnContext from './HistoricalReturnContext'
import SelectionLabel from './SelectionLabel'

const calculateReturnValue = (startNumber: any, endNumber: any) => endNumber / startNumber - 1

const ChartWrapper = ({ ...props }) => {
  // @ts-expect-error TS(2339) FIXME: Property 'chartData' does not exist on type 'unkno... Remove this comment to see the full error message
  const { chartData, brushStartIndex, brushEndIndex } = useContext(HistoricalReturnContext)
  const { width, ref: resizeDetectorRef } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'debounce',
    refreshRate: 100,
  })
  const [selection, setSelection] = useState({})
  const [isSelecting, setIsSelecting] = useState(false)
  const windowSize = useWindowSize()

  // @ts-expect-error TS(2339) FIXME: Property 'start' does not exist on type '{}'.
  const setSelectionEnd = (newEnd: any) => setSelection({ start: selection.start, end: newEnd })

  useLockBodyScroll(isSelecting)

  const selectionActive =
    // @ts-expect-error TS(2339) FIXME: Property 'start' does not exist on type '{}'.
    selection.start !== undefined &&
    // @ts-expect-error TS(2339) FIXME: Property 'end' does not exist on type '{}'.
    selection.end !== undefined &&
    // @ts-expect-error TS(2339) FIXME: Property 'start' does not exist on type '{}'.
    selection.start !== selection.end

  const handleStartSelection = (index: any) => {
    setSelection({ start: index })
    setIsSelecting(true)
  }

  const handleChangeSelection = (index: any) => {
    if (!isSelecting) return

    setSelectionEnd(index)
  }

  const handleCompleteSelection = (index: any) => {
    handleChangeSelection(index)
    setIsSelecting(false)
  }

  const handleMouseDown = (event: any) => {
    if (event === null) return

    handleStartSelection(event.activeTooltipIndex + brushStartIndex)
  }

  const handleMouseMove = (event: any) => {
    if (!event.isTooltipActive) return handleChangeSelection(brushEndIndex)

    return handleChangeSelection(event.activeTooltipIndex + brushStartIndex)
  }

  const handleMouseUp = (event: any) => {
    if (event === null) return handleCompleteSelection(brushEndIndex)

    return handleCompleteSelection(event.activeTooltipIndex + brushStartIndex)
  }

  const handleDragOutsideChart = (event: any) => {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    if (event.clientX < windowSize.width / 2) {
      handleCompleteSelection(brushStartIndex)
    } else {
      handleCompleteSelection(brushEndIndex)
    }
  }

  const chartRef = useEventOutside('mouseup', handleDragOutsideChart)

  let selectionAreaContent

  if (selectionActive) {
    const sortedSelection = sortBy(Object.values(selection))
    const [selectionStart, selectionEnd] = sortedSelection

    const startData = chartData[selectionStart]
    const endData = chartData[selectionEnd]

    const { value: startValue, timestamp: startTimestamp, date: startDate } = startData
    const { value: endValue, timestamp: endTimestamp, date: endDate } = endData

    const returnValue = calculateReturnValue(startValue, endValue)

    selectionAreaContent = (
      <ReferenceArea
        x1={startTimestamp}
        x2={endTimestamp}
        strokeOpacity={0.3}
        // eslint-disable-next-line react/no-unstable-nested-components
        label={({ viewBox }) => (
          <SelectionLabel
            viewBox={viewBox}
            returnValue={returnValue}
            startDate={startDate}
            endDate={endDate}
          />
        )}
      />
    )
  }

  return (
    <>
      <div ref={resizeDetectorRef}>
        <div className={styles.chartWrapper} ref={chartRef}>
          <Chart
            selectionActive={selectionActive}
            // @ts-expect-error TS(2322) FIXME: Type '{ children: Element | undefined; selectionAc... Remove this comment to see the full error message
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            width={width}
            {...props}
          >
            {selectionAreaContent}
          </Chart>
        </div>
      </div>
      <Text as="p" align="center" className="mt-2">
        Klik og træk et sted på grafen, for at se afkast i den givne periode.
      </Text>
    </>
  )
}

export default ChartWrapper
