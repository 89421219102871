import Form from '@nord/ui/src/components/Form'
import Text from '@nord/ui/src/components/Text'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

const NameFields = ({ ...props }) => (
  <>
    <Row>
      <Col xs={12} sm={6}>
        <Form.FieldGroup name="firstName">
          <Form.Field {...props} />
        </Form.FieldGroup>
      </Col>
      <Col xs={12} sm={6}>
        <Form.FieldGroup name="lastName">
          <Form.Field {...props} />
        </Form.FieldGroup>
      </Col>
    </Row>
    <Row>
      <Col>
        <Text as="p" size="sm" variant="secondary">
          Tjek at dit navn er dit fulde navn, som det fremgår på bl.a. dit sundhedskort.
        </Text>
      </Col>
    </Row>
  </>
)

export default NameFields
