const initialValues = {
  password: '',
  passwordConfirmation: '',
  sustainabilityPreferencesOrder: [],
  acceptLowerSustainabilityPreference: false,
  age: undefined,
  americanCitizen: false,
  americanTaxpayer: false,
  americanTaxNumber: '',
  pep: false,
  understandsEtfsAndTaxation: false,
  citizenOrTaxpayerUSA: false,
  additionalTaxableCountries: [],
  monthlyIncome: '',
  yearlyIncome: '',
}

export default initialValues
