import Paginator from '@nord/ui/src/components/Paginator'
import Sustainability from '@nord/ui/src/components/Sustainability'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentUser } from '@nord/ui/src/store/current/user'
import { useFormikContext } from 'formik'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useState } from 'react'

import ContentTitle from '../../ContentTitle'

import validationSchema from './validationSchema'

export interface WrapperProps {
  children: React.ReactNode
}

const Wrapper = ({ children, ...otherProps }: WrapperProps) => {
  // @ts-expect-error TS(2339) FIXME: Property 'sustainabilityPreferencesOrder' does not... Remove this comment to see the full error message
  const { sustainabilityPreferencesOrder } = useObjectSelector(selectCurrentUser)

  const userWithoutPreferences = isEmpty(sustainabilityPreferencesOrder)

  const pageProps = {}

  // @ts-expect-error TS(2339) FIXME: Property 'xl' does not exist on type '{}'.
  if (userWithoutPreferences) pageProps.xl = { span: 8, offset: 2 }

  return (
    <Paginator.Form.Page
      fields={['sustainabilityPreferencesOrder']}
      validationSchema={validationSchema}
      {...pageProps}
      {...otherProps}
    >
      {children}
    </Paginator.Form.Page>
  )
}

const Header = () => <ContentTitle title="Miljømål" />

const SustainabilityPreferencePage = ({ ...otherProps }) => {
  const {
    // @ts-expect-error TS(2339) FIXME: Property 'sustainabilityPreferencesOrder' does not... Remove this comment to see the full error message
    errors: { sustainabilityPreferencesOrder: error },
    // @ts-expect-error TS(2339) FIXME: Property 'sustainabilityPreferencesOrder' does not... Remove this comment to see the full error message
    touched: { sustainabilityPreferencesOrder: isTouched },
    setFieldValue,
  } = useFormikContext()

  const [items, setItems] = useState()

  const handleOrderChange = useCallback(
    (newOrderedItems: any) => {
      setItems(newOrderedItems)

      setFieldValue('sustainabilityPreferencesOrder', newOrderedItems.ordered, true)
    },
    [setFieldValue],
  )

  const errorMessage = !!isTouched && error

  return (
    <Sustainability.Sorting
      // @ts-expect-error TS(2322) FIXME: Type '({ children, ...otherProps }: WrapperProps) ... Remove this comment to see the full error message
      wrapperComponent={Wrapper}
      // @ts-expect-error TS(2322) FIXME: Type '() => JSX.Element' is not assignable to type... Remove this comment to see the full error message
      headerComponent={Header}
      error={errorMessage}
      items={items}
      onOrderChange={handleOrderChange}
      {...otherProps}
    />
  )
}

export default SustainabilityPreferencePage
