import type { ForwardedRef } from 'react'
import React, { forwardRef } from 'react'
import { Table } from 'react-bootstrap'
import { PieChart, Pie, Cell } from 'recharts'

import { numberToPercent } from '../../utilities/numberFormatter'

import colors from './colors'
import styles from './index.module.scss'

type PortfolioTableProps = {
  data: {
    symbol: string
    danishName: string
    percentage: number
    legalName: string
    url: string | null
    position?: number
    offset?: number
    initialOffset?: number
  }[]
}

const PortfolioTable = (
  { data }: PortfolioTableProps,
  ref: ForwardedRef<HTMLTableSectionElement>,
) => (
  <Table responsive="xs" className={styles.portfolioTable}>
    <thead>
      <tr>
        <th className="font-weight-normal">Værdipapirer</th>
        <th className="text-right font-weight-normal">Procent</th>
      </tr>
    </thead>
    <tbody ref={ref}>
      {data.map(
        ({ symbol, danishName, percentage, legalName, url, position, initialOffset, offset }) => (
          <tr
            key={symbol}
            data-key={symbol}
            data-position={position}
            data-offset={offset}
            data-initial-offset={initialOffset}
            data-symbol={symbol}
            className={styles.etfRow}
            title={danishName}
            style={offset ? { transform: `translate3d(0, ${offset}px, 0)` } : undefined}
          >
            <td>
              <div>
                <div className={styles.etfTitle}>{danishName}</div>
                <div>
                  <a
                    className="font-size-sm text-secondary text-label"
                    href={url ?? '#'}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {legalName}
                  </a>
                </div>
              </div>
            </td>
            <td>
              <div className="float-right">
                <PieChart
                  width={48}
                  height={48}
                  margin={{
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  }}
                >
                  <text x="50%" y="50%" dy={4.8} textAnchor="middle" fill="#000" className="small">
                    {numberToPercent(percentage, { fractionDigits: 1 })}
                  </text>
                  <Pie
                    dataKey="value"
                    data={[
                      {
                        name: 'etf',
                        value: percentage,
                      },
                      {
                        name: 'other',
                        value: 1.0 - percentage,
                      },
                    ]}
                    fill={colors[0]}
                    innerRadius="95%"
                    outerRadius="100%"
                  >
                    <Cell fill={colors[0]} stroke={colors[0]} />
                    <Cell fill="#dee2e6" stroke="#dee2e6" />
                  </Pie>
                </PieChart>
              </div>
            </td>
          </tr>
        ),
      )}
    </tbody>
  </Table>
)

export default forwardRef(PortfolioTable)
