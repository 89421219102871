import ConfirmationPage from './ConfirmationPage'
import Onboarding from './Onboarding'
import SaxoVerification from './SaxoVerification'
import StartPage from './StartPage'

const Private = {
  Onboarding,
  SaxoVerification,
  ConfirmationPage,
  StartPage,
}

export default Private
