import React from 'react'
import { Tooltip as RechartsTooltip } from 'recharts'

import formatDate from '../../utilities/formatDate'
import { numberToPercent } from '../../utilities/numberFormatter'
import Card from '../Card'

import styles from './Tooltip.module.scss'

const calculateReturn = (startNumber: any, endNumber: any) => endNumber / startNumber - 1

export interface ContentProps {
  payload: {
    value: string
    payload?: {
      date: string
    }
  }[]
  startValue?: number
}

const Content = ({ payload, startValue }: ContentProps) => {
  if (!payload) return null
  if (!payload.length) return null
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const date = formatDate(payload[0].payload.date, 'MMM yyyy')

  const returnValue = calculateReturn(startValue, payload[0].value)

  return (
    <Card className={styles.tooltip}>
      <Card.Body className={styles.tooltipBody}>
        {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; className: string; align... Remove this comment to see the full error message */}
        <Card.Title className={styles.tooltipTitle} align="right">
          {numberToPercent(returnValue, { fractionDigits: 1 })}
        </Card.Title>
        <Card.Text>{date}</Card.Text>
      </Card.Body>
    </Card>
  )
}

Content.defaultProps = {
  startValue: undefined,
}

export interface TooltipProps {
  startValue?: number
  hide?: boolean
  active?: boolean
}

const Tooltip = React.forwardRef<any, TooltipProps>(
  ({ startValue, hide, active, ...props }, ref) => (
    <RechartsTooltip
      labelFormatter={(timestamp) => new Date(timestamp * 1000).getFullYear()}
      // @ts-expect-error TS(2322) FIXME: Type 'Payload<ValueType, NameType>[] | undefined' ... Remove this comment to see the full error message
      // eslint-disable-next-line react/no-unstable-nested-components
      content={({ payload }) => <Content payload={payload} startValue={startValue} />}
      {...props}
      active={hide ? false : active}
      ref={ref}
    />
  ),
)

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type '(p... Remove this comment to see the full error message
Tooltip.defaultProps = {
  active: undefined,
  hide: undefined,
  startValue: undefined,
}

// Makes sure that recharts matches correctly
// @ts-expect-error TS(2339) FIXME: Property 'displayName' does not exist on type '(pr... Remove this comment to see the full error message
Tooltip.displayName = 'Tooltip'

export default Tooltip
