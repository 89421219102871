import React from 'react'

import PagesWrapper from './PagesWrapper'
import Referral from './Referral'

const OnboardingFlow = () => (
  <Referral.Wrapper>
    <PagesWrapper />
  </Referral.Wrapper>
)

export default OnboardingFlow
