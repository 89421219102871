import Paginator from '@nord/ui/src/components/Paginator'
import React from 'react'

import ContentTitle from '../../../ContentTitle'

import AdditionalTaxableCountries from './AdditionalTaxableCountries'
import CitizenOrTaxpayerInTheUSA from './CitizenOrTaxpayerInTheUSA'
import labels, { fields } from './labels'
import validationSchema from './validationSchema'

const TaxableCountriesPage = ({ ...otherProps }) => (
  <Paginator.Form.Page
    validationSchema={validationSchema}
    labelDescriptions={labels}
    fields={fields}
    {...otherProps}
  >
    <ContentTitle title="Skattepligtige lande" />
    <CitizenOrTaxpayerInTheUSA />
    <AdditionalTaxableCountries />
  </Paginator.Form.Page>
)

export default TaxableCountriesPage
