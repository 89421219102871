import SignIn from './SignIn'
import SignUp from './SignUp'
import StartInvestmentPlan from './StartInvestmentPlan'
import StartOnboarding from './StartOnboarding'
import UpdateRiskScore from './UpdateRiskScore'

const Buttons = {
  SignIn,
  SignUp,
  StartInvestmentPlan,
  StartOnboarding,
  UpdateRiskScore,
}

export default Buttons
