import LoadingButton from '@nord/ui/src/components/LoadingButton'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import classNames from 'classnames'
import isNil from 'lodash/isNil'
import React from 'react'

import styles from './styles.module.scss'
import TableColumn from './TableColumn'

export interface Props {
  transferable: {
    status: boolean
    reason: string
  }
  depotKey: string
  providerName: string
  typeName: string
  type: string
  multiple: boolean
  onSelect: (...args: any[]) => any
  submitting: boolean
  value?: number
  className?: string
}

const PensionDepotEntry = ({
  transferable: { status: transferableStatus, reason: transferableReason },
  value,
  typeName,
  type,
  providerName,
  multiple,
  onSelect,
  submitting,
  className,
  depotKey,
}: Props) => {
  const isNORD = providerName === 'NORD.investments'

  const handleClick = () => {
    onSelect({
      expectedInvestment: value,
      depotType: type,
      key: depotKey,
    })
  }

  return (
    <tr
      className={classNames(styles.pensionProviderRow, className, {
        [styles.disabledPensionProviderRow]: !transferableStatus,
      })}
    >
      <TableColumn>{typeName}</TableColumn>
      <TableColumn>{isNil(value) ? '-' : numberToCurrency(value)}</TableColumn>
      <TableColumn>{transferableReason}</TableColumn>
      {multiple && (
        <TableColumn fit>
          {transferableStatus ? (
            <div className="d-flex justify-content-center align-items-center flex-column text-wrap">
              <LoadingButton
                // @ts-expect-error TS(2322) FIXME: Type '{ children: string; size: string; variant: s... Remove this comment to see the full error message
                size="sm"
                variant="primary"
                onClick={handleClick}
                // @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
                loading={submitting && submitting === depotKey}
                disabled={!!submitting}
              >
                Se investeringsplan
              </LoadingButton>
            </div>
          ) : (
            <div>{isNORD ? '' : 'Kan ikke flyttes'}</div>
          )}
        </TableColumn>
      )}
    </tr>
  )
}

PensionDepotEntry.defaultProps = {
  className: undefined,
  value: undefined,
}

PensionDepotEntry.TableColumn = TableColumn

export default PensionDepotEntry
