import BaffleButton from '@nord/ui/src/components/BaffleButton'
import { getConfig } from '@nord/ui/src/configuration'
import { selectCurrentPortfolioId } from '@nord/ui/src/store/current/portfolioId'
import React from 'react'
import { useSelector } from 'react-redux'

import useSignedIn from '../../../../../../../hooks/useSignedIn'

const getSuitabilityFlowStartUrl = getConfig('dynamicUrls.suitabilityFlow.start')

const UpdateRiskAdvice = () => {
  const signedIn = useSignedIn()

  const portfolioId = useSelector(selectCurrentPortfolioId)

  const suitabilityFlowStartUrl = portfolioId && getSuitabilityFlowStartUrl(portfolioId)

  return (
    <BaffleButton
      variant="link"
      className="text-light"
      disabled={!signedIn}
      type={undefined}
      as="a"
      href={suitabilityFlowStartUrl}
    >
      Opdater din risikovurdering
    </BaffleButton>
  )
}

export default UpdateRiskAdvice
