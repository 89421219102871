import Form from '@nord/ui/src/components/Form'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import Alert from 'react-bootstrap/Alert'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useHistory } from 'react-router-dom'
import { object, string } from 'yup'

import ContentTitle from '../ContentTitle'

import Advantages from './Advantages'
import { useReferralContext } from './ReferralContext'

const labels = {
  referralCode: 'Henvisningskode',
}

const validationSchema = object().shape({
  referralCode: string().required(),
})

const FormContent = () => {
  const { referralData, onChangeReferralToken } = useReferralContext()
  const history = useHistory()
  const {
    location: { pathname },
  } = history

  const { errors = {} } = referralData || {}

  const handleSubmit = ({ referralCode }: { referralCode: string }) => {
    onChangeReferralToken(referralCode)
    history.replace({
      pathname,
      search: `?referral_token=${referralCode}`,
    })
  }

  return (
    <>
      <ContentTitle title="Du er blevet inviteret til NORD" />
      <Advantages />
      {!isEmpty(errors) && (
        <Row>
          <Col xs={12} lg={{ span: 6, offset: 3 }}>
            <Alert variant="danger" className="text-center">
              {Object.values(errors).join('. ')}
            </Alert>
          </Col>
        </Row>
      )}
      <Form
        onSubmit={handleSubmit}
        labelDescriptions={labels}
        validationSchema={validationSchema}
        fields={Object.keys(labels)}
      >
        <Row>
          <Col xs={12} lg={{ span: 6, offset: 3 }}>
            <Form.FieldGroup name="referralCode">
              <Form.Field />
            </Form.FieldGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="text-center py-3">
            {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; variant: string; loading... Remove this comment to see the full error message */}
            <Form.SubmitButton variant="primary" loadingIcon>
              Indsend
            </Form.SubmitButton>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default FormContent
