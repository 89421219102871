import classNames from 'classnames'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

export interface Props {
  fontSize?: string
  className?: string
  title: string
}

const ContentTitle = ({ title, fontSize, className, ...props }: Props) => (
  <Row>
    <Col>
      <h2
        className={classNames('mt-2', 'mb-4', `font-size-${fontSize}`, className)}
        data-cy="section-title"
        {...props}
      >
        {title}
      </h2>
    </Col>
  </Row>
)

ContentTitle.defaultProps = {
  className: undefined,
  fontSize: 'xl',
}

export default ContentTitle
