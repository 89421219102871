import Icon from '@nord/ui/src/components/Icon'
import React from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Loader from 'react-feather/dist/icons/loader'

export interface Props {
  children: React.ReactNode
}

const LoadingIcon = ({ children, ...props }: Props) => (
  <div className="text-center mt-3">
    <div>{children}</div>
    <Icon icon={Loader} size={2} spin {...props} />
  </div>
)

export default LoadingIcon
