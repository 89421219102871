import React from 'react'

import Switch from './Switch'

const ESGSwitch = ({ ...otherProps }) => (
  <Switch
    name="esg"
    label="Ansvarlig portefølje"
    tooltipText={
      <>
        Hvis du vælger en ansvarlig portefølje, bliver du ikke investeret i virksomheder, der ikke
        lever op til vores krav ift. miljø, arbejdsforhold mm. Du vil f.eks. ikke blive investeret i
        virksomheder der producerer våben eller forurener i særlig grad.
      </>
    }
    {...otherProps}
  />
)

export default ESGSwitch
