import { object, string, number } from 'yup'

const validationSchema = object().shape({
  firstName: string().required(),
  lastName: string().required(),
  email: string().email().required(),
  phone: number().required().positive(),
  phoneCode: string().required(),
})

export default validationSchema
