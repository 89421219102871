import { deepFreeze } from '../../../utilities/deepIterate'
import environment from '../environment'

const { domains } = environment

const prefixesForTypes = {
  private: 'privat',
  pension: 'pension',
  company: 'virksomhed',
} as const

type InvestmentType = keyof typeof prefixesForTypes

const urls = deepFreeze({
  onboarding: {
    start: (type: InvestmentType) => `${domains.onboarding}/${prefixesForTypes[type]}/`,

    investmentPlan: (type: InvestmentType, id: 'ny' | number = 'ny') =>
      `${domains.onboarding}/${prefixesForTypes[type]}/${id}/investeringsplan`,

    investmentPlanResult: (type: InvestmentType, id: 'ny' | number = 'ny') =>
      `${domains.onboarding}/${prefixesForTypes[type]}/${id}/investeringsplan/resultat`,

    onboarding: (type: InvestmentType, id: number) =>
      `${domains.onboarding}/${prefixesForTypes[type]}/${id}/opret`,

    verification: (type: InvestmentType, id: number) =>
      `${domains.onboarding}/${prefixesForTypes[type]}/${id}/verificering`,

    confirmation: (type: InvestmentType, id: number) =>
      `${domains.onboarding}/${prefixesForTypes[type]}/${id}/bekraeft`,

    documents: (type: InvestmentType, id: number) =>
      `${domains.onboarding}/${prefixesForTypes[type]}/${id}/dokumenter`,

    saxoVerificationStart: (type: InvestmentType, id: number) =>
      `${domains.onboarding}/${prefixesForTypes[type]}/${id}/saxo_verificering`,

    saxoVerificationFailure: (type: InvestmentType, id: number) =>
      `${domains.onboarding}/${prefixesForTypes[type]}/${id}/saxo_verificering/afvist`,

    saxoVerificationCompleted: (type: InvestmentType, id: number) =>
      `${domains.onboarding}/${prefixesForTypes[type]}/${id}/saxo_verificering/afsluttet`,
  },
  suitabilityFlow: {
    start: (id: any) => `${domains.suitabilityFlow}/portefoeljer/${id}/start`,
  },
})

export default urls
