import otherSourceOfAssetsValue from './otherSourceOfAssetsValue'

const options = [
  {
    value: 'Savings from salary/pension',
    label: 'Opsparing fra løn/pension',
  },
  {
    value: 'Inheritance',
    label: 'Arv',
  },
  {
    value: 'Trading Profit',
    label: 'Gevinst på værdipapirhandler',
  },
  {
    value: 'Profit from selling own company',
    label: 'Fortjeneste ved salg af virksomhed',
  },
  {
    value: 'Royalties (Source of Wealth)',
    label: 'Royalties',
  },
  {
    value: 'Gift/Donation',
    label: 'Gave/donation',
  },
  {
    value: otherSourceOfAssetsValue,
    label:
      'Andet (ved salg af ejendom, angiv om det er privat ejendomssalg f.eks. "salg af privatejendom")',
  },
]

export default options
