import classNames from 'classnames'
import React from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Check from 'react-feather/dist/icons/check'

import Circle from '../Circle'
import Icon from '../Icon'
import Text, { type TextProps } from '../Text'

const circleSizes = {
  md: 'xs',
  lg: 'sm',
}

export interface Props {
  iconVariant: string
  children: React.ReactNode
  size: TextProps['size']
  textVariant: string
}

const CheckItem = ({ iconVariant, children, size, textVariant, ...otherProps }: Props) => {
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const circleSize = circleSizes[size]

  return (
    <li {...otherProps}>
      <div className="d-none d-lg-flex align-items-baseline py-1">
        <div>
          {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; className: string; size... Remove this comment to see the full error message */}
          <Circle className="shadow-none" size={circleSize} variant={iconVariant}>
            <Icon icon={Check} />
          </Circle>
        </div>
        <Text
          as="span"
          variant={textVariant}
          size={size}
          className={classNames({
            'ml-4': size === 'lg',
            'ml-3': size === 'md',
          })}
        >
          {children}
        </Text>
      </div>
      <div className="d-flex d-lg-none align-items-baseline">
        <div>
          <Icon icon={Check} className={`text-${iconVariant}`} />
        </div>
        <Text as="span" variant={textVariant} align="left" size="md" className="ml-2">
          {children}
        </Text>
      </div>
    </li>
  )
}

export default CheckItem
