import BaffleButton from '@nord/ui/src/components/BaffleButton'
import { getConfig } from '@nord/ui/src/configuration'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import React from 'react'
import { useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import { PATHS } from '../../../../../../../configuration/constants'
import useFlow from '../../../../../../../hooks/useFlow'
import useSignedIn from '../../../../../../../hooks/useSignedIn'
import { selectOnboardingState } from '../../../../../../../store/current/portfolioId'

const getSuitabilityFlowStartUrl = getConfig('dynamicUrls.suitabilityFlow.start')

const RiskScoreOutsideRange = () => {
  const flow = useFlow()
  const onboardingState = useSelector(selectOnboardingState)
  // @ts-expect-error TS(2339) FIXME: Property 'advisedRiskScore' does not exist on type... Remove this comment to see the full error message
  const { advisedRiskScore, id: portfolioId } = useObjectSelector(selectCurrentPortfolio)
  const signedIn = useSignedIn()

  const userAlreadyOnboarded = signedIn && onboardingState === 'saxo_onboarded'
  const suitabilityFlowStartUrl = portfolioId && getSuitabilityFlowStartUrl(portfolioId)

  return (
    <>
      <p>
        Din valgte risikoprofil ligger desværre langt fra den risikoprofil Norm Invest har anbefalet
        dig, som er risikoprofil {advisedRiskScore}. Ønsker du at opdatere formålet med din
        investeringsplan, din tidshorisont eller dine risikopræferencer på porteføljen, er du
        velkommen til at tage investeringsplanen igen.
      </p>
      {userAlreadyOnboarded ? (
        <BaffleButton
          variant="link"
          className="text-primary"
          type={undefined}
          href={suitabilityFlowStartUrl}
        >
          Tag investeringsplanen igen
        </BaffleButton>
      ) : (
        <BaffleButton
          variant="link"
          className="text-primary"
          as={Link}
          type={undefined}
          // @ts-expect-error TS(2322) FIXME: Type '{ children: string; variant: string; classNa... Remove this comment to see the full error message
          to={PATHS[flow].investmentPlan(portfolioId)}
        >
          Tag investeringsplanen igen
        </BaffleButton>
      )}
    </>
  )
}

export default RiskScoreOutsideRange
