import { deepFreeze } from '../../../utilities/deepIterate'

import contactInformation from './contactInformation'
import documentationFolders from './documentationFolders'
import dynamicUrls from './dynamicUrls'
import numbers from './numbers'
import setup from './setup'
import urls from './urls'

const shared = deepFreeze({
  documentationFolders,
  dynamicUrls,
  contactInformation,
  urls,
  numbers,
  setup,
})

export default shared
