import styles from '@nord/ui/src/configuration/styleConfiguration'
import React from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import MediaQuery from 'react-responsive'

export const { breakpoints } = styles

export interface Props {
  size: string
}

const Breakpoint = ({ size, ...mediaQueryProps }: Props) => (
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  <MediaQuery {...mediaQueryProps} minWidth={breakpoints[size]} />
)

export default Breakpoint
