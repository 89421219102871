import BooleanInput from '@nord/ui/src/components/BooleanInput'
import Form from '@nord/ui/src/components/Form'
import Paginator from '@nord/ui/src/components/Paginator'
import useInitial from '@nord/ui/src/hooks/useInitial'
import { useFormikContext } from 'formik'
import React from 'react'
import { useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { object, number } from 'yup'

import { MINIMUM_PENSION_INVESTMENT } from '../../../../configuration/constants'
import { selectCurrentPortfolio } from '../../../../store/current/portfolioId'
import ContentTitle from '../../ContentTitle'
import DebtTestFields from '../../DebtTestFields'

const labels = {
  expectsPensionDeposits:
    'Forventer du at lave nye indbetalinger til denne pension i løbet af de næste 2 år?',
  investableAssets:
    'Hvor stor er din investerbare formue (kontanter, indestående i banken, og værdipapirer)?',
  expectedInvestment:
    'Hvor meget forventer du at investere igennem Norm Invest (i løbet af de første 12 måneder)?',
  debt: (
    <>
      Hvad er din samlede gæld? Du skal <strong>IKKE</strong> medregne boliggæld såsom
      realkreditlån, andelsboliglån eller andre boliglån, samt SU-lån. Gæld kan f.eks. være
      forbrugslån eller billån
    </>
  ),
}

const fields = Object.keys(labels)

const validationSchema = object().shape({
  investableAssets: number()
    .when('expectsPensionDeposits', {
      is: false,
      then: number(),
      otherwise: number().required(),
    })
    .safeInteger()
    .positive(),
  debt: number()
    .safeInteger()
    .min(0)
    .when(
      ['expectsPensionDeposits', 'investableAssets'],
      // @ts-expect-error TS(2345)
      (expectsPensionDepositsValue, investableAssetsValue, schema) => {
        if (expectsPensionDepositsValue !== true) return schema
        if (!investableAssetsValue) schema.required()

        return schema
          .required()
          .max(investableAssetsValue, 'Beløbet kan ikke overstige din investerbare formue')
      },
    ),
  expectedInvestment: number()
    .required()
    .safeInteger()
    .min(MINIMUM_PENSION_INVESTMENT)
    .when(
      ['expectsPensionDeposits', 'investableAssets'],
      // @ts-expect-error TS(2345)
      (expectsPensionDepositsValue, investableAssetsValue, schema) => {
        if (expectsPensionDepositsValue !== true) return schema
        if (!investableAssetsValue) return schema

        return schema.max(
          investableAssetsValue,
          'Beløbet kan ikke overstige din investerbare formue',
        )
      },
    ),
})

// TODO open automatically if validation errors in expected investment
const SuitabilitTestPage = (otherProps: object) => {
  const {
    setFieldValue,
    // @ts-expect-error TS(2339) FIXME: Property 'expectsPensionDeposits' does not exist o... Remove this comment to see the full error message
    values: { expectsPensionDeposits, expectedInvestment, debt, investableAssets },
  } = useFormikContext()
  const { migrationDepot } = useSelector(selectCurrentPortfolio)
  const initialDebt = useInitial(debt)
  const initialInvestableAssets = useInitial(investableAssets)
  const initialExpectedInvestment = useInitial(expectedInvestment)

  const handleChange = () => {
    setFieldValue('expectedInvestment', initialExpectedInvestment, false)
    setFieldValue('debt', initialDebt, false)
    setFieldValue('investableAssets', initialInvestableAssets, false)
  }

  let content
  if (migrationDepot) {
    content = (
      <>
        <ContentTitle title="Indbetaler du løbende til dit depot?" />
        <Form.FieldGroup name="expectsPensionDeposits">
          <Form.Field
            // @ts-expect-error TS(2739) FIXME: Type '{ ({ id, name, label, type, onChange, onValu... Remove this comment to see the full error message
            inputComponent={BooleanInput}
            type="switch"
            onCheckedChange={handleChange}
            asFormControl
          />
        </Form.FieldGroup>
        <CSSTransition classNames="slide-down" in={expectsPensionDeposits} timeout={500}>
          <div
            className={`slide-down ${
              expectsPensionDeposits ? 'slide-down-show' : 'slide-down-hide'
            }`}
          >
            <DebtTestFields />
          </div>
        </CSSTransition>
      </>
    )
  } else {
    content = (
      <>
        <ContentTitle title="Vi skal bruge lidt information om din økonomi" />
        <DebtTestFields />
      </>
    )
  }

  return (
    <Paginator.Form.Page
      fields={fields}
      labelDescriptions={labels}
      validationSchema={validationSchema}
      {...otherProps}
    >
      {content}
    </Paginator.Form.Page>
  )
}

export default SuitabilitTestPage
