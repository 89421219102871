import { getMonth, getYear, subMonths } from 'date-fns'
import isEmpty from 'lodash/isEmpty'
import React, { useState, useContext } from 'react'
import { Table } from 'react-bootstrap'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ChevronDown from 'react-feather/dist/icons/chevron-down'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ChevronUp from 'react-feather/dist/icons/chevron-up'

import formatDate from '../../utilities/formatDate'
import { numberToPercent } from '../../utilities/numberFormatter'
import Card from '../Card'
import Icon from '../Icon'
import Text from '../Text'

import HistoricalReturnContext from './HistoricalReturnContext'

const calculateReturn = (startNumber: any, endNumber: any) => endNumber / startNumber - 1

const ReturnTable = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'chartData' does not exist on type 'unkno... Remove this comment to see the full error message
  const { chartData: data } = useContext(HistoricalReturnContext)
  const [isToggled, setIsToggled] = useState(false)

  let dataForTable = []
  if (!isEmpty(data)) {
    const filteredData = data.filter(({ date }: any) => getMonth(date) === 0)

    dataForTable = filteredData
      .slice(0, filteredData.length - 1)
      // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
      .map((item, index) => ({
        value: calculateReturn(item.value, filteredData[index + 1].value),
        year: getYear(item.date),
      }))
      .reverse()

    const lastDataPoint = data[data.length - 1]
    const lastDataPointTestDate = subMonths(lastDataPoint.date, 1)

    // Add the current partial year, unless last data point is december (11)
    if (getMonth(lastDataPointTestDate) !== 11) {
      const lastJanuaryDataPoint = filteredData[filteredData.length - 1]
      const lastDataPointYear = getYear(lastDataPointTestDate)
      const lastDataPointMonth = formatDate(lastDataPointTestDate, 'MMM')
      const incompleteReturn = {
        value: calculateReturn(lastJanuaryDataPoint.value, lastDataPoint.value),
        year: (
          <>
            {lastDataPointYear} - jan. <span className="font-size-xs">t.o.m.</span>{' '}
            {lastDataPointMonth}
          </>
        ),
      }

      dataForTable.unshift(incompleteReturn)
    }
  }

  const handleToggle = (event: any) => {
    event.preventDefault()
    setIsToggled(!isToggled)
  }

  return (
    <>
      <Card.Title>Afkast pr. år</Card.Title>
      <Table bordered>
        <thead>
          <tr>
            <th>Periode</th>
            <th>Afkast</th>
          </tr>
        </thead>
        <tbody>
          {dataForTable.slice(0, 6).map((row: any) => (
            <tr key={row.year}>
              <td>{row.year}</td>
              <td>{numberToPercent(row.value, { fractionDigits: 1 })}</td>
            </tr>
          ))}
          {isToggled &&
            dataForTable.slice(6).map((row: any) => (
              <tr key={row.year}>
                <td>{row.year}</td>
                <td>{numberToPercent(row.value, { fractionDigits: 1 })}</td>
              </tr>
            ))}
        </tbody>
      </Table>
      <Text
        as="a"
        href="/"
        className="link-dark d-flex justify-content-center align-items-center"
        onClick={handleToggle}
      >
        {isToggled ? (
          <>
            Se færre år
            <Icon className="ml-1" icon={ChevronUp} />
          </>
        ) : (
          <>
            Se alle år
            <Icon className="ml-1" icon={ChevronDown} />
          </>
        )}
      </Text>
      <Text as="p" align="center" variant="secondary" className="pt-4">
        Det historiske afkast er baseret på NORDs modelporteføljer, der er opgjort inden
        investeringsomkosninger og i EUR. Der er altid omkostninger forbundet med investering, og
        disse omkostninger vil have en negativ påvirkning på det faktiske afkast. Tilsvarende vil
        udsving i valutakursen mellem DKK/EUR også påvirke afkastet positivt eller negativt, da de
        underliggende investeringer er i EUR.
      </Text>
    </>
  )
}

export default ReturnTable
