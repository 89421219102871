import { isObject, isArray, times, clamp } from 'lodash'

import { ROUND_FACTOR } from './generalSnapRatioToSteps'
import { calculateBounds, calculateStepRatiosFromStepsObject } from './utilities'

const SEQUENCE_OFFSET = 1 / (ROUND_FACTOR * 10)

const generalSnapRatioToBounds =
  ({ max, min, steps, step, snapToSteps }: any) =>
  (ratio: any) => {
    if (step === undefined && steps === undefined) return [ratio, ratio]

    let ratios
    let stepsCount
    if (steps === undefined || (step && !snapToSteps)) {
      const range = max - min
      stepsCount = range / step
    } else if (isArray(steps)) {
      stepsCount = steps.length - 1
    } else if (isObject(steps)) {
      ratios = calculateStepRatiosFromStepsObject(steps)
    }

    if (ratios === undefined) {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      const stepRatio = 1.0 / stepsCount
      ratios = times(
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        stepsCount + 1,
        (index) => Math.round(stepRatio * index * ROUND_FACTOR) / ROUND_FACTOR,
      )
    }

    return [
      calculateBounds(ratios, clamp(ratio - SEQUENCE_OFFSET, 0, 1))[0],
      calculateBounds(ratios, clamp(ratio + SEQUENCE_OFFSET, 0, 1))[1],
    ]
  }

export default generalSnapRatioToBounds
