import { startOfYear } from 'date-fns'

export const nordStartDate = startOfYear(new Date(2017, 1, 1))

export const chartMargins = {
  left: 25,
  top: 0,
  bottom: 0,
  right: 5,
}
