import React from 'react'

import Buttons from './Buttons'
import useButtonType from './useButtonType'

const buttonTypes = {
  signUp: {
    button: <Buttons.SignUp />,
  },
  signIn: {
    button: <Buttons.SignIn />,
  },
  startInvestmentPlan: { button: <Buttons.StartInvestmentPlan /> },
  startOnboarding: { button: <Buttons.StartOnboarding /> },
  updateRiskScore: { button: <Buttons.UpdateRiskScore /> },
}

const CallToActionButton = ({ ...otherProps }) => {
  const buttonType = useButtonType()

  if (!buttonType) return null

  const {
    [buttonType]: { button },
  } = buttonTypes

  return React.cloneElement(button, otherProps)
}

export default CallToActionButton
