import nextCount, { resetCount, currentCount } from './nextCount'

let globalPrefix = 'id__'

export const setPrefix = (newPrefix: any) => {
  globalPrefix = newPrefix
}

export const resetId = resetCount

export const idFromCount = (count: any, localPrefix: any) =>
  `${localPrefix || globalPrefix}${count}`

export const currentId = (localPrefix: any) => idFromCount(currentCount(), localPrefix)

const nextId = (localPrefix: any) => {
  const count = nextCount()

  return idFromCount(count, localPrefix)
}

export const getIdPrefix = () => globalPrefix

export default nextId
