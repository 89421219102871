import { getYear, getMonth } from 'date-fns'
import isEmpty from 'lodash/isEmpty'
import round from 'lodash/round'
import sortedUniq from 'lodash/sortedUniq'
import React, { useContext } from 'react'
import { AreaChart, XAxis, YAxis, Area } from 'recharts'

import styleConfiguration from '../../configuration/styleConfiguration'
import useBreakpointHandler from '../../hooks/useBreakpointHandler'
import { valueToDate } from '../../utilities/formatDate'
import Loader from '../Loader'

import { chartMargins } from './constants'
import HistoricalReturnContext from './HistoricalReturnContext'
import Legend from './Legend'
import Tooltip from './Tooltip'

const { themeColors } = styleConfiguration

const yAxisMinValueBuffer = 50
const yAxisMaxValueBuffer = 10

const generateYTicks = (data: any, brushStartValue: any) => {
  const yAxisTickCount = 5

  const valuesForYTicks = sortedUniq(
    data.map(({ value }: any) => round((value - brushStartValue) * 100), -1),
  )
  // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
  const minValueForYTicks = round(Math.min(...valuesForYTicks), -1) - yAxisMinValueBuffer
  // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
  const maxValueForYTicks = round(Math.max(...valuesForYTicks), -1) + yAxisMaxValueBuffer
  const intervalForYAxis = round(
    (Math.abs(minValueForYTicks) + Math.abs(maxValueForYTicks)) / (yAxisTickCount - 1),
    -1,
  )
  const initialYTicksArray = new Array(yAxisTickCount).fill(minValueForYTicks)

  const yTicks = initialYTicksArray.map(
    (value, index) => (index * intervalForYAxis + value) / 100 + brushStartValue,
  )

  return yTicks
}

const calculateReturnNumber = (startNumber: any, endNumber: any) =>
  (endNumber / startNumber - 1) * 100

export interface ChartProps {
  width?: number
  chartData?: {
    value: number
    timestamp: number
  }[]
  nordStartPoint?: number
  brushStartIndex?: number
  brushEndIndex?: number
  selectionActive: boolean
  children?: React.ReactNode
}

const Chart = ({ width, children, selectionActive, ...props }: ChartProps) => {
  // @ts-expect-error TS(2339) FIXME: Property 'chartData' does not exist on type 'unkno... Remove this comment to see the full error message
  const { chartData, brushStartIndex, brushEndIndex, nordStartPoint, loading } =
    useContext(HistoricalReturnContext)
  const mediumAndUp = useBreakpointHandler('md')

  let aspectRatio
  let xTicksSequence: any
  if (mediumAndUp) {
    aspectRatio = 2
    xTicksSequence = 3
  } else {
    aspectRatio = 1
    xTicksSequence = 4
  }

  let filteredData
  let xTicks
  let yTicks = []
  if (!isEmpty(chartData)) {
    filteredData = chartData.slice(brushStartIndex, brushEndIndex + 1)

    xTicks = filteredData
      .filter(({ date }: any) => getMonth(date) === 0)
      .filter((data: any, index: any) => {
        if (filteredData.length >= 150 && index % xTicksSequence === 0) return true

        if (filteredData.length < 150 && index % 2 === 0) return true

        if (filteredData.length < 70) return true

        return false
      })
      .map((data: any) => data.timestamp)

    const brushStartValue = chartData[brushStartIndex].value

    yTicks = generateYTicks(filteredData, brushStartValue)
  }

  return isEmpty(chartData) ? (
    <Loader className="d-flex justify-content-center my-5 font-size-xxl" />
  ) : (
    <div>
      {loading && <Loader className="font-size-xl text-secondary" centeredInParent />}
      <AreaChart
        data={chartData && chartData.slice(brushStartIndex, brushEndIndex + 1)}
        width={width}
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        height={width / aspectRatio}
        margin={chartMargins}
        {...props}
      >
        <defs>
          <linearGradient id="splitColor" fillOpacity="1">
            <stop offset={nordStartPoint} stopColor={themeColors.secondary} stopOpacity="1" />
            <stop offset={nordStartPoint} stopColor={themeColors.primary} stopOpacity="1" />
          </linearGradient>
          <pattern
            id="diagonalHatch"
            patternUnits="userSpaceOnUse"
            width="4"
            height="4"
            x="0"
            y="0"
          >
            <path
              d="M-1,3 l2,2 M0,0  l4,4 M3,-1 l2,2"
              stroke="#fff"
              strokeOpacity="0.5"
              strokeWidth="1"
            />
          </pattern>
          <mask id="patternMask" x="0" y="0" width="1" height="1">
            <rect x="0" y="0" width="100%" height="100%" fill="url('#diagonalHatch')" />
          </mask>
        </defs>
        <XAxis
          dataKey="timestamp"
          type="number"
          domain={['dataMin', 'dataMax']}
          ticks={xTicks}
          interval="preserveStart"
          // @ts-expect-error TS(2322) FIXME: Type '(timestamp: any) => number' is not assignabl... Remove this comment to see the full error message
          tickFormatter={(timestamp) => getYear(valueToDate(timestamp))}
          tickMargin={5}
        />
        <YAxis
          dataKey="value"
          type="number"
          orientation="right"
          width={40}
          tickMargin={5}
          interval="preserveStart"
          // @ts-expect-error TS(2322) FIXME: Type '(value: any) => number' is not assignable to... Remove this comment to see the full error message
          tickFormatter={(value) =>
            round(calculateReturnNumber(chartData[brushStartIndex].value, value), -1)
          }
          domain={['dataMin', 'dataMax']}
          ticks={yTicks}
        />
        <Tooltip
          hide={selectionActive}
          startValue={chartData && chartData[brushStartIndex].value}
        />
        <Legend />
        <Area
          type="monotone"
          dataKey="value"
          strokeWidth="2"
          dot={false}
          stroke="url('#splitColor')"
          fill="url('#splitColor')"
          mask="url('#patternMask')"
          isAnimationActive={false}
        />
        {children}
      </AreaChart>
    </div>
  )
}

Chart.defaultProps = {
  brushEndIndex: undefined,
  brushStartIndex: undefined,
  chartData: undefined,
  children: undefined,
  nordStartPoint: undefined,
  width: undefined,
}

export default Chart
