import React from 'react'

import Buttons from './Buttons'
import useButtonType from './useButtonType'

const buttonTypes = {
  retakeInvestmentPlan: {
    button: <Buttons.RetakeInvestmentPlan />,
  },
  updateRiskAdvice: {
    button: <Buttons.UpdateRiskAdvice />,
  },
}

const SecondaryCallToActionButton = () => {
  const buttonType = useButtonType()

  if (!buttonType) return null

  const {
    [buttonType]: { button },
  } = buttonTypes

  return button
}

export default SecondaryCallToActionButton
