import { object, string, number } from 'yup'

const occupationIsNotAJob = (value: any) =>
  value && ['Student', 'Retired', 'Unemployed'].includes(value)

const maxMonthlyIncome = 100000000
const validationSchema = object().shape({
  currentOccupation: string().required(),
  nameOfEmployee: string().when('currentOccupation', {
    is: (value: any) => occupationIsNotAJob(value),
    then: string(),
    otherwise: string().required(),
  }),
  jobTitle: string().when('currentOccupation', {
    is: (value: any) => occupationIsNotAJob(value),
    then: string(),
    otherwise: string().required(),
  }),
  jobIndustry: string().when('currentOccupation', {
    is: (value: any) => occupationIsNotAJob(value),
    then: string(),
    otherwise: string().required(),
  }),
  monthlyIncome: number().min(0).max(maxMonthlyIncome).required(),
  yearlyIncome: number().min(0).required(),
})

export default validationSchema
