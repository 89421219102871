import ForgotPasswordForm from '@nord/ui/src/components/ForgotPasswordForm'
import React from 'react'

import ContentContainer from '../../ContentContainer'

const ForgotPasswordPage = () => (
  <ContentContainer>
    <ForgotPasswordForm />
  </ContentContainer>
)

export default ForgotPasswordPage
