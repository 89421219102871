import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import useQuery from '@nord/ui/src/hooks/useQuery'
import { selectCurrentUser } from '@nord/ui/src/store/current/user'

const useKreditDataId = () => {
  const queryKreditdataId = useQuery('kreditdataId')
  // @ts-expect-error TS(2339) FIXME: Property 'kreditdataId' does not exist on type 'un... Remove this comment to see the full error message
  const { kreditdataId: storedKreditdataId, kreditdataHasPensionsInfo } =
    useObjectSelector(selectCurrentUser)

  const kreditDataId = (kreditdataHasPensionsInfo && storedKreditdataId) || queryKreditdataId

  return kreditDataId
}

export default useKreditDataId
