import React from 'react'

export interface Props {
  children?: React.ReactNode
}

const Container = ({ children, ...otherProps }: Props) => <div {...otherProps}>{children}</div>

Container.defaultProps = {
  children: undefined,
}

export default Container
