import isEmpty from 'lodash/isEmpty'
import React, { useContext } from 'react'
import { useResizeDetector } from 'react-resize-detector'
import { AreaChart, Brush, YAxis, Area } from 'recharts'

import styleConfiguration from '../../configuration/styleConfiguration'
import useBreakpointHandler from '../../hooks/useBreakpointHandler'
import formatDate from '../../utilities/formatDate'
import Text from '../Text'

import styles from './ChartBrush.module.scss'
import { chartMargins } from './constants'
import HistoricalReturnContext from './HistoricalReturnContext'

const { themeColors } = styleConfiguration

const ChartBrush = ({ ...props }) => {
  const {
    // @ts-expect-error TS(2339) FIXME: Property 'chartData' does not exist on type 'unkno... Remove this comment to see the full error message
    chartData,
    // @ts-expect-error TS(2339) FIXME: Property 'brushStartIndex' does not exist on type ... Remove this comment to see the full error message
    brushStartIndex,
    // @ts-expect-error TS(2339) FIXME: Property 'brushEndIndex' does not exist on type 'u... Remove this comment to see the full error message
    brushEndIndex,
    // @ts-expect-error TS(2339) FIXME: Property 'nordStartIndex' does not exist on type '... Remove this comment to see the full error message
    nordStartIndex,
    // @ts-expect-error TS(2339) FIXME: Property 'onBrushStartIndexChange' does not exist ... Remove this comment to see the full error message
    onBrushStartIndexChange,
    // @ts-expect-error TS(2339) FIXME: Property 'onBrushEndIndexChange' does not exist on... Remove this comment to see the full error message
    onBrushEndIndexChange,
  } = useContext(HistoricalReturnContext)

  const { width, ref: resizeDetectorRef } = useResizeDetector({
    handleHeight: false,
    refreshRate: 100,
    refreshMode: 'debounce',
  })

  const mediumAndUp = useBreakpointHandler('md')
  const brushHandleWidth = mediumAndUp ? 7.5 : 12.5

  const handleBrushMove = (event: any) => {
    const { endIndex, startIndex } = event

    onBrushStartIndexChange(startIndex)
    onBrushEndIndexChange(endIndex)
  }

  const nordStartPoint = isEmpty(chartData) ? undefined : nordStartIndex / chartData.length

  return (
    <div>
      <div ref={resizeDetectorRef} className={styles.brushWrapper}>
        <AreaChart width={width} height={70} data={chartData} margin={chartMargins} {...props}>
          <defs>
            <linearGradient id="brushSplitColor" fillOpacity="1">
              <stop offset={nordStartPoint} stopColor={themeColors.secondary} stopOpacity="1" />
              <stop offset={nordStartPoint} stopColor={themeColors.primary} stopOpacity="1" />
            </linearGradient>
            <pattern
              id="brushDiagonalHatch"
              patternUnits="userSpaceOnUse"
              width="4"
              height="4"
              x="0"
              y="0"
            >
              <path
                d="M-1,3 l2,2 M0,0  l4,4 M3,-1 l2,2"
                stroke="#fff"
                strokeOpacity="0.5"
                strokeWidth="1"
              />
            </pattern>
            <mask id="brushPatternMask" x="0" y="0" width="1" height="1">
              <rect x="0" y="0" width="100%" height="100%" fill="url('#brushDiagonalHatch')" />
            </mask>
          </defs>
          <Area
            type="monotone"
            dataKey="value"
            strokeWidth="1"
            dot={false}
            stroke="url('#brushSplitColor')"
            fill="url('#brushSplitColor')"
            mask="url('#brushPatternMask')"
            isAnimationActive={false}
          />
          <YAxis
            style={{ display: 'none' }}
            domain={['dataMin', 'dataMax']}
            ticks={[0, 3]}
            orientation="right"
            width={40}
          />
        </AreaChart>
        <div className={styles.brush}>
          <AreaChart width={width} height={70} data={chartData} margin={chartMargins} {...props}>
            <YAxis domain={['auto', 'auto']} orientation="right" width={40} />
            <Brush
              startIndex={brushStartIndex}
              endIndex={brushEndIndex}
              dataKey="timestamp"
              height={70}
              fill="rgba(0, 0, 0, 0)"
              leaveTimeOut={0}
              travellerWidth={brushHandleWidth}
              tickFormatter={(timestamp) => formatDate(timestamp, 'MMM yyy')}
              onChange={handleBrushMove}
            />
          </AreaChart>
        </div>
      </div>
      <Text as="p" align="center" className="mt-2">
        Klik og træk ovenfor, for at zoome ind og ud på grafen.
      </Text>
      <small>Kilde: DWS Group GmbH og Blackrock, Inc.</small>
    </div>
  )
}

export default ChartBrush
