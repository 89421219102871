import ReferralBanner from './ReferralBanner'
import ReferralContext from './ReferralContext'
import ReferralPage from './ReferralPage'
import ReferralWrapper from './ReferralWrapper'
import TermsModal from './TermsModal'

const Referral = {
  Banner: ReferralBanner,
  Context: ReferralContext,
  Page: ReferralPage,
  TermsModal,
  Wrapper: ReferralWrapper,
}

export default Referral
