import AlertModal from '@nord/ui/src/components/AlertModal'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useHistory } from 'react-router-dom'

import { PATHS } from '../../configuration/constants'
import useFlow from '../../hooks/useFlow'
import { changeUserAccountType } from '../../store/current/user'

import accountTypeTextMap from './accountTypeTextMap'

const ChangeAccountTypeButton = ({ ...otherProps }) => {
  const dispatch = useDispatch()
  const flow = useFlow()
  const history = useHistory()
  const [showWarning, setShowWarning] = useState(false)

  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const accountTypeTexts = accountTypeTextMap[flow]
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const otherAccountTypeTexts = accountTypeTextMap[accountTypeTexts.other]

  const handleChangeAccountType = async () => {
    const newAccountType = otherAccountTypeTexts.accountType
    // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
    await dispatch(changeUserAccountType(newAccountType))

    if (newAccountType === 'company_account') history.push(PATHS.company.root())
    else history.push(PATHS.shared.root())
  }

  const handleShowWarning = (event: any) => {
    event.preventDefault()
    setShowWarning(true)
  }

  const handleRejectWarning = () => setShowWarning(false)

  return (
    <>
      <div {...otherProps}>
        <div>Ville du hellere oprette en anden type depot?</div>
        <div>
          Skift til{' '}
          {flow === 'private' && (
            <>
              {/* @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0. */}
              <a href={PATHS.pension.investmentPlan()}>pensionsdepot</a> eller{' '}
            </>
          )}
          {flow === 'pension' && (
            <>
              {/* @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0. */}
              <a href={PATHS.private.investmentPlan()}>frie midler depot</a> eller{' '}
            </>
          )}
          <a onClick={handleShowWarning} href={PATHS.company.root()}>
            {otherAccountTypeTexts.onboardingText}
          </a>
          .
        </div>
      </div>

      <AlertModal
        show={showWarning}
        title="Skift af kontotype"
        onHide={handleRejectWarning}
        onAccept={handleChangeAccountType}
        acceptButtonText="Jeg ønsker at skifte"
        acceptable
      >
        <p>
          Du er ved at skifte fra en <strong>{accountTypeTexts.accountText}</strong> til en{' '}
          <strong>{otherAccountTypeTexts.accountText}</strong>. Dette kræver at du laver en ny
          investeringsplan. Hvis du i stedet beholder din {accountTypeTexts.accountText} oprettelse,
          vil det ikke længere være muligt at skifte.
        </p>
        <p>Vil du starte en {otherAccountTypeTexts.onboardingText}?</p>
      </AlertModal>
    </>
  )
}

export default ChangeAccountTypeButton
