import React from 'react'

import Section from './Section'

const NordWealth = ({ ...otherProps }) => (
  // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; title: string; id: st... Remove this comment to see the full error message
  <Section title="Du er kvalificeret til Norm Wealth" id="nord-wealth" {...otherProps}>
    <p className="text-center">
      Til dig med en formue på over 2 mio kr. Med Norm Wealth får du endnu lavere omkostninger og en
      dedikeret personlig kontaktperson, der prioriterer dig og dine spørgsmål. Private Banking uden
      de høje omkostninger.
    </p>
    {/* eslint-disable react/jsx-no-target-blank */}
    <a
      href={`${process.env.REACT_APP_WEBSITE_DOMAIN}/wealth`}
      target="_blank"
      className="d-block text-center"
    >
      Læs mere om dine fordele med Norm Wealth
    </a>
  </Section>
)

export default NordWealth
