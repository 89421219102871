import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'

import chainEventHandler from '../utilities/chainEventHandler'

import StrengthChecker, { defaultUserInputs } from './StrengthChecker'

const withStrengthChecker = (WrappedComponent: any) => {
  const WrapperComponent = ({ onBlur, onFocus, onChange, userInputs, ...otherProps }: any) => {
    const inputRef = useRef(null)
    const [value, setValue] = useState('')
    const [showStrengthChecker, setShowStrengthChecker] = useState(false)

    const handleBlur = chainEventHandler(() => setShowStrengthChecker(false), onBlur)
    const handleFocus = chainEventHandler(() => setShowStrengthChecker(true), onFocus)
    const handleChange = chainEventHandler((event: any) => {
      const { value: newValue } = event.target

      setValue(newValue)
    }, onChange)

    return (
      <>
        <StrengthChecker
          target={inputRef.current}
          show={showStrengthChecker}
          userInputs={userInputs}
          password={value}
        />
        <WrappedComponent
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          ref={inputRef}
          value={value}
          {...otherProps}
        />
      </>
    )
  }

  WrapperComponent.displayName = `withStrengthChecker(${
    WrappedComponent.displayName || WrappedComponent.name
  })`

  WrapperComponent.propTypes = {
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    userInputs: PropTypes.arrayOf(PropTypes.string),
  }

  WrapperComponent.defaultProps = {
    onBlur: undefined,
    onChange: undefined,
    onFocus: undefined,
    userInputs: defaultUserInputs,
  }

  return WrapperComponent
}

export default withStrengthChecker
