import Paginator from '@nord/ui/src/components/Paginator'
import QuestionsSection from '@nord/ui/src/components/QuestionsSection'
import useApi, { useApiRequest } from '@nord/ui/src/hooks/useApi'
import React, { useCallback } from 'react'

import useFlow from '../../../../hooks/useFlow'
import LoadingIcon from '../../../LoadingIcon'

export interface Props {
  onComplete: (...args: any[]) => any
}

const QuestionnaireSection = ({ onComplete, ...otherProps }: Props) => {
  const flow = useFlow()
  const getInvestmentPlanQuestions = useApi('/investment_profiles')

  const { data } = useApiRequest(getInvestmentPlanQuestions, {
    payload: {
      type: flow,
    },
    autoCall: true,
  })

  const { version, data: questions, type } = data || {}
  const handleAnswered = useCallback(
    (newAnswers: any) => {
      onComplete(newAnswers, version, type)
    },
    [onComplete, type, version],
  )

  // @ts-expect-error TS(2741) FIXME: Property 'children' is missing in type '{}' but re... Remove this comment to see the full error message
  if (!questions) return <LoadingIcon />

  return (
    <QuestionsSection
      questions={questions}
      onAnswered={handleAnswered}
      // @ts-expect-error TS(2739) FIXME: Type '{ ({ onPageChange, useAbsolute, history, use... Remove this comment to see the full error message
      paginatorComponent={Paginator.Nested.PagesWrapper.WithQueryParam}
      useAbsolute
      {...otherProps}
    />
  )
}

export default QuestionnaireSection
