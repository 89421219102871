import React from 'react'

import Apple from './Apple'
import Google from './Google'
import styles from './index.module.scss'

const AppButton = (props: any) => (
  <div className={styles.appIcons}>
    <Apple {...props} />
    <Google {...props} />
  </div>
)

AppButton.Apple = Apple
AppButton.Google = Google

export default AppButton
