import useApi, { useApiClient } from '@nord/ui/src/hooks/useApi'
import isArray from 'lodash/isArray'
import isPlainObject from 'lodash/isPlainObject'
import { useCallback } from 'react'

const formatSignedUrlParams = ({ file, path, fileName, fileExtension, contentType }: any) => {
  let finalName
  if (fileName) {
    const finalExtension = fileExtension || file.name.split('.').pop() || file.type.split('/').pop()

    finalName = `${fileName}.${finalExtension}`
  } else {
    finalName = file.name
  }

  return {
    path,
    name: finalName,
    // eslint-disable-next-line camelcase
    content_type: contentType || file.type,
  }
}

const useGetSignedUrl = () => {
  const getSignedUrl = useApi('/uploads/sign')

  return useCallback(
    async ({ file, path, fileName, fileExtension, contentType }: any) => {
      const params = formatSignedUrlParams({
        file,
        path,
        fileName,
        fileExtension,
        contentType,
      })
      const { data } = await getSignedUrl(params)

      return {
        signedUrl: data.signedUrl,
        name: `${path}/${params.name}`,
      }
    },
    [getSignedUrl],
  )
}

const useUploadToSignedUrl = () => {
  // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
  const client = useApiClient()

  return useCallback(
    ({ signedUrl, file, options = {} }: any) => {
      Object.assign(options, {
        headers: {
          'Content-Type': file.type,
          'x-amz-acl': 'private',
        },
      })

      return client.put(signedUrl, file, options)
    },
    [client],
  )
}

export const useUploadFile = (): ((arg0: unknown) => void) => {
  const getSignedUrl = useGetSignedUrl()
  const uploadToSignedUrl = useUploadToSignedUrl()

  return useCallback(
    async ({ file, path, fileName, fileExtension, contentType, options }: any) => {
      const { signedUrl } = await getSignedUrl({
        file,
        path,
        fileName,
        fileExtension,
        contentType,
      })

      return uploadToSignedUrl({ signedUrl, file, options })
    },
    [getSignedUrl, uploadToSignedUrl],
  )
}

export const useUploadDocuments = () => {
  const storeFile = useApi('/uploads/store', { method: 'POST' })

  return useCallback(
    async ({ documents, email, onUpload, uploadedFiles, directory }: any) => {
      if (isPlainObject(documents)) documents = Object.values(documents)
      if (!isArray(documents) || documents.length <= 0) return undefined

      const requests = documents
        .filter((documentFile) => !uploadedFiles.includes(documentFile))
        .map((documentFile) =>
          storeFile({
            name: `${email}/temp/${documentFile.name}`,
            path: `/${email}/${directory}`,
          }),
        )

      const documentFiles = await Promise.all(requests)
      documentFiles.forEach((documentFile) => {
        onUpload(documentFile)
      })

      return documentFiles
    },
    [storeFile],
  )
}

export const useUploadSourceOfFunds = () => {
  const uploadDocuments = useUploadDocuments()

  return useCallback(
    ({ sourceOfFunds, email, onUpload, uploadedFiles }: any) => {
      uploadDocuments({
        documents: sourceOfFunds,
        directory: process.env.REACT_APP_SOURCE_OF_FUNDS_FOLDER,
        email,
        onUpload,
        uploadedFiles,
      })
    },
    [uploadDocuments],
  )
}

export const useUploadOtherDocumentation = () => {
  const uploadDocuments = useUploadDocuments()

  return useCallback(
    ({ documentation, uploadedFiles, onUpload, email }: any) => {
      uploadDocuments({
        documents: documentation,
        directory: process.env.REACT_APP_DOCUMENTATION_FOLDER,
        uploadedFiles,
        onUpload,
        email,
      })
    },
    [uploadDocuments],
  )
}
