import BooleanInput from '@nord/ui/src/components/BooleanInput'
import LoadingButton from '@nord/ui/src/components/LoadingButton'
import useApi, { useApiRequest } from '@nord/ui/src/hooks/useApi'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import {
  selectCurrentPortfolio,
  selectCurrentPortfolioId,
} from '@nord/ui/src/store/current/portfolioId'
import { numberToPercent, numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import React, { useContext, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'

import LoadingIcon from '../../../../../LoadingIcon'
import ResultPageContext from '../ResultPageContext'

export interface Props {
  show: boolean
  onHide: (...args: any[]) => any
}

const UpdateRiskScoreModal = ({ show, onHide }: Props) => {
  // @ts-expect-error TS(2339) FIXME: Property 'advisedRiskScore' does not exist on type... Remove this comment to see the full error message
  const { advisedRiskScore } = useObjectSelector(selectCurrentPortfolio)

  const portfolioId = useSelector(selectCurrentPortfolioId)
  // @ts-expect-error TS(2339) FIXME: Property 'riskScore' does not exist on type 'unkno... Remove this comment to see the full error message
  const { riskScore, esg, onAcceptUpdateRiskScore, isSavingRiskScore } =
    useContext(ResultPageContext)

  const [updateAccepted, setUpdateAccepted] = useState(false)
  const getRiskScoreChangeCost = useApi(
    `/onboarding/${portfolioId}/portfolios/change_risk_score_price`,
    {
      withCredentials: true,
    },
  )

  const { data: riskScoreChangePrice, loading } = useApiRequest(getRiskScoreChangeCost, {
    payload: {
      riskScore,
      esg,
    },
    autoCall: show,
  })

  const handleToggleAccept = () => setUpdateAccepted(!updateAccepted)

  const handleExited = () => setUpdateAccepted(false)

  return (
    <Modal
      className="text-dark"
      size="lg"
      onHide={onHide}
      onExited={handleExited}
      show={show}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="w-100 text-center" data-cy="modal-title">
          Ændr din risikoprofil
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="text-center">
        {riskScoreChangePrice && !loading ? (
          <>
            <p>
              {`Du er ved at ændre din risikoprofil fra ${riskScoreChangePrice.riskScoreFrom} til ${riskScoreChangePrice.riskScoreTo}.`}
            </p>
            <p>
              Dette indebærer nogle omkostninger til henholdsvis salg og køb af værdipapirer på
              imellem{' '}
              <strong>
                {`${numberToCurrency(riskScoreChangePrice.priceLow)} og ${numberToCurrency(
                  riskScoreChangePrice.priceHigh,
                )}`}
              </strong>
              , svarende til{' '}
              {numberToPercent(riskScoreChangePrice.costPercentage, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}{' '}
              af din porteføljes værdi.
            </p>
            {advisedRiskScore !== riskScore && (
              <p>
                Vær også opmærksom på, at du har valgt en anden risikoprofil end den, som vi
                anbefaler. Hvis du ønsker at acceptere skal du være opmærksom på, at du kan blive
                eksponeret for en større eller mindre risiko end det som dine forhold ligger op til.
              </p>
            )}
            <BooleanInput
              value={updateAccepted}
              onCheckedChange={handleToggleAccept}
              type="switch"
              label={<strong>Jeg bekræfter at jeg vil skifte risikoprofil.</strong>}
              id="risk-score-update-accepted"
              name="riskScoreUpdateAccepted"
            />
          </>
        ) : (
          // @ts-expect-error TS(2741) FIXME: Property 'children' is missing in type '{}' but re... Remove this comment to see the full error message
          <LoadingIcon />
        )}
      </Modal.Body>
      {riskScoreChangePrice && !loading && (
        <Modal.Footer className="justify-content-center">
          <Button variant="link" onClick={onHide}>
            Tilbage
          </Button>

          <LoadingButton
            // @ts-expect-error TS(2322) FIXME: Type '{ children: string; variant: string; onClick... Remove this comment to see the full error message
            variant="primary"
            onClick={onAcceptUpdateRiskScore}
            disabled={!updateAccepted}
            loading={isSavingRiskScore}
          >
            Opdater min risikoprofil
          </LoadingButton>
        </Modal.Footer>
      )}
    </Modal>
  )
}

export default UpdateRiskScoreModal
