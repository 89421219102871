import Card from '@nord/ui/src/components/Card'
import styleConfiguration from '@nord/ui/src/configuration/styleConfiguration'
import useApi from '@nord/ui/src/hooks/useApi'
import useBreakpointHandler from '@nord/ui/src/hooks/useBreakpointHandler'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import pick from 'lodash/pick'
import range from 'lodash/range'
import uniq from 'lodash/uniq'
import React, { useState, useMemo, useEffect } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import {
  AreaChart,
  XAxis,
  YAxis,
  Legend,
  Area,
  ReferenceLine,
  ReferenceArea,
  Tooltip,
  Label,
} from 'recharts'

import { selectCurrentUser } from '../../../../store/current/user'
import ResponsiveContainer from '../../../ResponsiveContainer'
import Section from '../../Shared/InvestmentPlan/ResultPage/Section'

const colors = Object.values(styleConfiguration.blues)
const successColor = styleConfiguration.themeColors.success
const primaryColor = styleConfiguration.themeColors.primary

const LABELS = {
  stocks: 'Aktier',
  bonds: 'Obligationer',
}

const filterStepEntries = (chartData: any) => {
  if (chartData.length === 0) return []

  const firstEntry = chartData[0]
  const firstStepIndex = chartData.findIndex(
    (dataEntry: any) => dataEntry.riskScore !== firstEntry.riskScore,
  )
  const lastStepIndex = chartData.findIndex((dataEntry: any) => dataEntry.riskScore === 1)

  const stepSize = (lastStepIndex - firstStepIndex) / (firstEntry.riskScore - 2)
  const indices = [
    firstStepIndex - stepSize,
    ...range(firstStepIndex, lastStepIndex, stepSize),
    lastStepIndex,
  ]
  const filteredData = chartData.filter((dataEntry: any, index: any) => indices.includes(index))

  return filteredData
}

const getCurrentDataEntry = ({ chartData, yearsToPension }: any) => {
  if (chartData.length === 0) return {}
  if (!yearsToPension) return {}

  return chartData.find((dataEntry: any) => dataEntry.yearsToPension === yearsToPension)
}

const getSelectedDataEntry = ({ chartData, riskScore }: any) => {
  if (chartData.length === 0) return {}

  const firstSelectedDataEntry = chartData.find(
    (dataEntry: any) => dataEntry.riskScore === riskScore,
  )

  const middleDataEntry = chartData[Math.floor(chartData.length / 2)]

  return {
    ...firstSelectedDataEntry,
    ...pick(middleDataEntry, ['age', 'year', 'yearsToPension']),
  }
}

const calculateXTicks = ({ stepDataEntries, currentEntry }: any) => {
  if (!currentEntry || Object.keys(currentEntry).length === 0) return []

  const stepTicks = stepDataEntries.map((dataEntry: any) => dataEntry.yearsToPension)

  const xTicks = [currentEntry.yearsToPension, ...stepTicks]

  return xTicks.sort((a, b) => a - b).reverse()
}

const calculateYTicks = (chartData: any) => [
  ...uniq(chartData.map((dataEntry: any) => dataEntry.stocks)),
  0,
]

const formatPercentage = (value: any) => `${(value * 100).toFixed()} %`
const formatYear = (value: any) => value.toLocaleString('da')

export interface CustomTooltipProps {
  active: boolean
  payload: {
    payload?: {
      age: number
      year: number
      riskScore: number
    }
  }[]
  personalized: boolean
  label: number
}

const CustomTooltip = ({
  active,
  payload,
  personalized,
  label: yearsToPension,
}: CustomTooltipProps) => {
  if (active && payload) {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'age' does not exist on type '{ age: numb... Remove this comment to see the full error message
      payload: { age, year, riskScore },
    } = payload[0]

    let labelText
    if (yearsToPension > 0) {
      labelText = `${formatYear(yearsToPension)} år til pension`
    } else if (yearsToPension === 0) {
      labelText = `Start på pension`
    } else {
      labelText = `${formatYear(yearsToPension)} år siden pension`
    }

    return (
      <Card>
        <Card.Body>
          <div className="text-center">
            <div>
              {personalized && (
                <>
                  <div>{Math.ceil(year)}</div>
                  <div>Alder {Math.ceil(age)} år</div>
                </>
              )}
              <div>{labelText}</div>
            </div>
            <div className="mt-3">
              <div>Risikoprofil: P{riskScore}</div>
              {/* @ts-expect-error TS(2339) FIXME: Property 'color' does not exist on type '{ payload... Remove this comment to see the full error message */}
              {payload.map(({ color, dataKey, value }) => (
                <div style={{ color }} key={dataKey}>
                  {/* @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
                  {LABELS[dataKey]}: {formatPercentage(value)}
                </div>
              ))}
            </div>
          </div>
        </Card.Body>
      </Card>
    )
  }

  return null
}

const renderReferenceArea = ({
  dataEntry: { riskScore, yearsToPension, stocks },
  riskScore: currentRiskScore,
}: any) => (
  <ReferenceArea
    key={riskScore}
    x1={yearsToPension - 5}
    x2={yearsToPension}
    y1={stocks}
    y2={stocks - 0.1}
    fill="none"
    stroke="none"
  >
    <Label
      value={riskScore}
      formatter={(value: any) => `P${value}`}
      fill={currentRiskScore === riskScore ? primaryColor : undefined}
      position="top"
    />
  </ReferenceArea>
)

export interface EscalationProps {
  yearsToPension?: number
  depotType: string
  riskScore: number
  advisedRiskScore?: number
  chosen?: boolean
  personalized?: boolean
}

const Escalation = ({
  yearsToPension,
  depotType,
  riskScore,
  advisedRiskScore,
  chosen,
  personalized,
}: EscalationProps) => {
  // @ts-expect-error TS(2339) FIXME: Property 'birthdate' does not exist on type 'unkno... Remove this comment to see the full error message
  const { birthdate } = useObjectSelector(selectCurrentUser)
  const [chartData, setChartData] = useState([])
  const desktop = useBreakpointHandler('md')
  const currentEntry = useMemo(
    () => getCurrentDataEntry({ chartData, yearsToPension }),
    [chartData, yearsToPension],
  )
  const selectedEntry = chosen ? undefined : getSelectedDataEntry({ chartData, riskScore })
  const escalationData = useMemo(() => {
    if (chosen) return chartData

    return chartData.map((dataEntry) => ({
      // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      ...dataEntry,
      ...pick(selectedEntry, ['stocks', 'bonds', 'riskScore']),
    }))
  }, [chartData, chosen, selectedEntry])
  const yTicks = useMemo(() => calculateYTicks(chartData), [chartData])
  const stepDataEntries = useMemo(() => filterStepEntries(chartData), [chartData])
  const xTicks = useMemo(
    () => calculateXTicks({ stepDataEntries, currentEntry }),
    [stepDataEntries, currentEntry],
  )
  const getEscalationData = useApi('/risk_profile/pension_escalation_data')
  const lastEntry = stepDataEntries[stepDataEntries.length - 1]

  const firstEntry = stepDataEntries[0]
  const firstStepEntry = chosen
    ? stepDataEntries.find((dataEntry: any) => dataEntry.riskScore === firstEntry.riskScore - 1)
    : undefined

  useEffect(() => {
    if (!depotType || depotType === 'frie_midler') return

    const fetchData = async () => {
      const { data } = await getEscalationData({ depotType, birthdate })

      setChartData(data)
    }

    fetchData()
  }, [depotType, birthdate, getEscalationData])

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; title: string; id: st... Remove this comment to see the full error message
    <Section title="Gradvis nedtrapning" id="nedtrapning">
      <Row>
        <Col>
          {chosen ? (
            <>
              <p>
                Vi anbefaler at du sænker din risiko, jo nærmere du kommer din pensionsalder. På den
                måde sikres du den rigtige fordeling mellem stabile og risikofyldte investeringer, i
                forhold til hvornår du går på pension.
              </p>
              {personalized && (
                <p>
                  Baseret på din nuværende alder og din forventede folkepensionsalder anbefaler vi
                  denne nedtrapning af din pensionporteføljes risiko.
                </p>
              )}
              {currentEntry && lastEntry && (
                <p>
                  {personalized && (
                    <>
                      Vi anbefaler du starter med at have risikoprofil P{advisedRiskScore}, hvilken
                      består af {formatPercentage(currentEntry.stocks)} aktier.{' '}
                    </>
                  )}
                  Fra {firstStepEntry && Math.ceil(firstStepEntry.yearsToPension)} år før din
                  pension, sænkes din risiko hvert 5. år, indtil du når P1, som består af{' '}
                  {formatPercentage(lastEntry.stocks)} aktier og {formatPercentage(lastEntry.bonds)}{' '}
                  obligationer.
                </p>
              )}
            </>
          ) : (
            <p className="text-center">
              Ved at vælge en anden risikoprofil, end vores anbefaling, vil din portefølje ikke
              blive nedtrappet automatisk.
            </p>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <ResponsiveContainer>
            {/* @ts-expect-error TS(2322) FIXME: Type '({ width }: any) => JSX.Element' is not assi... Remove this comment to see the full error message */}
            {({ width }: any) => {
              const height = desktop ? width / 2 : Math.round((width / 3) * 2)

              return (
                <AreaChart
                  data={escalationData}
                  width={width}
                  height={height}
                  stackOffset="expand"
                  margin={{
                    top: 0,
                    right: 0,
                    bottom: 20,
                    left: desktop ? 20 : 0,
                  }}
                >
                  <Legend
                    verticalAlign="top"
                    iconType="circle"
                    height={50}
                    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    formatter={(dataKey) => LABELS[dataKey]}
                  />
                  <XAxis
                    dataKey="yearsToPension"
                    type="number"
                    scale="linear"
                    xAxisId={0}
                    ticks={xTicks}
                    tickFormatter={formatYear}
                    reversed
                  >
                    <Label value="År til forventet pensionsalder" offset={0} position="bottom" />
                  </XAxis>
                  {/* @ts-expect-error TS(2322) FIXME: Type 'unknown[]' is not assignable to type '(strin... Remove this comment to see the full error message */}
                  <YAxis tickFormatter={formatPercentage} ticks={yTicks}>
                    {desktop && (
                      <Label value="Aktier i %" offset={0} angle={-90} position="insideLeft" />
                    )}
                  </YAxis>
                  {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
                  <Tooltip content={CustomTooltip} personalized={personalized} />
                  <Area
                    dataKey="stocks"
                    type="stepAfter"
                    stackId="1"
                    stroke={colors[0]}
                    fill={colors[0]}
                    fillOpacity={1}
                  />
                  <Area
                    dataKey="bonds"
                    type="stepAfter"
                    stackId="1"
                    stroke={colors[4]}
                    fill={colors[4]}
                    fillOpacity={1}
                  />
                  {personalized && (
                    <ReferenceLine x={yearsToPension} stroke={successColor} strokeWidth={2} isFront>
                      <Label value="Nu" position="top" />
                    </ReferenceLine>
                  )}
                  {chosen
                    ? stepDataEntries.map((dataEntry: any) =>
                        renderReferenceArea({ dataEntry, riskScore }),
                      )
                    : renderReferenceArea({
                        dataEntry: selectedEntry,
                        riskScore,
                      })}
                </AreaChart>
              )
            }}
          </ResponsiveContainer>
        </Col>
      </Row>
    </Section>
  )
}

Escalation.defaultProps = {
  advisedRiskScore: undefined,
  chosen: undefined,
  personalized: undefined,
  yearsToPension: undefined,
}

export default Escalation
