import Paginator from '@nord/ui/src/components/Paginator'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import ContentTitle from '../../../ContentTitle'

import labels, { fields } from './labels'
import MonthlyIncomeField from './MonthlyIncomeField'
import OccupationFields from './OccupationFields'
import validationSchema from './validationSchema'

const IncomePage = ({ ...otherProps }) => (
  <Paginator.Form.Page
    validationSchema={validationSchema}
    labelDescriptions={labels}
    fields={fields}
    {...otherProps}
  >
    <ContentTitle title="Vi skal bruge lidt mere information om dig" />
    <OccupationFields />
    <Row>
      <Col xs={12}>
        <MonthlyIncomeField />
      </Col>
    </Row>
  </Paginator.Form.Page>
)

export default IncomePage
