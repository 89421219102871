import Loader from '@nord/ui/src/components/Loader'
import type { url as urlPropType } from '@nord/ui/src/utilities/propTypes'
import classNames from 'classnames'
import React, { useState } from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'

import styles from './PDFViewer.module.scss'

export interface Props {
  // @ts-expect-error TS(2749) FIXME: 'urlPropType' refers to a value, but is being used... Remove this comment to see the full error message
  src: urlPropType
  height?: number
  width?: number
}

const PDFViewer = ({ src, height, width }: Props) => {
  const [pdfPagesLength, setPdfPagesLength] = useState()

  const handleDocumentLoadSuccess = ({ numPages }: any) => setPdfPagesLength(numPages)

  return (
    <div className={classNames(styles.wrapper, 'bg-secondary')} style={{ height }}>
      <Document
        file={src}
        onLoadSuccess={handleDocumentLoadSuccess}
        loading={<Loader className="text-white font-size-xxl" centeredInParent />}
        error="Kunne ikke indlæse dokumentet. Genindlæs siden og prøv igen."
      >
        {Array.from(new Array(pdfPagesLength), (_el, index) => (
          <Page
            width={width}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            renderAnnotationLayer={false}
            className={styles.page}
          />
        ))}
      </Document>
    </div>
  )
}

PDFViewer.defaultProps = {
  height: undefined,
  width: undefined,
}

export default PDFViewer
