import { numberToPercent } from '@nord/ui/src/utilities/numberFormatter'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import colors from '../colors'

export interface DotProps {
  fill: string
  width: string
  height: string
}

const Dot = ({ width, height, fill }: DotProps) => (
  <svg viewBox="0 0 100 100" height={height} width={width}>
    <circle cx="50" cy="50" r="50" fill={fill} />
  </svg>
)

export interface LegendItemProps {
  index: number
  name: string
  value: number
  onCurrentHoverChange: (...args: any[]) => any
}

const LegendItem = ({ name, value, index, onCurrentHoverChange }: LegendItemProps) => {
  const handleMouseEnter = () => onCurrentHoverChange(index)
  const handleMouseLeave = () => onCurrentHoverChange(null)

  return (
    <Row as="li" className="pb-1" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Col>
        <div className="d-flex flex-row align-items-baseline">
          <Dot fill={colors[index]} width="10px" height="10px" />
          <span className="text-dark ml-2">
            {numberToPercent(value, {
              fractionDigits: 0,
            })}{' '}
            {name}{' '}
          </span>
        </div>
      </Col>
    </Row>
  )
}

export default LegendItem
