/* eslint-disable @typescript-eslint/no-explicit-any */
const pushToDataLayer = (data: any) => {
  // @ts-expect-error TS(2339) FIXME: Property 'dataLayer' does not exist on type 'Windo... Remove this comment to see the full error message
  if (!window.dataLayer) window.dataLayer = []

  // @ts-expect-error TS(2339) FIXME: Property 'dataLayer' does not exist on type 'Windo... Remove this comment to see the full error message
  window.dataLayer.push(data)
}

export default pushToDataLayer
