import React from 'react'

const labels = {
  expectedInvestment:
    'Hvor meget forventer du at investere igennem Norm Invest (i løbet af de første 12 måneder)?',
  investableAssets:
    'Hvor stor er din investerbare formue (kontanter, indestående i banken, og værdipapirer)?',
  debt: (
    <>
      Hvad er din samlede gæld? Du skal <strong>IKKE</strong> medregne boliggæld såsom
      realkreditlån, andelsboliglån eller andre boliglån, samt SU-lån. Gæld kan f.eks. være
      forbrugslån eller billån
    </>
  ),
  sourcesOfAssets: 'Hvad er de primære kilder til denne formue',
  sourceOfAssetsOther: 'Hvad er de primære kilder til din formue',
  sourceOfFunds: 'Dokumentation',
}

export const fields = Object.keys(labels)

export default labels
