import classNames from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'

import useSignedIn from '../../../../../../hooks/useSignedIn'
import { selectOnboardingState } from '../../../../../../store/current/portfolioId'
import Breakpoint from '../../../../../Breakpoint'
import CallToActionButton from '../CallToActionButton'
import useButtonType from '../CallToActionButton/useButtonType'
import SecondaryCallToActionButton from '../SecondaryCallToActionButton'

const FooterButtons = () => {
  const callToActionButtonType = useButtonType()
  const signedIn = useSignedIn()
  const onboardingState = useSelector(selectOnboardingState)

  const userAlreadyOnboarded = signedIn && onboardingState === 'saxo_onboarded'

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: (large: any) => Element; size: s... Remove this comment to see the full error message
    <Breakpoint size="lg">
      {(large: any) => (
        <>
          <div className="result-section">
            <div className="text-center">
              {large && !userAlreadyOnboarded && <CallToActionButton />}
              <SecondaryCallToActionButton />
            </div>
          </div>
          {!large && callToActionButtonType && (
            <div className="fixed-footer-container">
              <div className="fixed-footer z-index-layer-1 bg-primary">
                <CallToActionButton
                  variant="primary"
                  className={classNames('pl-2', 'w-100 text-left', {
                    'text-light': userAlreadyOnboarded,
                  })}
                />
              </div>
            </div>
          )}
        </>
      )}
    </Breakpoint>
  )
}

export default FooterButtons
