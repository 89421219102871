import React from 'react'

import LegendItem from './LegendItem'

export interface Props {
  data?: {
    name?: string
    value?: number
  }[]
  onCurrentHoverChange: (...args: any[]) => any
}

const Legend = ({ data, onCurrentHoverChange }: Props) => {
  const handleCurrentHoverChange = (value: any) => onCurrentHoverChange(value)

  return (
    <div className="d-flex justify-content-center justify-content-lg-start">
      <ul className="list-unstyled">
        {data &&
          data.map(({ name, value }, index) => (
            <LegendItem
              key={name}
              // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
              name={name}
              // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
              value={value}
              index={index}
              onCurrentHoverChange={handleCurrentHoverChange}
            />
          ))}
      </ul>
    </div>
  )
}

Legend.defaultProps = {
  data: [],
}

export default Legend
