import { updateFromApiResponse } from '@nord/ui/src/store/helpers'
import { createSlice } from '@reduxjs/toolkit'

import { createInvestmentProfile } from './actions'

const initialState = {}

// @ts-expect-error TS(2345) FIXME: Argument of type '{ name: "current.investmentProfi... Remove this comment to see the full error message
export const investmentProfileSlice = createSlice({
  name: 'current.investmentProfile',
  initialState,
  extraReducers: {
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [createInvestmentProfile.fulfilled]: updateFromApiResponse,
  },
})

export default investmentProfileSlice
