import { clamp } from 'lodash'
import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import { Overlay, PopoverContent } from 'react-bootstrap'

import useLockBodyScroll from '../../hooks/useLockBodyScroll'
import extractProps from '../../utilities/extractProps'
import MovingPopover from '../MovingPopover'

import generalRatioToValue from './generalRatioToValue'
import generalSnapRatioToBounds from './generalSnapRatioToBounds'
import generalSnapRatioToSteps from './generalSnapRatioToSteps'
import generalValueToRatio from './generalValueToRatio'
import Horizontal from './Horizontal'
import positionToRatio from './positionToRatio'
import Round from './Round'

const inputPropKeys = ['form', 'required', 'onChange']

const UPDATE_BUFFER = 10

const disableTouchScroll = (event: any) => event.preventDefault()

export interface RangeInputProps {
  round?: boolean
  fill?: boolean
  value?: string | number
  defaultValue?: string | number
  snapToSteps?: boolean
  min?: number
  max?: number
  step?: number
  steps?:
    | number[]
    | {
        [key: string]: number
      }
  onChange?: (...args: any[]) => any
  onValueChange?: (...args: any[]) => any
  popover?: boolean
  popoverFormat?: (...args: any[]) => any
  popoverProps?: {
    placement?: string
    className?: string
  }
}

const RangeInput = ({
  round,
  value,
  defaultValue,
  min,
  max,
  step,
  steps,
  snapToSteps,
  onValueChange,
  popover,
  popoverFormat,
  popoverProps,
  fill,
  ...otherProps
}: RangeInputProps) => {
  const snapRatioToSteps = useMemo(
    () => generalSnapRatioToSteps({ max, min, step, steps, snapToSteps }),
    [max, min, step, steps, snapToSteps],
  )
  const snapRatioToBounds = useMemo(
    () => generalSnapRatioToBounds({ max, min, step, steps, snapToSteps }),
    [max, min, step, steps, snapToSteps],
  )
  const valueToRatio = useMemo(
    () => generalValueToRatio({ min, max, steps, snapRatioToSteps }),
    [min, max, steps, snapRatioToSteps],
  )

  const ratioToValue = useMemo(
    () => generalRatioToValue({ min, max, step, steps }),
    [min, max, step, steps],
  )

  let initialRatio = 0
  if (defaultValue !== undefined) initialRatio = valueToRatio(defaultValue)
  const [ratio, setRatio] = useState(initialRatio)
  const [focus, setFocus] = useState(false)
  const [isSelecting, setIsSelecting] = useState(false)
  const [internalValue, setInternalValue] = useState(defaultValue || 0)
  const sliderRef = useRef()
  const wrapperRef = useRef()
  const handleRef = useRef()
  const debouncedUpdate = useRef(null)
  const valueRef = useRef(value)

  const [showPopover, setShowPopover] = useState(false)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'boolean | undefined' is not assi... Remove this comment to see the full error message
  useEffect(() => setShowPopover(popover), [popover])

  useLockBodyScroll(isSelecting)

  const handleChangeValue = useCallback(
    (newValue: any) => {
      let formattedValue = parseFloat(newValue)
      formattedValue = clamp(formattedValue, min || formattedValue, max || formattedValue)
      const ratioFromValue = valueToRatio(formattedValue)

      setRatio(ratioFromValue)
      setInternalValue(formattedValue || 0)
    },
    [max, min, valueToRatio],
  )

  const handleChangeRatio = useCallback(
    (rawRatio: any) => {
      const newRatio = snapRatioToSteps(rawRatio)
      const newValue = ratioToValue(newRatio)

      if (onValueChange !== undefined && newValue !== valueRef.current) {
        valueRef.current = newValue
        onValueChange(newValue)
      }

      setRatio(newRatio)
      setInternalValue(newValue)
    },
    [onValueChange, ratioToValue, snapRatioToSteps],
  )

  const handleChangeStep = useCallback(
    (increase: any) => {
      const [lowerRatio, upperRatio] = snapRatioToBounds(ratio)
      const newRatio = increase ? upperRatio : lowerRatio

      handleChangeRatio(newRatio)
    },
    [handleChangeRatio, ratio, snapRatioToBounds],
  )

  useEffect(() => {
    if (debouncedUpdate.current) clearTimeout(debouncedUpdate.current)

    if (value !== undefined && value !== internalValue) {
      // @ts-expect-error TS(2322) FIXME: Type 'Timeout' is not assignable to type 'null'.
      debouncedUpdate.current = setTimeout(() => {
        handleChangeValue(value)
      }, UPDATE_BUFFER)
    }
  }, [handleChangeValue, internalValue, value, valueToRatio])

  const handleClickOrDrag = (event: any) => {
    event.stopPropagation()
    setIsSelecting(true)

    const { clientX, clientY } = !event.touches ? event : event.touches[0]
    const {
      width: sliderWidth,
      left: sliderX,
      top: sliderY,
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    } = sliderRef.current.getBoundingClientRect()

    const rawRatio = positionToRatio({
      clientX,
      clientY,
      sliderWidth,
      sliderX,
      sliderY,
      round,
    })

    handleChangeRatio(rawRatio)
  }

  const handleEnd = () => {
    setIsSelecting(false)
    document.removeEventListener('mousemove', handleClickOrDrag)
    document.removeEventListener('mouseup', handleEnd)
    document.body.removeEventListener('touchmove', disableTouchScroll, {
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      passive: false,
    })
  }

  const handleStart = (event: any) => {
    setIsSelecting(true)
    handleClickOrDrag(event)
    document.addEventListener('mousemove', handleClickOrDrag)
    document.addEventListener('mouseup', handleEnd)
    document.body.addEventListener('touchmove', disableTouchScroll, {
      passive: false,
    })
  }

  const handleKeyDown = useCallback(
    (event: any) => {
      event.preventDefault()

      switch (event.key) {
        case 'ArrowRight':
        case 'ArrowUp':
          handleChangeStep(true)
          break
        case 'ArrowLeft':
        case 'ArrowDown':
          handleChangeStep(false)
          break
        default:
          break
      }
    },
    [handleChangeStep],
  )

  const handleBlur = () => {
    setFocus(false)
    document.removeEventListener('keydown', handleKeyDown)
  }

  const handleFocus = () => {
    setFocus(true)
  }

  useEffect(() => {
    const eventHandler = handleKeyDown
    if (focus) document.addEventListener('keydown', eventHandler)

    return () => document.removeEventListener('keydown', eventHandler)
  }, [focus, handleKeyDown])

  const [inputProps, wrapperProps] = extractProps(otherProps, inputPropKeys)
  const percentage = ratio * 100

  const calculatedProps = {
    onClickOrDrag: handleClickOrDrag,
    onEnd: handleEnd,
    onStart: handleStart,
    onFocus: handleFocus,
    onBlur: handleBlur,
    ratio,
    percentage,
    sliderRef,
    handleRef,
    fill,
  }

  let rangeContent
  if (round) {
    rangeContent = <Round {...calculatedProps} />
  } else {
    rangeContent = <Horizontal {...calculatedProps} />
  }

  return (
    // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
    <div {...wrapperProps} ref={wrapperRef}>
      {rangeContent}
      <input value={internalValue} type="hidden" {...inputProps} />
      {popover && (
        <Overlay
          // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
          placement={popoverProps.placement}
          // @ts-expect-error TS(2322) FIXME: Type 'undefined' is not assignable to type 'DOMCon... Remove this comment to see the full error message
          target={handleRef.current}
          transition={false}
          container={wrapperRef.current}
          show={showPopover}
        >
          {({ show: _show, ...placementProps }) => (
            <MovingPopover triggerValue={ratio} {...placementProps} {...popoverProps}>
              {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
              <PopoverContent className={popoverProps.className}>
                {/* @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message */}
                {popoverFormat(internalValue)}
              </PopoverContent>
            </MovingPopover>
          )}
        </Overlay>
      )}
    </div>
  )
}

RangeInput.defaultProps = {
  defaultValue: undefined,
  fill: undefined,
  max: 100,
  min: 0,
  onChange: undefined,
  onValueChange: undefined,
  popover: undefined,
  popoverFormat: (value: any) => value,
  popoverProps: {},
  round: undefined,
  snapToSteps: undefined,
  step: 1,
  steps: undefined,
  value: undefined,
}

export default RangeInput
