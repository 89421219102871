import { getConfig } from '@nord/ui/src/configuration'
import useApi, { useApiRequest } from '@nord/ui/src/hooks/useApi'
import classNames from 'classnames'
import sumBy from 'lodash/sumBy'
import React from 'react'

import useSignedIn from '../../../../hooks/useSignedIn'
import LoadingIcon from '../../../LoadingIcon'

import PensionProviderEntry from './PensionProviderEntry'
import styles from './styles.module.scss'
import TableColumn from './TableColumn'

const websiteUrl = getConfig('domains.website')

const NordEntry = () => {
  const isSignedIn = useSignedIn()
  const fetchPensionPortfolios = useApi('/portfolios', {
    withCredentials: true,
  })
  const { data: pensionPortfolios, called: portfoliosLoaded } = useApiRequest(
    fetchPensionPortfolios,
    {
      autoCall: isSignedIn,
      payload: {
        filter: { depotType: '!frie_midler' },
      },
    },
  )

  if (!isSignedIn) return null

  if (!portfoliosLoaded)
    return (
      <tbody>
        <tr className={classNames(styles.providerRow, styles.disabledPensionProviderRow)}>
          <TableColumn>
            {/* @ts-expect-error TS(2741) FIXME: Property 'children' is missing in type '{}' but re... Remove this comment to see the full error message */}
            <LoadingIcon />
          </TableColumn>
        </tr>
      </tbody>
    )

  const pensions = pensionPortfolios.map(({ totalValue, depotTypeTitle }: any) => ({
    transferable: 'no',
    transferableReason: null,
    value: totalValue,
    typeName: depotTypeTitle,
  }))

  return (
    <PensionProviderEntry
      key="NORD"
      // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '{ status:... Remove this comment to see the full error message
      transferable="no"
      transferableReason="Din pensionsopsparing hos NORD"
      name="NORD.investments"
      totalValue={sumBy(pensions, 'value')}
      referenceNumber="-"
      webSiteUrl={websiteUrl}
      pensions={pensions}
      mainPension={pensions[0]}
      onSelect={() => {}}
    />
  )
}

export default NordEntry
