import { numberToPercent } from '@nord/ui/src/utilities/numberFormatter'
import React from 'react'
import { Sector } from 'recharts'

// import styles from './index.module.scss'

const RADIAN = Math.PI / 180

export interface Props {
  cx: string | number
  cy: string | number
  midAngle: number
  innerRadius: string | number
  outerRadius: string | number
  startAngle: number
  endAngle: number
  fill: string
  payload: {
    name?: string
  }
  value: number
}

const renderActiveShape = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  fill,
  payload,
  value,
}: Props) => {
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  // @ts-expect-error TS(2365) FIXME: Operator '+' cannot be applied to types 'string | ... Remove this comment to see the full error message
  const sx = cx + (outerRadius + 10) * cos
  // @ts-expect-error TS(2365) FIXME: Operator '+' cannot be applied to types 'string | ... Remove this comment to see the full error message
  const sy = cy + (outerRadius + 10) * sin
  // @ts-expect-error TS(2365) FIXME: Operator '+' cannot be applied to types 'string | ... Remove this comment to see the full error message
  const mx = cx + (outerRadius + 30) * cos
  // @ts-expect-error TS(2365) FIXME: Operator '+' cannot be applied to types 'string | ... Remove this comment to see the full error message
  const my = cy + (outerRadius + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  return (
    <g>
      {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        stroke={fill}
      />
      {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        // @ts-expect-error TS(2365) FIXME: Operator '+' cannot be applied to types 'string | ... Remove this comment to see the full error message
        innerRadius={outerRadius + 6}
        // @ts-expect-error TS(2365) FIXME: Operator '+' cannot be applied to types 'string | ... Remove this comment to see the full error message
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} dx={10} y={ey} textAnchor={textAnchor} fill="#333">
        {payload.name}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        dx={10}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {numberToPercent(value, { fractionDigits: 0 })}
      </text>
    </g>
  )
}

export default renderActiveShape
