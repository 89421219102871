const labels = {
  citizenOrTaxpayerUSA: 'Jeg er statsborger og/eller skattepligtig i USA',
  americanCitizen: 'Jeg er amerikansk statsborger',
  americanTaxpayer: 'Jeg er skattepligtig i USA',
  americanTaxNumber: 'Skattenummer i USA',
  taxpayerInOtherCountry: 'Jeg er skattepligtig i andre lande (udover Danmark og USA)',
}

export const fields = ['additionalTaxableCountries', ...Object.keys(labels)]

export default labels
