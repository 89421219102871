import akademikerPension from './akademiker-pension.svg'
import alka from './alka.svg'
import almBrandPension from './alm-brand.svg'
import apPension from './ap-pension.jpg'
import atp from './atp.png'
import danicaPension from './danica-pension.png'
import danskeBank from './danske-bank.svg'
import forenedeGruppeliv from './forenede-gruppeliv.png'
import industriensPension from './industriens-pension.png'
import jyskeBank from './jyske-bank.png'
import laegernesPension from './laegernes-pension.svg'
import laerernesPension from './laerernes-pension.svg'
import ldFonde from './ld-fonde.svg'
import nordea from './nordea.png'
// @ts-expect-error TS(2307) FIXME: Cannot find module './nykredit.webp' or its corres... Remove this comment to see the full error message
import nykredit from './nykredit.webp'
import penSam from './pen-sam.svg'
import pensionDanmark from './pension-danmark.svg'
import pfaPension from './pfa-pension.png'
import pfs from './pfs.png'
import pka from './pka.svg'
import pplus from './pplus.svg'
import sampension from './sampension.jpg'
import seb from './seb.jpg'
import skandia from './skandia.svg'
import sparNordBank from './spar-nord.png'
import sydbank from './sydbank.svg'
import topdanmark from './topdanmark.svg'
import velliv from './velliv.svg'

const logos = {
  1: atp,
  6: industriensPension,
  8: pensionDanmark,
  9: skandia,
  10: pfaPension,
  11: laerernesPension,
  12: ldFonde,
  15: apPension,
  25: pka,
  30: pka,
  31: pka,
  36: pplus,
  48: penSam,
  54: danicaPension,
  55: danskeBank,
  56: jyskeBank,
  57: nordea,
  58: velliv,
  59: alka,
  63: topdanmark,
  65: sampension,
  66: sampension,
  67: sampension,
  // 68: paedagogernesPension,
  // 69: isp,
  // 70: arkitektPension,
  // 71: pjd,
  72: akademikerPension,
  73: almBrandPension,
  75: laegernesPension,
  // 78: pka,
  95: sparNordBank,
  // 177: vestjyskBank,
  180: nykredit,
  182: seb,
  // 226: landboBanken,
  // 227: skjernBank,
  230: sydbank,
  // 231: fynskeBank,
  243: forenedeGruppeliv,
  244: forenedeGruppeliv,
  245: forenedeGruppeliv,
  246: forenedeGruppeliv,
  248: forenedeGruppeliv,
  525: pfs,
}

export default logos
