import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import useQuery from '@nord/ui/src/hooks/useQuery'
import { selectInitialLoading } from '@nord/ui/src/store/current/loading'
import {
  selectCurrentPortfolio,
  selectCurrentPortfolioId,
} from '@nord/ui/src/store/current/portfolioId'
import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'

import useFlow from '../../../../../../hooks/useFlow'
import useReadyToOnboard from '../../../../../../hooks/useReadyToOnboard'
import useSignedIn from '../../../../../../hooks/useSignedIn'
import { selectOnboardingState } from '../../../../../../store/current/portfolioId'
import ResultPageContext from '../ResultPageContext'

const useButtonType = () => {
  const loading = useSelector(selectInitialLoading)
  const signedIn = useSignedIn()
  const flow = useFlow()
  const onboardingState = useSelector(selectOnboardingState)
  const emailParam = useQuery('email')

  // @ts-expect-error TS(2339) FIXME: Property 'showSignupModal' does not exist on type ... Remove this comment to see the full error message
  const { showSignupModal, riskScore, esg } = useContext(ResultPageContext)
  const portfolioId = useSelector(selectCurrentPortfolioId)
  // @ts-expect-error TS(2339) FIXME: Property 'riskScore' does not exist on type 'unkno... Remove this comment to see the full error message
  const { riskScore: initialRiskScore, esg: initialEsg } = useObjectSelector(selectCurrentPortfolio)

  const userAlreadyOnboarded = signedIn && onboardingState === 'saxo_onboarded'

  const readyToOnboard = useReadyToOnboard()

  const buttonType = useMemo(() => {
    if (loading) return null
    if (!showSignupModal) return 'signUp'
    if (readyToOnboard) return 'startOnboarding'
    if (
      flow &&
      portfolioId &&
      userAlreadyOnboarded &&
      (riskScore !== initialRiskScore || esg !== initialEsg)
    )
      return 'updateRiskScore'
    if (
      flow &&
      portfolioId &&
      userAlreadyOnboarded &&
      (riskScore === initialRiskScore || esg === initialEsg)
    )
      return null

    if (emailParam) return 'signIn'

    return 'startInvestmentPlan'
  }, [
    emailParam,
    esg,
    flow,
    initialEsg,
    initialRiskScore,
    loading,
    portfolioId,
    readyToOnboard,
    riskScore,
    showSignupModal,
    userAlreadyOnboarded,
  ])

  return buttonType
}

export default useButtonType
