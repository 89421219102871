import { buildDebugLog } from '@nord/ui/src/utilities/debugLog'

const debugLog = buildDebugLog('extractOnboardingState')

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extractOnboardingState = (currentUser: any, currentPortfolio = undefined) => {
  // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type '{}'.
  const { id: portfolioId } = currentPortfolio || {}

  const { onboardingState: userOnboardingState } = currentUser || {}
  // @ts-expect-error TS(2339) FIXME: Property 'userOnboardingState' does not exist on t... Remove this comment to see the full error message
  const { userOnboardingState: portfolioOnboardingState } = currentPortfolio || {}

  // If portfolio onboarding state present
  debugLog.logIf(!!portfolioOnboardingState, `portfolio - ${portfolioOnboardingState}`, {
    currentUser,
    currentPortfolio,
    portfolioId,
    userOnboardingState,
    portfolioOnboardingState,
  })
  if (portfolioOnboardingState) return portfolioOnboardingState

  // If not in portfolio scope
  debugLog.logIf(!!userOnboardingState, `no portfolio - ${userOnboardingState}`, {
    currentUser,
    currentPortfolio,
    portfolioId,
    userOnboardingState,
  })

  if (userOnboardingState) return userOnboardingState

  debugLog.logIf(true, `no user - user_created`, {
    currentUser,
    currentPortfolio,
  })

  // If no data
  return 'user_created'
}

export default extractOnboardingState
